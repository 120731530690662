import { useState, useEffect } from "react";
import { useGetStatesQuery } from './../../hooks/apiSlice';
import { useSearchParams } from "react-router-dom";

const StateSelection = ({ StateSelectionHandler }) => {
  const [checked, setChecked] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: dataStates, isLoading: isLoadingStates } = useGetStatesQuery()

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, parseInt(event.target.value)];
    } else {
      updatedList.splice(checked.indexOf(parseInt(event.target.value)), 1);
    }

    setChecked(updatedList);
    StateSelectionHandler(updatedList);

    if (updatedList.length === 0)
      searchParams.delete("State")
    else
      searchParams.set("State", updatedList);

    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (searchParams.get("State")) {
      setChecked(searchParams.get("State").split(",").map((n) => parseInt(n)));
      StateSelectionHandler(searchParams.get("State").split(",").map((n) => parseInt(n)));
    }
  }, []);



  return (
    <div className="horizontal-scroll-bar">
      {
        isLoadingStates ? <h6>loading...</h6> :
          dataStates.payload.map((item, index) => (
            <div key={index}>
              <label className="form-check-label">
                <input className="form-check-input" checked={checked.some(element => { return element === item.stateId })} value={item.stateId} type="checkbox" onChange={(e) => handleCheck(e)} />&nbsp;&nbsp;{item.stateName}
              </label>
            </div>
          ))}
    </div>
  );
};

export default StateSelection;
