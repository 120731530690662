

const RatingStar = ({ starCount }) => {
    return [...Array(5)].map((el, i) =>
      i < starCount && i + 1 > starCount ? (
        <span key={i} className="fa fa-star fa-star-half-alt checked" /> // if ratingAverage has remainder 
      ) :
        i < starCount ? (
          <span key={i} className="fa fa-star checked" />
        ) : (
          <span key={i} className="fa-regular fa-star" />
        )
    );
  };

  export default RatingStar



  export const StarRating = ({ count, value, onChange, activeColor }) => {

    const handleChange = (value) => {
      onChange(value + 1);
    }

    return (
      <div>
        {Array.from({ length: 5 }).map((s, index) => {
          let style = '#ddd';
          if (index < value) {
            style = activeColor;
          }
          return (
            <i key={index} style={{ cursor: 'pointer', color: style, fontSize: 24 }} onClick={() => handleChange(index)} className="fa-solid fa-star checked" ></i>
          )
        })}
      </div>
    )
  }