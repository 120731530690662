
import { DateRangePicker } from "react-date-range";
import { useState } from "react";
import moment from 'moment'
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addYears } from 'date-fns';

export const DateRangeFilter = ({ onChange, open, setOpen }) => {
    const [state, setState] = useState([
      {
        startDate: new Date("2021-01-02"),
        endDate: addYears(new Date(), 1),
        key: 'selection'
      }
    ]);
  
    const handleOnChange = (ranges) => {
      const { selection } = ranges;
      onChange(selection);
      setState([selection]);
    };
  
    const formatDate = (date) => moment(date).format("YYYY-MM-DD");
    const Endate = state[0].endDate === null ? formatDate(state[0].startDate) : formatDate(state[0].endDate);
    const startDate = state[0].startDate ? formatDate(state[0].startDate) : "";
  
    return (
      <div style={{ 'position': 'relative' }}>
        <input id="txtDateRange"
          value={startDate.toString() + " - " + Endate.toString()}
          readOnly
          className="form-control"
          onClick={() => setOpen(!open)}
        />
  
        {open && (
          <DateRangePicker
            onChange={handleOnChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            editableDateInputs={true}
            dateDisplayFormat="MM-dd-yyyy"
            ranges={state}
            direction="horizontal"
          />
        )}
      </div>
    );
  };