import React, { useState } from "react";
import { useGetContactQuery } from '../hooks/apiSlice';
import moment from 'moment'


const Contact = () => {

  const [page, setPage] = useState(1)
  const [sortField, setSortField] = useState("CreatedOn");
  const [order, setOrder] = useState("desc");

  const { data: dataContact, isLoading: isLoadingContact, isFetching } = useGetContactQuery({pageNum: page, sortColumn: sortField, sortDirection: order})

  const handleSortingChange = (accessor) => {
    setSortField(accessor);
    setOrder(order === "asc" ? "desc" : "asc");
  };

  function getColumnHeader(columnName, sortString) {
    const arrowDirection = sortField === sortString && order === "asc" ? <i className="fas fa-arrow-up"></i> : sortField === sortString && order === "desc" ? <i className="fas fa-arrow-down"></i> : ""

    return (
      <span onClick={() => handleSortingChange(sortString)}> {isFetching ? <span className="ms-2 spinner-border spinner-border-sm"></span> : columnName} {arrowDirection}</span>
    )
  }

  return (
    <>
      {isLoadingContact ? "" :

        <div>

          <div className="container-fluid">
          <div className="row">
              <div className="col-md-12">
                <h4><b><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;Contact Form Messages</b></h4>
              </div>
            </div>                 
            <div className="row">
              <div className="col-md-12">
                <div className="strpied-tabled-with-hover card">

                  <div className="table-full-width table-responsive px-0 card-body">

                    <table className="table-hover table-striped table"><thead>
                      <tr>
                        <th>{getColumnHeader('Name', 'Name')}</th>
                        <th>{getColumnHeader('Email', 'Email')}</th>
                        <th>{getColumnHeader('Phone', 'Phone')}</th>
                        <th>{getColumnHeader('Add On', 'CreatedOn')}</th>
                        <th>{getColumnHeader('Consent Use Of Info.', 'ConsentUseOfInfo')}</th>                        
                        <th>{getColumnHeader('Message', 'Message')}</th>
                      </tr>
                    </thead>
                      <tbody>
                        {dataContact.data.length === 0 ? <tr><td colSpan="6" className="text-center p-4">No record found</td></tr> :
                          dataContact.data.map((cont, index) =>
                            <tr key={index}>
                              <td>{cont.name}</td>
                              <td>{cont.email}</td>
                              <td>{cont.phone}</td>
                              <td style={{'width':'180px'}}>{moment(cont.createdOn).format("MM-DD-YYYY h:mm A")}</td>
                              <td className="text-center">{cont.consentUseOfInfo ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-sharp fa-solid fa-circle-xmark text-danger"></i>}</td>
                              <td style={{'fontSize':'0.8rem'}}>{cont.message}</td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>


                  <div className="pagination pb-3">

                    <button className="prev-btn"
                      onClick={() => setPage((prev) => prev - 1)}
                      disabled={page === 1 || isFetching}
                    >
                      {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-left"></i>}
                    </button>
                    <button className="next-btn"
                      onClick={() => setPage((prev) => prev + 1)}
                      disabled={page >= dataContact.totalPages || isFetching}
                    >
                      {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-right"></i>}
                    </button>
                    <div className="page">
                      {`${page} / ${dataContact.totalPages}`}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}


export default Contact