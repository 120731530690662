import Navbar from '../components/Navbar'
import { useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import SignupPanel from './../components/SignupPanel';



const Signup = () => {

  const location = useLocation()

  return (

    <div>
      <div>
        <ToastContainer />
      </div>
      <Navbar />

      <section className="mb-4 pb-5 bg-pink*">
        <div className="container">

          <div className="row align-items-center">

            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="standardHeading">

                {(location.state.from === 'business') &&
                  <h2><strong>Add business,</strong></h2>
                }

                <h3>Sign-up to get started!</h3>
              </div>



              <SignupPanel userType={location.state.from} parent="SignupPage" />

              <p className="text-left newlink mt-3">Already a member?  <Link to="/signin" style={{ color: "black" }}>Sign-in</Link> </p>

            </div>
            <div className="offset-md-1 col-lg-7 col-md-12 col-sm-12 col-12">
              <img src={'/images/' + ((location.state.from === 'business') ? "signup-vendor.png" : "siginin.jpg")} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      
      </section>
      <Footer></Footer>
    </div>
  )
}


export default Signup