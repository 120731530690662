import Navbar from "../components/Navbar";
import Footer from '../components/Footer'
import { useGetFavoriteQuery } from '../hooks/apiSlice';
import CatererCard from '../components/CatererCard';
import AuthService from "../hooks/Auth";

const Favorite = () => {

    const { data: dataFavorite, isLoading: isLoadingFavorite } = useGetFavoriteQuery()

    return (

        <div>
            <Navbar />

            <section className="pt-4* listing-options bg-pink">
                <div className="container">
                    <div className="row m-0">
                        <h2 className="standardHeading m-0 p-0">My Favorites</h2>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {
                                isLoadingFavorite ?
                                    <div className="text-center">
                                        <div className="mt-5 spinner-border text-danger" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    dataFavorite.payload.length > 0 ?
                                        dataFavorite.payload.map((item, index) => (
                                            <CatererCard item={item} key={index} />
                                        ))
                                        :
                                        <div className="text-center mb-5">
                                            <div className="mt-5 text-danger" role="status">
                                                <span >No favorite caterers selected</span>
                                            </div>
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
    )
}

export default Favorite

