import React, { useState } from "react";
import { useGetUserProfileByUserProfileTypeQuery } from '../hooks/apiSlice';
import moment from 'moment'


const Customers = () => {

  //const emailInputRef = useRef(null);

  const [page, setPage] = useState(1)
  const [searchCustomer, setSearchCustomer] = useState('')
  const [searchEmail, setSearchEmail] = useState('')

  const [sortField, setSortField] = useState("FirstName");
  const [order, setOrder] = useState("asc");

  const { data: dataUserProfileByUserProfileType, isLoading: isLoadingUserProfileByUserProfileType, isFetching } = useGetUserProfileByUserProfileTypeQuery({ type: "customer", pageNum: page, customerName: searchCustomer, email: searchEmail, sortColumn: sortField, sortDirection: order })

  const handleSearch = () => {
    setSearchCustomer(document.getElementById('cutomerNameSearch').value);
    setSearchEmail(document.getElementById('emailSearch').value);
    setPage(1);
  }

  const handleSortingChange = (accessor) => {
    setSortField(accessor);
    setOrder(order === "asc" ? "desc" : "asc");
    handleSearch();
  };


  function getColumnHeader(columnName, sortString) {
    const arrowDirection = sortField === sortString && order === "asc" ? <i className="fas fa-arrow-up"></i> : sortField === sortString && order === "desc" ? <i className="fas fa-arrow-down"></i> : ""

    return (
      <span onClick={() => handleSortingChange(sortString)}> {isFetching ? <span className="ms-2 spinner-border spinner-border-sm"></span> : columnName} {arrowDirection}</span>
    )

  }



  return (
    <>
      {isLoadingUserProfileByUserProfileType ? "" :

        <div>

          <div className="container-fluid">
          <div className="row">
              <div className="col-md-12">
                <h4><b><i className="fas fa-solid fa-users"></i>&nbsp;Customers</b></h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="strpied-tabled-with-hover card">
                  <div className="card-header"><div className="row">
                    <div className="col-md-12">

                      <div className="row">
                        <div className="col-md-6"><div>
                          <input placeholder="Customer Name" type="text" className="form-control" id="cutomerNameSearch" name="cutomerNameSearch" /></div>
                        </div>
                        <div className="col-md-4"><div>
                          <input placeholder="Customer Email" type="email" name="emailSearch" id="emailSearch" className="form-control" /></div>
                        </div>
                        <div className="col-md-2">
                          <button type="submit" className="btn-fill pull-right secondary-btn btn btn-info btn-small" onClick={handleSearch}>
                            <i className="fa-sharp fa-solid fa-magnifying-glass"></i>
                          </button>
                        </div>
                      </div>
                      <div className="clearfix"></div>

                    </div>
                  </div>
                  </div>
                  <div className="table-full-width table-responsive px-0 card-body">

                    <table className="table-hover table-striped table"><thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>{getColumnHeader('Customer ID', 'UserProfileID')}</th>
                        <th>{getColumnHeader('Customer Name', 'FirstName')}</th>
                        <th>{getColumnHeader('Email', 'Email')}</th>
                        <th>{getColumnHeader('Registration Date', 'CreatedOn')}</th>
                      </tr>
                    </thead>
                      <tbody>
                        {dataUserProfileByUserProfileType.data.length === 0 ? <tr><td colSpan="5" className="text-center p-4">No record found</td></tr> :
                          dataUserProfileByUserProfileType.data.map((usProf, index) =>
                            <tr key={index}>
                              <td className="text-center"><i className="fas fa-user-circle"></i></td>
                              <td>{usProf.userProfileId}</td>
                              <td>{usProf.firstName}</td>
                              <td>{usProf.email}</td>
                              <td>{moment(usProf.createdOn).format("MM-DD-YYYY h:mm A")}</td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>


                  <div className="pagination pb-3">

                    <button className="prev-btn"
                      onClick={() => setPage((prev) => prev - 1)}
                      disabled={page === 1 || isFetching}
                    >
                      {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-left"></i>}
                    </button>
                    <button className="next-btn"
                      onClick={() => setPage((prev) => prev + 1)}
                      disabled={page >= dataUserProfileByUserProfileType.totalPages || isFetching}
                    >
                      {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-right"></i>}
                    </button>
                    <div className="page">
                      {`${page} / ${dataUserProfileByUserProfileType.totalPages}`}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}


export default Customers