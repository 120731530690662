
import SpecialtySelect from "./OffersSelect/SpecialtySelect"
import EventTypeSelect from "./OffersSelect/EventTypeSelect"
import AddonServiceSelect from "./OffersSelect/AddonServiceSelect"
import ServingOptionSelect from "./OffersSelect/ServingOptionSelect"
import CertificationSelect from "./OffersSelect/CertificationSelect"
import CuisineSelect from "./OffersSelect/CuisineSelect"
import DietarySelect from "./OffersSelect/DietarySelect"

const Offers = (SpecialitySelectionHandler) => {




    return (
        <div>
            <div className="row d-none d-lg-block">
                <div className="signin-up-wrapper">
                    <h3>
                        <strong>What we offer</strong>
                    </h3>
                </div>
            </div>

            <div className="row what-we-offer form-log">

                 <CuisineSelect />
                <DietarySelect />
                <SpecialtySelect />
                <EventTypeSelect />
                <AddonServiceSelect />
                <ServingOptionSelect />
               <CertificationSelect />

            </div>
            <div className="py-3">
                <button className="btn btn-danger" >Save</button>
            </div>

        </div>
    )
}

export default Offers



