import React, { useState } from "react";
import {
  useGetCompanyListQuery, useGetDietariesQuery, useGetCuisinesQuery, useGetSpecialitiesQuery, useGetStatesQuery,
  useUpdateCompanyStatusMutation, useAddCompanyMutation, useInitiateCompanyPanelMutation, useCloneCompanyMutation
} from '../hooks/apiSlice';
import moment from 'moment'
import { default as ReactSelect } from "react-select";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
// import "react-date-range/dist/styles.css"; // main css file
// import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangeFilter } from '../shared/DateRangeFilter';
//import { addYears } from 'date-fns'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import validator from 'validator'

let varCuisines, varDietaries, varSpecialities

const CompanyManagement = () => {
  const [page, setPage] = useState(1)
  const [sortField, setSortField] = useState("CompanyId");
  const [order, setOrder] = useState("desc");

  const [showCloneModel, setShowCloneModel] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [showAddModel, setAddModel] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({ companyName: "" });

  const [cloneBusinessName, setCloneBusinessName] = useState('');
  const [cloneBusinessId, setCloneBusinessId] = useState();


  const [searchCompany, setSearchCompany] = useState('')
  const [searchEmail, setSearchEmail] = useState('')
  const [searchContact, setSearchContact] = useState('')
  const [selectedSubscriptionPlanId, setSelectedSubscriptionPlanId] = useState(0);
  const [dateRange, setDateRange] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [selectedStateId, setSelectedStateId] = useState(0);
  const [searchZipCode, setSearchZipCode] = useState('');
  const [verificationStatus, setVerificationStatus] = useState(0);
  const [listingStatus, setListingStatus] = useState(0);
  const [addedByHalalCaters, setAddedByHalalCaters] = useState(true);
  const [addedByCompanyUser, setAddedByCompanyUser] = useState(true);


  const [selectedCuisines, setSelectedCuisines] = useState('');
  const [selectedDietaries, setSelectedDietaries] = useState('');
  const [selectedSpecialities, setSelectedSpecialities] = useState('');

  const [missingBusProfile, setMissingBusProfile] = useState('');
  const [missingLogo, setMissingLogo] = useState('');
  const [missingGallery, setMissingGallery] = useState('');
  const [missingMenu, setMissingMenu] = useState('');

  const { data: dataCompanySearch, isLoading: isLoadingCompanySearch, isFetching } = useGetCompanyListQuery({ companyName: searchCompany, email: searchEmail, contactNumber: searchContact, city: searchCity, stateId: selectedStateId, zipCode: searchZipCode, verificationStatus: verificationStatus, listingStatus: listingStatus, subscriptionPlanId: selectedSubscriptionPlanId, dateRange: dateRange, cuisineIds: selectedCuisines, dietaryIds: selectedDietaries, specialtyIds: selectedSpecialities, missingBusProfile: missingBusProfile, missingLogo: missingLogo, missingGallery: missingGallery, missingMenu: missingMenu, includeAddbyHalalCater: addedByHalalCaters, includeAddByCompanyUser: addedByCompanyUser, sortColumn: sortField, sortDirection: order, pageNum: page })
  const { data: dataDietary, isLoading: isLoadingDietary } = useGetDietariesQuery()
  const { data: dataCuisine, isLoading: isLoadingCuisine } = useGetCuisinesQuery()
  const { data: dataSpecialty, isLoading: isLoadingSpecialty } = useGetSpecialitiesQuery()

  const [InitiateCompanyPanel] = useInitiateCompanyPanelMutation()
  const { data: dataStates, isLoading: isLoadingStates } = useGetStatesQuery()
  const [updateCompany] = useUpdateCompanyStatusMutation()
  const [addCompany] = useAddCompanyMutation()
  const [cloneCompany] = useCloneCompanyMutation()


  const handleSearch_onEnter = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  }

  const handleSearch = () => {
    setSearchCompany(document.getElementById('companyNameSearch').value);
    setSearchEmail(document.getElementById('emailSearch').value);
    setSearchContact(document.getElementById('contactSearch').value);

    setSelectedSubscriptionPlanId(0);
    setDateRange(document.getElementById('txtDateRange').value)

    setSearchCity(document.getElementById('city').value)
    setSelectedStateId(document.getElementById('stateId').value);
    setSearchZipCode(document.getElementById('zipcode').value)

    setVerificationStatus(document.getElementById('isVerified').value)
    setListingStatus(document.getElementById('listingStatus').value)

    setSelectedCuisines(varCuisines);
    setSelectedDietaries(varDietaries);
    setSelectedSpecialities(varSpecialities);

    setMissingBusProfile(document.getElementById('missingBusProfile').value);
    setMissingLogo(document.getElementById('missingLogo').value);
    setMissingGallery(document.getElementById('missingGallery').value);
    setMissingMenu(document.getElementById('missingMenu').value);

    setAddedByCompanyUser(document.getElementById('addedByCompanyUser').checked);
    setAddedByHalalCaters(document.getElementById('addedByHalalCater').checked);
    setPage(1);
  }

  const handleReset = () => {
    document.getElementById('companyNameSearch').value = '';
    document.getElementById('emailSearch').value = '';
    document.getElementById('contactSearch').value = '';
    document.getElementById('subscriptionPlanId').value = 0;
    //document.getElementById('txtDateRange').value
    document.getElementById('city').value = '';
    document.getElementById('stateId').value = 0;
    document.getElementById('zipcode').value = '';
    document.getElementById('isVerified').value = 0;
    document.getElementById('missingBusProfile').value = '';
    document.getElementById('missingLogo').value = '';
    document.getElementById('missingGallery').value = '';
    document.getElementById('missingMenu').value = '';
    document.getElementById('addedByCompanyUser').checked = true;
    document.getElementById('addedByHalalCater').checked = true;

    setSearchCompany('')
    setSearchEmail('')
    setSearchContact('')
    setSelectedSubscriptionPlanId(0);
    setDateRange('');
    setSearchCity('');
    setSelectedStateId(0);
    setSearchZipCode('');
    setVerificationStatus(0);
    setSelectedCuisines('');
    setSelectedDietaries('');
    setSelectedSpecialities('');
    setMissingBusProfile('');
    setMissingLogo('');
    setMissingGallery('');
    setMissingMenu('');

    setAddedByCompanyUser(true);
    setAddedByHalalCaters(true);
  }

  const handleSortingChange = (accessor) => {
    setSortField(accessor);
    setOrder(order === "" ? "desc" : "");
    handleSearch();
  };

  const navigateToCompanyPanel = (e) => {
    InitiateCompanyPanel(e);
  }

  function getColumnHeader(columnName, sortString) {
    const arrowDirection = sortField === sortString && order === "" ? <i className="fas fa-arrow-up"></i> : sortField === sortString && order === "desc" ? <i className="fas fa-arrow-down"></i> : ""

    return (
      <span onClick={() => handleSortingChange(sortString)}> {isFetching ? <span className="ms-2 spinner-border spinner-border-sm"></span> : columnName} {arrowDirection}</span>
    )

  }


  function EditCompanyInfo() {
    const [companyTitle, setCompanyTitle] = useState(companyInfo.companyTitle);
    const [slug, setSlug] = useState(companyInfo.companySlug);
    const [isVerified, setIsVerified] = useState(companyInfo.isVerified);
    const [isVetted, setIsVetted] = useState(companyInfo.isVetted);

    const updateHandler = (e) => {
      updateCompany({ companyId: companyInfo.companyId, companyTitle: companyTitle, companySlug: slug, isVerified: isVerified, isVetted: isVetted });
    }





    return (
      <Modal show={showModel} >
        <Modal.Header closeButton>
          <Modal.Title>{companyInfo.companyName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-2">
            <span className="p-0">TITLE</span>
            <input type="text" onChange={(e) => setCompanyTitle(e.target.value)} required className="form-control" value={companyTitle} maxLength="200" />
          </div>

          <div className="row m-2 mt-3">
            <span className="p-0">SLUG</span>
            <input type="text" onChange={(e) => setSlug(e.target.value)} required className="form-control" value={slug} maxLength="200" />
          </div>

          <hr className="my-4" />

          <div className="row m-2">
            <div className="form-check mb-3">
              <input style={{ 'width': '36px', 'height': '18px', 'borderColor': '#000' }} onChange={(e) => setIsVerified(e.target.checked)} checked={isVerified} type="checkbox" id="approvalStatus" className="form-check-input" />
              <label className="form-check-label" htmlFor="approvalStatus">&nbsp;Verified</label>
            </div>
          </div>

          <div className="row m-2">
            <div className="form-check mb-3">
              <input style={{ 'width': '36px', 'height': '18px', 'borderColor': '#000' }} onChange={(e) => setIsVetted(e.target.checked)} checked={isVetted} type="checkbox" id="isVetted" className="form-check-input" />
              <label className="form-check-label" htmlFor="isVetted">&nbsp;Vetted</label>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button className="adminButton" onClick={() => { setShowModel(false) }}>
            Close
          </button>
          <button className="adminButton" onClick={() => {
            setShowModel(false);
            updateHandler();
          }}>Save</button>
        </Modal.Footer>
      </Modal>
    )
  }

  function AddCompany() {
    const [companyName, setCompanyName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const addCompanyHandler = () => {
      addCompany({ companyName: companyName, 'userProfile.email': email, 'userProfile.firstName': name })
        .then(
          setAddModel(false)
        );
    }

    return (
      <Modal show={showAddModel} >
        <Modal.Header>
          <Modal.Title>ADD BUSINESS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-2">
            <span className="p-0" >BUSINESS NAME</span>
            <input type="text" onChange={(e) => setCompanyName(e.target.value)} required className={companyName === "" ? "form-control border border-danger" : "form-control"} value={companyName} maxLength="300" />
          </div>
          <div className="row m-2">
            <span className="p-0">NAME</span>
            <input type="text" onChange={(e) => setName(e.target.value)} required className={name === "" ? "form-control border border-danger" : "form-control"} value={name} maxLength="300" />
          </div>
          <div className="row m-2">
            <span className="p-0">EMAIL</span>
            <input type="text" onChange={(e) => setEmail(e.target.value)} required placeholder="example@example.com" className={email === "" || !validator.isEmail(email) ? "form-control border border-danger" : "form-control"} value={email} maxLength="300" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="adminButton" onClick={() => { setAddModel(false) }}>
            Close
          </button>
          <button disabled={companyName === "" || name === "" || email === "" || !validator.isEmail(email)} className="adminButton" onClick={() => {
            addCompanyHandler();
          }}>Save</button>
        </Modal.Footer>
      </Modal>
    )
  }


  function CloneCompany() {

    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [stateId, setStateId] = useState('0');
    const [phoneNumber, setPhoneNumber] = useState('');
    const phoneMaskGenerator = createDefaultMaskGenerator('(999) 999-9999');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [orderPageURL, setOrderPageURL] = useState('');
    const [description, setDescription] = useState('');



    const cloneCompanyHandler = () => {
      cloneCompany({ companyIdToClone: cloneBusinessId, streetAddress: address, city: city, stateId: stateId, zipCode: zip, 'userProfile.email': email, 'userProfile.firstName': name, contactNumber: phoneNumber, description: description, companyTitle: title, orderPageURL: orderPageURL })
        .then((response) => {
          setShowCloneModel(false)
          if (!response.data.success)
            toast.error(response.data.message, { position: toast.POSITION.TOP_CENTER });
        });
    }

    return (
      <Modal show={showCloneModel} >
        <Modal.Header>
          <Modal.Title><i className="fas fa-clone" title="clone"></i>&nbsp;CLONE <b>'{cloneBusinessName}'</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row pt-2 margin0 form-log">

            <div className="col-lg-12 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
              <input type="text" placeholder="Street Address" aria-label="Street Address"
                onChange={(e) => setAddress(e.target.value)}
                required
                className={address === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                value={address}
                maxLength="100" />
            </div>

            <div className="col-lg-6 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
              <input type="text" placeholder="City" aria-label="City"
                onChange={(e) => setCity(e.target.value)}
                required
                className={city === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                value={city}
                maxLength="60" />
            </div>

            <div className="col-lg-6 col-md-12 col-12 pe-lg-0 p-lg-2 px-md-0">
              {isLoadingStates ? <h1>loading...</h1> :
                <select value={stateId}
                  className={stateId === "0" ? "combobox dropdown border border-danger" : "combobox dropdown"}
                  onChange={(e) => setStateId(e.target.value)}>
                  <option value="0">State</option>
                  {
                    dataStates.payload.map((st, index) =>
                      <option key={index} value={st.stateId} >{st.stateName}</option>
                    )
                  }
                </select>}
            </div>

            <div className="col-lg-6 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
              <input className="form-control form-control-lg" type="text" placeholder="ZIP / Postal Code" aria-label="ZIP / Postal Code"
                onChange={(e) => setZip((v) => (e.target.validity.valid ? e.target.value : v))}
                pattern="[0-9]*"
                value={zip}
                maxLength="10" />
            </div>
            <div className="col-lg-6 col-md-12 col-12 pe-lg-0 p-lg-2 px-md-0">
              <MaskedInput type="tel" keepMask="true" maskGenerator={phoneMaskGenerator} value={phoneNumber} onChange={setPhoneNumber} placeholder="Contact Number" aria-label="Business Phone Number" className="form-control form-control-lg" />
            </div>
            <hr />
            <div className="col-lg-6 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
              <span className="p-0">NAME</span>
              <input type="text" onChange={(e) => setName(e.target.value)} required className={name === "" ? "form-control border border-danger" : "form-control"} value={name} maxLength="300" />
            </div>
            <div className="col-lg-6 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
              <span className="p-0">EMAIL</span>
              <input type="text" onChange={(e) => setEmail(e.target.value)} required placeholder="example@example.com" className={email === "" || !validator.isEmail(email) ? "form-control border border-danger" : "form-control"} value={email} maxLength="300" />
            </div>
            <div className="col-lg-6 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
              <span className="p-0">TITLE</span>
              <input type="text" onChange={(e) => setTitle(e.target.value)} className="form-control" value={title} />
            </div>
            <div className="col-lg-6 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
              <span className="p-0">Order Page URL</span>
              <input type="text" onChange={(e) => setOrderPageURL(e.target.value)} placeholder="https://example.com" className={orderPageURL !== "" && !validator.isURL(orderPageURL, { require_protocol: true, protocols: ['http', 'https'] }) ? "form-control border border-danger" : "form-control"} value={orderPageURL} />
            </div>
            <div className="col-lg-12 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
              <span className="p-0">DESCRIPTION</span>
              <input type="text" onChange={(e) => setDescription(e.target.value)} className="form-control" value={description} />
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="adminButton" onClick={() => { setShowCloneModel(false) }}>
            Close
          </button>
          <button className="adminButton" disabled={address === "" || city === "" || stateId === "0" ? true : false || name === "" || email === "" || !validator.isEmail(email) || (orderPageURL !== "" && !validator.isURL(orderPageURL, { require_protocol: true, protocols: ['http', 'https'] }))} onClick={() => { cloneCompanyHandler() }}>Clone</button>
        </Modal.Footer>
      </Modal>
    )
  }

  const [open, setOpen] = useState(false);

  const onChange = (ranges) => {
    if (moment(ranges.startDate).format("MM-DD-YYYY") !== moment(ranges.endDate).format("MM-DD-YYYY")) {
      setOpen(false);
    } else if (ranges.startDate === "" && ranges.endDate === "") {
      setOpen(false);
    }
  };


  return (
    <>
      {isLoadingCompanySearch ? "" :

        <div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h4><b><i className="fa-solid fa-bell-concierge"></i>&nbsp;Company Management</b></h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="strpied-tabled-with-hover card">
                  <div className="card-header"><div className="row">
                    <div className="col-md-12">

                      <div className="row">
                        <div className="col">
                          <a className="badge rounded-pill bg-secondary text-light text-decoration-none" data-bs-toggle="collapse" href="#collapsewithlink" role="button" aria-expanded="false" aria-controls="collapsewithlink"><i className="fas fa-filter"></i> Apply Filter</a>
                        </div>
                        <div className="col-auto">
                          <button type="submit" className="btn-fill pull-right btn btn-dark btn-small py-0 px-1" onClick={() => { setAddModel(true) }}><i className="fa-solid fa-plus"></i></button>
                          <AddCompany />
                        </div>
                      </div>


                      <div className="row mt-2 collapse" id="collapsewithlink">
                        <div className="col-11 admin-filters">
                          <div className="row">
                            <div className="col-3">
                              <input placeholder="Company Name" type="text" className="form-control" id="companyNameSearch" name="companyNameSearch" maxLength="12" onKeyDown={handleSearch_onEnter} />
                            </div>
                            <div className="col-3">
                              <input placeholder="Company Email" type="text" name="emailSearch" id="emailSearch" className="form-control" maxLength="20" onKeyDown={handleSearch_onEnter} />
                            </div>
                            <div className="col-2">
                              <input placeholder="Contact Number" type="text" name="emailSearch" id="contactSearch" className="form-control" maxLength="10" onKeyDown={handleSearch_onEnter} />
                            </div>
                            <div className="col-2">
                              <select id="listingStatus" className="col form-select" title="Listing Status">
                                <option value="0" defaultValue>Status</option>
                                <option value="true">Active</option>
                                <option value="false">InActive</option>
                              </select>
                            </div>
                            <div className="col-2"><div>
                              <input type="checkbox" className="form-check-input" style={{ width: '1.2em', height: '1.2em' }} defaultChecked="true" id="addedByHalalCater"></input>
                              <label htmlFor="addedByHalalCater">&nbsp;<i style={{ fontSize: '22px', color: 'orange' }} className="fas fa-user-circle" title="Added by Halal Cater"></i></label>
                              &nbsp;&nbsp;&nbsp;
                              <input type="checkbox" className="form-check-input" style={{ width: '1.2em', height: '1.2em' }} defaultChecked="true" id="addedByCompanyUser"></input>
                              <label className="form-check-label ps-1" htmlFor="addedByCompanyUser">&nbsp;<i style={{ fontSize: '22px', color: 'red' }} className="fas fa-user-circle" title="Added by company user"></i></label>
                            </div>
                            </div>
                          </div>

                          <div className="mt-2 row">
                            <div className="col-4">
                              <DateRangeFilter onChange={onChange} open={open} setOpen={setOpen} />
                            </div>
                            <div className="col-2">
                              <input placeholder="City" type="text" name="SearchCity" id="city" className="form-control" maxLength="100" onKeyDown={handleSearch_onEnter} />
                            </div>
                            <div className="col-2">

                              {isLoadingStates ? <h1>loading...</h1> :
                                <select id="stateId" className="form-select" name="dietary" >
                                  <option value="0">States</option>
                                  {
                                    dataStates.payload.map((st, index) =>
                                      <option key={index} value={st.stateId}>{st.stateName}</option>
                                    )
                                  }
                                </select>}

                            </div>
                            <div className="col-2">
                              <input placeholder="ZipCode" type="text" name="SearchZipcode" id="zipcode" className="form-control" maxLength="5" onKeyDown={handleSearch_onEnter} />
                            </div>
                            <div className="col-2">
                              <select id="isVerified" className="col form-select" title="Missing Menu">
                                <option value="0" defaultValue>Verification Status</option>
                                <option value="true">Verified</option>
                                <option value="false">Not Verified</option>
                              </select>
                            </div>
                          </div>

                          <div className="mt-2 row">
                            <div className="col-4 ">
                              <ReactSelect
                                options={!isLoadingDietary &&
                                  dataDietary.payload.map((dietaryData) => {
                                    return { value: dietaryData.dietaryId, label: dietaryData.dietaryName }
                                  })}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                placeholder="Dietary"
                                onChange={(e) => {
                                  varDietaries = ''
                                  e.map(dty => {
                                    return varDietaries = varDietaries + dty.value + ",";
                                  })
                                }}
                                allowSelectAll={true}
                              />
                            </div>

                            <div className="col-4">
                              <ReactSelect
                                options={!isLoadingCuisine &&
                                  dataCuisine.payload.map((cuisineData) => {
                                    return { value: cuisineData.cuisineId, label: cuisineData.cuisineName }
                                  })}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                placeholder="Cuisine" onsele
                                onChange={(e) => {
                                  varCuisines = ''
                                  e.map(cui => {
                                    return varCuisines = varCuisines + cui.value + ",";
                                  })
                                }}
                                allowSelectAll={true}
                              />
                            </div>

                            <div className="col-4">
                              <ReactSelect
                                options={!isLoadingSpecialty &&
                                  dataSpecialty.payload.map((specialty) => {
                                    return { value: specialty.specialityId, label: specialty.specialityName }
                                  })}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                placeholder="Specialty"
                                onChange={(e) => {
                                  varSpecialities = ''
                                  e.map(spe => {
                                    return varSpecialities = varSpecialities + spe.value + ",";
                                  })
                                }}
                                allowSelectAll={true}
                              />
                            </div>
                          </div>

                          <div className="mt-3 m-1 p-2 row bg-dark bg-opacity-25 rounded">
                            <div className="col-3">
                              <div className="row">
                                <i className="far fa-image fa-2x col-auto" title="Missing Gallery"></i>
                                <select className="col form-select" id="missingGallery" title="Missing Gallery">
                                  <option value="" defaultValue>Filter Gallery</option>
                                  <option value="1">Missing</option>
                                  <option value="0">Exists</option>
                                </select>
                              </div>

                            </div>
                            <div className="col-3">
                              <div className="row">

                                <div className="col-auto">
                                  <i title="Missing Menu" className="me-1 fas fa-file-alt fa-2x"></i>
                                  <i title="Digital Menu Exists" className="fas fa-file-signature fa-2x"></i>
                                </div>

                                <select className="col form-select" id="missingMenu" title="Missing Menu">
                                  <option value="" defaultValue>Filter Menu</option>
                                  <option>Digital Menu Exists</option>
                                  <option>Digital Menu Missing</option>
                                  <option>Document Menu Exists</option>
                                  <option>Document Menu Missing</option>
                                  <option>Digital & Document Menu Exists</option>
                                  <option>Digital & Document Menu Missing</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row">
                                <i className="fas fa-address-card fa-2x col-auto" title="Missing Business Profile"></i>
                                <select className="col form-select" id="missingBusProfile" title="Missing Business Profile">
                                  <option value="" defaultValue>Filter Business Profile</option>
                                  <option value="1">Missing</option>
                                  <option value="0">Exists</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row">
                                <i className="fas fa-camera fa-2x col-auto" title="Missing Logo"></i>
                                <select className="col form-select" id="missingLogo" title="Missing Logo">
                                  <option value="" defaultValue>Filter Logo</option>
                                  <option value="1">Missing</option>
                                  <option value="0">Exists</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-1">
                          <div className="mt-2">
                            <div className="col">
                              <button type="submit" className="pull-right adminButton" onClick={handleSearch}>
                                <i className="fa-sharp fa-solid fa-magnifying-glass"></i>&nbsp;SEARCH
                              </button></div>
                            <div className="mt-2 col">
                              <button type="submit" className="pull-right adminButton" onClick={handleReset} >
                                <i className="fa-sharp fa-solid fa-cancel"></i>&nbsp;RESET
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-auto">
                              <div style={{ 'fontSize': '0.8rem', 'fontWeight': 'bold' }} className="px-2 mt-3 bg-dark text-white rounded text-start">TOTAL : {dataCompanySearch.totalRecords}</div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                  </div>
                  <div className="table-full-width table-responsive px-0 card-body pt-1">
                    <table className="table-hover table-striped table"><thead>
                      <tr>
                        <th>{getColumnHeader('ID', 'CompanyID')}</th>
                        <th>{getColumnHeader('Company Name', 'CompanyName')}</th>
                        <th>{getColumnHeader('Company Title', 'CompanyTitle')}</th>
                        <th>{getColumnHeader('Sub. Plan', 'SubscriptionPlanName')}</th>
                        <th>{getColumnHeader('Verified', 'IsVerified')}</th>
                        <th>{getColumnHeader('Name/Email', 'Name')}</th>
                        <th>{getColumnHeader('Contact Number', 'ContactNumber')}</th>
                        <th>{getColumnHeader('Address', 'StreetAddress')}</th>
                        <th>{getColumnHeader('City', 'City')}</th>
                        <th>{getColumnHeader('State', 'StateName')}</th>
                        <th>{getColumnHeader('Zip', 'ZipCode')}</th>
                        <th>{getColumnHeader('Reg. Date', 'CreatedOn')}</th>
                        <th>{getColumnHeader('Created By', 'CreatedBy')}</th>
                        <th>{getColumnHeader('Status', 'ListingStatus')}</th>
                        <th>Missing</th>
                        <th>Menu</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                      <tbody>

                        {dataCompanySearch.payload.length === 0 ? <tr><td colSpan="15" className="text-center p-4">No record found</td></tr> :
                          dataCompanySearch.payload.map((cat, index) =>
                            <tr key={index}>
                              <td>&nbsp;&nbsp;{cat.companyId}</td>
                              <td><div className="row"><div className="col-auto"><img src={cat.logoFileName ? process.env.REACT_APP_BASE_URL + "MediaStorage/logo/" + cat.logoFileName : '/images/DefaultCaterer.png'} alt="" style={{ 'height': '30px', 'width': '30px' }} className="bg-info rounded-circle" /></div><div className="col p-0"><a href={'../company-detail/' + cat.companyId + '/' + (cat.locationSlug === '' ? 'none' : cat.locationSlug) + '/' + cat.companySlug} target="_blank" style={{ 'textDecoration': 'none' }}>{cat.companyName}</a><br /><span style={{ 'fontSize': '11px' }}>{cat.companySlug}</span></div></div></td>
                              <td>{cat.companyTitle}</td>
                              <td className="text-center"><span style={{ fontWeight: 400 }} className={cat.subscriptionPlanName === "PREMIUM" ? "badge rounded-pill bg-warning" : cat.subscriptionPlanName === "SPOTLIGHT" ? "badge rounded-pill bg-primary" : "badge rounded-pill bg-secondary"}>{cat.subscriptionPlanName}</span></td>
                              <td className="text-center">{cat.isVerified ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-sharp fa-solid fa-circle-xmark text-danger"></i>}</td>
                              <td>{cat.name}<br />{cat.email !== null && (cat.email.length > 20 ? cat.email.substring(0, 17) + "..." : cat.email)}</td>
                              <td>{cat.contactNumber}</td>
                              <td>{cat.streetAddress}</td>
                              <td>{cat.city}</td>
                              <td>{cat.stateName}</td>
                              <td>{cat.zipCode}</td>
                              <td>{moment(cat.createdOn).format("MM-DD-YY HH:mm")}</td>
                              <td className="text-center"><i style={{ fontSize: '22px', color: cat.createdBy === 336 ? 'orange' : 'red' }} className="fas fa-user-circle" title={cat.createdBy === 336 ? 'Added by Halal Cater' : 'Added by Company User'}></i></td>
                              <td className="text-center">{cat.listingStatus}{cat.listingStatus ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-sharp fa-solid fa-circle-xmark text-danger"></i>}</td>
                              <td style={{ 'width': '80px' }}>
                                <div className="row">
                                  <div className="col-auto">
                                    {cat.eventMissing ? <i title="Event Missing" className="far fa-image fa-2x px-1 text-danger"></i> : <i title="Event Exists" className="far fa-image fa-2x px-1 text-success"></i>}
                                    {cat.businessProfileMissing ? <i title="Business Profile Missing" className="fas fa-address-card fa-2x px-1 text-danger"></i> : <i title="Business Profile Exists" className="fas fa-address-card fa-2x px-1 text-success"></i>}
                                    {cat.logoMissing ? <i title="Logo Missing" className="fas fa-camera fa-2x px-1 text-danger"></i> : <i title="Logo Exists" className="fas fa-camera fa-2x px-1 text-success"></i>}
                                  </div>
                                </div>
                              </td>
                              <td style={{ 'width': '80px' }}>
                                <div className="row">
                                  <div className="col-auto">
                                    {cat.digitalMenuMissing ? <i title="Digital Menu Missing" className="fas fa-file-alt fa-2x px-1 text-danger"></i> : <i title="Digital Menu Exists" className="fas fa-file-alt fa-2x px-1 text-success"></i>}
                                    {cat.documentMenuMissing ? <i title="Document Menu Missing" className="fas fa-file-signature fa-2x px-1 text-danger"></i> : <i title="Document Menu Exists" className="fas fa-file-signature fa-2x px-1 text-success"></i>}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <i onClick={(e) => { setCompanyInfo(cat); setShowModel(true) }} className="simpleIconButton text-secondary fas fa-edit" title="update company info."></i>
                                &nbsp;
                                <i onClick={(e) => {
                                  navigateToCompanyPanel({ companyId: cat.companyId.toString() })
                                  window.open('../../company-panel', '_blank')
                                }} className="simpleIconButton text-secondary far fa-window-maximize" title="open company panel of this company"></i>
                                &nbsp;
                                <i onClick={(e) => { setCloneBusinessId(cat.companyId); setCloneBusinessName(cat.companyName); setShowCloneModel(true) }} className="simpleIconButton text-secondary fas fa-clone" title="clone"></i>
                              </td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>


                  <div className="pagination pb-3">

                    <button className="prev-btn"
                      onClick={() => setPage((prev) => prev - 1)}
                      disabled={page === 1 || isFetching}
                    >
                      {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-left"></i>}
                    </button>
                    <button className="next-btn"
                      onClick={() => setPage((prev) => prev + 1)}
                      disabled={page >= dataCompanySearch.totalPages || isFetching}
                    >
                      {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-right"></i>}
                    </button>
                    <div className="page">
                      {`${page} / ${dataCompanySearch.totalPages}`}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <EditCompanyInfo />
          <CloneCompany />
        </div>
      }
    </>
  )
}





export default CompanyManagement




