import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { toPascal } from '../../../hooks/util';
import {
    useGetSpecialitiesSelectionsByCompanyIdQuery, useGetPendingApprovalSpecialitiesQuery,
    useAddSpecialityMutation, useDeleteSpecialityMutation, useSelectSpecialityMutation, useUnselectSpecialityMutation
} from '../../../hooks/apiSlice';


const SpecialitySelect = () => {

    const [newSpecialityName, setNewSpecialityName] = useState('')
    const [newSpecialityLoading, setNewSpecialityLoading] = useState(false)
    const [specialityFilter, setSpecialityFilter] = useState('')
    const [specialitiesCache, setSpecialitiesCache] = useState([])
    const [selectionCount, setSelectionCount] = useState(0)

    const { data: dataSpecialities, isLoading: isLoadingSpecialities } = useGetSpecialitiesSelectionsByCompanyIdQuery()
    const { data: dataPendingSpecialities, isLoading: isLoadingPendingSpecialities } = useGetPendingApprovalSpecialitiesQuery()

    const [addSpeciality] = useAddSpecialityMutation()
    const [deleteSpeciality] = useDeleteSpecialityMutation()
    const [unselectSpeciality] = useUnselectSpecialityMutation()
    const [selectSpeciality] = useSelectSpecialityMutation()

    const AddNewSpecialityName = async (e) => {
        e.preventDefault();
        if (newSpecialityName !== "") {
            if (dataSpecialities.payload.some(item => newSpecialityName.toUpperCase() === item.specialityName.toUpperCase())) {
                toast.error(newSpecialityName + ` already exists, Please select it from the list`, { position: toast.POSITION.TOP_CENTER });
            }
            else if (dataPendingSpecialities.payload.some(item => newSpecialityName.toUpperCase() === item.specialityName.toUpperCase())) {
                toast.error(newSpecialityName + ` already added`, { position: toast.POSITION.TOP_CENTER });
            }
            else {
                setNewSpecialityLoading(true);
                const returned = await addSpeciality({ specialityName: toPascal(newSpecialityName) }).unwrap();
                setNewSpecialityLoading(false);
                setNewSpecialityName('');
            }
        }
    }


    
    useEffect(() => {
        if (!isLoadingSpecialities) {
            setSpecialitiesCache(dataSpecialities.payload);
        }
      }, [isLoadingSpecialities]);


    const toggleSpeciality = (e, id) => {
        if (e.target.checked)
            selectSpeciality({ specialityId: parseInt(id) });
        else
            unselectSpeciality({ specialityId: parseInt(id) })

    const currentIndex = specialitiesCache.findIndex((todo) => todo.specialityId === id);
     const updatedTodo = {...specialitiesCache[currentIndex], isSelected: e.target.checked};
    const newTodos = [
      ...specialitiesCache.slice(0, currentIndex),
      updatedTodo,
      ...specialitiesCache.slice(currentIndex + 1)
    ];
    setSpecialitiesCache(newTodos);

    setSelectionCount(e.target.checked ? selectionCount + 1 : selectionCount - 1)            
    }


    const applyFilter = (e) => {
        setSpecialityFilter(e.target.value.toLowerCase());
    }


    useEffect(() => {
        if (isLoadingSpecialities === false) {
            setSelectionCount(dataSpecialities.payload.filter(item => item.isSelected === true).length)
        }
    }, [isLoadingSpecialities])

    return (

        <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-4 border-top">
            <h4>Choose Specialities({selectionCount})</h4>
            <div className="mt-3 row">
                <div className="col-12 col-md-6">
                    <div className="input-group what-we-offer-search mb-3">
                        <div className="form-outline">
                            <input type="search" id="search" placeholder="Type event" className="form-control" onChange={(e) => applyFilter(e)} />
                        </div>
                        <button type="button" className="btn">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div className="horizontal-scroll-bar">
                        {isLoadingSpecialities ? <h6>Loading...</h6> :
                            specialitiesCache.filter((m) => m.specialityName.toLowerCase().includes(specialityFilter) || specialityFilter === '').map((spc, index) =>
                                <div key={index} >
                                    <label htmlFor={'speciality' + index} className="form-check-label">
                                        <input className="form-check-input ps-2" type="checkbox" checked={spc.isSelected} onChange={(e)=>toggleSpeciality(e, spc.specialityId)} id={'speciality' + index}  />&nbsp;&nbsp;{spc.specialityName}
                                    </label>
                                </div>)
                        }
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div>
                        <div className="my-2">
                            {isLoadingPendingSpecialities ? "" :
                                <>
                                    {dataPendingSpecialities.payload.length > 0 && <h6>Pending Requests:</h6>}
                                    {dataPendingSpecialities.payload.map((spec, index) => <div key={index} className="tag-item" >
                                        <span className="text">{spec.specialityName}</span>&nbsp;
                                        <span className="itemClose" onClick={() => { deleteSpeciality({ specialityId: spec.specialityId }) }}><i className="fa-solid fa-circle-xmark"></i></span>
                                    </div>)}
                                </>
                            }
                        </div>
                        <div className="alert alert-light d-flex p-2" role="alert">
                            <i className="fa-sharp fa-solid fa-circle-info ms-1 me-2"></i>
                            <div className="text-dark tiny-text">If you do not find specific event type in your search scroll please add it below, it will start appear in your public profile after administrator approval.</div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col">
                                    <input type="text" value={newSpecialityName} onChange={(e) => setNewSpecialityName(e.target.value)} className="form-control" placeholder="Speciality Name" maxLength="50"></input>
                                </div>
                                <div className="col-auto align-self-center">
                                    <button className="btn btn-danger mt-2" onClick={AddNewSpecialityName} type="submit">
                                        {newSpecialityLoading ? <span className="spinner-grow spinner-grow-sm"></span> :
                                            <i className="fa-sharp fa-solid fa-plus"></i>}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SpecialitySelect

