import React from "react";
import { useGetMessageByCompanyIdQuery } from './../../hooks/apiSlice';
import moment from 'moment'

const Message = () => {


    const { data: dataMessageByCompany, isLoading: isLoadingMessageByCompany } = useGetMessageByCompanyIdQuery()


    return (
        <div>
            <div className="row">
                <div className="signin-up-wrapper d-none d-lg-block">
                    <h3>
                        <strong>Message</strong>
                    </h3>
                    <h6>
                        Check your customers messages
                    </h6>
                </div>

                <div>
                    <div className="row ms-2 mt-4">
                        {isLoadingMessageByCompany ? "" :
                            dataMessageByCompany.payload.map((msg, index) =>
                                <div key={index} className="messageBox2 row">
                                    <div className="col-6"><i className="fas fa-user-circle me-1 text-danger"></i>{msg.userProfile.firstName}&nbsp;|&nbsp;{msg.contactNumber}<br /><span style={{'fontSize':'11px'}}>{msg.userProfile.company === null ? "" : msg.userProfile.company.companyName}</span></div> 
                                    <div className="col-6 text-end"><i className="fas fa-clock me-1 text-danger"></i>{moment.utc(new Date(msg.messageDate), 'DD/MM/YYYY hh:mm').local().startOf('seconds').fromNow()}</div><div className="col-12 mt-3">{msg.messageBody}</div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Message

