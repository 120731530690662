import { useGetClickThroughReportQuery } from './../../hooks/apiSlice';
import { useAddPageInsideMutation } from './../../hooks/apiSlice';


const AddPageInside = () => {
    const [AddPageInside] = useAddPageInsideMutation()

return (
    <>
    <button onClick={(e)=>AddPageInside({entityName:'company', entityId:7034, eventId:'Call Click', ipAddress:'127.0.0.1', clientInfo:'me'})}>AddPageInside</button>
    </>
)

}





const ClickThroughReport = () => {
    const { data: dataClickThroughReport, isLoading: isLoadingClickThroughReport } = useGetClickThroughReportQuery({startDate:'12-12-2023',endDate:'12-12-2024',entityName:'Company',entityId:7043})


    return (
        <>
        <AddPageInside />
            {isLoadingClickThroughReport ? "Loading" :
                <table className="table-hover table-striped table" ><thead>
                    <tr>
                        <th>Company Name</th>
                        <th>Detail PageView Count</th>
                        <th>Detail Page Phone Number Click Through Counts</th>
                        <th>ListingPagePhoneNumberClickThroughCount</th>
                    </tr>
                </thead>
                    <tbody>
                        {dataClickThroughReport.map((rpt, index) =>
                            <tr key={index}>
                                <td>{rpt.companyName}</td>
                                <td>{rpt.detailPageViewCount}</td>
                                <td>{rpt.detailPagePhoneNumberClickThroughCount}</td>
                                <td>{rpt.listingPagePhoneNumberClickThroughCount}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
        </>
    )


}

export default ClickThroughReport