import {useGetSubscriptionQuery} from './../hooks/apiSlice';
import moment from 'moment'

const BoostInvoice = (props) => {

    const { data: dataSubscription, isLoading: isLoadingSubscription } = useGetSubscriptionQuery(props.sub);

    return (
        <>
            {!isLoadingSubscription && <>
                <div className="row">
                    <div className="col-6">
                        <img src="/images/logo.png" alt="Halal Caters" />
                    </div>
                    <div className="col-6">
                        <h1 className="bg-dark text-light">INVOICE</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mt-2"><b>
                        Invoice Number: {("00000" + dataSubscription.payload.subscriptionId).substr(("00000" + dataSubscription.payload.subscriptionId).length - 5)}
                        <br />
                        Date: {moment(dataSubscription.payload.subscriptionDate).format("MMMM DD YYYY")}
                        <br />
                        Product: Banner Ad
                    </b>
                    </div>
                    <div>

                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        Bill to: <br />
                        {dataSubscription.payload.company.companyName}
                        {dataSubscription.payload.company.addresses[0].streetAddress}, {dataSubscription.payload.company.addresses[0].city}, {dataSubscription.payload.company.addresses[0].state.stateName}, {dataSubscription.payload.company.addresses[0].zipCode}<br />
                    </div>
                </div>

                <hr />

                <div className="row">
                    {dataSubscription.payload.subscriptionDetails.map((sd, index) =>
                        <div key={index} className="row">
                            <div className="col">
                                {sd.targetAudience.offer} - {sd.targetAudience.offerName} - {sd.targetAudience.state.stateName} / {sd.productPlan.duration}
                            </div>
                            <div className="col-auto">
                                ${sd.price}
                            </div>
                        </div>
                    )}

                    <div className="row mt-2">
                        <div className="col">
                            <b>Total</b>
                        </div>
                        <div className="col-auto">
                            <b>${dataSubscription.payload.amount}</b>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    )
}


export default BoostInvoice