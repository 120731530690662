import Navbar from './Navbar'
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import { Link, useNavigate } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import axios from "axios";
import AuthService from "../hooks/Auth";
import { toPascal } from '../hooks/util';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useAddUserProfileMutation } from '../hooks/apiSlice';

//new branch added

const USER_REGEX = /^.{3,}$/;
//const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const SignupPanel = (props) => {

  const location = useLocation()
  const navigate = useNavigate();

  const userRef = useRef();
  const businessNameRef = useRef();

  const [userFullName, setUserFullName] = useState('');
  const [validFullName, setValidFullName] = useState(false);

  const [businessName, setBusinessName] = useState('');
  const [validBusinessName, setValidBusinessName] = useState(false);

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  //const [validMatch, setValidMatch] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false)

  const [errMsg, setErrMsg] = useState('');



  const [passwordType, setPasswordType] = useState("password");
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState("");



  const handlePassword = (passwordValue) => {
    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
      verifiedList.length === 5
        ? "Strong"
        : verifiedList.length >= 2
          ? "Medium"
          : "Weak";

    setPwd(passwordValue);
    setProgress(`${(verifiedList.length / 5) * 100}%`);
    setMessage(strength);
  }
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }


  const getActiveColor = (type) => {
    if (type === "Strong") return "#8BC926";
    if (type === "Medium") return "#FEBD01";
    return "#FF0054";
  };


  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setValidFullName(USER_REGEX.test(userFullName));
    setValidBusinessName(USER_REGEX.test(businessName));
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email, userFullName, businessName])

  useEffect(() => {
    setValidPwd(pwd.length >= 5);
  }, [pwd])

  useEffect(() => {
    setErrMsg('');
  }, [userFullName, pwd, matchPwd])

  const [addUserProfileMutation] = useAddUserProfileMutation()

  const handleSubmit = async (e) => {
    //e.preventDefault();
    setSubmitLoading(true);
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(userFullName);
    const v2 = pwd.length >= 5;
    const v3 = EMAIL_REGEX.test(email);

    if (!v1 || !v2 || !v3) {
      toast.error(`Invalid Entry`, { position: toast.POSITION.TOP_CENTER });
      return;
    }

    addUserProfileMutation({ FirstName: userFullName, Email: email, password: pwd, businessName: businessName, file: currentFile })
      .then(response => {
        if (response.data.success === false)
          toast.error(response.data.message, { position: toast.POSITION.TOP_CENTER });
        else {
          AuthService.signup(response)

          if (props.parent === "SignupPage")
            navigate(props.userType === 'business' ? '../company-panel' : '../company-search');
          else
            props.onSuccessSignup();
        }
      })
    setSubmitLoading(false);
  }
  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);
  
  const selectFile = (event) => {
    if (event.target.files[0].size > 1048576) //not more than 1MB
      alert('Image file should not exceeds 1MB in size')
    else {
      setCurrentFile(event.target.files[0]);
      setPreviewImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (

    <div className="row pt-4 margin0 form-log">


      <div className="pic-container pic-medium">
        <label htmlFor="companyImg">
          <input type="file" name="companyImg" id="companyImg" className="form-control" style={{ 'display': 'none' }} onChange={selectFile} accept="image/*" />
          {previewImage !== undefined ? <img alt="" src={previewImage} style={{ 'maxHeight': '140px', 'maxWidth': '140px', 'cursor': 'pointer' }} title="click to change your avatar" /> :
            <img alt="" src="/images/AvatarDefault.png" style={{ 'maxHeight': '140px', 'maxWidth': '140px', 'cursor': 'pointer' }} title="click to change your avatar" />
          }
          <div className="upload-profilepic text-center card-img-overlay d-none flex-column justify-content-center">
            <i className="fa-solid fa-camera"></i>
            <div className="overlay-txt">Upload<br />200x165 recommended</div>
          </div>
        </label>
      </div>



      <input
        type="text"
        id="username"
        ref={userRef}
        autoComplete="off"
        onChange={(e) => setUserFullName(toPascal(e.target.value))}
        value={userFullName}
        maxLength="60"
        className={!validFullName ? "form-control form-control-lg border " : "form-control form-control-lg"}
        placeholder="Full Name"
        required
        aria-invalid={validFullName ? "false" : "true"}
        aria-describedby="uidnote"
      />




      {(props.userType === 'business') &&

        <input
          type="text"
          id="businessname"
          ref={businessNameRef}
          autoComplete="off"
          onChange={(e) => setBusinessName(toPascal(e.target.value))}
          value={businessName}
          maxLength="60"
          className={!validBusinessName ? "form-control form-control-lg border " : "form-control form-control-lg"}
          placeholder="Business Name"
          required
          aria-invalid={validBusinessName ? "false" : "true"}
          aria-describedby="uidnote"
        />
      }



      <input type="text" id="email" autoComplete="off" onChange={(e) => setEmail(e.target.value)} value={email} maxLength="100"
        className={!validEmail ? "form-control form-control-lg border " : "form-control form-control-lg"}
        placeholder="Email Address" required aria-invalid={validEmail ? "false" : "true"} aria-describedby="uidnote" />

      <div style={{ 'padding': '0px' }}>
        <div className="password-field">
          <input
            value={pwd}
            onChange={({ target }) => {
              handlePassword(target.value);
            }}
            type={passwordType}
            className={!validPwd ? "form-control form-control-lg border " : "form-control form-control-lg"}
            placeholder="Enter Password"
          />
          <i className={passwordType === "password" ? "far fa-eye" : "far fa-eye-slash"}  onClick={togglePassword}></i> 
        </div>


        <div className="progress-bg2">
          <div
            className="progress2"
            style={{
              width: progress,
              backgroundColor: getActiveColor(message),
            }}
          ></div>
        </div>


        {pwd.length !== 0 ? (
          <p className="message2" style={{ color: getActiveColor(message) }}>
            Your password is {message}
          </p>
        ) : null}
      </div>







      {(props.userType === 'business') ?
        <button onClick={handleSubmit} className="btn btn-danger btn-lg" disabled={!validFullName || !validBusinessName || !validEmail || !validPwd || submitLoading ? true : false}>Add My Business
          {submitLoading && <span className="ms-2 spinner-border spinner-border-sm"></span>}
        </button>
        :
        <button onClick={handleSubmit} className="btn btn-danger btn-lg" disabled={!validFullName || !validEmail || !validPwd || submitLoading ? true : false}>Continue
          {submitLoading && <span className="ms-2 spinner-border spinner-border-sm"></span>}
        </button>
      }


    </div>
  )
}


export default SignupPanel