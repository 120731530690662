import React, { useState, useRef } from "react";
import { useAddReviewMutation, useGetReviewByCompanyQuery, useUpdateReviewStatusMutation, useGetUserByKeywordQuery
  ,useRemoveReviewMutation, useGetReviewQuery } from '../hooks/apiSlice';
import moment from 'moment'
import CompanySelection from "../components/CatererSearchOption/CompanySelection";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { StarRating } from "../components/RatingStar";
import { default as ReactSelect } from "react-select";

const ReviewManagement = () => {

  const [page, setPage] = useState(1)

  const [sortField, setSortField] = useState("ReviewDate");
  const [order, setOrder] = useState("desc");

  const [addCompanyId, setAddCompanyId] = useState(0)


  const [editReviewId, setEditReviewId] = useState(0);
  //const [comment, setComment] = useState('');



  const { data: dataReviewByCompany, isLoading: isLoadingReviewByCompany, isFetching } = useGetReviewByCompanyQuery({ companyId: addCompanyId, pageNum: page, sortColumn: sortField, sortDirection: order })
  const [updateReviewStatusMutation] = useUpdateReviewStatusMutation()
  const [removeReviewMutation] = useRemoveReviewMutation()
  


  const handleSortingChange = (accessor) => {
    setSortField(accessor);
    setOrder(order === "asc" ? "desc" : "asc");
    setPage(1);
  };


  function getColumnHeader(columnName, sortString) {
    const arrowDirection = sortField === sortString && order === "asc" ? <i className="fas fa-arrow-up"></i> : sortField === sortString && order === "desc" ? <i className="fas fa-arrow-down"></i> : ""
    return (
      <span onClick={() => handleSortingChange(sortString)}> {isFetching ? <span className="ms-2 spinner-border spinner-border-sm"></span> : columnName} {arrowDirection}</span>
    )
  }

  const onCompanySelection = (e) => {
    setAddCompanyId(e.companyId);
    setPage(1);
  }


  const [showReviewDetailModel, setShowReviewDetailModel] = useState(false);
  const [modalMode, setModalMode] = useState('add');

  const { data: dataReview, isLoading: isLoadingReview } = useGetReviewQuery(editReviewId)

  const ReviewDetail = () => {
    const [startDate, setStartDate] = useState((!isLoadingReview && dataReview.payload !== null) ? moment(new Date(dataReview.payload.reviewDate)).format("YYYY-MM-DD") : new Date().toISOString().split('T')[0]);

    const [rating, setRating] = useState((!isLoadingReview && dataReview.payload !== null) ? dataReview.payload.ratingTaste : 0);
    const [rating2, setRating2] = useState((!isLoadingReview && dataReview.payload !== null) ? dataReview.payload.ratingOnTimeDelivery : 0);
    const [rating3, setRating3] = useState((!isLoadingReview && dataReview.payload !== null) ? dataReview.payload.ratingCommunication : 0);
    const [comment, setComment] = useState((!isLoadingReview && dataReview.payload !== null) ? dataReview.payload.comment : '');
    const [reviewImages, setReviewImages] = useState((!isLoadingReview && dataReview.payload !== null) && dataReview.payload.reviewImages);
    const [submittedBy, setSubmittedBy] = useState({ userProfileId: (!isLoadingReview && dataReview.payload !== null) ? dataReview.payload.ratingCommunication : 0, fullName: 'no name' });

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);


    const removeImage = (event) => {
      const newPeople = imagePreviews.filter((_, i) => i !== parseInt(event.target.previousElementSibling.alt))
      const newPeople2 = selectedFiles.filter((_, i) => i !== parseInt(event.target.previousElementSibling.alt))
      setImagePreviews(newPeople);
      setSelectedFiles(newPeople2);
    };

    const [imgIdToDelete, setImgIdToDelete] = useState([]);
    const removeSavedImage = (event) => {
      setImgIdToDelete([...imgIdToDelete, parseInt(event.target.dataset.key)])
      const newPeople = reviewImages.filter((_, i) => i !== parseInt(event.target.previousElementSibling.alt))
      setReviewImages(newPeople);
    };

    const selectFiles = (event) => {
      let imageFileSizeExceed = false;
      if ((imagePreviews.length + event.target.files.length) > 5)
        toast.error(`Please do not upload more than 5 pictures. Thank You!`, { position: toast.POSITION.TOP_CENTER });
      else {
        for (let i = 0; i < event.target.files.length; i++) {
          if (event.target.files[i].size > 5242880) {
            imageFileSizeExceed = true;
          }
          else {
            setImagePreviews(imagePreviews => [...imagePreviews, URL.createObjectURL(event.target.files[i])]);
            setSelectedFiles(selectedFiles => [...selectedFiles, event.target.files[i]]);
          }
        }

        if (imageFileSizeExceed)
          toast.warning(`One or more image file size exceeds from 5MB, that has been excluded.`, { position: toast.POSITION.TOP_CENTER })
      }
    };

    const [addReviewMutation] = useAddReviewMutation()

    const saveReview = async () => {
      let formData = new FormData()
      const files = Array.from(selectedFiles);

      formData.append("file", files);

      files.forEach(item => {
        (item.size <= 5242880) && formData.append('pictures', item)
      })

      //formData.append("ImagesToDel", imgIdToDelete);

      await addReviewMutation({ reviewId: editReviewId, file: formData, 'company.companyId': addCompanyId, 'userProfile.userProfileId': submittedBy.userProfileId, comment: comment, ratingTaste: rating, ratingOnTimeDelivery: rating2, ratingCommunication: rating3, reviewDate: startDate, ImagesToDel: imgIdToDelete }).unwrap();

      setSelectedFiles([]);
      setImagePreviews([]);
      setComment('');

      setRating(0);
      setRating2(0);
      setRating3(0);

      setShowReviewDetailModel(false);
      //}
    }

    const removeReview = () => {
      removeReviewMutation(editReviewId);
      setShowReviewDetailModel(false);
    }

    const UserSelect = () => {

      const [keyword, setKeyword] = useState('');
      const [skip, setSkip] = useState(true)
      const companyReactSel = useRef(null);



      const { data: dataUserByKeyword, isLoading: isLoadingUserByKeyword } = useGetUserByKeywordQuery(keyword === '' ? '0' : keyword, {
        skip,
      })

      const handle = (opt) => {
        setKeyword(opt);
        setSkip(false);
      }

      return (
        <>
          <div className="App" id="companySearchComp">
            <ReactSelect ref={companyReactSel}
              isLoading={isLoadingUserByKeyword}
              isClearable={true}
              name="company"
              options={(isLoadingUserByKeyword || dataUserByKeyword === undefined) ? [] : dataUserByKeyword.payload}
              getOptionLabel={(x) => x.fullName}
              onChange={opt => {
                if (opt !== null)
                  setSubmittedBy(opt);
              }}
              onInputChange={opt => { handle(opt); }}
              placeholder="Type company name ..."
              value={submittedBy}
            />
          </div>
        </>
      );
    }

    return (
      <Modal size="xl" show={showReviewDetailModel} >
        <Modal.Header>
          <Modal.Title>{modalMode === 'modify' ? 'MODIFY' : 'CREATE REVIEW'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div className="row mb-4">
            <div className="col-8">
              <div className="row">
                <div className="col-12">
                  <input type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                    value={startDate}
                    className="form-control-sm" />
                </div>
                <div className="col-12">
                  <div className="col-12 mt-5">
                    <div className="row rating-boxes px-0 py-4">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center bg-light-">
                        <div className={rating === 0 ? "star-validation" : ""}>
                          <StarRating
                            count={5}
                            value={rating}
                            activeColor={'#F2AF29'}
                            onChange={(e) => {
                              setRating(e);
                            }
                            } />
                          <span style={{ 'color': '#111111' }} className="fw-bold">TASTE</span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center bg-light-">
                        <div className={rating2 === 0 ? "star-validation" : ""}>
                          <StarRating
                            count={5}
                            value={rating2}
                            activeColor={'#F2AF29'}
                            onChange={(e) => {
                              setRating2(e);
                            }
                            } />
                          <span style={{ 'color': '#111111' }} className="fw-bold">ON TIME DELIVERY</span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center bg-light-">
                        <div className={rating3 === 0 ? "star-validation" : ""}>
                          <StarRating
                            count={5}
                            value={rating3}
                            activeColor={'#F2AF29'}
                            onChange={(e) => {
                              setRating3(e);
                            }
                            } />
                          <span style={{ 'color': '#111111' }} className="fw-bold">COMMUNICATION</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="col-12">
                  <textarea className="form-control" placeholder="Comments" value={comment} onChange={(e) => setComment(e.target.value)} name="comments" />
                </div>

                <div className="col-12 mt-4">
                  <div className="row">
                    {reviewImages && (
                      reviewImages.map((img, i) => {
                        return (
                          <div key={i} className="col-auto position-relative">
                            <img className="review-image-upload" src={process.env.REACT_APP_BASE_URL + "MediaStorage/ReviewImages/" + img.imageFileName + img.imageFileExtention} alt={i} />
                            <img className="review-image-remove" src="/images/reviewImageRemove.png" alt="remove" title="remove" value={i} onClick={(e) => removeSavedImage(e)} data-key={img.reviewImageId} ></img>
                          </div>
                        );
                      })
                    )}

                    {imagePreviews && (
                      imagePreviews.map((img, i) => {
                        return (
                          <div key={i} className="col-auto position-relative">
                            <img className="review-image-upload" src={img} alt={i} />
                            <img className="review-image-remove" src="/images/reviewImageRemove.png" alt="remove" title="remove" value={i} onClick={(e) => removeImage(e)} data="s" ></img>
                          </div>
                        );
                      })
                    )}

                    {(imagePreviews.length < 5) &&
                      <div className="col-auto">
                        <label id="upload-label" htmlFor="upload" className="btn-picture-upload">
                          <i className="fas fa-upload"></i><br />Upload Picture
                        </label>
                        <input type="file" multiple accept="image/*" onChange={selectFiles} id="upload" style={{ 'visibility': 'hidden', 'display': 'block', 'width': '0', 'height': '0' }} />
                      </div>
                    }
                  </div>
                </div>

                <div className="col-12">
                  <div className="review-picture-upload-note mt-2">Max. 5 pictures upto 5MB</div>
                </div>



              </div>
            </div>
            <div className="col-4">
              <UserSelect />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        {modalMode === 'modify' &&
        <button className="adminDeleteButton" onClick={() => { removeReview() }}>Remove&nbsp;<span style={{"fontSize":"8px"}}><i className="fas fa-exclamation-triangle"></i>&nbsp;this action will never undo</span></button>}
          <button className="adminButton" disabled={rating === 0 || rating2 === 0 || rating3 === 0 || submittedBy.userProfileId === 0} onClick={(e) => saveReview()}>
            Save
          </button>
          <button className="adminButton" onClick={() => { setShowReviewDetailModel(false) }}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    )
  }







  return (
    <>
      {isLoadingReviewByCompany ? "" :

        <div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h4><b><i className="fa-regular fa-comment"></i>&nbsp;Review</b></h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="strpied-tabled-with-hover card">
                  <div className="card-header"><div className="row">
                    <div className="col-md-12">

                      <div className="row">
                        <div className="col-md-9 res-rev-search">
                          <CompanySelection handleSelection={onCompanySelection} />
                        </div>
                        <div className="col-md-2 text-end">
                          {addCompanyId !== 0 && <a href={"/company-detail/" + addCompanyId} className="btn btn-rounded" target="_blank"><i className="fas fa-external-link-alt"></i>&nbsp;Public Profile</a>}
                        </div>
                        <div className="col-md-1 text-end">
                          <button type="submit" disabled={addCompanyId === 0} className="btn-fill pull-right btn btn-dark btn-small py-0 px-1" onClick={() => { setModalMode('add'); setShowReviewDetailModel(true); setEditReviewId(0) }}><i className="fa-solid fa-plus"></i></button>
                        </div>
                      </div>
                      <div className="clearfix"></div>

                    </div>
                  </div>
                  </div>
                  <div className="table-full-width table-responsive px-0 card-body">


                    <div>
                      <table className="table-hover table-striped table"><thead>
                        <tr>
                          <th>{getColumnHeader('Date', 'ReviewDate')}</th>
                          <th>{getColumnHeader('Submitted By', 'UserProfile.FirstName')}</th>
                          <th>{getColumnHeader('Avg', 'RatingAverage')}</th>
                          <th>{getColumnHeader('Rating', 'RatingCatering')}</th>
                          <th>{getColumnHeader('Comment', 'Comment')}</th>
                          <th>{getColumnHeader('Images', 'Review Image(s)')}</th>                          
                          <th>Action</th>
                        </tr>
                      </thead>
                        <tbody>
                          {dataReviewByCompany.data.length === 0 ? <tr><td colSpan="7" className="text-center p-4">No review found</td></tr> :
                            dataReviewByCompany.data.map((rv, index) =>
                              <tr key={index} style={{ 'backgroundColor': rv.hideReview && '#fff0f0' }}>
                                <td style={{ 'width': '140px' }}>{moment(rv.reviewDate).format("MM-DD-YYYY h:mm A")}</td>
                                <td style={{ 'width': '140px' }}><div className="row"><div className="col-auto"><img src={rv.userProfile.avatar !== null ? process.env.REACT_APP_BASE_URL + "MediaStorage/Avatar/" + rv.userProfile.avatar : '/images/AvatarDefault.png'} alt="" style={{ 'height': '30px', 'width': '30px' }} className="bg-info rounded-circle" />&nbsp;{rv.userProfile.firstName}</div></div></td>
                                <td className="text-center">{rv.ratingAverage}</td>
                                <td>
                                  <div className="fw-bold">
                                    <span title="Taste" style={{ 'color': '#008e93', 'fontSize': '1.6rem' }}>★</span>{rv.ratingTaste}
                                    &nbsp;
                                    <span title="Communication" style={{ 'color': '#93628f', 'fontSize': '1.6rem' }}>★</span>{rv.ratingCommunication}
                                    &nbsp;
                                    <span title="On Time Delivery" style={{ 'color': '#e84f40', 'fontSize': '1.6rem' }}>★</span>{rv.ratingOnTimeDelivery}
                                  </div>
                                </td>
                                <td>{rv.comment}</td>
                                <td>{rv.reviewImages.map((ri, index) => <img key={index} src={process.env.REACT_APP_BASE_URL + "MediaStorage/ReviewImages/" + ri.imageFileName + ri.imageFileExtention} alt="" style={{ 'height': '30px', 'width': '30px' }} className="bg-info rounded m-1" />)}</td>
                                <td style={{ 'width': '80px' }} className="text-center">
                                  <span onClick={(e) => {
                                    setModalMode('modify');
                                    setShowReviewDetailModel(true);
                                    setEditReviewId(rv.reviewId);
                                  }}><i className="h4 simpleIconButton text-secondary fas fa-edit" title='edit comment'></i></span>&nbsp;
                                  {rv.hideReview ?
                                    <span onClick={() => { updateReviewStatusMutation({ reviewId: rv.reviewId, hideReview: false }) }}><i className="h4 simpleIconButton text-secondary fas fa-eye" title='show review for public'></i></span>
                                    :
                                    <span onClick={() => { updateReviewStatusMutation({ reviewId: rv.reviewId, hideReview: true }) }}><i className="h4 simpleIconButton text-secondary fas fa-eye-slash" title='hide review for public'></i></span>
                                  }
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>


                      <ReviewDetail />

                    </div>


                  </div>


                  <div className="pagination pb-3">

                    <button className="prev-btn"
                      onClick={() => setPage((prev) => prev - 1)}
                      disabled={page === 1 || isFetching}
                    >
                      {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-left"></i>}
                    </button>
                    <button className="next-btn"
                      onClick={() => setPage((prev) => prev + 1)}
                      disabled={page >= dataReviewByCompany.totalPages || isFetching}
                    >
                      {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-right"></i>}
                    </button>
                    <div className="page">
                      {`${page} / ${dataReviewByCompany.totalPages}`}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      }
    </>
  )
}


export default ReviewManagement