import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { toPascal } from '../../../hooks/util';
import {
    useGetServingOptionsSelectionsByCompanyIdQuery, useGetPendingApprovalServingOptionsQuery,
    useAddServingOptionMutation, useDeleteServingOptionMutation, useSelectServingOptionMutation, useUnselectServingOptionMutation
} from '../../../hooks/apiSlice';


const ServingOptionSelect = () => {

    const [newServingOptionName, setNewServingOptionName] = useState('')
    const [newServingOptionLoading, setNewServingOptionLoading] = useState(false)
    const [servingOptionFilter, setServingOptionFilter] = useState('')
    const [servingOptionCache, setServingOptionCache] = useState([])
    const [selectionCount, setSelectionCount] = useState(0)

    const { data: dataServingOptions, isLoading: isLoadingServingOptions } = useGetServingOptionsSelectionsByCompanyIdQuery()
    const { data: dataPendingServingOptions, isLoading: isLoadingPendingServingOptions } = useGetPendingApprovalServingOptionsQuery()

    const [addServingOption] = useAddServingOptionMutation()
    const [deleteServingOption] = useDeleteServingOptionMutation()
    const [unselectServingOption] = useUnselectServingOptionMutation()
    const [selectServingOption] = useSelectServingOptionMutation()

    const AddNewServingOptionName = async (e) => {
        e.preventDefault();
        if (newServingOptionName !== "") {
            if (dataServingOptions.payload.some(item => newServingOptionName.toUpperCase() === item.servingOptionName.toUpperCase())) {
                toast.error(newServingOptionName + ` already exists, Please select it from the list`, { position: toast.POSITION.TOP_CENTER });
            }
            else if (dataPendingServingOptions.payload.some(item => newServingOptionName.toUpperCase() === item.servingOptionName.toUpperCase())) {
                toast.error(newServingOptionName + ` already added`, { position: toast.POSITION.TOP_CENTER });
            }
            else {
                setNewServingOptionLoading(true);
                const returned = await addServingOption({ servingOptionName: toPascal(newServingOptionName) }).unwrap();
                setNewServingOptionLoading(false);
                setNewServingOptionName('');
            }
        }
    }


    useEffect(() => {
        if (!isLoadingServingOptions) {
            setServingOptionCache(dataServingOptions.payload);
        }
    }, [isLoadingServingOptions]);

    const toggleServingOption = (e, id) => {
        if (e.target.checked)
            selectServingOption({ servingOptionId: parseInt(id) });
        else
            unselectServingOption({ servingOptionId: parseInt(id) })

        const currentIndex = servingOptionCache.findIndex((todo) => todo.servingOptionId === id);
        const updatedTodo = { ...servingOptionCache[currentIndex], isSelected: e.target.checked };
        const newTodos = [
            ...servingOptionCache.slice(0, currentIndex),
            updatedTodo,
            ...servingOptionCache.slice(currentIndex + 1)
        ];
        setServingOptionCache(newTodos);

        setSelectionCount(e.target.checked ? selectionCount + 1 : selectionCount - 1)
    }

    const applyFilter = (e) => {
        setServingOptionFilter(e.target.value.toLowerCase());
    }


    useEffect(() => {
        if (isLoadingServingOptions === false) {
            setSelectionCount(dataServingOptions.payload.filter(item => item.isSelected === true).length)
        }
    }, [isLoadingServingOptions])

    return (

        <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-4 border-top">
            <h4>Choose Serving Options({selectionCount})</h4>
            <div className="mt-3 row">
                <div className="col-12 col-md-6">
                    <div className="input-group what-we-offer-search mb-3">
                        <div className="form-outline">
                            <input type="search" id="search" placeholder="Type event" className="form-control" onChange={(e) => applyFilter(e)} />
                        </div>
                        <button type="button" className="btn">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div className="horizontal-scroll-bar">
                        {isLoadingServingOptions ? <h6>Loading...</h6> :
                            servingOptionCache.filter((m) => m.servingOptionName.toLowerCase().includes(servingOptionFilter) || servingOptionFilter === '').map((spc, index) =>
                                <div key={index} >
                                    <label htmlFor={'servingOption' + index} className="form-check-label">
                                        <input className="form-check-input ps-2" type="checkbox" checked={spc.isSelected} onChange={(e)=>toggleServingOption(e, spc.servingOptionId)} id={'servingOption' + index}  />&nbsp;&nbsp;{spc.servingOptionName}                                        
                                    </label>
                                </div>)
                        }
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div>
                        <div className="my-2">
                            {isLoadingPendingServingOptions ? "" :
                                <>
                                    {dataPendingServingOptions.payload.length > 0 && <h6>Pending Requests:</h6>}
                                    {dataPendingServingOptions.payload.map((spec, index) => <div key={index} className="tag-item" >
                                        <span className="text">{spec.servingOptionName}</span>&nbsp;
                                        <span className="itemClose" onClick={() => { deleteServingOption({ servingOptionId: spec.servingOptionId }) }}><i className="fa-solid fa-circle-xmark"></i></span>
                                    </div>)}
                                </>
                            }
                        </div>
                        <div className="alert alert-light d-flex p-2" role="alert">
                            <i className="fa-sharp fa-solid fa-circle-info ms-1 me-2"></i>
                            <div className="text-dark tiny-text">If you do not find specific event type in your search scroll please add it below, it will start appear in your public profile after administrator approval.</div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col">
                                    <input type="text" value={newServingOptionName} onChange={(e) => setNewServingOptionName(e.target.value)} className="form-control" placeholder="Serving Option Name" maxLength="50"></input>
                                </div>
                                <div className="col-auto align-self-center">
                                    <button className="btn btn-danger mt-2" onClick={AddNewServingOptionName} type="submit">
                                        {newServingOptionLoading ? <span className="spinner-grow spinner-grow-sm"></span> :
                                            <i className="fa-sharp fa-solid fa-plus"></i>}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ServingOptionSelect

