import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import { useRef, useState, useEffect } from 'react';
import AuthService from "../hooks/Auth";
import { toPascal } from '../hooks/util';
import { useUpdateUserProfileMutation } from '../hooks/apiSlice';
import { useNavigate } from 'react-router-dom';


const USER_REGEX = /^.{3,}$/;


const AddBusiness = () => {

    const navigate = useNavigate();
    const businessNameRef = useRef();
    const [businessName, setBusinessName] = useState('');
    const [validBusinessName, setValidBusinessName] = useState(false);

    const [submitLoading, setSubmitLoading] = useState(false)
    const [updateUserProfile] = useUpdateUserProfileMutation()

    useEffect(() => {
        setValidBusinessName(USER_REGEX.test(businessName));
    }, [businessName])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitLoading(true);

        await updateUserProfile({ userProfileId: AuthService.getUserProfileId(), userProfileType: 'business', 'company.companyName': businessName })
            .then((response) => {
                AuthService.updateUserProfileTypeOnLocalStorage(response.data.payload);
                navigate('../company-panel');
            });
        setSubmitLoading(false);
    }


    return (

        <div>
            <Navbar />
            <section className="my-4 bg-pink">
                <form onSubmit={handleSubmit}>
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <div className="standardHeading">
                                    <h2><strong>Add your business</strong></h2>
                                </div>
                                <div className="row pt-4 margin0 form-log">
                                    <input
                                        type="text"
                                        id="businessname"
                                        ref={businessNameRef}
                                        autoComplete="off"
                                        onChange={(e) => setBusinessName(toPascal(e.target.value))}
                                        value={businessName}
                                        maxLength="60"
                                        className={!validBusinessName ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                                        placeholder="Business Name"
                                        required
                                        aria-invalid={validBusinessName ? "false" : "true"}
                                        aria-describedby="uidnote"
                                    />

                                    <button className="btn btn-danger btn-lg" disabled={!validBusinessName || submitLoading ? true : false}>Add My Business
                                        {submitLoading && <span className="ms-2 spinner-border spinner-border-sm"></span>}
                                    </button>

                                </div>
                            </div>
                            <div className="offset-md-1 col-lg-7 col-md-12 col-sm-12 col-12">
                                <img src="/images/signup-vendor.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <Footer></Footer>
        </div>
    )
}

export default AddBusiness