import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import axios from "axios";

import { useGetCuisinesQuery } from './../hooks/apiSlice';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';



const Menu = (props) => {

    const params = useParams();
    const [data, setData] = useState(props);
    const [cuisineId, setCuisineId] = useState("0");

    const { data: dataCuisines, isLoading: isLoadingCuisines } = useGetCuisinesQuery()

    useEffect(() => {
        axios.get(process.env.REACT_APP_BASE_URL + `Menu/GetMenuItemByCompanyId/${params.companyId}`)
            .then((response) => {
                setData(response.data);
            })
    }, [])


    const MenuItem = (props) => {

        const [showMore, setShowMore] = useState(false);

        return (
            <div className="row mx-1 mt-4 align-items-center dish-box">
                <div className="col-sm-5 col-5 text-center">
                    <i className="fas fa-globe"></i>&nbsp;{props.MenuItem.cuisine.cuisineName}
                    <img className="item-img" src={process.env.REACT_APP_BASE_URL + "MediaStorage/MenuItemImages/" + (props.MenuItem.pictureFileName === "" ? "MenuItemNoImage.png" : props.MenuItem.pictureFileName)} alt={props.MenuItem.pictureFileName} />
                </div>
                <div className="col-sm-7 col-7">
                    <div className="dish-info">
                        <div className="d-flex justify-content-between title">
                            <h5>{props.MenuItem.menuItemName}</h5>
                        </div>
                        <div className="dietry d-flex flex-wrap gap-1">
                            {
                                props.MenuItem.dietaries.map((e, index) => <img key={index} src={"/images/dietaryIcon_" + e.dietaryName.replace(" ", "") + ".png"} height="24" width="24" alt={e.dietaryName} title={e.dietaryName} />)
                            }
                        </div>

                        {
                            props.MenuItem.description.length > 30 ?
                                <p className="desc">
                                    {
                                        showMore ? props.MenuItem.description : props.MenuItem.description.slice(0, 30)
                                    }
                                    <button className="mt-1 btn btn-danger btn-rounded more-btn" onClick={() => setShowMore(!showMore)}>
                                        {showMore ? "show less" : " more"}
                                    </button>
                                </p>
                                : <p className="desc">{props.MenuItem.description}</p>
                        }

                        <p className="serving"><i className="fas fa-user"></i>&nbsp;{props.MenuItem.noOfServing}</p>
                        <p className="price">${props.MenuItem.price}</p>
                    </div>

                </div>
            </div>
        )
    }





    const MenuList = (props) => {

        const groupByCategory = data.payload.filter((m) => m.cuisine.cuisineId === parseInt(cuisineId) || cuisineId === "0").reduce((sum, li, index) => {
            sum[li.menuItemCategory.menuItemCategoryName] = sum[li.menuItemCategory.menuItemCategoryName] ?? [];
            sum[li.menuItemCategory.menuItemCategoryName].push(li);
            return sum;
        }, {});

        return (
            <Tabs className="mb-5">
                {Object.keys(groupByCategory).map((m, index) =>
                    <Tab key={index} eventKey={index} title={<span><img className="mx-2" src={"/images/" + m.replace(" ", "") + ".png"} alt={m} title={m} width="54px" height="53px" /></span>}>
                        <div className="row">
                            {groupByCategory[m].map((k, index) =>
                                <div className="col-12 col-md-6 col-lg-6 col-xl-4" key={index}><MenuItem MenuItem={k} /></div>
                            )}
                        </div>
                    </Tab>
                )}
            </Tabs>
        )
    }


    return (
        
        <div className="mt-xl-5">
            {/* {console.log(data.payload)} */}
            {data.payload &&
                <section className="" id="menu">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-12 col-md-12 col-lg-11 col-xl-12 col-xxl-12">
                                <div className="menu-select">
                                    {isLoadingCuisines ? <h1>Loading...</h1> :
                                        <select onChange={(e) => setCuisineId(e.target.value)} name="selectCuisine" >
                                            <option value="0">Select by Cusinie</option>
                                            {
                                                dataCuisines.payload.map((cu, index) =>
                                                    <option key={index} value={cu.cuisineId} >{cu.cuisineName}</option>
                                                )
                                            }
                                        </select>
                                    }
                                </div>
                                <MenuList MenuItemList={props.MenuItem} />
                            </div>
                        </div>
                    </div>
                </section>
            }
        </div>
    )
}


export default Menu