import { useGetReviewByCompanyQuery } from './../../hooks/apiSlice';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'




const Review = () => {

    const { data: dataReviewByCompanyId, isLoading: isLoadingReviewByCompanyId } = useGetReviewByCompanyQuery({ companyid: 0, pageNum: 1, sortColumn: 'reviewDate', sortDirection: 'desc', hideReview: false })

    return (
        <div>
            <div className="row recievedReviews">
                <div className="signin-up-wrapper">
                    <h3>
                        <strong>Recieved Review(s)</strong>
                    </h3>
                </div>

                <div className="Review new-reviews">
                    {isLoadingReviewByCompanyId ? "Loading..." :

                        dataReviewByCompanyId.data.map((review, index) => 
                            < div key={index} className="review-items">
                                <div className="row box py-4*">
                                    <div className="col-12 user-info">
                                        {review.userProfile.avatar === null ?
                                            <div className="picture-placeholder">
                                               <div className="reviewAvatar">{review.userProfile.firstName.substring(0, 1)}</div>
                                            </div>
                                            :
                                            <div className="user-picture">
                                                <img style={{ 'borderRadius': '50%' }} src={process.env.REACT_APP_BASE_URL + "MediaStorage/Avatar/" + review.userProfile.avatar} alt="" />
                                            </div>}

                                        <div className="user-meta">
                                            <div className="meta">
                                                <p className="name">{review.userProfile.firstName}</p> <p className="time">{moment.utc(new Date(review.reviewDate), 'DD/MM/YYYY hh:mm').local().startOf('seconds').fromNow()}</p>
                                            </div>
                                            <div className="stars-yellow me-2*">
                                                {
                                                    Array.from({ length: review.ratingAverage }).map((s, index) => {
                                                        return <i key={index} className="fa-solid fa-star checked"></i>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 px-3* mt-2 user-review">
                                        <p>
                                            {review.comment}
                                        </p>
                                    </div>
                                    <div className="col-12 px-3 pt-2">
                                        <div className="row">
                                            {review.reviewImages.map((reviewImg, index) =>
                                                <div key={index}  className="col-auto px-1">
                                                    <img className="review-image zoom*" src={process.env.REACT_APP_BASE_URL + "MediaStorage/ReviewImages/" + reviewImg.imageFileName + reviewImg.imageFileExtention} alt='' />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div >
    )
}

export default Review
