import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import axios from "axios";

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


const ForgotPassword = () => {

    const [fpEmail, setFpEmail] = useState("");
    const [fpEmailValid, setFpEmailValid] = useState(false);
    const [show, setShow] = useState(false);



    useEffect(() => {
        setFpEmailValid(EMAIL_REGEX.test(fpEmail));
    }, [fpEmail])



    const submitForgetPassword = () => {
        axios.post(process.env.REACT_APP_BASE_URL + 'UserProfile/ForgetPassword', null, {
            params: { email: fpEmail }
          }).then((res) => {
                toast.success(`Your password has been sent to your registered email address`, { position: toast.POSITION.TOP_CENTER })
                setShow(false)
            })
    };


    const handleClose = () => setShow(false);


    return (
        <>
            
                <button className="lowHightButton" onClick={()=>{setShow(true)}} href="#" title="click here if you forgot your password">
                <i className="fas fa-key"></i>  Forgot Password?
                </button>
            
            
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> <h5 className="modal-title" id="exampleModalLabel">Forgot Password</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div >
                        <div className="py-2">
                            <h6>You will receive instructions for reseting your password</h6>
                        </div>
                        <input value={fpEmail} className="form-control" onChange={(e) => { setFpEmail(e.target.value) }}  ></input>
                        <div className="text-end">
                            <button className="btn btn-danger mt-2" disabled={!fpEmailValid ? true : false} onClick={submitForgetPassword}>Submit</button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>



        </>
    )


}


export default ForgotPassword