import React, { useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom';
import AuthService from "../hooks/Auth";
import { useNavigate } from "react-router-dom";
import CompanySelect from './CatererSearchOption/CompanySelect';
import CartReducer from "./../components/CompanyPanel/CartContext";
import {shortify} from "../hooks/util";

var ChildStateModificationFunc;
const Navbar = (props) => {

  const [cartState, dispatch] = useReducer(CartReducer, []);

  const navigate = useNavigate();
  const logOut = () => {
    AuthService.logout();
    navigate("/");
  };

  const onCompanySelection = (e) => {
    //navigate("/company-detail/1070");
  }

  useEffect(() => {
    if (props.setRefreshFunction) {
      props.setRefreshFunction(() => refreshMe)
    }
  }, []);


  useEffect(() => {
    refreshMe();
  }, []);

  function refreshMe() {
    dispatch({ type: "GET-CART" });
  };



  ChildStateModificationFunc = () => {
    document.getElementsByClassName("user-image")[0].firstChild.src = AuthService.getAvatar() === 'null' ? "/Images/AvatarDefault.png" : process.env.REACT_APP_BASE_URL + "MediaStorage/Avatar/" + AuthService.getAvatar();
    document.getElementById("userFullName").innerHTML = shortify(AuthService.getCurrentUser(), 20);

    if (AuthService.getUserProfileType() === 'business')
      document.getElementById("companyName").innerHTML = shortify(AuthService.getCompanyName(), 30);
  }
  
  return (

    <header>
      <div className="container-xl">
        <nav className="navbar navbar-expand-sm top-navigation* float-end* aos-init aos-animate" data-aos="fade-left" >
          <a href="/" className="align-self-start">
            <img src="/images/logo.png" alt="Halal Caters" />
          </a>
          <button id="dd" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse top-navigation justify-content-end " id="navbarNavDropdown">
            <ul className="mobile navbar-nav align-items-sm-center mr-auto">
              <li className="nav-item search">

                <CompanySelect handleSelection={onCompanySelection} />
                <svg className="search-icon" style={{ 'marginLeft': '10px', 'cursor': 'pointer' }} width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                  onClick={(e) => {
                    var x = document.getElementById("companySearchComp");
                    x.style.display = (x.style.display === "block") ? "none" : "block"
                  }}
                ><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/flatdeal">Flat Deals</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/customdeal">Custom Deal </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/about-us">About us </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact-us">Contact us</a>
              </li>
              {(AuthService.getCurrentUser() !== null) ?
                (
                  <li>
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item dropdown user-dd">
                        <a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">

                          {AuthService.getUserProfileType() === 'admin' ?
                            <>
                              <div className="user-image"><i style={{ 'color': 'orange' }} className="fas fa-user-circle fa-2x"></i></div>
                              <div className="username">
                                {AuthService.getCurrentUser()} &nbsp;
                              </div>
                            </> :

                            AuthService.getUserProfileType() === 'business' ?
                              <>
                                <div className="user-image">
                                  <img src={AuthService.getAvatar() === 'null' ? "/Images/AvatarDefault.png" : process.env.REACT_APP_BASE_URL + "MediaStorage/Avatar/" + AuthService.getAvatar()} />
                                </div><div className="username me-2">&nbsp;<span id="userFullName">{shortify(AuthService.getCurrentUser(), 20)}</span>
                                  <br />
                                  <span style={{ 'fontSize': '11px' }} id="companyName" className="caterer-dietry-icons px-2 py-0">{shortify(AuthService.getCompanyName(), 30)}</span>
                                </div>

                              </> :
                              <>
                                <div className="user-image"><img src={AuthService.getAvatar() === 'null' ? "/Images/AvatarDefault.png" : process.env.REACT_APP_BASE_URL + "MediaStorage/Avatar/" + AuthService.getAvatar()} /></div>
                                <div className="username">
                                  <span id="userFullName">{shortify(AuthService.getCurrentUser(), 20)}</span>
                                </div>
                              </>
                          }
                          <i className="fa-solid fa-chevron-down"></i>
                        </a>

                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ 'left': 'inherit', 'right': '0px' }}>

                          <Link to="/profile" className="dropdown-item user-list">Profile</Link>

                          {AuthService.getUserProfileType() === "customer" &&
                            <>
                              <Link to="/add-business" className="dropdown-item user-list">Add My Business</Link>
                              <Link to="/favorites" className="dropdown-item user-list">My Favorites</Link>
                            </>
                          }
                          {AuthService.getUserProfileType() === "business" &&
                            <>
                              <Link to="/company-panel" className="dropdown-item user-list">My Company Panel</Link>
                              <Link to={'/company-detail/' + AuthService.getCompanyId()} className="dropdown-item user-list">Preview Business Profile</Link>
                              <Link to="/favorites" className="dropdown-item user-list">My Favorites</Link>
                            </>
                          }

                          {AuthService.getUserProfileId() === '336' &&
                            <>
                              <Link to="/admin" className="dropdown-item user-list">Admin Panel</Link>
                            </>
                          }
                          <button className="dropdown-item user-list" onClick={logOut}>Logout</button>
                        </div>
                      </li>
                    </ul>
                  </li>
                )
                : (<>
                  <li className="nav-item">
                    <Link to="/signin" className="nav-link red-link">Sign-in</Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/signup-home" className="nav-link red-link">Sign-up</Link>
                  </li>
                </>)}
              {cartState !== null &&
                <li className="nav-item">
                  <a href="/cart"><i className="fas fa-shopping-cart"></i><span style={{ width: '1.4rem', height: '1.4rem', position: 'absolute', color: '#FFF', borderRadius: '50%', display: 'grid', placeItems: 'center', top: '35%', left: '98%', backgroundColor: 'red' }}> {cartState.length}</span></a>
                </li>}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}


export default Navbar
export { ChildStateModificationFunc }