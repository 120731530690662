import React from 'react'
import { useGetCompanyDetailQuery } from '../hooks/apiSlice';
import { useParams } from "react-router-dom";

const Header = () => {

    const params = useParams();
    const { data: dataCompanyDetail, isLoading: isLoadingCompanyDetail } = useGetCompanyDetailQuery(params.companyId)

    return (

        <header className="customer-website-header">
            <div className="container-xl">
                <nav className="navbar navbar-expand-md top-navigation* float-end* aos-init aos-animate px-xxl-5 mx-xxl-5" data-aos="fade-left" >
                    {(isLoadingCompanyDetail) ? "Loading..." :
                        <a href="/home-index" className="align-self-start">
                            <img src={dataCompanyDetail.payload.logoFileName !== null ? process.env.REACT_APP_BASE_URL + `MediaStorage/Logo/${dataCompanyDetail.payload.logoFileName}` : '/images/DefaultCaterer.png'} alt="Logo" />
                        </a>
                    }
                    <button id="dd" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse top-navigation justify-content-end " id="navbarNavDropdown">
                        <ul className="mobile navbar-nav align-items-center mr-auto">
                            <li className="nav-item">
                                <a className="nav-link" href={"/my-site/home/"+params.companyId}>Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={"/my-site/about-us/"+params.companyId}>About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={"/my-site/live-menu/"+params.companyId}>Live Menu</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={"/my-site/tray-menu/"+params.companyId}>Tray Menu</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={"/my-site/location/"+params.companyId}>Location</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link customer btn-primary" href="#">Order Now</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>

    )
}


export default Header