import React from 'react'


const Footer = () => {

  return (



    <footer>
      <div className="footer-overlay"></div>
        <div className="container mb-4 footer-top">
          <div className="row justify-content-center mb-4 ">
            <div className="col-auto">
              <div className="footer-logo">
                  <img src="/images/footer-logo.png" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <div className="footer-txt">
                <p>Whether you're planning a small gathering or a large event, a halal caters can help you create a memorable and delicious meal that meets your dietary needs and preferences, by selecting a halal vendors, you can ensure that your event is inclusive and accommodating to all of your guests.</p>
              </div>
              <div>
                <div className="template-demo mt-3">
                  <a href="https://facebook.com" target="_blank" className="btn btn-social-icon btn-rounded mx-2"><i className="fa-brands fa-facebook"></i></a>
                  <a href="https://youtube.com" target="_blank" className="btn btn-social-icon btn-rounded mx-2"><i className="fa-brands fa-youtube"></i></a>
                  <a href="https://twitter.com" target="_blank" className="btn btn-social-icon btn-rounded mx-2"><i className="fa-brands fa-twitter"></i></a>
                  <a href="https://instagram.com" target="_blank" className="btn btn-social-icon btn-rounded mx-2"><i className="fa-brands fa-instagram"></i></a>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12  col-xs-12">
              <h6>City Vendors</h6>
              <ul className="list-unstyled">
                <li><a className="footer-botton-link" href="/restaurants/california">California</a></li>
                <li><a className="footer-botton-link" href="/restaurants/florida">Florida</a></li>
                <li><a className="footer-botton-link" href="/restaurants/illinois">Illinois</a></li>
                <li><a className="footer-botton-link" href="/restaurants/michigan">Michigan</a></li>
                <li><a className="footer-botton-link" href="/restaurants/texas">Texas</a></li>
              </ul>
            </div>
            
            <div className="col-lg-2 col-md-2 col-sm-12  col-xs-12">
              <h6>Quick Links</h6>
              <ul className="list-unstyled ">
                <li><a className="footer-botton-link" href="/advertise-with-us">Advertise with us</a></li>
                <li><a className="footer-botton-link" href="/faq">FAQ</a></li>
                <li><a className="footer-botton-link" href="/about-us">About Us</a></li>
                <li><a className="footer-botton-link" href="/contact-us">Contact Us</a></li>
                <li><a className="footer-botton-link" href="/blog">Blog</a></li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12  col-xs-12">
              <h6>Others</h6>
              <ul className="list-unstyled ">
                <li><a className="footer-botton-link" href="/airline-meals">Airline Meals</a></li>
                <li><a className="footer-botton-link" href="/halal-authorities">Halal Authorities</a></li>
                <li><a className="footer-botton-link" href="/american-halal-manufacturers">American Halal Manufacturers</a></li>
                <li><a className="footer-botton-link" href="/halal-offering">Halal Offering</a></li>
                <li><a className="footer-botton-link" href="/meat-poultry">Meat & Poultry (Processed)</a></li>
              </ul>
            </div>
          </div>
        </div>

      <div className="container footer-bottom">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-6">
            <ul className="list-unstyled">
              <li><a className="footer-botton-link" href="/privacy-policy">Privacy Policy </a></li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-6 text-md-end text-sm-center copyright-txt">
            © {new Date().getFullYear()} Halal Caters.com
          </div>


        </div>
      </div>

    </footer>


  )
}


export default Footer