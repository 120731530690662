import { useState, useEffect } from "react";
import { useGetServingOptionsQuery } from '../../hooks/apiSlice';
import { useSearchParams } from "react-router-dom";

const ServingOptionSelection = ({ ServingOptionSelectionHandler }) => {

  const [checked, setChecked] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: dataServingOption, isLoading: isLoadingServingOption } = useGetServingOptionsQuery()


  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, parseInt(event.target.value)];
    } else {
      updatedList.splice(checked.indexOf(parseInt(event.target.value)), 1);
    }
    setChecked(updatedList);
    ServingOptionSelectionHandler(updatedList);


    if (updatedList.length === 0)
            searchParams.delete("ServingOption")
        else
            searchParams.set("ServingOption", updatedList);

        setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get("ServingOption")) {
      setChecked(searchParams.get("ServingOption").split(",").map((n) => parseInt(n)));
      ServingOptionSelectionHandler(searchParams.get("ServingOption").split(",").map((n) => parseInt(n)));
    }
  }, []);




  return (
    <div className="horizontal-scroll-bar">
      {
        isLoadingServingOption ? <h1>loading...</h1> :
          dataServingOption.payload.map((item, index) => (
            <div key={index}>
              <label className="form-check-label">
                <input className="form-check-input" checked={checked.some(element => { return element === item.servingOptionId })} value={item.servingOptionId} type="checkbox" onChange={(e) => handleCheck(e)} />&nbsp;&nbsp;{item.servingOptionName}
              </label>
            </div>
          ))
      }
    </div>
  );
};

export default ServingOptionSelection;