import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import { ToastContainer } from 'react-toastify';
import BusinessProfile from '../components/CompanyPanel/BusinessProfile';
import Offers from '../components/CompanyPanel/Offers';
import Message from '../components/CompanyPanel/Message';
import Video from '../components/CompanyPanel/Video';
import EventGallery from '../components/CompanyPanel/EventGallery';
import ListingAd from '../components/CompanyPanel/PaidListing';
import BannerAd from '../components/CompanyPanel/BannerAd';
import Boost from '../components/CompanyPanel/Boost';
import Review from '../components/CompanyPanel/Review';
import MenuItem from '../components/CompanyPanel/MenuItem';
import Deal from '../components/CompanyPanel/Deal';
import { useState, useEffect } from "react";

import { useToggleListingStatusMutation, useGetBusinessProfileQuery, useGetOfferCompletionStatusQuery } from './../hooks/apiSlice';

const CompanyPanel = () => {
    const [bpPer, setBpPer] = useState(0);
    const [videoPer, setVideoPer] = useState(0);

    const { data: dataBusinessProfile, isLoading: isLoadingBusinessProfile } = useGetBusinessProfileQuery()
    const { data: dataGetOfferCompletionStatus, isLoading: isLoadingGetOfferCompletionStatus } = useGetOfferCompletionStatusQuery()
    const [toggleListingStatus] = useToggleListingStatusMutation()

    const [listingStatus, setListingStatus] = useState(false);
    const [companyId, setCompanyId] = useState(0);

    const [childRefreshFunction, setChildRefreshFunction] = useState(null);

    useEffect(() => {
        if (isLoadingBusinessProfile === false) {
            setListingStatus(dataBusinessProfile.payload.listingStatus)
            setCompanyId(dataBusinessProfile.payload.companyId)
        }
    }, [isLoadingBusinessProfile])

    const updateBusinessProfileCompletionPercentage = (pert) => {
        setBpPer(pert);
    }

    const updateVideoCompletionPercentage = (pert) => {
        setVideoPer(pert);
    }

    const updateCartItemCount = () => {
        childRefreshFunction();
    }




    return (

        <div>
            <div>
                <ToastContainer />
            </div>
            <Navbar setRefreshFunction={(f) => {
                setChildRefreshFunction(f);
            }} />
            <main role="main">
                <section className="pt-4 pb-4 mb-5">
                    <div className="container">
                        <div className="row mb-0">
                            <div className="col-12">
                                <h2 className="standardHeading m-0 p-0">Dashboard</h2>
                            </div>
                        </div>

                        <div className="row ">

                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                <div className="card progress-card mt-4">
                                    <div className="card-body">
                                        <div className="d-lg-none form-check form-switch disable-profile justify-content-center">
                                            <span>
                                                <input className="form-check-input" type="checkbox" id="disableProfile" />
                                                <label className="form-check-label" htmlFor="disableProfile"></label>
                                            </span>
                                            <span style={{ 'paddingLeft': '10px', 'lineHeight': '1' }}>Business listing</span>
                                        </div>
                                        {isLoadingGetOfferCompletionStatus ? "" :
                                            <h4><strong>{Math.round((bpPer + dataGetOfferCompletionStatus.payload + videoPer) / 3)}%</strong> Profile Completed</h4>
                                        }
                                        <div className="progress mb-4">
                                            {isLoadingGetOfferCompletionStatus ? "" :
                                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-primary2" role="progressbar" aria-label="Animated striped example" aria-valuenow="24" aria-valuemin="60" aria-valuemax="100" style={{ width: Math.round(((bpPer + dataGetOfferCompletionStatus.payload + videoPer) / 3) * 2.75) }}></div>
                                            }
                                        </div>
                                        <div className="d-lg-block d-none nav caterer-panel-items flex-column nav-pills me-3*" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ 'flexWrap': 'nowrap' }}>

                                            <div className="nav-link active" id="v-pills-businessprofile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-businessprofile" type="button" role="tab" aria-controls="v-pills-businessprofile" aria-selected="true">
                                                <div className="row progress-chart" >
                                                    <div className="col-md-8 progress-left" >
                                                        <p>Business Profile</p>
                                                    </div>
                                                    <div className="col-md-4 ps-0 progress-right">
                                                        {bpPer}%
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="nav-link" id="v-pills-offers-tab" data-bs-toggle="pill" data-bs-target="#v-pills-offers" type="button" role="tab" aria-controls="v-pills-offers" aria-selected="false">
                                                <div className="row progress-chart">
                                                    <div className="col-md-8 progress-left">
                                                        <p>What we offer</p>
                                                    </div>
                                                    <div className="col-md-4 ps-0 progress-right">
                                                        {isLoadingGetOfferCompletionStatus ? "" : dataGetOfferCompletionStatus.payload + "%"}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="nav-link" id="v-pills-paidListing-tab" data-bs-toggle="pill" data-bs-target="#v-pills-paidListing" type="button" role="tab" aria-controls="v-pills-paidListing" aria-selected="true">
                                                <div className="row progress-chart">
                                                    <div className="col-md-8 progress-left">
                                                        <p>Paid Listing</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nav-link" id="v-pills-bannerAd-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bannerAd" type="button" role="tab" aria-controls="v-pills-bannerAd" aria-selected="true">
                                                <div className="row progress-chart">
                                                    <div className="col-md-8 progress-left">
                                                        <p>Banner Ad</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nav-link" id="v-pills-boost-tab" data-bs-toggle="pill" data-bs-target="#v-pills-boost" type="button" role="tab" aria-controls="v-pills-boost" aria-selected="true">
                                                <div className="row progress-chart">
                                                    <div className="col-md-8 progress-left">
                                                        <p>Boost</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nav-link" id="v-pills-eventgallery-tab" data-bs-toggle="pill" data-bs-target="#v-pills-eventgallery" type="button" role="tab" aria-controls="v-pills-eventgallery" aria-selected="true">
                                                <div className="row progress-chart">
                                                    <div className="col-md-8 progress-left">
                                                        <p>Gallery</p>
                                                    </div>
                                                    <div className="col-md-4 ps-0 progress-right">
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="nav-link" id="v-pills-menuitem-tab" data-bs-toggle="pill" data-bs-target="#v-pills-menuitem" type="button" role="tab" aria-controls="v-pills-menuitem" aria-selected="true">
                                                <div className="row progress-chart" >
                                                    <div className="col-md-8 progress-left">
                                                        <p>Menu Item</p>
                                                    </div>
                                                    <div className="col-md-4 ps-0 progress-right">
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="nav-link" id="v-pills-deal-tab" data-bs-toggle="pill" data-bs-target="#v-pills-deal" type="button" role="tab" aria-controls="v-pills-deal" aria-selected="true">
                                                <div className="row progress-chart" >
                                                    <div className="col-md-8 progress-left">
                                                        <p>Deal</p>
                                                    </div>
                                                    <div className="col-md-4 ps-0 progress-right">
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="nav-link" id="v-pills-videos-tab" data-bs-toggle="pill" data-bs-target="#v-pills-videos" type="button" role="tab" aria-controls="v-pills-videos" aria-selected="false">
                                                <div className="row progress-chart">
                                                    <div className="col-md-8 progress-left">
                                                        <p>Video</p>
                                                    </div>
                                                    <div className="col-md-4 ps-0 progress-right">
                                                        {videoPer}%
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="nav-link" id="v-pills-reviews-tab" data-bs-toggle="pill" data-bs-target="#v-pills-reviews" type="button" role="tab" aria-controls="v-pills-reviews" aria-selected="true">
                                                <div className="row progress-chart">
                                                    <div className="col-md-8 progress-left">
                                                        <p>Reviews</p>
                                                    </div>
                                                    <div className="col-md-4 ps-0 progress-right">
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                                <div className="row progress-chart">
                                                    <div className="col-md-9 progress-left">
                                                        <p>Messages</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nav-link" data-bs-toggle="pill" type="button" role="tab" aria-selected="false">
                                                <div className="row progress-chart bg-danger rounded text-white">
                                                    <div className="col-md-9 progress-left">
                                                        <p><a href={'/my-site/home/'+companyId} target="_blank" style={{'color':'#ffffff', 'textDecoration':'none'}}>Preview Your Website</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="nav-link" id="v-pills-badges-tab" data-bs-toggle="pill" data-bs-target="#v-pills-badges" type="button" role="tab" aria-controls="v-pills-badges" aria-selected="false">
                                                    <div className="row progress-chart">
                                                        <div className="col-md-9 progress-left">
                                                            <p>Badge</p>
                                                        </div>
                                                    </div>
                                                </div> */}

                                            {/* <div className="nav-link" id="v-pills-awards-tab" data-bs-toggle="pill" data-bs-target="#v-pills-awards" type="button" role="tab" aria-controls="v-pills-awards" aria-selected="false">
                                                <div className="row progress-chart">
                                                    <div className="col-md-9 progress-left">
                                                        <p>Awards</p>
                                                    </div>
                                                    <div className="col-md-3 ps-0 progress-right">
                                                        {awardPer}%
                                                    </div>
                                                </div>
                                            </div> */}

                                            {isLoadingBusinessProfile ? "" :
                                                <div className="form-check form-switch disable-profile">
                                                    <span>
                                                        <input className="form-check-input" type="checkbox" id="desktopdisableProfile" checked={listingStatus} onChange={(e) => {
                                                            toggleListingStatus(e.target.checked);
                                                            setListingStatus(e.target.checked);
                                                        }} />
                                                        <label className="form-check-label" htmlFor="desktopdisableProfile"></label>
                                                    </span>
                                                    <span style={{ 'paddingLeft': '10px', 'lineHeight': '1' }}>Business listing</span>
                                                </div>
                                            }















                                        </div>

                                    </div>
                                </div>
                            </div>



                            <div className="col-lg-9 col-md-12 col-sm-12 col-12 d-lg-block d-none  profile-details-container">
                                <div className="card border-0">
                                    <div className="card-body">
                                        <div className="tab-content" style={{ backgroundColor: "#ffffff", border: 'yellow 0px solid' }} id="v-pills-tabContent">
                                            <div className="tab-pane fade show active" id="v-pills-businessprofile" role="tabpanel" aria-labelledby="v-pills-businessprofile-tab"><BusinessProfile menuCompletionPercentageHandler={updateBusinessProfileCompletionPercentage} /></div>
                                            <div className="tab-pane fade" id="v-pills-offers" role="tabpanel" aria-labelledby="v-pills-offers-tab"><Offers /></div>
                                            {/*<div className="tab-pane fade" id="v-pills-awards" role="tabpanel" aria-labelledby="v-pills-awards-tab"><Award menuCompletionPercentageHandler={updateAwardCompletionPercentage} /></div>*/}
                                            <div className="tab-pane fade" id="v-pills-videos" role="tabpanel" aria-labelledby="v-pills-videos-tab"><Video menuCompletionPercentageHandler={updateVideoCompletionPercentage} /></div>
                                            {/*  <div className="tab-pane fade" id="v-pills-badges" role="tabpanel" aria-labelledby="v-pills-badges-tab"><Badge /></div>*/}
                                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab"><Message /></div>
                                            <div className="tab-pane fade" id="v-pills-eventgallery" role="tabpanel" aria-labelledby="v-pills-eventgallery-tab"><EventGallery /></div>
                                            <div className="tab-pane fade" id="v-pills-paidListing" role="tabpanel" aria-labelledby="v-pills-paidListing-tab"><ListingAd onCartItemChange={updateCartItemCount} /></div>
                                            <div className="tab-pane fade" id="v-pills-bannerAd" role="tabpanel" aria-labelledby="v-pills-bannerAd-tab"><BannerAd onCartItemChange={updateCartItemCount} /></div>
                                            <div className="tab-pane fade" id="v-pills-boost" role="tabpanel" aria-labelledby="v-pills-boost-tab"><Boost onCartItemChange={updateCartItemCount} /></div>
                                            <div className="tab-pane fade" id="v-pills-reviews" role="tabpanel" aria-labelledby="v-pills-reviews-tab"><Review /></div>
                                            <div className="tab-pane fade" id="v-pills-deal" role="tabpanel" aria-labelledby="v-pills-deal-tab"><Deal /></div>
                                            <div className="tab-pane fade" id="v-pills-menuitem" role="tabpanel" aria-labelledby="v-pills-menuitem-tab"><MenuItem /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-5 d-lg-none profile-details-container mob">
                                <div className="accordion accordion-flush" id="accordionCatererPanel">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="businessprofile-tab">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#content-businessprofile-tab" aria-expanded="false" aria-controls="content-businessprofile-tab">
                                                <div className="progress-chart" >
                                                    <div className="progress-left" >
                                                        <img src="images/business.svg" alt="" /><p>Business Profile</p>
                                                    </div>
                                                    <div className="progress-right">
                                                        {bpPer}%
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="content-businessprofile-tab" className="accordion-collapse collapse" aria-labelledby="businessprofile-tab" data-bs-parent="#accordionCatererPanel">
                                            <div className="accordion-body">
                                                <BusinessProfile menuCompletionPercentageHandler={updateBusinessProfileCompletionPercentage} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="offers-tab">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#content-offers-tab" aria-expanded="false" aria-controls="content-offers-tab">
                                                <div className="progress-chart">
                                                    <div className="progress-left">
                                                        <img src="images/cuisine-icon.svg" alt="" /><p>What we offer</p>
                                                    </div>
                                                    <div className="progress-right">
                                                        {isLoadingGetOfferCompletionStatus ? "" : dataGetOfferCompletionStatus + "%"}
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="content-offers-tab" className="accordion-collapse collapse" aria-labelledby="offers-tab" data-bs-parent="#accordionCatererPanel">
                                            <div className="accordion-body">
                                                <Offers />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="eventgallery-tab">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#content-eventgallery-tab" aria-expanded="false" aria-controls="content-eventgallery-tab">
                                                <div className="progress-chart">
                                                    <div className="progress-left">
                                                        <img src="images/gallery.svg" alt="" /><p>Gallery</p>
                                                    </div>
                                                    <div className="progress-right">
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="content-eventgallery-tab" className="accordion-collapse collapse" aria-labelledby="eventgallery-tab" data-bs-parent="#accordionCatererPanel">
                                            <div className="accordion-body">
                                                <EventGallery />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="paidListing-tab">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#content-paidListing-tab" aria-expanded="false" aria-controls="content-paidListing-tab">
                                                <div className="progress-chart">
                                                    <div className="progress-left">
                                                        <img src="images/gallery.svg" alt="" /><p>Gallery</p>
                                                    </div>
                                                    <div className="progress-right">
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="content-paidListing-tab" className="accordion-collapse collapse" aria-labelledby="paidListing-tab" data-bs-parent="#accordionCatererPanel">
                                            <div className="accordion-body">
                                                {/* <ListingAd /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="menuitem-tab">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#content-menuitem-tab" aria-expanded="false" aria-controls="content-menuitem-tab">
                                                <div className="progress-chart" >
                                                    <div className="progress-left">
                                                        <img src="images/gallery.svg" alt="" /><p>Menu Item</p>
                                                    </div>
                                                    <div className="progress-right">
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="content-menuitem-tab" className="accordion-collapse collapse" aria-labelledby="menuitem-tab" data-bs-parent="#accordionCatererPanel">
                                            <div className="accordion-body">
                                                <MenuItem />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="deal-tab">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#content-deal-tab" aria-expanded="false" aria-controls="content-deal-tab">
                                                <div className="progress-chart" >
                                                    <div className="progress-left">
                                                        <img src="images/gallery.svg" alt="" /><p>Deal</p>
                                                    </div>
                                                    <div className="progress-right">
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="content-menuitem-tab" className="accordion-collapse collapse" aria-labelledby="deal-tab" data-bs-parent="#accordionCatererPanel">
                                            <div className="accordion-body">
                                                <Deal />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="videos-tab">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#content-videos-tab" aria-expanded="false" aria-controls="content-videos-tab">
                                                <div className="progress-chart">
                                                    <div className="progress-left">
                                                        <img src="images/gallery.svg" alt="" /><p>Video</p>
                                                    </div>
                                                    <div className="progress-right">
                                                        {videoPer}%
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="content-videos-tab" className="accordion-collapse collapse" aria-labelledby="videos-tab" data-bs-parent="#accordionCatererPanel">
                                            <div className="accordion-body">
                                                <Video menuCompletionPercentageHandler={updateVideoCompletionPercentage} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="reviews-tab">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#content-reviews-tab" aria-expanded="false" aria-controls="content-reviews-tab">
                                                <div className="progress-chart">
                                                    <div className="progress-left">
                                                        <img src="images/gallery.svg" alt="" /><p>Reviews</p>
                                                    </div>
                                                    <div className="progress-right">
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="content-reviews-tab" className="accordion-collapse collapse" aria-labelledby="reviews-tab" data-bs-parent="#accordionCatererPanel">
                                            <div className="accordion-body">
                                                <Review />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="messages-tab">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#content-messages-tab" aria-expanded="false" aria-controls="content-messages-tab">
                                                <div className="progress-chart">
                                                    <div className="progress-left">
                                                        <img src="images/award-icon.svg" alt="" /><p>Messages</p>
                                                    </div>
                                                    <div className="progress-right">
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="content-messages-tab" className="accordion-collapse collapse" aria-labelledby="messages-tab" data-bs-parent="#accordionCatererPanel">
                                            <div className="accordion-body">
                                                <Message />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="accordion-item">
                                            <h2 className="accordion-header" id="badges-tab">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#content-badges-tab" aria-expanded="false" aria-controls="content-badges-tab">
                                                    <div className="progress-chart">
                                                        <div className="progress-left">
                                                            <img src="images/award-icon.svg" alt="" /><p>Badge</p>
                                                        </div>
                                                        <div className="progress-right">
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id="content-badges-tab" className="accordion-collapse collapse" aria-labelledby="badges-tab" data-bs-parent="#accordionCatererPanel">
                                                <div className="accordion-body">
                                                    <Badge />
                                                </div>
                                            </div>
                                        </div> */}
                                    {/* <div className="accordion-item">
                                            <h2 className="accordion-header" id="awards-tab">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#content-awards-tab" aria-expanded="false" aria-controls="content-awards-tab">
                                                    <div className="progress-chart">
                                                        <div className="progress-left">
                                                            <img src="images/award-icon.svg" alt="" /><p>Awards</p>
                                                        </div>
                                                        <div className="progress-right">
                                                            {awardPer}%
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id="content-awards-tab" className="accordion-collapse collapse" aria-labelledby="awards-tab" data-bs-parent="#accordionCatererPanel">
                                                <div className="accordion-body">
                                                    <Award menuCompletionPercentageHandler={updateAwardCompletionPercentage} />
                                                </div>
                                            </div>
                                        </div> */}
                                </div>
                            </div>


                        </div>

                    </div>
                </section>
                <Footer></Footer>
            </main>
        </div>
    )
}

export default CompanyPanel
