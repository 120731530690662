import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { toPascal } from '../../../hooks/util';
import {
    useGetCertificationsSelectionsByCompanyIdQuery, useGetPendingApprovalCertificationsQuery,
    useAddCertificationMutation, useDeleteCertificationMutation, useSelectCertificationMutation, useUnselectCertificationMutation
} from '../../../hooks/apiSlice';


const CertificationSelect = () => {

    const [newCertificationName, setNewCertificationName] = useState('')
    const [newCertificationLoading, setNewCertificationLoading] = useState(false)
    const [certificationFilter, setCertificationFilter] = useState('')
    const [certificationCache, setCertificationCache] = useState([])
    const [selectionCount, setSelectionCount] = useState(0)

    const { data: dataCertifications, isLoading: isLoadingCertifications } = useGetCertificationsSelectionsByCompanyIdQuery()
    const { data: dataPendingCertifications, isLoading: isLoadingPendingCertifications } = useGetPendingApprovalCertificationsQuery()

    const [addCertification] = useAddCertificationMutation()
    const [deleteCertification] = useDeleteCertificationMutation()
    const [unselectCertification] = useUnselectCertificationMutation()
    const [selectCertification] = useSelectCertificationMutation()

    const AddNewCertificationName = async (e) => {
        e.preventDefault();
        if (newCertificationName !== "") {
            if (dataCertifications.payload.some(item => newCertificationName.toUpperCase() === item.certificationName.toUpperCase())) {
                toast.error(newCertificationName + ` already exists, Please select it from the list`, { position: toast.POSITION.TOP_CENTER });
            }
            else if (dataPendingCertifications.payload.some(item => newCertificationName.toUpperCase() === item.certificationName.toUpperCase())) {
                toast.error(newCertificationName + ` already added`, { position: toast.POSITION.TOP_CENTER });
            }
            else {
                setNewCertificationLoading(true);
                const returned = await addCertification({ certificationName: toPascal(newCertificationName) }).unwrap();
                setNewCertificationLoading(false);
                setNewCertificationName('');
            }
        }
    }

    useEffect(() => {
        if (!isLoadingCertifications) {
            setCertificationCache(dataCertifications.payload);
        }
    }, [isLoadingCertifications]);


    const toggleCertification = (e, id) => {
        if (e.target.checked)
            selectCertification({ certificationId: parseInt(id) });
        else
            unselectCertification({ certificationId: parseInt(id) })

        const currentIndex = certificationCache.findIndex((todo) => todo.certificationId === id);
        const updatedTodo = { ...certificationCache[currentIndex], isSelected: e.target.checked };
        const newTodos = [
            ...certificationCache.slice(0, currentIndex),
            updatedTodo,
            ...certificationCache.slice(currentIndex + 1)
        ];
        setCertificationCache(newTodos);

        setSelectionCount(e.target.checked ? selectionCount + 1 : selectionCount - 1)
    }

    const applyFilter = (e) => {
        setCertificationFilter(e.target.value.toLowerCase());
    }

    useEffect(() => {
        if (isLoadingCertifications === false) {
            setSelectionCount(dataCertifications.payload.filter(item => item.isSelected === true).length)
        }
    }, [isLoadingCertifications])

    return (

        <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-4 border-top">
            <h4>Choose Certification({selectionCount})</h4>
            <div className="mt-3 row">
                <div className="col-12 col-md-6">
                    <div className="input-group what-we-offer-search mb-3">
                        <div className="form-outline">
                            <input type="search" id="search" placeholder="Type event" className="form-control" onChange={(e) => applyFilter(e)} />
                        </div>
                        <button type="button" className="btn">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div className="horizontal-scroll-bar">
                        {isLoadingCertifications ? <h6>Loading...</h6> :
                            certificationCache.filter((m) => m.certificationName.toLowerCase().includes(certificationFilter) || certificationFilter === '').map((spc, index) =>
                                <div key={index} >
                                    <label htmlFor={'certification' + index} className="form-check-label">
                                        <input className="form-check-input ps-2" type="checkbox" checked={spc.isSelected} onChange={(e)=>toggleCertification(e, spc.certificationId)} id={'certification' + index}  />&nbsp;&nbsp;{spc.certificationName}
                                    </label>
                                </div>)
                        }
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div>
                        <div className="my-2">
                            {isLoadingPendingCertifications ? "" :
                                <>
                                    {dataPendingCertifications.payload.length > 0 && <h6>Pending Requests:</h6>}
                                    {dataPendingCertifications.payload.map((spec, index) => <div key={index} className="tag-item" >
                                        <span className="text">{spec.certificationName}</span>&nbsp;
                                        <span className="itemClose" onClick={() => { deleteCertification({ certificationId: spec.certificationId }) }}><i className="fa-solid fa-circle-xmark"></i></span>
                                    </div>)}
                                </>
                            }
                        </div>
                        <div className="alert alert-light d-flex p-2" role="alert">
                            <i className="fa-sharp fa-solid fa-circle-info ms-1 me-2"></i>
                            <div className="text-dark tiny-text">If you do not find specific event type in your search scroll please add it below, it will start appear in your public profile after administrator approval.</div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col">
                                    <input type="text" value={newCertificationName} onChange={(e) => setNewCertificationName(e.target.value)} className="form-control" placeholder="Certification" maxLength="50"></input>
                                </div>
                                <div className="col-auto align-self-center">
                                    <button className="btn btn-danger mt-2" onClick={AddNewCertificationName} type="submit">
                                        {newCertificationLoading ? <span className="spinner-grow spinner-grow-sm"></span> :
                                            <i className="fa-sharp fa-solid fa-plus"></i>}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CertificationSelect

