import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import React, { createContext, useContext } from 'react'
// import { UserContext2 } from "../components/ProviderComponent";

import SigninPanel from './../components/SigninPanel';

import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';



import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";

// const UserContext = createContext();



const Signin = () => {
  

  // const context = useContext(UserContext2)


  return (

    <div>

      <Navbar />


      <section className="mt-4 mb-4 pb-5 border-0 signin-up-wrapper">
        <div className="container">
          <div className="row align-items-center">


            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              {/* current user: {context?.userName2}  */}
              <h2><strong>Hello! </strong>sign-in to
                your account</h2>

              {/* <div className="row margin0">


                <div className="col-lg-12 d-flex justify-content-center border rounded p-3 mt-4 loginwith">

                  <LoginSocialFacebook appId="2228773394176812"

                    onResolve={(response) => {
                      console.log(response)
                      setProfile(response.data)
                    }}

                    onReject={(error) => { console.log(error) }
                    }
                  >

                    <FacebookLoginButton />
                  </LoginSocialFacebook>

                  {profile.data ? <div><h1>{profile.name}</h1> <img src={profile.picture.data.url} alt="" /></div> : ""}



                </div>

                <div className="col-lg-12 d-flex justify-content-center p-3 mt-2 or-div">
                  <span>or</span>
                </div>

              </div> */}

              <div className="row margin0 pt-3 form-log">
              <SigninPanel parent="SigninPage" />
                <p className="text-center newlink">New user?  <Link to="/signup-home">Create an account</Link></p>

              </div>
            </div>


            <div className="offset-md-1 col-lg-7 col-md-12 col-sm-12 col-12">
              <img src="images/siginin.jpg" className="img-fluid" alt="" />
            </div>



          </div>
        </div>
      </section>


      <Footer></Footer>
    </div>
  )
}

export default Signin