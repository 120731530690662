import { useState } from "react";
import { useToggleFavoriteMutation } from '../hooks/apiSlice';
import AuthService from "../hooks/Auth";
import RatingStar from "./RatingStar"
import { CalculateDistance, getCoordinatesFromLink } from "../hooks/DistanceLib";

const CatererCardGrid = ({ item, crtLoc }) => {

  const [favoriteStatus, setFavoriteStatus] = useState(item.isFavorite);
  const [toggleFavoriteMutation] = useToggleFavoriteMutation();



  const Favorite = (e) => {
    return (
      <>
        {favoriteStatus ? <i style={{ 'cursor': 'pointer' }} title="remove from your favorites" className="text-danger fa-solid fa-heart"
          onClick={
            () => {
              toggleFavoriteMutation({ 'company.companyId': item.companyId, 'userProfile.userProfileId': AuthService.getUserProfileId(), status: !favoriteStatus })
              setFavoriteStatus(!favoriteStatus)
            }
          }></i> : <i style={{ 'cursor': 'pointer' }} title="add to your favorites" className="fa-regular fa-heart"
            onClick={
              () => {
                toggleFavoriteMutation({ 'company.companyId': item.companyId, 'userProfile.userProfileId': AuthService.getUserProfileId(), status: !favoriteStatus })
                setFavoriteStatus(!favoriteStatus)
              }
            }></i>
        }
      </>
    )
  }

  let coordinates = undefined
  const Distance = (props) => {
    coordinates = getCoordinatesFromLink(props.mapLocation)

    const calculateDistance = () => {
      return CalculateDistance({ from: { latitude: props.crtLoc.latitude, longitude: props.crtLoc.longitude }, to: coordinates })
    }
    return (
      (coordinates !== null) &&
      (props.crtLoc !== "") &&
      <>
        <div className="location-distance">
          <a className="IconLink" href={props.mapLocation} target="_blank" rel="noreferrer"><i className='fas fa-location-dot'></i><span>{calculateDistance(props.mapLocation)} km</span></a>
        </div>
      </>
    )
  }


  return (
    <div className="listing-box">
      <div className={(item.subscriptionPlanId === 3) ? "card premium-listing shadow-sm border rounded-3" : "card regular-listing shadow-sm border rounded-3"}>

        <div className="listing-head rounded-3 position-relative"
          style={{
            backgroundImage: 'url(' + process.env.REACT_APP_BASE_URL + "MediaStorage/EventGalleryImages/" + (item.defaultSnap === null ? "defaultGallerySnap.jpg" : item.defaultSnap) + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '175px',
          }}>
          <div className="subscription-plan badge">
            {(item.subscriptionPlanId === 2 || item.subscriptionPlanId === 3)
              &&
              <div className={item.subscriptionPlanId === 3 ? "list-badge yellow-bg" : "list-badge blue-bg"}>
                {item.subscriptionPlanName}
              </div>
            }
          </div>
          {item.companyRatingAverage > 0 ?
            <span className="niche list-badge blue-bg">{item.niche === 'Restaurant,Caterer' ? 'Restaurant & Caterer Both' : item.niche + ' Only'}</span>
            :
            <div>
              <span className="new-on-halal-cat list-badge blue-bg">New</span>
              <span className="niche list-badge blue-bg">{item.niche === 'Restaurant,Caterer' ? 'Restaurant & Caterer Both' : item.niche + ' Only'}</span>
            </div>
          }
          <div className="d-none d-sm-block* cat-save-icon float-end">
            {AuthService.getCurrentUser() !== null && <Favorite company={item} />}
          </div>
        </div>
        <div className="listing-body">
          <div className="body-top row padding0">
            <div className="col-9 d-flex align-items-center gap-2">
              <div className="listing-img">
                {
                  (item.logoFileName) ?
                    <img className="rounded-circle" src={process.env.REACT_APP_BASE_URL + "MediaStorage/logo/" + item.logoFileName} alt={item.companyName} style={{ 'maxHeight': '38px', 'maxWidth': '38px' }} />
                    :
                    <div className="picture-placeholder">
                      <div>{item.companyName.substring(0, 1)}</div>
                    </div>
                }
              </div>
              <div className="listing-name">
                <h5><a className="RegularLink" href={'/company-detail/' + item.companyId + '/' + item.city + '/' + item.companySlug}>{item.companyName}</a>

                  {(item.isVerified)
                    &&
                    <img src="/images/vetted-icon.svg" alt="verified by HalalCater" className="ps-1" />
                  }
                </h5>

                <div className="review-box">
                  {item.companyRatingAverage > 0 &&
                    <div className="red-bg">
                      <RatingStar starCount={item.companyRatingAverage} />
                      &nbsp;
                      <p>(
                        {item.companyRatingAverage}
                        )</p>
                    </div>}
                  <div className="cityname">
                    {item.city && item.city}, {item.stateName && item.stateName}{item.zipCode !== null && ', ' + item.zipCode}
                  </div>
                </div>

              </div>
            </div>
            <div className="col-3">
              <div className="contact-info">
                {item.certificationCSV &&
                  <div className="certificates mb-1">
                    <div className="row margin0 gap-2 justify-content-end" >
                      {(item.certificationCSV.split(',').filter(Boolean).map((e, index) => <div key={index} className="col-auto padding0"><img src={process.env.REACT_APP_BASE_URL + "MediaStorage/Offers/" + e.replace(" ", "") + ".png"} height="20" width="20" alt={e} title={e} /></div>))}
                    </div>
                  </div>
                }
                <div className=" business-info">

                  {(item.website !== '')
                    &&
                    <a className="IconLink me-2" href={item.website} target="_blank"> <i className=' fas fa-globe' title={item.website}></i></a>
                  }


                  {(item.contactNumber !== '')
                    &&
                    <a href={`tel:${item.contactNumber}`} className="IconLink"><i className=' fas fa-phone' title={item.contactNumber}></i></a>
                  }
                </div>
              </div>
            </div>
          </div>

          <hr></hr>


          <div className="body-mid row align-items-center">
            <div className="col-9">
              {item.dietaryCSV &&
                <div className="row margin0 gap-2" >

                  {(item.dietaryCSV.split(',').filter(Boolean).map((e, index) => <div key={index} className="col-auto caterer-dietry-icons"><img src={"/images/dietaryIcon_" + e.replace(" ", "") + ".png"} height="20" width="20" alt={e} title={e} /><span>{e}</span></div>))}
                  <div className="col-auto padding0">
                    <div className="more-dietry">
                      <span className="tooltip-icon"><i className="fa-solid fa-circle-info"></i></span>
                      <div className="row more-caterer-dietry gap-1">
                        {(item.dietaryCSV.split(',').filter(Boolean).map((e, index) => <div key={index} className="col-12 padding0 more-caterer-dietry-icons"><img src={"/images/dietaryIcon_" + e.replace(" ", "") + ".png"} height="17" width="17" alt={e} title={e} /><span>{e}</span></div>))}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="col-3">
              <Distance mapLocation={item.mapLocation} crtLoc={crtLoc} />
            </div>
          </div>

          <div className="body-bottom row mt-3 pt-1">

            {item.eventTypeCSV != null &&
              <div className=" col-4">
                <p>Event Type <span>({item.eventTypeCount})</span></p>
                <div className="items">
                  <div>
                    {item.eventTypeCSV}
                    {item.eventTypeCount > 1 && <span>...</span>}
                  </div>
                </div>
              </div>
            }

            {item.cuisineCSV != null &&
              <div className="col-4">
                <p>Cuisine <span>({item.cuisineCount})</span></p>
                <div className="items">
                  <div>
                    {item.cuisineCSV}
                    {item.cuisineCount > 1 && <span>...</span>}
                  </div>
                </div>
              </div>
            }


            {item.specialityCSV != null &&
              <div className="col-4">
                <p>Specialty <span>({item.specialityCount})</span></p>
                <div className="items">
                  <div>
                    {item.specialityCSV}
                    {item.specialityCount > 1 && <span>...</span>}
                  </div>
                </div>
              </div>
            }
            {/* <button type="button" className="btn btn-secondary rounded-pill"><a href={'/company-detail/' + item.companyId + '/' + item.companySlug}>View Profile</a></button> */}


          </div>
        </div>


      </div>
    </div>
  );
};



export default CatererCardGrid;