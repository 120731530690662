import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home"
import CompanySearch from "./pages/company-search/CompanySearch"
import CompanyDetail from "./pages/CompanyDetail";
import CompanyPanel from "./pages/CompanyPanel";
import ShoppingCart from "./components/CompanyPanel/ShoppingCart";
import Checkout from "./components/CompanyPanel/Checkout";
import SignupHome from "./pages/SignupHome";
import Signup from "./pages/Signup";
import Signin from "./pages/Signin";
import FlatDeal from "./pages/FlatDeal";
import CustomDeal from "./pages/CustomDeal";
import AddBusiness from "./pages/AddBusiness";
import MyProfile from "./pages/MyProfile";
import HalalOffering from "./pages/StaticPages/HalalOffering";
import AmericanHalalManufacturers from "./pages/StaticPages/AmericanHalalManufacturers";
import MeatPoultry from "./pages/StaticPages/MeatPoultry";
import AdvertiseWithUs from "./pages/StaticPages/AdvertiseWithUs";
import AirlineMeals from "./pages/StaticPages/AirlineMeals";
import FAQ from "./pages/StaticPages/FAQ";
import HalalAuthorities from "./pages/StaticPages/HalalAuthorities";
import AboutUs from "./pages/StaticPages/AboutUs";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/StaticPages/PrivacyPolicy";
import PrivateRoute from "./hooks/PrivateRoute";
import Favorites from "./pages/Favorites";
import AdminMaster from "./admin-panel/AdminMaster";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetails";
import { HelmetProvider } from 'react-helmet-async';

import MySiteAboutUs from "./my-site/AboutUs";
import MySiteHome from "./my-site/Home";
import MySiteLocation from "./my-site/Location";
import MySiteLiveMenu from "./my-site/LiveMenu";
import MySiteTrayMenu from "./my-site/TrayMenu";


function App() {


  return (
    <div>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          
          {/* <Route path="/company-search/:cuisineId/:slug" element={<CompanySearch />} /> */}
          <Route path="/:qNiche/:qAtt/:qPara/:qLoc" element={<HelmetProvider><CompanySearch /></HelmetProvider>} />
          <Route path="/:qNiche/:qAtt/:qPara" element={<HelmetProvider><CompanySearch /></HelmetProvider>} />
          <Route path="/:qNiche/:qAtt" element={<HelmetProvider><CompanySearch /></HelmetProvider>} />          
          <Route path="/:qNiche" element={<HelmetProvider><CompanySearch /></HelmetProvider>} />
          <Route path="/company-search" element={<HelmetProvider><CompanySearch /></HelmetProvider>} />
          {/* <Route path="/caterer-by-location/:qwherein/:slug" element={<CompanySearch />} /> */}

          <Route path="/favorites" element={<Favorites />} />
          <Route path="/company-panel" element={<PrivateRoute scope="caterer"><CompanyPanel /></PrivateRoute>} />
          <Route path="/cart" element={<PrivateRoute scope="caterer"><ShoppingCart /></PrivateRoute>} />
          <Route path="/checkout" element={<PrivateRoute scope="caterer"><Checkout /></PrivateRoute>} />
          <Route path="/company-panel/:catererId" element={<PrivateRoute scope="caterer"><CompanyPanel /></PrivateRoute>} />

          <Route path="/signup-home" element={<SignupHome />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/add-business" element={<AddBusiness />} />
          
          <Route path="/company-detail/:companyId/:city/:catslug" element={<HelmetProvider><CompanyDetail /></HelmetProvider>} />
          <Route path="/company-detail/:companyId/:catslug" element={<HelmetProvider><CompanyDetail /></HelmetProvider>} />
          <Route path="/company-detail/:companyId" element={<HelmetProvider><CompanyDetail /></HelmetProvider>} />
          
          <Route path="/customdeal" element={<CustomDeal />} />
          <Route path="/flatdeal" element={<FlatDeal />} />
          <Route path="/profile" element={<MyProfile />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-detail/:blogId/:blogslug" element={<BlogDetail />} />

          {/* Static Pages */}
          <Route path="/halal-offering" element={<HalalOffering />} />
          <Route path="/american-halal-manufacturers" element={<AmericanHalalManufacturers />} />
          <Route path="/meat-poultry" element={<MeatPoultry />} />

          <Route path="/advertise-with-us" element={<AdvertiseWithUs />} />
          <Route path="/airline-meals" element={<AirlineMeals />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/halal-authorities" element={<HalalAuthorities />} />

          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* Static Pages */}


          {/* Control Panel */}
          <Route path="/admin/*" element={<PrivateRoute scope="admin" ><AdminMaster /></PrivateRoute>} />
          {/* Control Panel */}

          {/* Create my site */}
          <Route path="/my-site/home/:companyId" element={<MySiteHome />} />
          <Route path="/my-site/about-us/:companyId" element={<MySiteAboutUs />} />
          <Route path="/my-site/location/:companyId" element={<MySiteLocation />} />
          <Route path="/my-site/tray-menu/:companyId" element={<MySiteTrayMenu />} />
          <Route path="/my-site/live-menu/:companyId" element={<MySiteLiveMenu />} />
          {/* Create my site */}

          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>






    </div>
  );
}

export default App;
