import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'


const AmericanHalalManufacturers = () => {



  return (

    <div>
      <Navbar />


      <main role="main">


<div className="innerpage-banner ">
  <img src="images/static-banners/AmericanHalalManufacturers.jpg" className="innerpage-banner-img" alt="American Halal Manufacturers"/>
</div>



<section className="innerpage-container">

  <div className="container">
    <div className="row">

    <div className="col-12 col-md-12 col-md-12 col-lg-12">
       

      <div className="row align-items-center">

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          
           

              <div className="halal-menufac-box align-item-center">
                
                  <p className="title mb-0">Saffron Road</p>
                  <p className="origin mb-0">Stamford, CT</p>
                
              </div>
            
            
          
            
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Lang's Chocolates</p>
            <p className="origin mb-0">Williamsport, PA</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Crescent Foods</p>
            <p className="origin mb-0">Oak Brook, IL</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Tyson Foods</p>
            <p className="origin mb-0">Springdale, AR</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Crave Foods</p>
            <p className="origin mb-0">Los Angeles, CA</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Ziyad Brothers Importing</p>
            <p className="origin mb-0">Cicero, IL</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Tom's of Maine</p>
            <p className="origin mb-0">Kennebunk, ME</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Allen Harim</p>
            <p className="origin mb-0">Seaford, DE</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Shabazz Cola</p>
            <p className="origin mb-0">Newark, NJ</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Insane Jerky</p>
            <p className="origin mb-0">Ashburn, VA</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Kronos Products</p>
            <p className="origin mb-0">Glendale Heights, IL</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Tex Med Beef Company</p>
            <p className="origin mb-0">Houston, TX</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Sukhi's</p>
            <p className="origin mb-0">Hayward, CA</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Coleman Natural</p>
            <p className="origin mb-0">Golden, CO</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Real Halal</p>
            <p className="origin mb-0">Washington, DC</p>
            
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Creekstone Farms</p>
            <p className="origin mb-0">Arkansas City, KS</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Javed & Sons</p>
            <p className="origin mb-0">Houston, TX</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">A&A Halal Distributors</p>
            <p className="origin mb-0">Orlando, FL</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">J&M Halal Meats</p>
            <p className="origin mb-0">Deerfield, IL</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Midamar Halal</p>
            <p className="origin mb-0">Cedar Rapids, IA</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Madani Halal</p>
            <p className="origin mb-0">Ozone Park, NY</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Australian Lamb Company</p>
            <p className="origin mb-0">Dominguez Hills, CA</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Harris Ranch Beef</p>
            <p className="origin mb-0">Selma, CA</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">S'Better Farms</p>
            <p className="origin mb-0">Carson City, NV</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Provision Halal Distributors</p>
            <p className="origin mb-0">Jersey City, NJ</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Mar-Jac Poultry</p>
            <p className="origin mb-0">Gainesville, GA</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Nema Food Company</p>
            <p className="origin mb-0">McKeesport, PA</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Cabot Creamery</p>
            <p className="origin mb-0">Montpelier, VT</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Townsend</p>
            <p className="origin mb-0">Georgetown, DE</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Zabiha Halal Meat Processors  </p>
            <p className="origin mb-0">Glendale Heights, IL</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Sharifa</p>
            <p className="origin mb-0">Detroit, MI</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">USA Zabiha Halal Food Technology</p>
            <p className="origin mb-0">Chicago, IL</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Nebraska Beef</p>
            <p className="origin mb-0">Omaha, NE</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Green Valley Meat Distributors, Inc.</p>
            <p className="origin mb-0">Stafford, TX</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Salwa Foods</p>
            <p className="origin mb-0">Norcross, GA</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Black Earth Meats</p>
            <p className="origin mb-0">Black Earth, WI</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Whole Earth Meats</p>
            <p className="origin mb-0">NoChicago, IL</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Badr Halal </p>
            <p className="origin mb-0">Brighton, TN</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">As Salaam Halal</p>
            <p className="origin mb-0">Jamaica, New York</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Open Nature</p>
            <p className="origin mb-0">Pleasanton, CA</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Greater Omaha</p>
            <p className="origin mb-0">Omaha, NE</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Tallgrass Beef</p>
            <p className="origin mb-0">Chicago , IL</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Afia Foods</p>
            <p className="origin mb-0">Austin, TX</p>
            
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
          <div className="halal-menufac-box align-item-center">
            
            <p className="title mb-0">Pre Brand Beef</p>
            <p className="origin mb-0">Chicago, IL</p>
            
          </div>
        </div>
        
    




      </div>

        


    </div>
        

    </div>



    </div>





</section>









  </main>


      <Footer></Footer>
    </div>
  )
}

export default AmericanHalalManufacturers