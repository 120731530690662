import { useNavigate } from "react-router-dom";
import { useGetDietariesQuery, useGetEventTypesQuery, useGetCuisinesQuery, useGetStatesQuery } from './../hooks/apiSlice';
import { toURLEncode } from './../hooks/util';


const HomeSearchOption = (props) => {

  const { data: dataDietary, isLoading: isLoadingDietary } = useGetDietariesQuery()
  const { data: dataEventTypes, isLoading: isLoadingEventTypes } = useGetEventTypesQuery()
  const { data: dataCuisines, isLoading: isLoadingCuisines } = useGetCuisinesQuery()
  // const { data: dataStates, isLoading: isLoadingStates } = useGetStatesQuery()

  const navigate = useNavigate();

  const queryHandler = (e) => {

    var eventTypeSelect = document.getElementById('select-eventType' + props.Id);
    var cuisineSelect = document.getElementById('select-cuisines' + props.Id);
    var dietarySelect = document.getElementById('select-dietary' + props.Id);
    var stateZipSelect = document.getElementById('txtState' + props.Id);

    if ((document.getElementById('event-tab' + props.Id).classList.contains('active')) && (eventTypeSelect.value === ''))
      eventTypeSelect.classList.add("border-danger")
    else if ((document.getElementById('cuisine-tab' + props.Id).classList.contains('active')) && (cuisineSelect.value === ''))
      cuisineSelect.classList.add("border-danger")
    else if ((document.getElementById('dietary-tab' + props.Id).classList.contains('active')) && (dietarySelect.value === ''))
      dietarySelect.classList.add("border-danger")
    else {

      let qNiche = "none", qAtt = "none", qPara = "none", qLoc = "";
      //if (cuisineSelect.value !== "") {
      if (e.target.innerText === "Find By Cuisine"){
        qNiche = "restaurants"
        qAtt = "cuisine";
        qPara = toURLEncode(cuisineSelect.options[cuisineSelect.selectedIndex].text);
      }
      //else if (dietarySelect.value !== "") {
        else if (e.target.innerText === "Find By Dietary") {
        qNiche = "restaurants";
        qAtt = "dietary";
        qPara = toURLEncode(dietarySelect.options[dietarySelect.selectedIndex].text);
      }
      //else if (eventTypeSelect.value !== "") {
      else //(e.target.innerText === "Find by Event") 
      {
        qNiche = "restaurants-caterers"
        qAtt = "events";
        qPara = toURLEncode(eventTypeSelect.options[eventTypeSelect.selectedIndex].text);
      }
      if (stateZipSelect.value !== '') {
        qLoc = toURLEncode(document.getElementById('txtState' + props.Id).value);
      }

      //console.log(`/${qNiche}/${qAtt}/${qPara}/${qLoc}`)
      window.location.href = `/${qNiche}/${qAtt}/${qPara}/${qLoc}`
      //navigate(`/${qNiche}/${qAtt}/${qPara}/${qLoc}`)
    }
  };


  function applyValidation(e) {
    if (e.target.value !== "")
      e.target.classList.remove("border-danger");
    else
      e.target.classList.add("border-danger");
  }


  function setButtonLabel(e) {
    document.getElementById('butFind' + props.Id).innerHTML = 'Find By ' + e.target.innerHTML;
  }





  return (



    <div className="p-4 text-white rounded-3 shadow form-bg">
      <div className="row search-tab align-items-end">
        <div className="col-12 col-md-6">

          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id={'event-tab' + props.Id} data-bs-toggle="tab" data-bs-target={'#event' + props.Id} type="button" role="tab" aria-controls="event" aria-selected="true" onClick={(e) => setButtonLabel(e)}>Event</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id={'cuisine-tab' + props.Id} data-bs-toggle="tab" data-bs-target={'#cuisine' + props.Id} type="button" role="tab" aria-controls="cuisine" aria-selected="false" onClick={(e) => setButtonLabel(e)}>Cuisine</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id={'dietary-tab' + props.Id} data-bs-toggle="tab" data-bs-target={'#dietary' + props.Id} type="button" role="tab" aria-controls="dietary" aria-selected="false" onClick={(e) => setButtonLabel(e)}>Dietary</button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id={'event' + props.Id} role="tabpanel" aria-labelledby="event-tab">
              <div className="row">
                <div className="col-12">
                  {isLoadingEventTypes ? <h1>loading...</h1> :
                    <select className="form-control tab-select" id={'select-eventType' + props.Id} name="event" onChange={(e) => applyValidation(e)}>
                      <option value="">Select your event</option>
                      {
                        dataEventTypes.payload.map((et, index) =>
                          <option key={index} text={et.eventTypeName} value={et.eventTypeId}>{et.eventTypeName}</option>
                        )
                      }
                    </select>
                  }
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id={'cuisine' + props.Id} role="tabpanel" aria-labelledby="cuisine-tab">
              <div className="row">
                <div className="col-12">
                  {isLoadingCuisines ? <h1>loading...</h1> :
                    <select className="form-control tab-select" name="cuisines" id={'select-cuisines' + props.Id} onChange={(e) => applyValidation(e)}>
                      <option value="">Select your cuisines</option>
                      {
                        dataCuisines.payload.map((cu, index) =>
                          <option key={index} text={cu.cuisineName} value={cu.cuisineId}>{cu.cuisineName}</option>
                        )
                      }
                    </select>
                  }
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id={'dietary' + props.Id} role="tabpanel" aria-labelledby="dietary-tab">
              <div className="row">
                <div className="col-12">
                  {isLoadingDietary ? <h1>loading...</h1> :
                    <select id={'select-dietary' + props.Id} className="form-control tab-select" name="dietary" onChange={(e) => applyValidation(e)}>
                      <option value="" >Select your dietary</option>
                      {
                        dataDietary.payload.map((dt, index) =>
                          <option key={index} text={dt.dietaryName} value={dt.dietaryId}>{dt.dietaryName}</option>
                        )
                      }
                    </select>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>



        <div className="col-12 col-md-6">
          <div className="zip-city-toggle input-group">
            <input id={'txtState' + props.Id} onKeyDown={(e) => {
              if (e.key === "Enter") {
                queryHandler();
              }
            }} type="text" className="form-control" placeholder="Enter zip code or city" />
          </div>
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="mt-3 p-0 text-right">
          <button type="button" id={'butFind' + props.Id} className="find btn submit-btn btn-danger float-end" onClick={(e)=>queryHandler(e)}>Find by Event</button>
        </div>
      </div>
    </div>
  )
}


export default HomeSearchOption