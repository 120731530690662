import { useState, useEffect } from "react";
import AuthService from "../hooks/Auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import ForgotPassword from "../components/ForgotPassword";


const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const SigninPanel = (props) => {


    const [profile, setProfile] = useState('');

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");

    const [validEmail, setValidEmail] = useState(false);
    const [validPwd, setValidPwd] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
        setValidPwd(password !== "");
    }, [email, password])

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }


    const handleLogin = (e) => {
        e.preventDefault();
        AuthService.login(email, password).then(
            (r) => {
                if (r === "Not_Exists") {
                    toast.error(`The Email and/or password do not matched`, { position: toast.POSITION.TOP_CENTER });
                }
                else {
                    if (props.parent === "SigninPage")
                        navigate(AuthService.getUserProfileId() === '336' ? '../admin' : AuthService.getUserProfileType() === 'business' ? '../company-panel' : '../company-search');
                    else
                        props.onSuccessSignup();
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }



    return (
        <>
            <div>
                <ToastContainer />
            </div>
            <div className="row margin0 pt-3 form-log">

                <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={!validEmail ? "form-control form-control-lg border" : "form-control form-control-lg"}
                    required
                    aria-invalid={validEmail ? "false" : "true"}
                    maxLength="60"
                    aria-describedby="uidnote"
                />
                <div className="password-field" style={{'padding':'0px'}}>
                    <input
                        type={passwordType}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={!validPwd ? "form-control form-control-lg border" : "form-control form-control-lg"}
                        required
                        aria-invalid={validPwd ? "false" : "true"}
                        maxLength="24"
                        aria-describedby="uidnote"
                    />
                    <i className={passwordType === "password" ? "far fa-eye" : "far fa-eye-slash"} onClick={togglePassword}></i>
                </div>

                <div href="#" className="d-flex justify-content-end py-2 padding0">
                    {props.parent === "SigninPage" && <ForgotPassword />}
                </div>
                <button className="btn btn-danger" onClick={handleLogin} disabled={!validEmail || !validPwd ? true : false}>Sign-in</button>


            </div>
        </>
    )
}


export default SigninPanel