import Navbar from "../Navbar";
import Footer from '../Footer';
import { ToastContainer } from 'react-toastify';
import { useReducer } from "react";
import CartReducer from "../CompanyPanel/CartContext";
import React, { useEffect, useState } from "react";
import { useGetAddressQuery, useSaveSubscriptionMutation } from './../../hooks/apiSlice';
import AuthService from "./../../hooks/Auth";

const ShoppingCart = () => {

    const [cartState, dispatch] = useReducer(CartReducer, []);
    const [saveSubscription] = useSaveSubscriptionMutation()

    const [address, setAddress] = useState('');

    const [number, SetNumber] = useState("");
    const [name, SetName] = useState("");
    const [date, SetDate] = useState("");
    const [cvc, SetCvc] = useState("");
    const [focus, SetFocus] = useState("");

    const { data: dataAddress, isLoading: isLoadingAddress } = useGetAddressQuery(0)



    useEffect(() => {
        if (isLoadingAddress === false) {
            if (dataAddress.payload) {
                setAddress(dataAddress.payload.streetAddress + ', ' + dataAddress.payload.city + ', ' + dataAddress.payload.state.stateName);
            }
        }
    }, [isLoadingAddress])

    useEffect(() => {
        dispatch({ type: "GET-CART" });
    }, [])


    const completePurchase = (e) => {
        var pkg, plan;
        let formData = new FormData()
        var sub = [];
        cartState.map(p => p.productDetail.map(o => {
            
            if (p.subscriptionTypeId === 4)
            {
                pkg  = p.productDetail[0].offers[0].targetAudiences[0].productPlanId;
                plan  = p.productDetail[0].offers[0].targetAudiences[0].subscriptionPlanName;
            }

            var subDet = [];
            o.offers.map(ta => {
                ta.targetAudiences.map(v => {
                    subDet.push({ price: v.price, amount: v.amount, targetAudience: { targetAudienceId: v.targetAudienceId }, productPlan: { productPlanId: v.productPlanId } });
                })
            })
            sub.push({ subscriptionTypeId: p.subscriptionTypeId, company: { companyId: AuthService.getCompanyId() }, payment: { paymentId: 5 }, title: o.title, startDate:o.startDate, endDate:o.endDate, imageFileName: o.imageData, description: o.description, subscriptionDetails: subDet })
        }))

        var details = JSON.stringify(sub);
        formData.append("productPrice", details);
        saveSubscription({ productPrice: formData });

        AuthService.updateLocalStorageOnCheckout({package:pkg, plan:plan});
    }

    return (
        <div>
            <div>
                <ToastContainer />
            </div>
            <Navbar />

            <main role="main">
                <section className="pt-4 pb-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <h2 className="standardHeading m-0 p-0">Checkout</h2>
                        </div>
                        <div className="row mb-0">
                            <div className="col-6">
                                <div className="row">
                                    <div className="row">
                                        <h3>Billing Address</h3>
                                    </div>
                                    <div className="row">
                                        {address}
                                    </div>
                                </div>
                                <div className="row">
                                    <h3>Payment</h3>
                                </div>


                                <div className="row">
                                    <div className="row">
                                        <div className="col-sm-11">
                                            <label htmlFor="name">Card Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={number}
                                                name="number"
                                                onChange={(e) => {
                                                    SetNumber(e.target.value);
                                                }}
                                                onFocus={(e) => SetFocus(e.target.name)}
                                            ></input>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-11">
                                            <label htmlFor="name">Card Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={name}
                                                name="name"
                                                onChange={(e) => {
                                                    SetName(e.target.value);
                                                }}
                                                onFocus={(e) => SetFocus(e.target.name)}
                                            ></input>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="name">Expiration Date</label>
                                            <input
                                                type="text"
                                                name="expiry"
                                                className="form-control"
                                                value={date}
                                                onChange={(e) => {
                                                    SetDate(e.target.value);
                                                }}
                                                onFocus={(e) => SetFocus(e.target.name)}
                                            ></input>
                                        </div>
                                        <div className="col-sm-5">
                                            <label htmlFor="name">CVV</label>
                                            <input
                                                type="tel"
                                                name="cvc"
                                                className="card"
                                                value={cvc}
                                                onChange={(e) => {
                                                    SetCvc(e.target.value);
                                                }}
                                                onFocus={(e) => SetFocus(e.target.name)}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <h3>Your Items Recap</h3>
                                </div>
                                <div className="row">
                                    {(cartState === undefined || cartState.length === 0) ? <div className="row">Your cart is empty</div>
                                        :
                                        cartState.map((crtItem, index) => <div className="row" key={index}>
                                            <div className="col-8">
                                                {crtItem.subscriptionTypeId}-{crtItem.productName}
                                            </div>
                                            <div className="col-4">
                                                ${crtItem.productDetail.map(o => o.offers.map(o => o.targetAudiences.map(movie => movie.price).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0).toFixed(2)}
                                            </div>

                                        </div>)}

                                    <div className="row border">
                                        <div className="col-8">
                                            Sub Total:
                                        </div>
                                        <div className="col-4">
                                            ${cartState.map(p => p.productDetail.map(o => o.offers.map(o => o.targetAudiences.map(movie => movie.price).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0).toFixed(2)}
                                        </div>
                                    </div>

                                    <div className="row border">
                                        <div className="col-8">
                                            VAT andd Fees:
                                        </div>
                                        <div className="col-4">
                                            $2.64
                                        </div>
                                    </div>

                                    <div className="row border">
                                        <div className="col-8">
                                            VAT andd Fees:
                                        </div>
                                        <div className="col-4">
                                            ${(cartState.map(p => p.productDetail.map(o => o.offers.map(o => o.targetAudiences.map(movie => movie.price).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0) + 2.64).toFixed(2)}
                                        </div>
                                    </div>


                                </div>








                            </div>
                        </div>
                        <div className="row mb-0">
                            <button className="btn btn-danger mt-2" onClick={(e) => { completePurchase(e) }}>
                                Complete Purchase
                            </button>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </main>
        </div>
    )
}

export default ShoppingCart