import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useGetCustomDealQuery } from '../hooks/apiSlice';
import { useState } from "react";



const CustomDeal = () => {

    const [selectedNiche, setSelectedNiche] = useState('1');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedZipCode, setSelectedZipCode] = useState('');

    const { data: dataCustomDeal, isLoading: isLoadingCustomDeal } = useGetCustomDealQuery({ nicheId: selectedNiche, zipCode: selectedZipCode, city: selectedCity })


    return (

        <div>

            <Navbar />


            <section className="mt-4 mb-4 pb-5 border-0 signin-up-wrapper">
                <div className="container">
                <div className="row">
                        <div className="col-lg-6">
                            <div className="deal-niche">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title"><img src="/images/niche-icon.png" height="20" width="20" alt="Location" /> Search by Niche</h5>
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="form-check search-check">
                                                    <input checked={selectedNiche === '1'} className="form-check-input" value="1" type="radio" name="flexRadioDefault" id="RadiRestaurantOnly" onChange={(e) => setSelectedNiche(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="RadiRestaurantOnly">
                                                        Restaurant
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check search-check">
                                                    <input checked={selectedNiche === '2'} className="form-check-input" value="2" type="radio" name="flexRadioDefault" id="RadiCatererOnly" onChange={(e) => setSelectedNiche(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="RadiCatererOnly">
                                                        Caterer
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check search-check">
                                                    <input checked={selectedNiche === '3'} className="form-check-input" value="3" type="radio" name="flexRadioDefault" id="RadioBakersOnly" onChange={(e) => setSelectedNiche(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="RadioBakersOnly">
                                                        Bakers
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="deal-location">
                                <div className="card">
                                    <div className="card-body">
                                    <h5 className="card-title"><img src="/images/marker.svg" width="20" height="20" alt=""/> Search by Location</h5>
                                        <div className="deal-location-search input-group">
                                            <input type="text" id="searchCity" placeholder="Search by City" maxLength="20" />
                                            <input type="text" id="searchZipCode" placeholder="Search by ZipCode" maxLength="20" />

                                            <button className="btn btn-danger" onClick={(e) => {
                                                setSelectedCity(document.getElementById('searchCity').value)
                                                setSelectedZipCode(document.getElementById('searchZipCode').value)
                                            }}>Apply Filter</button>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5 mt-5 DealBox">

                        <div className="col-12">

                            <div className="deal-offers">
                            {
                                !isLoadingCustomDeal &&

                                dataCustomDeal.payload.map((deal, index) =>
                                    <div className="deal-item" key={index}>
                                        <a href={deal.url} target="_blank">
                                            <img className="deal-img" src={process.env.REACT_APP_BASE_URL + "MediaStorage/dealimages/" + deal.imageFileName} alt={deal.imageFileName} />
                                            <div className="deal-details">
                                                <h5 className="deal-name">{deal.dealName}</h5>
                                                <p className="deal-tag">{deal.tagName}</p>
                                            </div>
                                        </a>
                                        {/* {deal.createdOn + "|" + deal.dealName + "|" + deal.startOn + "|" + deal.expiresOn + "|" + deal.tagName + "|" + deal.status + "|" + deal.url + "|" + deal.imageFileName} */}
                                    </div>
                                )

                            }
                            </div>
                        </div>

                    </div>
                    
                </div>
            </section>


            <Footer></Footer>
        </div>
    )
}

export default CustomDeal