import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetSpecialitiesQuery } from '../../hooks/apiSlice';
import { default as ReactSelect } from "react-select";
import { toURLDecode } from '../../hooks/util';



const SpecialitySelection = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data: dataSpecialties, isLoading: isLoadingSpecialties } = useGetSpecialitiesQuery()
    const [specialityDefaultSelection, setSpecialityDefaultSelection] = useState([]);

    const params = useParams();
    const [specialityLoaded, setSpecialityLoaded] = useState(false);

    useEffect(() => {
        var updatedList;
        if (isLoadingSpecialties === false) {
            if (params.qAtt === 'speciality')
                updatedList = [{ value: dataSpecialties.payload.find((obj) => { return (obj.specialityName === toURLDecode(params.qPara)) }).specialityId.toString(), label: toURLDecode(params.qPara) }];

            if (searchParams.get("Speciality")) {
                updatedList = searchParams.get("Speciality").split(",").map((n) => {
                    return {
                        value: n,
                        label: dataSpecialties.payload.find((obj) => { return (obj.specialityId === parseInt(n)) }).specialityName
                    }
                })
            }
            
            if (updatedList !== undefined) {
                setSpecialityDefaultSelection(updatedList)
                props.SpecialitySelectionHandler(updatedList);
            }

            setSpecialityLoaded(true);
        }
    }, [isLoadingSpecialties]);



    const handleSpecialtySelection = (selected) => {
        props.SpecialitySelectionHandler(selected);
    };

    return (
        specialityLoaded &&
        <div>
            <ReactSelect
                options={
                    //dataSpecialties.payload.filter((m) => m.nicheId.toString() === (selectedNiche === '3' ? selectedNiche : '1')).map((specialty) => {
                    dataSpecialties.payload.filter((m) => m.nicheId.toString() === '1').map((specialty) => {
                        return { value: specialty.specialityId, label: specialty.specialityName }
                    })}
                isMulti
                value={props.parentInput}
                closeMenuOnSelect={false}
                //hideSelectedOptions={false}
                placeholder="Specialty"
                onChange={handleSpecialtySelection}
                //filterOption={createFilter({ matchFrom: 'start' })}
                //allowSelectAll={true}
                defaultValue={specialityDefaultSelection}
            />
        </div>
    );
};



export default SpecialitySelection;





