import { useState, useEffect } from "react";
import axios from 'axios'

var ChildStateModificationFunc;

const CompanySelection = ({ handleSelection }) => {

    const [users, setUsers] = useState([])
    const [text, setText] = useState('')
    //const [text2, setText2] = useState('')
    //const [selectedCompanyId, setSelectedCompanyId] = useState('')
    const [suggestions, setSuggestions] = useState('')

    const loadUsers = async (keyword) => {
        //alert('loadUsers')
        const response = await axios.get(process.env.REACT_APP_BASE_URL + `Company/GetCompanyByKeyword/${keyword}`);
        return response.data.payload
    }

    //this method is smart enough that it will call the api only when first character typed, just to avoid unnecessary server calls
    const onChangeHandler = async (e) => {
        setText(e.target.value);
        let matches = []
        let u;

        if ((e.target.value.length === 1) && (e.nativeEvent.inputType !== "deleteContentBackward")) {
            setSuggestions([])
            u = await loadUsers(e.target.value);
            setUsers(u);
            matches = u.filter(user => {
                const regex = new RegExp(`${e.target.value}`, "gi");
                return user.companyName.match(regex);
            })
        }
        else {
            matches = users.filter(user => {
                const regex = new RegExp(`${e.target.value}`, "gi");
                return user.companyName.match(regex);
            })
        }
        setSuggestions(matches);
    }


    const onClickHandler = (e) => {
        handleSelection(e);
        setSuggestions([]);
        setText(e.companyName);
    }


    ChildStateModificationFunc = (Val)=>{
        setText(Val)
    }



    return (
        <div>
            <div>
                <input id="companySelected" className="form-control" maxLength="20" autoComplete="off" placeholder="Type name here" type="text" onChange={(e) => onChangeHandler(e)} value={text} />
            </div>
            <div style={{ 'position': 'absolute', 'width': '80%' }}>
                <div style={{ 'position': 'relative', 'overflow': 'auto', 'maxHeight': '200px', 'width': '100%', 'zIndex': '1' }}>
                    {suggestions && suggestions.map((suggestion, i) =>
                        <a className="auto-suggesion" key={i} href="#" onClick={(e) => onClickHandler(suggestion)} style={{ 'background': '#fff' }}>
                            {suggestion.companyName} ({suggestion.companyId})
                        </a>
                    )}
                </div>
            </div>
        </div>

    );
}

export default CompanySelection;
export {ChildStateModificationFunc}