import React, { useState, useEffect, useId } from "react";
import {
    useGetAllEventTypesQuery,
    useUpdateEventTypeMutation, useAddEventTypeMutation, useDeleteEventTypeMutation,
    useGetAllSpecialitiesQuery,
    useAddSpecialityMutation, useUpdateSpecialityMutation, useDeleteSpecialityMutation,
    useGetAllAddonServicesQuery, useAddAddonServiceMutation, useUpdateAddonServiceMutation, useDeleteAddonServiceMutation,
    useGetAllServingOptionsQuery, useAddServingOptionMutation, useUpdateServingOptionMutation, useDeleteServingOptionMutation,

    useGetAllCuisinesQuery, useAddCuisineMutation, useUpdateCuisineMutation, useDeleteCuisineMutation,
    useGetAllDietariesQuery,
    useAddDietaryMutation, useUpdateDietaryMutation, useDeleteDietaryMutation,
    useGetAllCertificationsQuery, useAddCertificationMutation, useUpdateCertificationMutation, useDeleteCertificationMutation
} from '../hooks/apiSlice';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment'
import { toast } from 'react-toastify';


const EditEntity = (props) => {
    const [entityId, setEntityId] = useState();
    const [entityName, setEntityName] = useState();
    const [currentFile, setCurrentFile] = useState(undefined);
    const [previewImage, setPreviewImage] = useState(undefined);
    const [isApproved, setIsApproved] = useState(false);
    const [displayOrder, setDisplayOrder] = useState(false);

    useEffect(() => {
        setEntityId(props.entity.entityId);
        setEntityName(props.entity.entityName);
        setIsApproved(props.entity.isApproved ? true : false);
        setCurrentFile(props.entity.imageFileName);
        setDisplayOrder(props.entity.displayOrder);
    }, [props.entity.entityName, props.entity.isApproved]);


    const selectFile = (event) => {
        if (event.target.files[0].size > 1048576) //not more than 1MB
            alert('Image file should not exceeds 1MB in size')
        else {
            setCurrentFile(event.target.files[0]);
            setPreviewImage(URL.createObjectURL(event.target.files[0]));
        }
    };


    return (

        <Modal show={props.show} >
            <Modal.Header>
                <Modal.Title>Edit Offer</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row m-2">
                    <input type="text" onChange={(e) => setEntityName(e.target.value)} required className="form-control form-control-lg" value={entityName} maxLength="100" />
                </div>
                <div className="row m-2">
                    <div className="form-check mb-3">
                        <input style={{ 'width': '36px', 'height': '18px', 'borderColor': '#000' }} onChange={(e) => setIsApproved(e.target.checked)} checked={isApproved} type="checkbox" id="approvalStatus" className="form-check-input" />
                        <label className="form-check-label" htmlFor="approvalStatus">&nbsp;Approval Status</label>
                    </div>
                </div>
                <div className="row m-2">
                    <div>
                        <div>
                            <div>
                                <input type="file" name="companyImg" className="form-control" onChange={(e) => selectFile(e)} accept="image/*" />

                                <div className="mt-1 p-1 border border-secondary rounded">
                                    <img src={previewImage !== undefined ? previewImage : process.env.REACT_APP_BASE_URL + `MediaStorage/Offers/` + currentFile} alt="" />

                                    <div className="upload-profilepic text-center card-img-overlay d-none flex-column justify-content-center">
                                        <i className="fa-solid fa-camera"></i>
                                        <div className="overlay-txt">Upload<br />200x165 recommended</div>
                                    </div>
                                    {currentFile !== props.defaultImage &&
                                        <button className="lowHightButton" onClick={() => {
                                            setCurrentFile(props.defaultImage)
                                        }}>Remove Image</button>}
                                </div>
                                {displayOrder !== undefined &&
                                    <div className="mt-3 row">
                                        <div className="col-4 item-align-right">
                                            Display Order:
                                        </div>
                                        <div className="col-8">
                                            <input type="number" style={{ width: '80px' }} name="displayOrder" className="form-control" value={displayOrder} onChange={(e) => setDisplayOrder(e.target.value)} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {props.entity.catererCount === 0 &&
                    <button className="adminDeleteButton" onClick={() => {
                        props.close()
                        props.deleteHandler({ entityId: entityId });
                        toast.success(`Offer has been deleted successfully!`, { position: toast.POSITION.TOP_CENTER });
                    }}>Remove</button>
                }
                <button className="adminButton" onClick={() => {
                    props.close()
                    setPreviewImage(undefined);
                }}>Close</button>
                <button className="adminButton" onClick={() => {
                    props.close();
                    props.editHandler({ isApproved: isApproved, entityName: entityName, imageFileName: currentFile, displayOrder: displayOrder });
                    toast.success(`Offer has been saved successfully!`, { position: toast.POSITION.TOP_CENTER });
                    setPreviewImage(undefined);
                }}>Save</button>
            </Modal.Footer>
        </Modal>
    )
}


const AddEntity = (props) => {
    const [entityName, setEntityName] = useState('');
    const [currentFile, setCurrentFile] = useState(undefined);
    const [previewImage, setPreviewImage] = useState(undefined);
    const compUniqueId = useId();

    const selectFile = (event) => {
        if (event.target.files[0].size > 1048576) //not more than 1MB
            alert('Image file should not exceeds 1MB in size')
        else {
            setCurrentFile(event.target.files[0]);
            setPreviewImage(URL.createObjectURL(event.target.files[0]));
        }
    };


    return (
        <div className="card">
            <div className="row p-1">
                <div className="col py-2">
                    <div className="ps-2">
                        <input type="text" onChange={(e) => setEntityName(e.target.value)} required className="form-control" value={entityName} maxLength="100" />
                    </div>
                </div>
                <div className="col-auto">
                    <input type="file" name="companyImg" id={compUniqueId} className="form-control" style={{ 'display': 'none' }} onChange={(e) => selectFile(e)} accept="image/*" />
                    <label htmlFor={compUniqueId}>
                        <img src={previewImage !== undefined ? previewImage : process.env.REACT_APP_BASE_URL + `MediaStorage/Offers/Default.png`} alt="" style={{ 'height': '62px', 'width': '62px' }} />
                    </label>

                    <div className="upload-profilepic text-center card-img-overlay d-none flex-column justify-content-center">
                        <i className="fa-solid fa-camera"></i>
                        <div className="overlay-txt">Upload<br />200x165 recommended</div>
                    </div>
                </div>
                <div className="col-auto py-2">
                    <button type="submit" className="btn-fill pull-right btn btn-dark btn-small" onClick={() => {
                        props.addHandler({ entityName: entityName, imageFileName: currentFile });
                        setEntityName(''); setPreviewImage(undefined)
                    }}><i className="fa-solid fa-plus"></i></button>
                </div>
            </div>
        </div>
    )
}

const EventType = () => {

    const { data: dataEventTypes, isLoading: isLoadingEventTypes } = useGetAllEventTypesQuery()
    const [updateEventType] = useUpdateEventTypeMutation()
    const [addEventType] = useAddEventTypeMutation()
    const [deleteEventType] = useDeleteEventTypeMutation()

    const [showModel, setShowModel] = useState(false);
    const [eventType, setEventType] = useState({ entityName: 'none' });
    const [eventTypeList, setEventTypeList] = useState();

    const [searchKeyword, setSearchKeyword] = useState();
    const [searchStatus, setSearchStatus] = useState();

    useEffect(() => {
        if (isLoadingEventTypes === false) {
            setEventTypeList(dataEventTypes.payload)
        }
    }, [dataEventTypes]);


    const handleSave = (e) => {
        updateEventType({ eventTypeId: eventType.entityId, eventTypeName: e.entityName, isApproved: e.isApproved, imageFileName: e.imageFileName, file: e.imageFileName }).unwrap();
    }



    const handleAdd = (e) => {
        if (e.entityName !== '') {
            if (dataEventTypes.payload.some(item => e.entityName.toUpperCase() === item.eventTypeName.toUpperCase()))
                toast.error(e.entityName + ` already exists`, { position: toast.POSITION.TOP_CENTER });
            else {
                addEventType({ eventTypeName: e.entityName, isApproved: true, createdBy: 336, imageFileName: "EventType_Default.png", file: e.imageFileName }).unwrap();
                toast.success(`Event Type has been created successfully!`, { position: toast.POSITION.TOP_CENTER });
            }
        }
    }

    const handleDelete = (e) => {
        deleteEventType({ eventTypeId: e.entityId });
    }

    return (
        <>

            <div className="row">
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row py-2">
                            <div class="col tiny-text"><i class="fa-solid fa-filter"></i>&nbsp;Search</div>
                        </div>
                        <div class="row pb-2">
                            <div className="col-6">
                                <input className="form-control" onChange={(e) => { setSearchKeyword(e.target.value) }}></input>
                            </div>
                            <div className="col-6">
                                <select className="form-control" onChange={(e) => setSearchStatus(e.target.value)}>
                                    <option value="">All</option>
                                    <option value="false">Pending</option>
                                    <option value="true">Approved</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row pb-2">
                            <div class="col tiny-text">
                                <i class="fas fa-plus-circle"></i>&nbsp;Add New Event Type
                            </div>
                        </div>
                        <AddEntity addHandler={handleAdd} />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <table className="table-hover table-striped table offering-td"><thead>
                    <tr>
                        <th>EventType Id</th>
                        <th>Image</th>
                        <th>EventType</th>
                        <th>Count</th>
                        <th>Approval Status</th>
                        {/* <th>Created By</th> */}
                        <th>Created On</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                    <tbody>
                        {
                            eventTypeList !== undefined &&
                            eventTypeList.filter(item => {
                                if (!searchKeyword) return true
                                if (item.eventTypeName.toLowerCase().includes(searchKeyword.toLowerCase())) {
                                    return true
                                }
                            }).filter(item => {
                                if (!searchStatus) return true
                                if (item.isApproved.toString() === searchStatus) {
                                    return true
                                }
                            }).map((sp, index) =>
                                <tr key={index}>
                                    <td className="text-center">{sp.eventTypeId}</td>
                                    <td><img src={process.env.REACT_APP_BASE_URL + `MediaStorage/Offers/` + sp.imageFileName} alt="" /></td>
                                    <td>{sp.eventTypeName}</td>
                                    <td className="text-center"><div className="badge rounded-pill bg-dark p-1 px-3" style={{ 'fontSize': '1rem' }}><i className="fa-solid fa-users"></i>&nbsp;{sp.childCount}</div></td>
                                    <td className="text-center">{sp.isApproved ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-sharp fa-solid fa-circle-xmark text-danger"></i>}</td>
                                    <td>{moment(sp.createdOn).format("MM-DD-YYYY h:mm A")}</td>
                                    <td className="text-center"><span><i onClick={(e) => { setEventType({ entityId: sp.eventTypeId, isApproved: sp.isApproved, entityName: sp.eventTypeName, catererCount: sp.childCount, imageFileName: sp.imageFileName }); setShowModel(true) }} className="simpleIconButton text-secondary fas fa-edit" title="modify"></i></span></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <EditEntity show={showModel} close={() => setShowModel(false)} entity={eventType} editHandler={handleSave} deleteHandler={handleDelete} defaultImage={'EventType_Default.png'} />
            </div>
        </>
    )
}

const Speciality = (props) => {

    const { data: dataSpecialties, isLoading: isLoadingSpecialties } = useGetAllSpecialitiesQuery(props.nicheId)
    const [updateSpecialty] = useUpdateSpecialityMutation()
    const [addSpecialty] = useAddSpecialityMutation()
    const [deleteSpecialty] = useDeleteSpecialityMutation()

    const [showModel, setShowModel] = useState(false);
    const [speciality, setSpeciality] = useState({ entityName: 'none' });
    const [specialtiesList, setSpecialtiesList] = useState();

    const [searchKeyword, setSearchKeyword] = useState();
    const [searchStatus, setSearchStatus] = useState();

    useEffect(() => {
        if (isLoadingSpecialties === false)
            setSpecialtiesList(dataSpecialties.payload)
    }, [dataSpecialties]);

    const handleSave = (e) => {
        updateSpecialty({ specialityId: speciality.entityId, specialityName: e.entityName, isApproved: e.isApproved, imageFileName: e.imageFileName, file: e.imageFileName }).unwrap();
    }

    const handleAdd = (e) => {
        if (e.entityName !== '') {
            if (dataSpecialties.payload.some(item => e.entityName.toUpperCase() === item.specialityName.toUpperCase()))
                toast.error(e.entityName + ` already exists`, { position: toast.POSITION.TOP_CENTER });
            else {
                addSpecialty({ specialityName: e.entityName, isApproved: true, createdBy: 336, imageFileName: "Speciality_Default.png", file: e.imageFileName, 'niche.nicheId': props.nicheId }).unwrap();
                toast.success(`Speciality has been created successfully!`, { position: toast.POSITION.TOP_CENTER });
            }
        }
    }

    const handleDelete = (e) => {
        deleteSpecialty({ specialityId: e.entityId });
    }


    return (
        <>

            <div className="row">
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row py-2">
                            <div class="col tiny-text"><i class="fa-solid fa-filter"></i>&nbsp;Search</div>
                        </div>
                        <div class="row pb-2">
                            <div className="col-6">
                                <input className="form-control" onChange={(e) => { setSearchKeyword(e.target.value) }}></input>
                            </div>
                            <div className="col-6">
                                <select className="form-control" onChange={(e) => setSearchStatus(e.target.value)}>
                                    <option value="">All</option>
                                    <option value="false">Pending</option>
                                    <option value="true">Approved</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row pb-2">
                            <div class="col tiny-text">
                                <i class="fas fa-plus-circle"></i>&nbsp;Add New Speciality
                            </div>
                        </div>
                        <AddEntity addHandler={handleAdd} />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <table className="table-hover table-striped table offering-td"><thead>
                    <tr>
                        <th>Specialty Id</th>
                        <th>Image</th>
                        <th>Specialty</th>
                        <th>Count</th>
                        <th>Approval Status</th>
                        {/* <th>Created By</th> */}
                        <th>Created On</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                    <tbody>
                        {
                            specialtiesList !== undefined &&
                            specialtiesList.filter(item => {
                                if (!searchKeyword) return true
                                if (item.specialityName.toLowerCase().includes(searchKeyword.toLowerCase())) {
                                    return true
                                }
                            }).filter(item => {
                                if (!searchStatus) return true
                                if (item.isApproved.toString() === searchStatus) {
                                    return true
                                }
                            }).map((sp, index) =>
                                <tr key={index}>
                                    <td className="text-center">{sp.specialityId}</td>
                                    <td><img src={process.env.REACT_APP_BASE_URL + `MediaStorage/Offers/` + sp.imageFileName} alt="" /></td>
                                    <td>{sp.specialityName}</td>
                                    <td className="text-center"><div className="badge rounded-pill bg-dark p-1 px-3" style={{ 'fontSize': '1rem' }}><i className="fa-solid fa-users"></i>&nbsp;{sp.childCount}</div></td>
                                    <td className="text-center">{sp.isApproved ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-sharp fa-solid fa-circle-xmark text-danger"></i>}</td>
                                    {/* <td>{sp.catererName == 'Administrator' ? <i className="fa-solid fa-circle-user"></i> : <i className="fa-solid fa-bell-concierge"></i>}&nbsp;{sp.catererName}</td> */}
                                    <td>{moment(sp.createdOn).format("MM-DD-YYYY h:mm A")}</td>
                                    <td className="text-center"><span><i onClick={(e) => { setSpeciality({ entityId: sp.specialityId, isApproved: sp.isApproved, entityName: sp.specialityName, catererCount: sp.childCount, imageFileName: sp.imageFileName }); setShowModel(true) }} className="simpleIconButton text-secondary fas fa-edit" title="modify"></i></span></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <EditEntity show={showModel} close={() => setShowModel(false)} entity={speciality} editHandler={handleSave} deleteHandler={handleDelete} defaultImage={'Speciality_Default.png'} />
            </div>
        </>
    )
}



const AddonService = () => {

    const { data: dataAddonServices, isLoading: isLoadingAddonServices } = useGetAllAddonServicesQuery()
    const [updateAddonService] = useUpdateAddonServiceMutation()
    const [addAddonService] = useAddAddonServiceMutation()
    const [deleteAddonService] = useDeleteAddonServiceMutation()
    const [addonServiceList, setAddonServiceList] = useState();

    const [searchKeyword, setSearchKeyword] = useState();
    const [searchStatus, setSearchStatus] = useState();

    useEffect(() => {
        if (isLoadingAddonServices === false)
            setAddonServiceList(dataAddonServices.payload)
    }, [dataAddonServices]);


    const [showModel, setShowModel] = useState(false);
    const [addonService, setAddonService] = useState({ entityName: 'none' });

    const handleSave = (e) => {
        updateAddonService({ addonServiceId: addonService.entityId, addonServiceName: e.entityName, isApproved: e.isApproved, imageFileName: e.imageFileName, file: e.imageFileName }).unwrap();
    }

    const handleAdd = (e) => {
        if (e.entityName !== '') {
            if (dataAddonServices.payload.some(item => e.entityName.toUpperCase() === item.addonServiceName.toUpperCase()))
                toast.error(e.entityName + ` already exists`, { position: toast.POSITION.TOP_CENTER });
            else {
                addAddonService({ addonServiceName: e.entityName, isApproved: true, createdBy: 336, imageFileName: "AddonService_Default.png", file: e.imageFileName }).unwrap();
                toast.success(`Addon Service has been created successfully!`, { position: toast.POSITION.TOP_CENTER });
            }
        }
    }

    const handleDelete = (e) => {
        deleteAddonService({ addonServiceId: e.entityId });
    }

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row py-2">
                            <div class="col tiny-text"><i class="fa-solid fa-filter"></i>&nbsp;Search</div>
                        </div>
                        <div class="row pb-2">
                            <div className="col-6">
                                <input className="form-control" onChange={(e) => { setSearchKeyword(e.target.value) }}></input>
                            </div>
                            <div className="col-6">
                                <select className="form-control" onChange={(e) => setSearchStatus(e.target.value)}>
                                    <option value="">All</option>
                                    <option value="false">Pending</option>
                                    <option value="true">Approved</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row pb-2">
                            <div class="col tiny-text">
                                <i class="fas fa-plus-circle"></i>&nbsp;Add New Addon Service
                            </div>
                        </div>
                        <AddEntity addHandler={handleAdd} />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <table className="table-hover table-striped table offering-td"><thead>
                    <tr>
                        <th>AddonService Id</th>
                        <th>Image</th>
                        <th>AddonService</th>
                        <th>Count</th>
                        <th>Approval Status</th>
                        {/* <th>Created By</th> */}
                        <th>Created On</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                    <tbody>
                        {
                            addonServiceList !== undefined &&
                            addonServiceList.filter(item => {
                                if (!searchKeyword) return true
                                if (item.addonServiceName.toLowerCase().includes(searchKeyword.toLowerCase())) {
                                    return true
                                }
                            }).filter(item => {
                                if (!searchStatus) return true
                                if (item.isApproved.toString() === searchStatus) {
                                    return true
                                }
                            }).map((sp, index) =>
                                <tr key={index}>
                                    <td className="text-center">{sp.addonServiceId}</td>
                                    <td><img src={process.env.REACT_APP_BASE_URL + `MediaStorage/Offers/` + sp.imageFileName} alt="" /></td>
                                    <td>{sp.addonServiceName}</td>
                                    <td className="text-center"><div className="badge rounded-pill bg-dark p-1 px-3" style={{ 'fontSize': '1rem' }}><i className="fa-solid fa-users"></i>&nbsp;{sp.childCount}</div></td>
                                    <td className="text-center">{sp.isApproved ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-sharp fa-solid fa-circle-xmark text-danger"></i>}</td>
                                    {/* <td>{sp.catererName == 'Administrator' ? <i className="fa-solid fa-circle-user"></i> : <i className="fa-solid fa-bell-concierge"></i>}&nbsp;{sp.catererName}</td> */}
                                    <td>{moment(sp.createdOn).format("MM-DD-YYYY h:mm A")}</td>
                                    <td className="text-center"><span><i onClick={(e) => { setAddonService({ entityId: sp.addonServiceId, isApproved: sp.isApproved, entityName: sp.addonServiceName, catererCount: sp.childCount, imageFileName: sp.imageFileName }); setShowModel(true) }} className="simpleIconButton text-secondary fas fa-edit" title="modify"></i></span></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <EditEntity show={showModel} close={() => setShowModel(false)} entity={addonService} editHandler={handleSave} deleteHandler={handleDelete} defaultImage={'AddonService_Default.png'} />
            </div>
        </>
    )
}



const ServingOption = () => {

    const { data: dataServingOptions, isLoading: isLoadingServingOptions } = useGetAllServingOptionsQuery()
    const [updateServingOption] = useUpdateServingOptionMutation()
    const [addServingOption] = useAddServingOptionMutation()
    const [deleteServingOption] = useDeleteServingOptionMutation()

    const [showModel, setShowModel] = useState(false);
    const [servingOption, setServingOption] = useState({ entityName: 'none' });
    const [servingOptionList, setServingOptionList] = useState();

    const [searchKeyword, setSearchKeyword] = useState();
    const [searchStatus, setSearchStatus] = useState();

    useEffect(() => {
        if (isLoadingServingOptions === false)
            setServingOptionList(dataServingOptions.payload)
    }, [dataServingOptions]);


    const handleSave = (e) => {
        updateServingOption({ servingOptionId: servingOption.entityId, servingOptionName: e.entityName, isApproved: e.isApproved, imageFileName: e.imageFileName, file: e.imageFileName }).unwrap();
    }

    const handleAdd = (e) => {
        if (e.entityName !== '') {
            if (dataServingOptions.payload.some(item => e.entityName.toUpperCase() === item.servingOptionName.toUpperCase()))
                toast.error(e.entityName + ` already exists`, { position: toast.POSITION.TOP_CENTER });
            else {
                addServingOption({ servingOptionName: e.entityName, isApproved: true, createdBy: 336, imageFileName: "ServingOption_Default.png", file: e.imageFileName }).unwrap();
                toast.success(`Serving Option has been created successfully!`, { position: toast.POSITION.TOP_CENTER });
            }
        }
    }

    const handleDelete = (e) => {
        deleteServingOption({ servingOptionId: e.entityId });
    }

    return (
        <>

            <div className="row">
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row py-2">
                            <div class="col tiny-text"><i class="fa-solid fa-filter"></i>&nbsp;Search</div>
                        </div>
                        <div class="row pb-2">
                            <div className="col-6">
                                <input className="form-control" onChange={(e) => { setSearchKeyword(e.target.value) }}></input>
                            </div>
                            <div className="col-6">
                                <select className="form-control" onChange={(e) => setSearchStatus(e.target.value)}>
                                    <option value="">All</option>
                                    <option value="false">Pending</option>
                                    <option value="true">Approved</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row pb-2">
                            <div class="col tiny-text">
                                <i class="fas fa-plus-circle"></i>&nbsp;Add New Serving Option
                            </div>
                        </div>
                        <AddEntity addHandler={handleAdd} />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <table className="table-hover table-striped table offering-td"><thead>
                    <tr>
                        <th>ServingOption Id</th>
                        <th>Image</th>
                        <th>ServingOption</th>
                        <th>Count</th>
                        <th>Approval Status</th>
                        {/* <th>Created By</th> */}
                        <th>Created On</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                    <tbody>
                        {
                            servingOptionList !== undefined &&
                            servingOptionList.filter(item => {
                                if (!searchKeyword) return true
                                if (item.servingOptionName.toLowerCase().includes(searchKeyword.toLowerCase())) {
                                    return true
                                }
                            }).filter(item => {
                                if (!searchStatus) return true
                                if (item.isApproved.toString() === searchStatus) {
                                    return true
                                }
                            }).map((sp, index) =>
                                <tr key={index}>
                                    <td className="text-center">{sp.servingOptionId}</td>
                                    <td><img src={process.env.REACT_APP_BASE_URL + `MediaStorage/Offers/` + sp.imageFileName} alt="" /></td>
                                    <td>{sp.servingOptionName}</td>
                                    <td className="text-center"><div className="badge rounded-pill bg-dark p-1 px-3" style={{ 'fontSize': '1rem' }}><i className="fa-solid fa-users"></i>&nbsp;{sp.childCount}</div></td>
                                    <td className="text-center">{sp.isApproved ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-sharp fa-solid fa-circle-xmark text-danger"></i>}</td>
                                    {/* <td>{sp.catererName == 'Administrator' ? <i className="fa-solid fa-circle-user"></i> : <i className="fa-solid fa-bell-concierge"></i>}&nbsp;{sp.catererName}</td> */}
                                    <td>{moment(sp.createdOn).format("MM-DD-YYYY h:mm A")}</td>
                                    <td className="text-center"><span><i onClick={(e) => { setServingOption({ entityId: sp.servingOptionId, isApproved: sp.isApproved, entityName: sp.servingOptionName, catererCount: sp.childCount, imageFileName: sp.imageFileName }); setShowModel(true) }} className="simpleIconButton text-secondary fas fa-edit" title="modify"></i></span></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <EditEntity show={showModel} close={() => setShowModel(false)} entity={servingOption} editHandler={handleSave} deleteHandler={handleDelete} defaultImage={'ServingOption_Default.png'} />
            </div>
        </>
    )
}


const Cuisine = () => {

    const { data: dataCuisines, isLoading: isLoadingCuisines } = useGetAllCuisinesQuery()
    const [updateCuisine] = useUpdateCuisineMutation()
    const [addCuisine] = useAddCuisineMutation()
    const [deleteCuisine] = useDeleteCuisineMutation()

    const [showModel, setShowModel] = useState(false);
    const [cuisine, setCuisine] = useState({ entityName: 'none' });
    const [cuisineList, setCuisineList] = useState();

    const [searchKeyword, setSearchKeyword] = useState();
    const [searchStatus, setSearchStatus] = useState();

    useEffect(() => {
        if (isLoadingCuisines === false)
            setCuisineList(dataCuisines.payload)
    }, [dataCuisines]);


    const handleSave = (e) => {
        updateCuisine({ cuisineId: cuisine.entityId, cuisineName: e.entityName, isApproved: e.isApproved, imageFileName: e.imageFileName, file: e.imageFileName, displayOrder: e.displayOrder }).unwrap();
    }

    const handleAdd = (e) => {
        if (e.entityName !== '') {
            if (dataCuisines.payload.some(item => e.entityName.toUpperCase() === item.cuisineName.toUpperCase()))
                toast.error(e.entityName + ` already exists`, { position: toast.POSITION.TOP_CENTER });
            else {
                addCuisine({ cuisineName: e.entityName, isApproved: true, createdBy: 336, imageFileName: "Cuisine_Default.png", file: e.imageFileName }).unwrap();
                toast.success(`Cuisine has been created successfully!`, { position: toast.POSITION.TOP_CENTER });
            }
        }
    }

    const handleDelete = (e) => {
        deleteCuisine({ cuisineId: e.entityId });
    }

    return (
        <>

            <div className="row">
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row py-2">
                            <div class="col tiny-text"><i class="fa-solid fa-filter"></i>&nbsp;Search</div>
                        </div>
                        <div class="row pb-2">
                            <div className="col-6">
                                <input className="form-control" onChange={(e) => { setSearchKeyword(e.target.value) }}></input>
                            </div>
                            <div className="col-6">
                                <select className="form-control" onChange={(e) => setSearchStatus(e.target.value)}>
                                    <option value="">All</option>
                                    <option value="false">Pending</option>
                                    <option value="true">Approved</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row pb-2">
                            <div class="col tiny-text">
                                <i class="fas fa-plus-circle"></i>&nbsp;Add New Cuisine
                            </div>
                        </div>
                        <AddEntity addHandler={handleAdd} />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <table className="table-hover table-striped table offering-td"><thead>
                    <tr>
                        <th>Cuisine Id</th>
                        <th>Image</th>
                        <th>Cuisine</th>
                        <th>Count</th>
                        <th>Approval Status</th>
                        <th>Created On</th>
                        <th>Display Order</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                    <tbody>
                        {
                            cuisineList !== undefined &&
                            cuisineList.filter(item => {
                                if (!searchKeyword) return true
                                if (item.cuisineName.toLowerCase().includes(searchKeyword.toLowerCase())) {
                                    return true
                                }
                            }).filter(item => {
                                if (!searchStatus) return true
                                if (item.isApproved.toString() === searchStatus) {
                                    return true
                                }
                            }).sort((a, b) => (a.displayOrder < b.displayOrder)).map((sp, index) =>
                                <tr key={index}>
                                    <td className="text-center">{sp.cuisineId}</td>
                                    <td><img src={process.env.REACT_APP_BASE_URL + `MediaStorage/Offers/` + sp.imageFileName} alt="" /></td>
                                    <td>{sp.cuisineName}</td>
                                    <td className="text-center"><div className="badge rounded-pill bg-dark p-1 px-3" style={{ 'fontSize': '1rem' }}><i className="fa-solid fa-users"></i>&nbsp;{sp.childCount}</div></td>
                                    <td className="text-center">{sp.isApproved ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-sharp fa-solid fa-circle-xmark text-danger"></i>}</td>
                                    <td>{moment(sp.createdOn).format("MM-DD-YYYY h:mm A")}</td>
                                    <td>{sp.displayOrder === 999 ? "" : sp.displayOrder}</td>
                                    <td className="text-center"><span><i onClick={(e) => { setCuisine({ entityId: sp.cuisineId, isApproved: sp.isApproved, entityName: sp.cuisineName, catererCount: sp.childCount, imageFileName: sp.imageFileName, displayOrder: sp.displayOrder }); setShowModel(true) }} className="simpleIconButton text-secondary fas fa-edit" title="modify"></i></span></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <EditEntity show={showModel} close={() => setShowModel(false)} entity={cuisine} editHandler={handleSave} deleteHandler={handleDelete} defaultImage={'Cuisine_Default.png'} />
            </div>
        </>
    )
}


const Dietary = () => {

    const { data: dataDietaries, isLoading: isLoadingDietaries } = useGetAllDietariesQuery()
    const [updateDietary] = useUpdateDietaryMutation()
    const [addDietary] = useAddDietaryMutation()
    const [deleteDietary] = useDeleteDietaryMutation()

    const [showModel, setShowModel] = useState(false);
    const [dietary, setDietary] = useState({ entityName: 'none' });
    const [dietaryList, setDietaryList] = useState();

    const [searchKeyword, setSearchKeyword] = useState();
    const [searchStatus, setSearchStatus] = useState();

    useEffect(() => {
        if (isLoadingDietaries === false)
            setDietaryList(dataDietaries.payload)
    }, [dataDietaries]);


    const handleSave = (e) => {
        updateDietary({ dietaryId: dietary.entityId, dietaryName: e.entityName, isApproved: e.isApproved, imageFileName: e.imageFileName, file: e.imageFileName }).unwrap();
    }

    const handleAdd = (e) => {
        if (e.entityName !== '') {
            if (dataDietaries.payload.some(item => e.entityName.toUpperCase() === item.dietaryName.toUpperCase()))
                toast.error(e.entityName + ` already exists`, { position: toast.POSITION.TOP_CENTER });
            else {
                addDietary({ dietaryName: e.entityName, isApproved: true, createdBy: 336, imageFileName: "Dietary_Default.png", file: e.imageFileName }).unwrap();
                toast.success(`Dietary has been created successfully!`, { position: toast.POSITION.TOP_CENTER });
            }
        }
    }

    const handleDelete = (e) => {
        deleteDietary({ dietaryId: e.entityId });
    }

    return (
        <>

            <div className="row">
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row py-2">
                            <div class="col tiny-text"><i class="fa-solid fa-filter"></i>&nbsp;Search</div>
                        </div>
                        <div class="row pb-2">
                            <div className="col-6">
                                <input className="form-control" onChange={(e) => { setSearchKeyword(e.target.value) }}></input>
                            </div>
                            <div className="col-6">
                                <select className="form-control" onChange={(e) => setSearchStatus(e.target.value)}>
                                    <option value="">All</option>
                                    <option value="false">Pending</option>
                                    <option value="true">Approved</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row pb-2">
                            <div class="col tiny-text">
                                <i class="fas fa-plus-circle"></i>&nbsp;Add New Dietary
                            </div>
                        </div>
                        <AddEntity addHandler={handleAdd} />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <table className="table-hover table-striped table offering-td"><thead>
                    <tr>
                        <th>Dietary Id</th>
                        <th>Image</th>
                        <th>Dietary</th>
                        <th>Count</th>
                        <th>Approval Status</th>
                        <th>Created On</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                    <tbody>
                        {
                            dietaryList !== undefined &&
                            dietaryList.filter(item => {
                                if (!searchKeyword) return true
                                if (item.dietaryName.toLowerCase().includes(searchKeyword.toLowerCase())) {
                                    return true
                                }
                            }).filter(item => {
                                if (!searchStatus) return true
                                if (item.isApproved.toString() === searchStatus) {
                                    return true
                                }
                            }).map((sp, index) =>
                                <tr key={index}>
                                    <td className="text-center">{sp.dietaryId}</td>
                                    <td><img src={process.env.REACT_APP_BASE_URL + `MediaStorage/Offers/` + sp.imageFileName} alt="" /></td>
                                    <td>{sp.dietaryName}</td>
                                    <td className="text-center"><div className="badge rounded-pill bg-dark p-1 px-3" style={{ 'fontSize': '1rem' }}><i className="fa-solid fa-users"></i>&nbsp;{sp.childCount}</div></td>
                                    <td className="text-center">{sp.isApproved ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-sharp fa-solid fa-circle-xmark text-danger"></i>}</td>
                                    <td>{moment(sp.createdOn).format("MM-DD-YYYY h:mm A")}</td>
                                    <td className="text-center"><span><i onClick={(e) => { setDietary({ entityId: sp.dietaryId, isApproved: sp.isApproved, entityName: sp.dietaryName, catererCount: sp.childCount, imageFileName: sp.imageFileName }); setShowModel(true) }} className="simpleIconButton text-secondary fas fa-edit" title="modify"></i></span></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <EditEntity show={showModel} close={() => setShowModel(false)} entity={dietary} editHandler={handleSave} deleteHandler={handleDelete} defaultImage={'Dietary_Default.png'} />
            </div>
        </>
    )
}


const Certification = () => {

    const { data: dataCertifications, isLoading: isLoadingCertifications } = useGetAllCertificationsQuery()
    const [updateCertification] = useUpdateCertificationMutation()
    const [addCertification] = useAddCertificationMutation()
    const [deleteCertification] = useDeleteCertificationMutation()

    const [showModel, setShowModel] = useState(false);
    const [certification, setCertification] = useState({ entityName: 'none' });
    const [certificationList, setCertificationList] = useState();

    const [searchKeyword, setSearchKeyword] = useState();
    const [searchStatus, setSearchStatus] = useState();

    useEffect(() => {
        if (isLoadingCertifications === false)
            setCertificationList(dataCertifications.payload)
    }, [dataCertifications]);

    const handleSave = (e) => {
        updateCertification({ certificationId: certification.entityId, certificationName: e.entityName, isApproved: e.isApproved, imageFileName: e.imageFileName, file: e.imageFileName }).unwrap();
    }

    const handleAdd = (e) => {
        if (e.entityName !== '') {
            if (dataCertifications.payload.some(item => e.entityName.toUpperCase() === item.certificationName.toUpperCase()))
                toast.error(e.entityName + ` already exists`, { position: toast.POSITION.TOP_CENTER });
            else {
                addCertification({ certificationName: e.entityName, isApproved: true, createdBy: 336, imageFileName: "Certification_Default.png", file: e.imageFileName }).unwrap();
                toast.success(`Certification has been created successfully!`, { position: toast.POSITION.TOP_CENTER });                
            }
        }
    }

    const handleDelete = (e) => {
        deleteCertification({ certificationId: e.entityId });
    }

    return (
        <>

            <div className="row">
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row py-2">
                            <div class="col tiny-text"><i class="fa-solid fa-filter"></i>&nbsp;Search</div>
                        </div>
                        <div class="row pb-2">
                            <div className="col-6">
                                <input className="form-control" onChange={(e) => { setSearchKeyword(e.target.value) }}></input>
                            </div>
                            <div className="col-6">
                                <select className="form-control" onChange={(e) => setSearchStatus(e.target.value)}>
                                    <option value="">All</option>
                                    <option value="false">Pending</option>
                                    <option value="true">Approved</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div class="rounded p-1" style={{ 'background-color': '#cfcfcf', 'height': '105px' }}>
                        <div class="row pb-2">
                            <div class="col tiny-text">
                                <i class="fas fa-plus-circle"></i>&nbsp;Add New Certification
                            </div>
                        </div>
                        <AddEntity addHandler={handleAdd} />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
            <table className="table-hover table-striped table offering-td"><thead>
                <tr>
                    <th>Certification Id</th>
                    <th>Image</th>
                    <th>Certification</th>
                    <th>Count</th>
                    <th>Approval Status</th>
                    <th>Created On</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
                <tbody>
                    {
                        certificationList !== undefined &&
                        certificationList.filter(item => {
                            if (!searchKeyword) return true
                            if (item.certificationName.toLowerCase().includes(searchKeyword.toLowerCase())) {
                                return true
                            }
                        }).filter(item => {
                            if (!searchStatus) return true
                            if (item.isApproved.toString() === searchStatus) {
                                return true
                            }
                        }).map((sp, index) =>
                            <tr key={index}>
                                <td className="text-center">{sp.certificationId}</td>
                                <td><img src={process.env.REACT_APP_BASE_URL + `MediaStorage/Offers/` + sp.imageFileName} alt="" /></td>
                                <td>{sp.certificationName}</td>
                                <td className="text-center"><div className="badge rounded-pill bg-dark p-1 px-3" style={{ 'fontSize': '1rem' }}><i className="fa-solid fa-users"></i>&nbsp;{sp.childCount}</div></td>
                                <td className="text-center">{sp.isApproved ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-sharp fa-solid fa-circle-xmark text-danger"></i>}</td>
                                <td>{moment(sp.createdOn).format("MM-DD-YYYY h:mm A")}</td>
                                <td className="text-center"><span><i onClick={(e) => { setCertification({ entityId: sp.certificationId, isApproved: sp.isApproved, entityName: sp.certificationName, catererCount: sp.childCount, imageFileName: sp.imageFileName }); setShowModel(true) }} className="simpleIconButton text-secondary fas fa-edit" title="modify"></i></span></td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <EditEntity show={showModel} close={() => setShowModel(false)} entity={certification} editHandler={handleSave} deleteHandler={handleDelete} defaultImage={'Certification_Default.png'} />
            </div>
        </>
    )
}

const CatererOfferings = () => {

    return (
        <>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h4><b><i className="fas fa-chalkboard-user"></i>&nbsp;Caterer Offerings</b></h4>
                    </div>
                </div>
                <ul className="nav nav-tabs-admin" id="myTab">
                    <li className="nav-item-admin">
                        <a href="#cuisine" className="nav-link-admin active" data-bs-toggle="tab">Cuisine</a>
                    </li>
                    <li className="nav-item-admin">
                        <a href="#dietary" className="nav-link-admin" data-bs-toggle="tab">Dietary</a>
                    </li>
                    <li className="nav-item-admin">
                        <a href="#specialty" className="nav-link-admin" data-bs-toggle="tab">Specialty</a>
                    </li>
                    <li className="nav-item-admin">
                        <a href="#eventtype" className="nav-link-admin" data-bs-toggle="tab">Event Type</a>
                    </li>
                    <li className="nav-item-admin">
                        <a href="#services" className="nav-link-admin" data-bs-toggle="tab">Services</a>
                    </li>
                    <li className="nav-item-admin">
                        <a href="#servingoption" className="nav-link-admin" data-bs-toggle="tab">Serving Options</a>
                    </li>
                    <li className="nav-item-admin">
                        <a href="#certification" className="nav-link-admin" data-bs-toggle="tab">Certification</a>
                    </li>
                    <li className="nav-item-admin">
                        <a href="#bakeryspecialty" className="nav-link-admin" data-bs-toggle="tab">Bakery Specialty</a>
                    </li>
                </ul>
                <div className="tab-content-admin">
                    <div className="tab-pane-admin fade show active" id="cuisine">
                        <h4 className="mt-2">Cuisine</h4>
                        <Cuisine />
                    </div>
                    <div className="tab-pane-admin fade" id="dietary">
                        <h4 className="mt-2">Dietary</h4>
                        <Dietary />
                    </div>
                    <div className="tab-pane-admin fade" id="specialty">
                        <h4 className="mt-1">Speciality</h4>
                        <Speciality nicheId={1} />
                    </div>
                    <div className="tab-pane-admin fade" id="eventtype">
                        <h4 className="mt-1">Event Type</h4>
                        <EventType />
                    </div>
                    <div className="tab-pane-admin fade" id="services">
                        <h4 className="mt-2">Services</h4>
                        <AddonService />
                    </div>
                    <div className="tab-pane-admin fade" id="servingoption">
                        <h4 className="mt-2">Serving Options</h4>
                        <ServingOption />
                    </div>
                    <div className="tab-pane-admin fade" id="certification">
                        <h4 className="mt-2">Certification</h4>
                        <Certification />
                    </div>

                    <div className="tab-pane-admin fade" id="bakeryspecialty">
                        <h4 className="mt-2">Bakery Speciality</h4>
                        <Speciality nicheId={3} />
                    </div>
                </div>




            </div>
        </>
    )
}


export default CatererOfferings