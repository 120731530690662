import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useState } from "react";
import { useAddContactMutation } from '../hooks/apiSlice';


const ContactUs = () => {

  const initialValues = { vname: "", phone: "", email: "", message: "", consentUseOfInfo: false }
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({ vname: "", phone: "", email: "", message: "", consentUseOfInfo: "" });
  const [isSubmit, setIsSubmit] = useState(false);

  const [addContactMutation] = useAddContactMutation()

  const handleChange = (e) => {
      if (e.target.name === "consentUseOfInfo")
        e.target.value = e.target.checked
    
        const { name, value } = e.target;
    if ((name === "phone" && (!/[0-9]/.test(e.target.value)))) {
      e.preventDefault();
    }
    else {
      setFormValues({ ...formValues, [name]: value })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length === 0) {
      addContactMutation({ name: formValues.vname, email: formValues.email, phone: formValues.phone, message: formValues.message, consentUseOfInfo: formValues.consentUseOfInfo })
      setIsSubmit(true);
    }
  }


  const validate = (values) => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!values.vname) {
      errors.vname = "Name is required";
    }
    if (!values.phone) {
      errors.phone = "Phone is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
    }

    if (!values.message) {
      errors.message = "Message is required";
    }
    return errors
  }

  return (

    <div>
      <Navbar />
      <main role="main">
        <div className="innerpage-banner ">
          <img src="images/static-banners/contact.jpg" className="innerpage-banner-img" alt="Contact Us" />
        </div>

        <section className="innerpage-container">

          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-md-8 col-lg-12">
                <div className="row contact-form-box align-items-center">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <h4 className="title mb-4">Want to simplify growing and managing your catering business?</h4>
                    <p>We are committed to making this site as useful for Halal food enthusiasts as possible. Send your feedback and constructive critiques to us.</p>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    {/* <pre>{JSON.stringify(formValues, undefined, 2)}</pre>
                    <pre>{JSON.stringify(formErrors, undefined, 2)}</pre>
                    {(Object.keys(formErrors).length === 0 ? "no error" : "error")} */}


                    {isSubmit ? <div className="mx-5"><h2 className='text-success'><i className="fas fa-comments"></i>&nbsp;Thank you for filling out your information!</h2>

                      <h5>We appreciate you contacting Halal Caters. If required one of our colleagues will get back in touch with you soon! Have a great day!</h5>
                    </div> :


                      <form onSubmit={handleSubmit} noValidate>

                        <div className="mb-3">
                          <input type="text" className="form-control" id="vname" name="vname" placeholder="Name*" maxLength="100" value={formValues.vname} onChange={handleChange} />
                          <div className="text-danger">{formErrors.vname}</div>
                        </div>
                        <div className="mb-3">
                          <input type="text" className="form-control" id="phone" name="phone" maxLength="12" placeholder="Phone*" value={formValues.phone} onChange={handleChange} />
                          <div className="text-danger">{formErrors.phone}</div>
                        </div>


                        <div className="mb-3">
                          <input type="email" className="form-control" id="email" name="email" placeholder="Email" maxLength="100" value={formValues.email} onChange={handleChange} />
                          <div className="text-danger">{formErrors.email}</div>
                        </div>


                        <div className="mb-3">
                          <textarea type="textarea" inputMode="m" className="form-control" id="message" name="message" placeholder="Message" maxLength="500" style={{ 'height': '200px' }} value={formValues.message} onChange={handleChange} />
                          <div className="text-danger">{formErrors.message}</div>
                        </div>

                        <div className="mb-4">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="consentUseOfInfo" id="consentUseOfInfo" value={formValues.consentUseOfInfo} onChange={handleChange} />
                            <label className="form-check-label" htmlFor="consentUseOfInfo">
                              I agree that my information will be used to contact me about Halal Caters services or
                              news.
                            </label>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                      </form>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

      </main>

      <Footer></Footer>

    </div>
  )
}

export default ContactUs