import React, { useState } from "react";
import { useGetBusinessClaimQuery } from '../hooks/apiSlice';
import moment from 'moment'


const Claims = () => {

    const [page, setPage] = useState(1)
    const [sortField, setSortField] = useState("CreatedOn");
    const [order, setOrder] = useState("desc");

    const { data: dataClaim, isLoading: isLoadingClaim, isFetching } = useGetBusinessClaimQuery({ pageNum: page, sortColumn: sortField, sortDirection: order })

    const handleSortingChange = (accessor) => {
        setSortField(accessor);
        setOrder(order === "asc" ? "desc" : "asc");
    };

    function getColumnHeader(columnName, sortString) {
        const arrowDirection = sortField === sortString && order === "asc" ? <i className="fas fa-arrow-up"></i> : sortField === sortString && order === "desc" ? <i className="fas fa-arrow-down"></i> : ""

        return (
            <span onClick={() => handleSortingChange(sortString)}> {isFetching ? <span className="ms-2 spinner-border spinner-border-sm"></span> : columnName} {arrowDirection}</span>
        )
    }

    return (
        <>
            {isLoadingClaim ? "" :

                <div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <h4><b><i className="fa-solid fa-plug-circle-exclamation"></i>&nbsp;Claims</b></h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="strpied-tabled-with-hover card">

                                    <div className="table-full-width table-responsive px-0 card-body">

                                        <table className="table-hover table-striped table"><thead>
                                            <tr>
                                                <th>{getColumnHeader('Name', 'Name')}</th>
                                                <th>{getColumnHeader('Email', 'Email')}</th>
                                                <th>{getColumnHeader('Phone', 'Phone')}</th>
                                                <th>{getColumnHeader('Comments', 'Comments')}</th>
                                                <th>{getColumnHeader('Date Submitted', 'CreatedOn')}</th>
                                                <th>{getColumnHeader('Company', 'Company.CompanyName')}</th>
                                            </tr>
                                        </thead>
                                            <tbody>
                                                {dataClaim.data.length === 0 ? <tr><td colSpan="6" className="text-center p-4">No record found</td></tr> :
                                                    dataClaim.data.map((cont, index) =>
                                                        <tr key={index}>
                                                            <td>{cont.name}</td>
                                                            <td>{cont.email}</td>
                                                            <td>{cont.phone}</td>
                                                            <td>{cont.comments}</td>
                                                            <td style={{ 'width': '180px' }}>{moment(cont.createdOn).format("MM-DD-YYYY h:mm A")}</td>
                                                            <td style={{ 'fontSize': '0.8rem' }} className="rounded listing-image">



                                                                {
                                                                    (cont.company.logoFileName) ?
                                                                        <img className="rounded-circle" src={process.env.REACT_APP_BASE_URL + "MediaStorage/logo/" + cont.company.logoFileName} alt={cont.company.companyName} style={{ 'width': '40px', 'height': '40px' }} />
                                                                        :

                                                                        <div className="picture-placeholder reviewAvatar">{cont.company.companyName.substring(0, 1)}

                                                                        </div>
                                                                }
                                                                &nbsp;
                                                                {cont.company.companyName}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>


                                    <div className="pagination pb-3">

                                        <button className="prev-btn"
                                            onClick={() => setPage((prev) => prev - 1)}
                                            disabled={page === 1 || isFetching}
                                        >
                                            {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-left"></i>}
                                        </button>
                                        <button className="next-btn"
                                            onClick={() => setPage((prev) => prev + 1)}
                                            disabled={page >= dataClaim.totalPages || isFetching}
                                        >
                                            {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-right"></i>}
                                        </button>
                                        <div className="page">
                                            {`${page} / ${dataClaim.totalPages}`}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default Claims