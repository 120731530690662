import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'


const HalalOffering = () => {



  return (

    <div>
      <Navbar />

      <main role="main">


<div className="innerpage-banner ">
  <img src="images/static-banners/halaloffering.jpg" className="innerpage-banner-img" alt="halal offering"/>
</div>



<section className="innerpage-container">

  <div className="container">
    <div className="row">

    <div className="col-12 col-md-12 col-md-12 col-lg-12">
        
      
      <h2>Chains offering halal products</h2>
      <p>There are a number of large chains that operate one or more locations that offer halal meals or Halal goods. Below is a list of the chains and locations that have halal products on hand. Be aware that not all stores in a chain will carry halal goods; you should verify this information for yourself.</p>

      
      <div className="row mt-4 align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">AMERICAN CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">
                  <img src="./images/halal-offering/safeway.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Safeway</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>589 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/publix.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Publix</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>482 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/costco.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Costco</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>380 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">
                  
                  <img src="./images/halal-offering/stop-shop.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Stop & Shop</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>302 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">
                  
                  <img src="./images/halal-offering/whole-food.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Whole Foods Market</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>236 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">
                  
                  <img src="./images/halal-offering/shaws.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Shaw's</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>178 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">BRITISH CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/subway.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Subway</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>111 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/tesco.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Tesco</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>79 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/kfc.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">KFC</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>78 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/nandos.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Nandos</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>72 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/gbk.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Gourmet Burger Kitchen</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>49 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/chopstix.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Chopstix Noodle Bar</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>49 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">CANADIAN CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/popeyes.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Popeyes Louisiana Kitchen</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>73 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/mary.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Mary Brown's</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>45 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/church.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Church's Texas Chicken</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>44 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/villa.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Villa Madina</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>43 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/south.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">South St. Burger</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>37 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/walmart.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Walmart</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>33 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2"> AUSSIE CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/oporto.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Oporto</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>94 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/sunshine.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Sunshine Kebabs</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>34 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/hungry.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Hungry Jack's</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>23 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/sitar.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Sitar Indian Restaurants</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>19 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/guzman.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Guzman Y Gomez</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>19 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/hot.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Hot Star Chicken</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>17 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">SOUTH AFRICAN CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/debonairs.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Debonairs Pizza</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>67 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/kauai.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Kauai</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>43 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/mochachos.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Mochachos</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>38 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/steers.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Steers</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>34 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/fego.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Fego Caffé</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>27 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/mammas1.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Mamma's Chicken</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>26 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">SINGAPOREAN CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/pizzahut.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Pizza Hut</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>45 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/polar.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Polar Puffs & Cakes</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>28 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/swensens.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Swensen's</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>26 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/banquet.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Banquet</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>23 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/fish-co.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Fish & Co.</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>18 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/sarpino.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Sarpino's Pizzeria</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>15 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">GERMAN CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/vapiano.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Vapiano</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>66 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/mr-chicken.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Mr. Chicken</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>15 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/eurogida.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Eurogida</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>13 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/bolu.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Bolu Markt</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>9 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/burger-brothers.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">BurgerBrothers</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>8 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/hasir.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Hasir</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2"> FRENCH CHAINS </h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/nabab.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Nabab Kebab</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>21 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/o-kebap.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">O'Kebap</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>12 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/o-tacos.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">O'Tacos</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>11 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/afrik.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Afrik'N'Fusion</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/noura.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Noura</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>4 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/quick.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Quick</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>3 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">RUSSIAN CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/livan.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Livan House</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>21 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/uryuk.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Uryuk</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>21 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/tubaty.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Tubatay</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/jerry.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Jerry Wings</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>5 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/saltenas.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Saltenas</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>5 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/tajj-mahal.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Tajj Mahal</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>4 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">KIWI CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/burger-fuel.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Burger Fuel</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>51 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/kebabs.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Kebabs on Queen</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>12 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/tulsi.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Tulsi</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/nandos-kiwi.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Nandos</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>5 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/pedros.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Pedro's House of Lamb</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>4 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/spice.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Spice Traders</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>2 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">MALAYSIAN  CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/secret.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Secret Recipe</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>22 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/delicious.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Delicious</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>7 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/kfc-malaysia.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">KFC Malaysia</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>5 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/borneo.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Borneo Farm</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>4 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/nasi.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Nasi Ayam Chee Meng</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>3 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/pizzahut.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Pizza Hut</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>2 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">DUTCH CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/johnny.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Johnny's Burger Co.</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>18 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/hfc.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Halal Fried Chicken</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>7 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/meram.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Meram</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/hbc.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Halal Burgers & Chicken</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>4 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/roopram.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Roopram Roti</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>4 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">FILIPINO CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/new-bombay.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">New Bombay</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/halal-guys.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">The Halal Guys</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/hossein.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Hossein's</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>4 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/secret-recipe.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Secret Recipe</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>3 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">INDIAN CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/mcdonald.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">McDonald's</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>240 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/hyderabad-house.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Hyderabad House</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>11 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/kathi.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Kathi Cartel</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/kfc.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">KFC India</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>1 OUTLET</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">SPANISH CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/doner.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Döner Kebab Lal Ali</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>12 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/beykebap.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">BeyKebap</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>7 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/pad-thai.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Pad Thai Wok</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>5 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">THAI CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/mrs-balbir.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Mrs Balbir's Indian Food</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>5 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/bawarchi.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Bawarchi</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>1 OUTLET</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/kfc-thailand.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">KFC Thailand</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>1 OUTLET</b>
                  </div>
                </div>
              </div>
            </div>

          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">SRI LANKAN CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/pizzahut.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Pizza Hut</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>15 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/mcdonald-srilanka.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">McDonald's</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>4 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/dinemore.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">DineMore</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>4 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>

          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2"> CHINESE CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/brotherskebab.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Brothers Kebab</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/khan-baba.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Khan Baba</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>4 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/indian-kitchen.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Indian Kitchen</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>1 OUTLET</b>
                  </div>
                </div>
              </div>
            </div>

          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">AUSTRIAN CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/aycan.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Aycan Supermarket</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>14 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/turkis.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Türkis Palast</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>9 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">BELGIAN CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/hector.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Hector Chicken</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/delhaize.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Delhaize</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>1 OUTLET</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">HONGKONGESE CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/ebeneezer.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Ebeneezer's Kebab</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>10 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/triple-Os.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Triple O's</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>5 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">FINNISH CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/erikin.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Eerikin Pippuri</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/alanya.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Alanya Oriental Market</p>
                  <p className="halal-offering-country mb-0">Grocery chain</p>
                  <div className="halal-offering-outlet">
                    <b>2 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">INDONESIAN CHAINS</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/pizzahut.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Pizza Hut Indonesia</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>226 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/kfc.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">KFC Indonesia</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>1 OUTLET</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">LEBANESE CHAIN</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/kababji.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Kababji Grill</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">ITALIAN CHAIN</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/chicken-n-chicken.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Chicken N Chicken</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>10 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">IRISH CHAIN</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/subway.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Subway</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>3 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">TURKISH CHAIN</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/bereket-doner.png" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Bereket Döner</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>82 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">PAKISTANI CHAIN</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/burger-king.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Burger King</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>1 OUTLET</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">EMIRATI CHAIN</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/just-falafel.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Just Falafel</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>10 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">SWISS CHAIN</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/supreme.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Suprême Tacos</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>6 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">AZERBAIJANI CHAIN</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/papa.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Papa John's</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>9 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">KENYAN CHAIN</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/galitos.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Galito's</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>3 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">VIETNAMESE CHAIN</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/lion.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Lion City</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>4 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">LAOTIAN CHAIN </h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/nazim.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Nazim</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>4 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="row border-top align-items-center">


        <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
          <h6 className="p-2">JAPANESE CHAIN</h6>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
          <div className="row offering-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

              <div className="halal-offering-box align-item-center">
                <div className="halal-offering-badge">

                  <img src="./images/halal-offering/siddique.jpg" width="90" height="60" alt='' />
                </div>
                <div className="halal-offering-details">
                  <p className="halal-offering-name mb-0">Siddique</p>
                  <p className="halal-offering-country mb-0">Restaurant chain</p>
                  <div className="halal-offering-outlet">
                    <b>19 OUTLETS</b>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      
    </div>
        

    </div>



    </div>

</section>









  </main>


      <Footer></Footer>
    </div>
  )
}

export default HalalOffering