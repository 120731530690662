import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";


const ModalRegister = () => {

    const [profile, setProfile] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setShow(true);
            clearInterval(interval)
        }, 50000);
        return () => clearInterval(interval);
    }, []);



    return (
        <Modal className="register-modal" show={show} onHide={handleClose} backdrop="static" keyboard="False">
            <Modal.Header closeButton>
                <Modal.Title><h3 className="text-danger">Join HalalCaters, for FREE !</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="col-12">
                    <div className="row margin0">
                        <div className="col-lg-12 d-flex justify-content-center border rounded p-3 mt-4 loginwith">
                            <LoginSocialFacebook appId="2228773394176812"

                                onResolve={(response) => {
                                    console.log(response)
                                    setProfile(response.data)
                                }}

                                onReject={(error) => { console.log(error) }
                                }
                            >

                                <FacebookLoginButton />
                            </LoginSocialFacebook>

                            {profile.data ? <div><h1>{profile.name}</h1> <img src={profile.picture.data.url} alt="" /></div> : ""}
                        </div>
                        <div className="col-lg-12 d-flex justify-content-center border rounded p-3 mt-2 loginwith">
                            <Link to="/signup" state={{ from: "customer" }} >
                                <img src="images/envelope.png" alt="" /> Sign-up as customer
                            </Link>
                        </div>

                        <div className="col-lg-12 d-flex justify-content-center p-3 mt-2 or-div">
                            <span>or</span>
                        </div>
                    </div>

                    <div className="row margin0 form-log justify-content-center">
                        <Link to="/signup" state={{ from: "business" }} className="btn btn-danger btn-lg w-75 ">Add My Business Listing</Link>
                        <p className="text-left newlink mt-2">By clicking Sign-up, <strong>Connect with Facebook, Connect with Google,</strong>you agree to Halal Caters <a href="/privacy-policy">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a></p>
                        <p className="text-left newlink mt-3">Already a member?  <Link to="/signin" style={{ color: "black" }}>Sign-in</Link> </p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <button className="btn btn-secondary" onClick={handleClose}>
                    Not interested right now
                </button>
            </Modal.Footer>
        </Modal>
    )

}

export default ModalRegister