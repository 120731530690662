// ver4.0
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'




export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${localStorage.getItem("accessToken")}`)
      return headers
    }
  }),
  // headers: {
  //   'Content-Type': 'application/json',
  //   Accept: '*/*',
  //   'Content-Encoding': 'gzip, deflate, br',
  // },

  tagTypes: ['speciality'],
  endpoints: (builder) => ({

    getStates: builder.query({
      query: () => '/State/GetStates',
      transformResponse: res => res,
      providesTags: ['speciality']
    }),


    // // //************ COMPANY **************/


    // InitiateCompanyPanel: builder.query({
    //   query: (companyId) => `UserProfile/InitiateCompanyPanel/${companyId}`,
    //   transformResponse: res => res
    // }),

    InitiateCompanyPanel: builder.mutation({
      query: (companyId) => ({
        url: `UserProfile/InitiateCompanyPanel`,
        method: "PUT",
        params:
          companyId,
      })
    }),



    getBusinessProfileCompletionStatus: builder.query({
      query: () => `/Company/GetBusinessProfileCompletionStatus`,
      transformResponse: res => res,
      providesTags: ['company-update']
    }),

    getOfferCompletionStatus: builder.query({
      query: () => `/Company/GetOfferCompletionStatus`,
      transformResponse: res => res,
      providesTags: ['company-update-completionStatus']
    }),

    getCompanyList: builder.query({
      query: (companySearch) => ({
        url: `/Company/GetCompanyList`,
        method: "GET",
        params:
          companySearch
      }),
      transformResponse: res => res,
      providesTags: ['company-search', 'company-update-admin', 'company-add', 'company-clone']
    }),

    updateBusinessProfile: builder.mutation({
      query: (company) => ({
        url: "Company/UpdateBusinessProfile",
        method: "PUT",
        params:
          company,
      }),
      invalidatesTags: ['company-update'],
    }),


    toggleListingStatus: builder.mutation({
      query: (status) => ({
        url: `Company/ToggleListingStatus/${status}`,
        method: "PUT"
      })
    }),

    updateCompanyStatus: builder.mutation({
      query: (company) => ({
        url: "Company/UpdateCompanyStatus",
        method: "PUT",
        params:
          company,
      }),
      invalidatesTags: ['company-update-admin'],
    }),

    updateCompanyImage: builder.mutation({
      query: (menuItem) => {
        let formData = new FormData();
        formData.append("file", menuItem.file);
        return {
          url: `Company/UpdateImage`,
          method: 'POST',
          body: formData,
          params: menuItem
        }
      }
    }),

    addCompany: builder.mutation({
      query: (company) => ({
        url: "Company/AddCompany",
        method: "POST",
        params:
          company,
      }),
      invalidatesTags: ['company-add']
    }),

    searchCompanyListing: builder.query({
      query: (multipleParams) => `/Company/SearchCompanyListing/${multipleParams.cuisineIds}/${multipleParams.certificationIds}/${multipleParams.dietaryIds}/${multipleParams.specialityIds}/${multipleParams.servingOptIds}/${multipleParams.eventTypeIds}/${multipleParams.rateId}/${multipleParams.stateIds}/${multipleParams.addonServiceIds}/${multipleParams.catererName}/${multipleParams.nicheIds}/${multipleParams.userProfileId}/${multipleParams.cityZipSearch}/${multipleParams.pageNo}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, currentArg) => {
        if (currentArg.arg.pageNo === 1) {
          currentCache.payload = newItems.payload
        }
        else {
          currentCache.payload.push(...newItems.payload);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      }
    }),





    getBusinessProfile: builder.query({
      query: () => `/Company/GetCompanyDetail`,
      transformResponse: res => res
    }),


    getCompanyDetail: builder.query({
      query: (companyId) => `/Company/GetCompanyDetail/${companyId}`,
      transformResponse: res => res,
      providesTags: ['review-add']
    }),


    getCompanyByKeyword: builder.query({
      query: (keyword) => `Company/GetCompanyByKeyword/${keyword}`,
      transformResponse: res => res
    }),


    cloneCompany: builder.mutation({
      query: (company) => ({
        url: "Company/CloneCompany",
        method: "POST",
        params:
          company,
      }),
      invalidatesTags: ['company-clone']
    }),


    // //************ COMPANY **************/



    // //************ NICHES **************/

    getNiche: builder.query({
      query: (multipleParams) => `/Niche/GetNiches`,
      transformResponse: res => res
    }),


    // //************ NICHES **************/


    //************ ADDRESS **************/
    getAddress: builder.query({
      query: (companyId) => `/Address/GetAddressByCompanyId/${companyId}`,
      transformResponse: res => res
    }),
    getTop11Cities: builder.query({
      query: () => `/Address/GetTop11Cities`,
      transformResponse: res => res
    }),
    //************ ADDRESS **************/


    // //************ FAVORITE **************
    getFavorite: builder.query({
      query: () => `/Company/GetFavorite`,
      transformResponse: res => res,
      providesTags: ['favorite-add']
    }),

    toggleFavorite: builder.mutation({
      query: (favorite) => ({
        url: 'Company/ToggleFavorite',
        method: "POST",
        params:
          favorite
      }),
      invalidatesTags: ['favorite-add']
    }),
    // //************ FAVORITE **************






    // //************ REVIEW **************/

    getReviewByCompany: builder.query({
      query: (reviewSearch) => ({
        url: `/Review/GetReviewByCompanyId`,
        method: "GET",
        params:
          reviewSearch
      }),
      transformResponse: res => res,
      providesTags: ['review-update-status', 'review-update-admin','review-add', 'review-remove']
    }),

    getReview: builder.query({
      query: (reviewId) => `/Review/GetReview/${reviewId}`,
      transformResponse: res => res,
      providesTags: ['review-add']
    }),

    updateReviewStatus: builder.mutation({
      query: (review) => ({
        url: "Review/ReviewUpdateStatus",
        method: "PUT",
        params:
          review
      }),
      invalidatesTags: ['review-update-status'],
    }),

    addReview: builder.mutation({
      query: (review) => ({
        url: "/Review/ReviewSave",
        method: "POST",
        body: review.file,
        params:
          review
      }),
      invalidatesTags: ['review-add'],
    }),

    removeReview: builder.mutation({
      query: (reviewId) => ({
        url: `Review/RemoveReview/${reviewId}`,
        method: "POST"
      }),
      invalidatesTags: ["review-remove"],
    }),

    // //************ REVIEW **************/




    // //************ AWARD **************///RTK-Query not supporting file upload
    // getAwardByCaterer: builder.query({
    //   query: (catererId) => `/Award/GetAwardByCatererId/${catererId}`,
    //   transformResponse: res => res,
    //   providesTags: ['award2']
    // }),

    // addAward: builder.mutation({
    //   query: (award) => {
    //     let formData = new FormData();
    //     formData.append("file", award.file);
    //     return {
    //       url: `Award/AwardAdd`,
    //       method: 'POST',
    //       body: formData,
    //       params: award
    //     }
    //   },
    //   invalidatesTags: ['award2'],
    // }),

    // deleteAward: builder.mutation({
    //   query: (award) => ({
    //     url: "Award/RemoveAward",
    //     method: "POST",
    //     params:
    //       award
    //   }),
    //   invalidatesTags: ["award2"],
    // }),

    // //************ AWARD **************///RTK-Query not supporting file upload


    // //************ VIDEO **************
    getVideoByCompanyId: builder.query({
      query: () => `/Video/GetVideoByCompanyId`,
      transformResponse: res => res,
      providesTags: ['video']
    }),

    addVideo: builder.mutation({
      query: (video) => {
        let formData = new FormData();
        formData.append("file", video.file);
        return {
          url: `Video/SaveVideo`,
          method: 'POST',
          body: formData,
          params: video
        }
      },
      invalidatesTags: ['video'],
    }),

    deleteVideo: builder.mutation({
      query: (video) => ({
        url: "Video/RemoveVideo",
        method: "POST",
        params:
          video
      }),
      invalidatesTags: ["video"],
    }),

    // //************ VIDEO **************



    // //************ MENU ITEM **************/
    getMenuItemByCompany: builder.query({
      query: (companyId) => `/Menu/GetMenuItemByCompanyId/${companyId}`,
      transformResponse: res => res,
      providesTags: ['menuItem-add', 'menuItem-remove']
    }),

    addMenuItem: builder.mutation({
      query: (menuItem) => {
        let formData = new FormData();
        formData.append("file", menuItem.file);
        return {
          url: `Menu/MenuAdd`,
          method: 'POST',
          body: formData,
          params: menuItem
        }
      },
      invalidatesTags: ['menuItem-add'],
    }),

    deleteMenuItem: builder.mutation({
      query: (menuItemId) => ({
        url: `Menu/MenuDelete/${menuItemId}`,
        method: "POST"
      }),
      invalidatesTags: ["menuItem-remove"],
    }),

    getMenuDocument: builder.query({
      query: (companyId) => `/Menu/GetMenuDocument/${companyId}`,
      transformResponse: res => res,
      providesTags: ['menu-document-upload', 'menu-document-delete']
    }),

    addMenuDoc: builder.mutation({
      query: (menuItem) => {
        let formData = new FormData();
        formData.append("file", menuItem.file);
        return {
          url: `Menu/MenuDocumentUpload`,
          method: 'POST',
          body: formData,
          params: menuItem
        }
      },
      invalidatesTags: ["menu-document-upload"]
    }),

    getMenuItemCategory: builder.query({
      query: (catererId) => `/Menu/GetMenuItemCategories`,
      transformResponse: res => res
    }),

    deleteMenuDocument: builder.mutation({
      query: (menuDocumentId) => ({
        url: `Menu/MenuDocumentDelete/${menuDocumentId}`,
        method: "POST"
      }),
      invalidatesTags: ['menu-document-delete'],
    }),

    saveExternalMenuURL: builder.mutation({
      query: (externalMenuURL) => ({
        url: `Menu/SaveExternalMenuURL`,
        method: "POST",
        params: externalMenuURL
      })
    }),

    // //************ MENU ITEM **************/

    // //************ EVENT GALLERY **************/

    getEventGallery: builder.query({
      query: (companyId) => `/Gallery/GetGalleryByCompanyId/${companyId}`,
      transformResponse: res => res,
      providesTags: ['eventgallery-add', 'eventgallerypicture-delete', 'eventgallery-name-update', 'eventgallery-mark-cover-picture']
    }),

    getEventGalleryPicture: builder.query({
      query: (eventGalleryId) => `/Gallery/GetEventGalleryPictureByEventGalleryId/${eventGalleryId}`,
      transformResponse: res => res,
      providesTags: ['eventgallery-add','eventgallerypicture-delete']
    }),

    saveEventGallery: builder.mutation({
      query: (eventGallery) => ({
        url: "/Gallery/GallerySave",
        method: "POST",
        body: eventGallery.file,
        params:
          eventGallery
      }),
      invalidatesTags: ["eventgallery-add"]//,

      // prepareHeaders: (headers) => {
      //   headers.set('authorization', `Bearer ${localStorage.getItem("accessToken")}`)
      //   return headers
      // },
    }),

    deleteEventGallery: builder.mutation({
      query: (eventGalleryId) => ({
        url: 'Gallery/GalleryRemove',
        method: "POST",
        params:
          eventGalleryId
      }),
      invalidatesTags: ['eventgallery-delete'],
    }),

    deleteEventGalleryPicture: builder.mutation({
      query: (eventGalleryPictureId) => ({
        url: `Gallery/GalleryPictureRemove/${eventGalleryPictureId}`,
        method: "POST"
      }),
      invalidatesTags: ['eventgallerypicture-delete'],
    }),


    saveEventGalleryName: builder.mutation({
      query: (eventGallery) => ({
        url: "/Gallery/GalleryNameUpdate",
        method: "POST",
        params:
          eventGallery
      }),
      invalidatesTags: ["eventgallery-name-update"],
    }),


    markCoverPicture: builder.mutation({
      query: (eventGalleryPictureId) => ({
        url: `/Gallery/MarkCoverPicture/${eventGalleryPictureId}`,
        method: "POST"
      }),
      invalidatesTags: ["eventgallery-mark-cover-picture"],
    }),

    // // addEventGallery: builder.mutation({
    // //   queryFn(eventGallery, _queryApi, _extraOptions, fetchWithBQ) {
    // //     const response = fetchWithBQ({
    // //       url: '/EventGallery/EventGalleryAdd',
    // //       method: 'POST',
    // //       body: eventGallery.file,
    // //       params: eventGallery
    // //     }, _extraOptions);
    // //     if (response.error) throw response.error;
    // //     return response.data ? { data: response.data } : { error: response.error };
    // //   },
    // //   invalidatesTags: ['eventgallery-add'],
    // // }),


    // // mutation with AXIOS
    // // upload: builder.mutation({
    // //   queryFn: ({ url, data }, api) => {
    // //     try {
    // //       const result = axios.post(url, data, {
    // //         //...other options like headers here
    // //         onUploadProgress: upload => {
    // //           //Set the progress value to show the progress bar
    // //           let uploadloadProgress = Math.round((100 * upload.loaded) / upload.total);
    // //           api.dispatch(setUploadProgress(uploadloadProgress));
    // //         },
    // //       });

    // //       return { data: result.data }

    // //     } catch (axiosError) {
    // //       let err = axiosError
    // //       return {
    // //         error: {
    // //           status: err.response?.status,
    // //           data: err.response?.data || err.message,
    // //         },
    // //       }
    // //     }
    // //   }
    // // }),

    // //************ EVENT GALLERY **************/


    // //************ BADGE **************/

    // getAllBadge: builder.query({
    //   query: () => `/Badge/GetAllBadge`,
    //   transformResponse: res => res
    // }),

    // getBadgeStatus: builder.query({
    //   query: () => `/Badge/GetBadgeStatus`,
    //   transformResponse: res => res
    // }),


    // getBadgeByCatererId: builder.query({
    //   query: (catererId) => `/Badge/GetBadgeByCatererId/${catererId}`,
    //   transformResponse: res => res,
    //   providesTags: ['caterer-badge-save']
    // }),

    // getVerifiedBadgeByCatererId: builder.query({
    //   query: (catererId) => `Badge/GetVerifiedBadgeByCatererId/${catererId}`,
    //   transformResponse: res => res,
    //   providesTags: ['caterer-badge-save1']
    // }),

    // getBadge: builder.query({
    //   query: (badgeSearch) => ({
    //     url: `/Badge/GetBadge`,
    //     method: "GET",
    //     params:
    //       badgeSearch
    //   }),
    //   transformResponse: res => res,
    //   providesTags: ['badge-search', 'caterer-badge-update', 'caterer-badge-save']
    // }),

    // saveCatererBadge: builder.mutation({
    //   query: (catererBadge) => ({
    //     url: "Badge/CatererBadgeSave",
    //     method: "POST",
    //     params:
    //       catererBadge
    //   }),
    //   invalidatesTags: ['caterer-badge-save'],
    // }),


    // updateCatererBadge: builder.mutation({
    //   query: (catererBadge) => ({
    //     url: "Badge/CatererBadgeUpdate",
    //     method: "PUT",
    //     params:
    //       catererBadge
    //   }),
    //   invalidatesTags: ['caterer-badge-update'],
    // }),

    // //************ BADGE **************/



    // //************ MESSAGE **************/
    getMessageByCompanyId: builder.query({
      query: () => `Message/GetMessageByCompanyId`,
      transformResponse: res => res,
      providesTags: ['message-save1']
    }),

    saveMessage: builder.mutation({
      query: (message) => ({
        url: "Message/MessageAdd",
        method: "POST",
        params:
          message
      }),
      invalidatesTags: ["message-save"],
    }),

    // //************ MESSAGE **************/


    // //************ CONTACT **************/

    getContact: builder.query({
      query: (contact) => ({
        url: `VisitorContact/VisitorContactGet`,
        method: "GET",
        params:
          contact
      }),
      transformResponse: res => res,
    }),

    addContact: builder.mutation({
      query: (contact) => ({
        url: "VisitorContact/VisitorContactAdd",
        method: "POST",
        params:
          contact
      })
    }),
    // //************ CONTACT **************/



    // //************ SPECIALTY **************/

    getAllSpecialities: builder.query({
      query: (nicheId) => `/Speciality/GetAllSpecialities/${nicheId}`,
      transformResponse: res => res,
      providesTags: ['speciality-add', 'speciality-update', 'speciality-delete']
    }),

    getSpecialities: builder.query({
      query: () => '/Speciality/GetSpecialities',
      transformResponse: res => res,
      providesTags: ['speciality']
    }),

    getSpecialitiesSelectionsByCompanyId: builder.query({
      query: () => `/Speciality/GetSpecialitiesByCompanyId`,
      transformResponse: res => res,
      providesTags: ['speciality']
    }),

    getPendingApprovalSpecialities: builder.query({
      query: () => `/Speciality/GetPendingApprovalSpecialities`,
      transformResponse: res => res,
      providesTags: ['speciality-add', 'speciality-delete']
    }),

    addSpeciality: builder.mutation({
      query: (speciality) => {
        let formData = new FormData();
        formData.append("file", speciality.file);
        return {
          url: `Speciality/AddSpeciality`,
          method: 'POST',
          body: formData,
          params: speciality
        }
      },
      invalidatesTags: ['speciality-add'],
    }),

    updateSpeciality: builder.mutation({
      query: (speciality) => {
        let formData = new FormData();
        formData.append("file", speciality.file);
        return {
          url: `Speciality/UpdateSpeciality`,
          method: 'POST',
          body: formData,
          params: speciality
        }
      },
      invalidatesTags: ['speciality-update'],
    }),

    deleteSpeciality: builder.mutation({
      query: (speciality) => ({
        url: "Speciality/DeleteSpeciality",
        method: "POST",
        params:
          speciality
      }),
      invalidatesTags: ["speciality-delete"]
    }),

    selectSpeciality: builder.mutation({
      query: (speciality) => ({
        url: "Speciality/SelectSpeciality",
        method: "POST",
        params:
          speciality
      }),
      invalidatesTags: ["company-update-completionStatus"]
    }),

    unselectSpeciality: builder.mutation({
      query: (speciality) => ({
        url: "Speciality/UnSelectSpeciality",
        method: "POST",
        params:
          speciality
      }),
      invalidatesTags: ["company-update-completionStatus"]
    }),
    // //************ SPECIALTY **************/







    // //************ EVENT TYPE **************/

    getAllEventTypes: builder.query({
      query: () => '/EventType/GetAllEventTypes',
      transformResponse: res => res,
      providesTags: ['eventType-add', 'eventType-update', 'eventType-delete']
    }),

    getEventTypes: builder.query({
      query: () => '/EventType/GetEventTypes',
      transformResponse: res => res,
      providesTags: ['eventType']
    }),

    getEventTypesSelectionsByCompanyId: builder.query({
      query: () => `/EventType/GetEventTypesByCompanyId`,
      transformResponse: res => res,
      providesTags: ['eventType']
    }),

    getEventTypesByCompanyId: builder.query({
      query: (companyId) => `/EventType/GetEventTypesByCompanyId/${companyId}`,
      transformResponse: res => res
    }),

    getPendingApprovalEventTypes: builder.query({
      query: () => `/EventType/GetPendingApprovalEventTypes`,
      transformResponse: res => res,
      providesTags: ['eventType-add', 'eventType-delete']
    }),

    addEventType: builder.mutation({
      query: (eventType) => {
        let formData = new FormData();
        formData.append("file", eventType.file);
        return {
          url: `EventType/AddEventType`,
          method: 'POST',
          body: formData,
          params: eventType
        }
      },
      invalidatesTags: ['eventType-add'],
    }),

    updateEventType: builder.mutation({
      query: (eventType) => {
        let formData = new FormData();
        formData.append("file", eventType.file);
        return {
          url: `EventType/UpdateEventType`,
          method: 'POST',
          body: formData,
          params: eventType
        }
      },
      invalidatesTags: ['eventType-update'],
    }),

    deleteEventType: builder.mutation({
      query: (eventType) => ({
        url: "EventType/DeleteEventType",
        method: "POST",
        params:
          eventType
      }),
      invalidatesTags: ["eventType-delete"]
    }),

    selectEventType: builder.mutation({
      query: (eventType) => ({
        url: "EventType/SelectEventType",
        method: "POST",
        params:
          eventType
      })
    }),

    unselectEventType: builder.mutation({
      query: (eventType) => ({
        url: "EventType/UnSelectEventType",
        method: "POST",
        params:
          eventType
      })
    }),
    // //************ EVENT TYPE **************/





    // //************ ADDON SERVICE **************/


    getAllAddonServices: builder.query({
      query: () => '/AddonService/GetAllAddonServices',
      transformResponse: res => res,
      providesTags: ['addonService-add', 'addonService-update', 'addonService-delete']
    }),

    getAddonServices: builder.query({
      query: () => '/AddonService/GetAddonServices',
      transformResponse: res => res,
      providesTags: ['addonService']
    }),

    getAddonServicesSelectionsByCompanyId: builder.query({
      query: () => `/AddonService/GetAddonServicesByCompanyId`,
      transformResponse: res => res,
      providesTags: ['addonService']
    }),

    getPendingApprovalAddonServices: builder.query({
      query: () => `/AddonService/GetPendingApprovalAddonServices`,
      transformResponse: res => res,
      providesTags: ['addonService-add', 'addonService-delete']
    }),

    addAddonService: builder.mutation({
      query: (addonService) => {
        let formData = new FormData();
        formData.append("file", addonService.file);
        return {
          url: `AddonService/AddAddonService`,
          method: 'POST',
          body: formData,
          params: addonService
        }
      },
      invalidatesTags: ['addonService-add'],
    }),

    updateAddonService: builder.mutation({
      query: (addonService) => {
        let formData = new FormData();
        formData.append("file", addonService.file);
        return {
          url: `AddonService/UpdateAddonService`,
          method: 'POST',
          body: formData,
          params: addonService
        }
      },
      invalidatesTags: ['addonService-update'],
    }),

    deleteAddonService: builder.mutation({
      query: (addonService) => ({
        url: "AddonService/DeleteAddonService",
        method: "POST",
        params:
          addonService
      }),
      invalidatesTags: ["addonService-delete"]
    }),

    selectAddonService: builder.mutation({
      query: (addonService) => ({
        url: "AddonService/SelectAddonService",
        method: "POST",
        params:
          addonService
      }),
      invalidatesTags: ["company-update-completionStatus"]
    }),

    unselectAddonService: builder.mutation({
      query: (addonService) => ({
        url: "AddonService/UnSelectAddonService",
        method: "POST",
        params:
          addonService
      }),
      invalidatesTags: ["company-update-completionStatus"]
    }),


    // //************ ADDON SERVICE **************/








    // //************ SERVING OPTION **************/
    getAllServingOptions: builder.query({
      query: () => '/ServingOption/GetAllServingOptions',
      transformResponse: res => res,
      providesTags: ['servingOption-add', 'servingOption-update', 'servingOption-delete']
    }),

    getServingOptions: builder.query({
      query: () => '/ServingOption/GetServingOptions',
      transformResponse: res => res,
      providesTags: ['servingOption']
    }),

    getServingOptionsSelectionsByCompanyId: builder.query({
      query: () => `/ServingOption/GetServingOptionsByCompanyId`,
      transformResponse: res => res,
      providesTags: ['servingOption']
    }),

    getPendingApprovalServingOptions: builder.query({
      query: () => `/ServingOption/GetPendingApprovalServingOptions`,
      transformResponse: res => res,
      providesTags: ['servingOption-add', 'servingOption-delete']
    }),

    addServingOption: builder.mutation({
      query: (servingOption) => {
        let formData = new FormData();
        formData.append("file", servingOption.file);
        return {
          url: `ServingOption/AddServingOption`,
          method: 'POST',
          body: formData,
          params: servingOption
        }
      },
      invalidatesTags: ['servingOption-add'],
    }),

    updateServingOption: builder.mutation({
      query: (servingOption) => {
        let formData = new FormData();
        formData.append("file", servingOption.file);
        return {
          url: `ServingOption/UpdateServingOption`,
          method: 'POST',
          body: formData,
          params: servingOption
        }
      },
      invalidatesTags: ['servingOption-update'],
    }),

    deleteServingOption: builder.mutation({
      query: (servingOption) => ({
        url: "ServingOption/DeleteServingOption",
        method: "POST",
        params:
          servingOption
      }),
      invalidatesTags: ["servingOption-delete"]
    }),

    selectServingOption: builder.mutation({
      query: (servingOption) => ({
        url: "ServingOption/SelectServingOption",
        method: "POST",
        params:
          servingOption
      }),
      invalidatesTags: ["company-update-completionStatus"]
    }),

    unselectServingOption: builder.mutation({
      query: (servingOption) => ({
        url: "ServingOption/UnSelectServingOption",
        method: "POST",
        params:
          servingOption
      }),
      invalidatesTags: ["company-update-completionStatus"]
    }),
    // //************ SERVING OPTION **************/













    // //************ CERTIFICATION **************/
    getAllCertifications: builder.query({
      query: () => '/Certification/GetAllCertifications',
      transformResponse: res => res,
      providesTags: ['eventType-add', 'eventType-update', 'eventType-delete']
    }),

    getCertifications: builder.query({
      query: () => '/Certification/GetCertifications',
      transformResponse: res => res,
      providesTags: ['eventType']
    }),

    getCertificationsSelectionsByCompanyId: builder.query({
      query: () => `/Certification/GetCertificationsByCompanyId`,
      transformResponse: res => res,
      providesTags: ['eventType']
    }),

    getPendingApprovalCertifications: builder.query({
      query: () => `/Certification/GetPendingApprovalCertifications`,
      transformResponse: res => res,
      providesTags: ['eventType-add', 'eventType-delete']
    }),

    addCertification: builder.mutation({
      query: (eventType) => {
        let formData = new FormData();
        formData.append("file", eventType.file);
        return {
          url: `Certification/AddCertification`,
          method: 'POST',
          body: formData,
          params: eventType
        }
      },
      invalidatesTags: ['eventType-add'],
    }),

    updateCertification: builder.mutation({
      query: (eventType) => {
        let formData = new FormData();
        formData.append("file", eventType.file);
        return {
          url: `Certification/UpdateCertification`,
          method: 'POST',
          body: formData,
          params: eventType
        }
      },
      invalidatesTags: ['eventType-update'],
    }),

    deleteCertification: builder.mutation({
      query: (eventType) => ({
        url: "Certification/DeleteCertification",
        method: "POST",
        params:
          eventType
      }),
      invalidatesTags: ["eventType-delete"]
    }),

    selectCertification: builder.mutation({
      query: (eventType) => ({
        url: "Certification/SelectCertification",
        method: "POST",
        params:
          eventType
      })
    }),

    unselectCertification: builder.mutation({
      query: (eventType) => ({
        url: "Certification/UnSelectCertification",
        method: "POST",
        params:
          eventType
      })
    }),
    // //************ CERTIFICATION **************/







    // //************ CUISINE **************/

    getExculsiveCuisines: builder.query({
      query: () => '/Cuisine/GetExclusive',
      transformResponse: res => res
    }),

    getAllCuisines: builder.query({
      query: () => '/Cuisine/GetAllCuisines',
      transformResponse: res => res,
      providesTags: ['cuisine-add', 'cuisine-update', 'cuisine-delete']
    }),

    getCuisines: builder.query({
      query: () => '/Cuisine/GetCuisines',
      transformResponse: res => res,
      providesTags: ['cuisine']
    }),

    getCuisinesSelectionsByCompanyId: builder.query({
      query: () => `/Cuisine/GetCuisinesByCompanyId`,
      transformResponse: res => res,
      providesTags: ['cuisine']
    }),

    getPendingApprovalCuisines: builder.query({
      query: () => `/Cuisine/GetPendingApprovalCuisines`,
      transformResponse: res => res,
      providesTags: ['cuisine-add', 'cuisine-delete']
    }),

    addCuisine: builder.mutation({
      query: (cuisine) => {
        let formData = new FormData();
        formData.append("file", cuisine.file);
        return {
          url: `Cuisine/AddCuisine`,
          method: 'POST',
          body: formData,
          params: cuisine
        }
      },
      invalidatesTags: ['cuisine-add'],
    }),

    updateCuisine: builder.mutation({
      query: (cuisine) => {
        let formData = new FormData();
        formData.append("file", cuisine.file);
        return {
          url: `Cuisine/UpdateCuisine`,
          method: 'POST',
          body: formData,
          params: cuisine
        }
      },
      invalidatesTags: ['cuisine-update'],
    }),

    deleteCuisine: builder.mutation({
      query: (cuisine) => ({
        url: "Cuisine/DeleteCuisine",
        method: "POST",
        params:
          cuisine
      }),
      invalidatesTags: ["cuisine-delete"]
    }),

    selectCuisine: builder.mutation({
      query: (cuisine) => ({
        url: "Cuisine/SelectCuisine",
        method: "POST",
        params:
          cuisine
      }),
      invalidatesTags: ["company-update-completionStatus"]
    }),

    unselectCuisine: builder.mutation({
      query: (cuisine) => ({
        url: "Cuisine/UnSelectCuisine",
        method: "POST",
        params:
          cuisine
      }),
      invalidatesTags: ["company-update-completionStatus"]
    }),
    // //************ CUISINE **************/







    // //************ DIETARY **************/
    getAllDietaries: builder.query({
      query: () => '/Dietary/GetAllDietaries',
      transformResponse: res => res,
      providesTags: ['dietary-add', 'dietary-update', 'dietary-delete']
    }),

    getDietaries: builder.query({
      query: () => '/Dietary/GetDietaries',
      transformResponse: res => res,
      providesTags: ['dietary']
    }),

    getDietariesSelectionsByCompanyId: builder.query({
      query: () => `/Dietary/GetDietariesByCompanyId`,
      transformResponse: res => res,
      providesTags: ['dietary']
    }),

    getPendingApprovalDietaries: builder.query({
      query: () => `/Dietary/GetPendingApprovalDietaries`,
      transformResponse: res => res,
      providesTags: ['dietary-add', 'dietary-delete']
    }),

    addDietary: builder.mutation({
      query: (dietary) => {
        let formData = new FormData();
        formData.append("file", dietary.file);
        return {
          url: `Dietary/AddDietary`,
          method: 'POST',
          body: formData,
          params: dietary
        }
      },
      invalidatesTags: ['dietary-add'],
    }),

    updateDietary: builder.mutation({
      query: (dietary) => {
        let formData = new FormData();
        formData.append("file", dietary.file);
        return {
          url: `Dietary/UpdateDietary`,
          method: 'POST',
          body: formData,
          params: dietary
        }
      },
      invalidatesTags: ['dietary-update'],
    }),

    deleteDietary: builder.mutation({
      query: (dietary) => ({
        url: "Dietary/DeleteDietary",
        method: "POST",
        params:
          dietary
      }),
      invalidatesTags: ["dietary-delete"]
    }),

    selectDietary: builder.mutation({
      query: (dietary) => ({
        url: "Dietary/SelectDietary",
        method: "POST",
        params:
          dietary
      }),
      invalidatesTags: ["company-update-completionStatus"]
    }),

    unselectDietary: builder.mutation({
      query: (dietary) => ({
        url: "Dietary/UnSelectDietary",
        method: "POST",
        params:
          dietary
      }),
      invalidatesTags: ["company-update-completionStatus"]
    }),
    // //************ DIETARY **************/





    // //************ USER PROFILE **************/

    getUserProfileByUserProfileId: builder.query({
      query: (userProfileId) => `/UserProfile/GetUserProfileByUserProfileId/${userProfileId}`,
      transformResponse: res => res,
    }),

    getUserProfileByUserProfileType: builder.query({
      query: (userProfileType) => ({
        url: `/UserProfile/GetUserProfileByUserProfileType`,
        method: "GET",
        params:
          userProfileType
      }),
      transformResponse: res => res,
    }),

    updateUserProfile: builder.mutation({
      query: (userProfile) => {
        let formData = new FormData();
        formData.append("file", userProfile.file);
        return {
          url: `UserProfile/UpdateUserProfile`,
          method: 'PUT',
          body: formData,
          params: userProfile
        }
      }
    }),

    getUserByKeyword: builder.query({
      query: (keyword) => `UserProfile/GetUserByKeyword/${keyword}`,
      transformResponse: res => res
    }),

    addUserProfile: builder.mutation({
      query: (userProfile) => {
        let formData = new FormData();
        formData.append("file", userProfile.file);
        return {
          url: `/UserProfile/UserProfileAdd`,
          method: 'POST',
          body: formData,
          params: userProfile
        }
      }
    }),

    // //************ USER PROFILE **************/


    //************ PAYMENT OPTIONS **************/
    getPaymentOptions: builder.query({
      query: () => `/PaymentOption/GetPaymentOptions`,
      transformResponse: res => res,
    }),
    //************ PAYMENT OPTIONS **************/


    // //************ REPORTS **************/
    // getDuplicateCaterer: builder.query({
    //   query: () => `/Caterer/GetDuplicateCaterers`,
    //   transformResponse: res => res,
    // }),
    // //************ REPORTS **************/


    // //************ SUBSCRIPTION PLAN **************/
    getSubscriptionPlan: builder.query({
      query: () => `/SubscriptionPlan/GetSubscriptionPlan`,
      transformResponse: res => res,
    }),



    // //************ SUBSCRIPTION PLAN **************/


    //     //************ BUSINESS CLAIM **************/
    getBusinessClaim: builder.query({
      query: (claim) => ({
        url: `/BusinessClaim/GetBusinessClaim`,
        method: "GET",
        params:
          claim
      }),
      transformResponse: res => res,
    }),


    addBusinessClaim: builder.mutation({
      query: (businessClaim) => ({
        url: "/BusinessClaim/AddBusinessClaim",
        method: "POST",
        params:
          businessClaim
      })
    }),

    //     //************ BUSINESS CLAIM ************



    //     //************ DEAL **************/

    saveCustomDeal: builder.mutation({
      query: (deal) => {
        let formData = new FormData();
        formData.append("file", deal.file);
        return {
          url: `Deal/SaveCustomDeal`,
          method: 'POST',
          body: formData,
          params: deal
        }
      },
      invalidatesTags: ['deal-save-custom'],
    }),

    saveFlatDeal: builder.mutation({
      query: (deal) => ({
        url: "Deal/SaveFlatDeal",
        method: "POST",
        params:
          deal
      }),
      invalidatesTags: ["deal-save-flat"],
    }),

    getFlatDeal: builder.query({
      query: (deal) => ({
        url: `/Deal/GetFlatDeal`,
        method: "GET",
        params:
          deal
      }),
      transformResponse: res => res,
      providesTags: ['deal-save-flat']
    }),

    getCustomDeal: builder.query({
      query: (deal) => ({
        url: `/Deal/GetCustomDeal`,
        method: "GET",
        params:
          deal
      }),
      transformResponse: res => res,
      providesTags: ['deal-save-custom']
    }),

    getAllTag: builder.query({
      query: () => `/Tag/GetAllTag`,
      transformResponse: res => res,
      providesTags: ['tag-edit', 'tag-add', 'tag-delete']
    }),


    editTag: builder.mutation({
      query: (tag) => ({
        url: "/Tag/EditTag",
        method: "PUT",
        params:
          tag
      }),
      invalidatesTags: ["tag-edit"]
    }),

    addTag: builder.mutation({
      query: (tag) => ({
        url: "/Tag/AddTag",
        method: "POST",
        params:
          tag
      }),
      invalidatesTags: ["tag-add"]
    }),



    deleteTag: builder.mutation({
      query: (tag) => ({
        url: "Tag/DeleteTag",
        method: "POST",
        params:
          tag
      }),
      invalidatesTags: ["tag-delete"]
    }),

    //     //************ DEAL **************/

    //     //************ SUBSCRIPTION **************/

    // getListingAd: builder.query({
    //   query: (multipleParams) => `/Subscription/GetListingAd/${multipleParams.startDate}/${multipleParams.endDate}/${multipleParams.includeInactive}`,
    //   transformResponse: res => res,
    // }),

    getBoost: builder.query({
      query: (companySearch) => ({
        url: `/Subscription/GetSubscriptionBoost`,
        method: "GET",
        params:
          companySearch
      }),
      transformResponse: res => res
    }),

    getBannerAd: builder.query({
      query: (companySearch) => ({
        url: `/Subscription/GetSubscriptionBannerAd`,
        method: "GET",
        params:
          companySearch
      }),
      transformResponse: res => res
    }),

    getPaidListing: builder.query({
      query: (companySearch) => ({
        url: `/Subscription/GetSubscriptionPaidListing`,
        method: "GET",
        params:
          companySearch
      }),
      transformResponse: res => res
    }),
    
    getPackage: builder.query({
      query: (companySearch) => ({
        url: `/Subscription/GetSubscriptionPackage`,
        method: "GET",
        params:
          companySearch
      }),
      transformResponse: res => res
    }),


    getProductPrices: builder.query({
      query: (multipleParams) => `/ProductPrice/GetProductPrice/${multipleParams.offer}/${multipleParams.offerId}/${multipleParams.stateId}/${multipleParams.productId}`,
      transformResponse: res => res,
    }),

    saveSubscription: builder.mutation({
      query: (productPrice) => ({
        url: "/Subscription/SaveSubscription",
        method: "POST",
        body: productPrice.productPrice,
        params:
          productPrice
      }),
      invalidatesTags: ["subscription-add"],
    }),


    getSubscriptions: builder.query({
      query: (multipleParams) => `/Subscription/GetSubscriptions/${multipleParams.subscriptionTypeId}/${multipleParams.includeActive}/${multipleParams.includeExpired}`,
      transformResponse: res => res,
      providesTags: ['subscription-update']
    }),

    getSubscription: builder.query({
      query: (subscriptionId) => `/Subscription/GetSubscription/${subscriptionId}`,
      transformResponse: res => res,
    }),

    getSubscriptionDetail: builder.query({
      query: (subscriptionId) => `/Subscription/GetSubscriptionDetail/${subscriptionId}`,
      transformResponse: res => res,
    }),

    getPaidListingSubscriptionDetail: builder.query({
      query: (multipleParams) => `/Subscription/GetPaidListingSubscriptionDetail/${multipleParams.includeActive}/${multipleParams.includeExpired}`,
      transformResponse: res => res,
    }),

    updateSubscription: builder.mutation({
      query: (sub) => ({
        url: "/Subscription/UpdateSubscription",
        method: "PUT",
        body:
          sub.file,
        params:
          sub
      }),
      invalidatesTags: ['subscription-update'],
    }),

    getSubscriptionByCompany: builder.query({
      query: () => `/Subscription/GetSubscription`,
      transformResponse: res => res,
    }),

    getPackageFeature: builder.query({
      query: (packageFeatureId) => `/PackageFeature/GetPackageFeature/${packageFeatureId}`,
      transformResponse: res => res,
    }),


    getPaidListingPrice: builder.query({
      query: (companySearch) => ({
        url: `/ProductPrice/GetPaidListingPrice`,
        method: "GET",
        params:
          companySearch
      }),
      transformResponse: res => res,
      providesTags: ['product-price-update']
    }),


    getProductPricesByProduct: builder.query({
      query: (ProductId) => `/ProductPrice/GetProductPrice/${ProductId}`,
      transformResponse: res => res,
      providesTags: ['product-price-update']
    }),

    saveProductPrice: builder.mutation({
      query: (productPrice) => ({
        url: `ProductPrice/SaveProductPrice`,
        method: "POST",
        params: productPrice
      }),
      invalidatesTags: ['product-price-update'],
    }),


    //     //************ SUBSCRIPTION **************/



    //     //************ PAYMENT **************/
    getPayment: builder.query({
      query: () => `/Payment/GetPayments`,
      transformResponse: res => res,
    }),
    //     //************ PAYMENT **************/


    //     //************ BLOG **************/
    getBlog: builder.query({
      query: (blog) => ({
        url: `/Blog/GetBlogs`,
        method: "GET",
        params:
          blog
      }),
      transformResponse: res => res,
      providesTags: ['blog-add','blog-delete']
    }),

    getActiveBlog: builder.query({
      query: () => `/Blog/GetActiveBlog`,
      transformResponse: res => res
    }),

    
    getBlogDetail: builder.query({
      query: (blogId) => `/Blog/GetBlogDetail/${blogId}`,
      transformResponse: res => res
    }),

    saveBlog: builder.mutation({
      query: (blog) => {
        
        let formData = new FormData();
        formData.append("file", blog.blogImage);
        formData.append("contentData", blog.description);
        blog.description = '';
        return {
          url: `Blog/SaveBlog`,
          method: 'POST',
          body: formData,
          params: blog
        }
      },
      invalidatesTags: ['blog-add'],
    }),

    deleteBlog: builder.mutation({
      query: (blogId) => ({
        url: `Blog/DeleteBlog/${blogId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["blog-delete"],
    }),








    saveBlogCategory: builder.mutation({
      query: (tag) => ({
        url: "/Blog/SaveBlogCategory",
        method: "POST",
        params:
          tag
      }),
      invalidatesTags: ['blog-category-add']
    }),
    
    getBlogCategory: builder.query({
      query: () => `/Blog/GetBlogCategory`,
      transformResponse: res => res,
      providesTags: ['blog-category-add', 'blog-category-delete']
    }),


    deleteBlogCategory: builder.mutation({
      query: (blogCategoryId) => ({
        url: `Blog/DeleteBlogCategory/${blogCategoryId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["blog-category-delete"],
    }),
    //     //************ BLOG **************/


    //************ INSIDES **************/

    getOfferSearchCountReport: builder.query({
      query: () => `/Report/GetOfferSearchCountReport`,
      transformResponse: res => res
    }),

    getClickThroughReport: builder.query({
      query: (multipleParams) => `/PageInside/GetClickThroughReport/${multipleParams.startDate}/${multipleParams.endDate}/${multipleParams.entityName}/${multipleParams.entityId}`,
      transformResponse: res => res
    }),

    getDailyKeywordInsightsAndTrends: builder.query({
      query: () => `/PageInside/GetDailyKeywordInsightsAndTrends`,
      transformResponse: res => res
    }),

    addPageInside: builder.mutation({
      query: (params) => {
        let formData = new FormData();
        formData.append("entityName", params.entityName);
        formData.append("entityId", params.entityId);
        formData.append("eventId", params.eventId);
        formData.append("ipAddress", params.ipAddress);
        formData.append("clientInfo", params.clientInfo);
        return {
          url: `/PageInside/AddPageInside`,
          method: 'POST',
          body: formData
        }
      }
    })

    //************ INSIDES **************/

  })
})

export const {

  useGetCompanyDetailQuery, useInitiateCompanyPanelMutation, useGetBusinessProfileQuery, useGetCompanyListQuery, useGetBusinessProfileCompletionStatusQuery, useGetOfferCompletionStatusQuery, useGetCompanyByKeywordQuery, useCloneCompanyMutation,
  useUpdateBusinessProfileMutation, useToggleListingStatusMutation, useUpdateCompanyImageMutation, useUpdateCompanyStatusMutation, useAddCompanyMutation,
  useSearchCompanyListingQuery,
  useGetAddressQuery, useGetTop11CitiesQuery,
  useGetStatesQuery,

  useGetReviewByCompanyQuery, useUpdateReviewMutation, useUpdateReviewStatusMutation, useRemoveReviewMutation,

  //useGetReviewByUserProfileQuery, 
  useGetReviewByCompanyAndUserProfileQuery, 
  useGetReviewQuery,
  //useGetReviewAverageByCompanyQuery, 
  useAddReviewMutation,
  useGetAwardByCatererQuery, useAddAwardMutation, useDeleteAwardMutation,
  useGetVideoByCompanyIdQuery, useAddVideoMutation, useDeleteVideoMutation,
  useToggleFavoriteMutation, useGetFavoriteQuery,

  useGetMenuItemByCompanyQuery, useAddMenuItemMutation, useDeleteMenuItemMutation, useGetMenuItemCategoryQuery, useAddMenuDocMutation,
  useGetMenuDocumentQuery, useDeleteMenuDocumentMutation, useSaveExternalMenuURLMutation,

  useGetEventGalleryQuery, useGetEventTypesByCompanyIdQuery, useGetEventGalleryPictureQuery, useSaveEventGalleryMutation, useDeleteEventGalleryMutation, useDeleteEventGalleryPictureMutation, useSaveEventGalleryNameMutation, useMarkCoverPictureMutation,

  useGetBadgeStatusQuery, useGetAllBadgeQuery, useGetBadgeQuery, useGetBadgeByCatererIdQuery, useGetVerifiedBadgeByCatererIdQuery, useSaveCatererBadgeMutation, useUpdateCatererBadgeMutation,
  useGetMessageByCompanyIdQuery, useSaveMessageMutation,
  useAddContactMutation, useGetContactQuery,
  useGetSpecialitiesQuery, useGetAllSpecialitiesQuery, useGetPendingApprovalSpecialitiesQuery, useAddSpecialityMutation, useUpdateSpecialityMutation, useDeleteSpecialityMutation, useSelectSpecialityMutation, useUnselectSpecialityMutation, useGetSpecialitiesSelectionsByCompanyIdQuery,
  useGetExculsiveCuisinesQuery, useGetCuisinesQuery, useGetAllCuisinesQuery, useGetPendingApprovalCuisinesQuery, useAddCuisineMutation, useUpdateCuisineMutation, useDeleteCuisineMutation, useSelectCuisineMutation, useUnselectCuisineMutation, useGetCuisinesSelectionsByCompanyIdQuery,
  useGetEventTypesQuery, useGetAllEventTypesQuery, useGetPendingApprovalEventTypesQuery, useAddEventTypeMutation, useUpdateEventTypeMutation, useDeleteEventTypeMutation, useSelectEventTypeMutation, useUnselectEventTypeMutation, useGetEventTypesSelectionsByCompanyIdQuery,
  useGetServingOptionsQuery, useGetAllServingOptionsQuery, useGetPendingApprovalServingOptionsQuery, useAddServingOptionMutation, useUpdateServingOptionMutation, useDeleteServingOptionMutation, useSelectServingOptionMutation, useUnselectServingOptionMutation, useGetServingOptionsSelectionsByCompanyIdQuery,
  useGetAddonServicesQuery, useGetAllAddonServicesQuery, useGetPendingApprovalAddonServicesQuery, useAddAddonServiceMutation, useUpdateAddonServiceMutation, useDeleteAddonServiceMutation, useSelectAddonServiceMutation, useUnselectAddonServiceMutation, useGetAddonServicesSelectionsByCompanyIdQuery,
  useGetCertificationsQuery, useGetAllCertificationsQuery, useGetPendingApprovalCertificationsQuery, useAddCertificationMutation, useUpdateCertificationMutation, useDeleteCertificationMutation, useSelectCertificationMutation, useUnselectCertificationMutation, useGetCertificationsSelectionsByCompanyIdQuery,

  useGetDietariesQuery, useGetAllDietariesQuery, useGetPendingApprovalDietariesQuery, useAddDietaryMutation, useUpdateDietaryMutation, useDeleteDietaryMutation, useSelectDietaryMutation, useUnselectDietaryMutation, useGetDietariesSelectionsByCompanyIdQuery,

  useGetUserProfileByUserProfileIdQuery, useUpdateUserProfileMutation, useGetUserProfileByUserProfileTypeQuery,
  useGetUserByKeywordQuery, useAddUserProfileMutation,
  useGetPaymentOptionsQuery,

  useGetDuplicateCatererQuery,

  useGetSubscriptionPlanQuery, useGetSubscriptionsQuery, useGetSubscriptionQuery, useGetSubscriptionDetailQuery,
  useSaveSubscriptionMutation, useUpdateSubscriptionMutation, useGetPaidListingSubscriptionDetailQuery,
  useSaveProductPriceMutation,

  useGetBusinessClaimQuery, useAddBusinessClaimMutation,
  useGetNicheQuery,

  useSaveCustomDealMutation, useSaveFlatDealMutation, useGetFlatDealQuery, useGetCustomDealQuery,
  useGetAllTagQuery, useEditTagMutation, useDeleteTagMutation, useAddTagMutation,
  useGetListingAdQuery, useGetBoostQuery, useGetProductPricesQuery, useGetBannerAdQuery, useGetPaidListingQuery, useGetPackageQuery,

  useGetSubscriptionByCompanyQuery, useGetPackageFeatureQuery, useGetPaidListingPriceQuery, useGetProductPricesByProductQuery,

  useGetPaymentQuery,
  useGetBlogQuery, useSaveBlogMutation, useGetBlogCategoryQuery, useSaveBlogCategoryMutation, useGetActiveBlogQuery, useGetBlogDetailQuery,
  useDeleteBlogCategoryMutation, useDeleteBlogMutation,

  useGetOfferSearchCountReportQuery, useGetClickThroughReportQuery, useGetDailyKeywordInsightsAndTrendsQuery, useAddPageInsideMutation

} = apiSlice