import React from 'react'
import Header from './Header';
import Footer from './Footer';
import { useGetCompanyDetailQuery } from './../hooks/apiSlice';
import { useParams } from "react-router-dom";

const Home = () => {
    const params = useParams();
    const { data: dataCompanyDetail, isLoading: isLoadingCompanyDetail } = useGetCompanyDetailQuery(params.companyId)

    return (
        <>
            {(isLoadingCompanyDetail) ? "Loading..." :
                <div>
                    <Header />
                    <section className="customer-banner">
                        <div className="container-fluid banner-home d-flex justify-content-center align-items-center">
                            <div className="row margin0 justify-content-center">
                                <div className="col-auto">
                                    <h1 className="text-uppercase banner-title"><strong>{dataCompanyDetail.payload.companyName}</strong></h1>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="customer-about">
                        <div className="container">
                            <div className="row align-items-center px-xxl-5 mx-xxl-5 py-xxl-5 my-5">
                                <div className="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                                    <h2 className="section-heading mb-4">About Catering</h2>

                                    <p>{dataCompanyDetail.payload.description}</p>
                                </div>


                                <div className="col-lg-5 col-md-5 col-sm-6 col-xs-12">
                                    {/* <img className="w-100 rounded" src="/images/customer-website/customer-video-placeholder-image.jpg" alt="" /> */}
                                    <div className="video-wrapper">
                                        <button type="button" className="play-btn video-btn" data-bs-toggle="modal" data-bs-target="#customer-video-modal" data-src=""></button>
                                    </div>
                                    <div className="modal fade" id="customer-video-modal" tabIndex="-1" aria-labelledby="customer-video-modalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header border-0 pb-0">
                                                    <h3 className="modal-title"><strong>About Catering</strong></h3>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body ">
                                                    <div className="embed-responsive-16by9">
                                                        <iframe width="100%" height="auto" src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="customer-locations">
                        <div className="container">
                            <div className="row px-xxl-5 mx-xxl-5">
                                <div className="col-sm-12 d-flex justify-content-center">
                                    <h2 className="section-heading">OUR LOCATIONS</h2>
                                </div>
                            </div>
                            <div className="row px-xxl-5 mx-xxl-5">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-12 px-xs-2 my-3 customer-location">
                                    <div className="location-img">
                                        <img className="w-100" src="/images/customer-website/location1.jpg" alt="" />
                                    </div>
                                    <div className="location-content">
                                        <i className="fa-solid fa-location-dot"></i>
                                        <h6>Florida</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-12 px-xs-2 my-3 customer-location">
                                    <div className="location-img">
                                        <img className="w-100" src="/images/customer-website/location2.jpg" alt="" />
                                    </div>
                                    <div className="location-content">
                                        <i className="fa-solid fa-location-dot"></i>
                                        <h6>New York</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-12 px-xs-2 my-3 customer-location">
                                    <div className="location-img">
                                        <img className="w-100" src="/images/customer-website/location3.jpg" alt="" />
                                    </div>
                                    <div className="location-content">
                                        <i className="fa-solid fa-location-dot"></i>
                                        <h6>Dubai</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="customer-serves">
                        <div className="container">
                            <div className="row px-xxl-5 mx-xxl-5 mb-4">
                                <div className="col-sm-12 d-flex justify-content-center">
                                    <h2 className="section-heading">WHAT WE SERVE</h2>
                                </div>
                            </div>
                            <div className="row px-xxl-5 mx-xxl-5">
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 px-xs-2 my-3 customer-serve">
                                    <div className="serve-img">
                                        <img className="w-100" src="/images/customer-website/customer-serve.jpg" alt="" />
                                    </div>
                                    <div className="serve-content">
                                        <h6>CORPORATE CATERING</h6>
                                        <p>Let us help you plan your next corporate event! daily lunches,
                                            breakfasts, open houses, grand openings, seminars, banquets and
                                            receptions, we can do it all.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 px-xs-2 my-3 customer-serve">
                                    <div className="serve-img">
                                        <img className="w-100" src="/images/customer-website/customer-serve.jpg" alt="" />
                                    </div>
                                    <div className="serve-content">
                                        <h6>Wedding</h6>
                                        <p>Let us help you plan your next corporate event! daily lunches,
                                            breakfasts, open houses, grand openings, seminars, banquets and
                                            receptions, we can do it all.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 px-xs-2 my-3 customer-serve">
                                    <div className="serve-img">
                                        <img className="w-100" src="/images/customer-website/customer-serve.jpg" alt="" />
                                    </div>
                                    <div className="serve-content">
                                        <h6>SOCIAL CATERING</h6>
                                        <p>Let us help you plan your next corporate event! daily lunches,
                                            breakfasts, open houses, grand openings, seminars, banquets and
                                            receptions, we can do it all.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="customer-newsletter">
                        <div className="container">
                            <div className="row px-xxl-5 mx-xxl-5 justify-content-center align-items-center gap-5">
                                <div className="col-auto px-2 newsletter-img">
                                    <img className="w-100" src="/images/customer-website/newsletter-img.png" alt="" />
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 px-2 newsletter-box">
                                    <h2 className="section-heading">Subscribe to our Newsletter!</h2>
                                    <p>Subscribe to our newsletter and stay updated.</p>
                                    <form>
                                        <div className="mb-3">
                                            <input type="email" className="form-control" id="email" name="email" placeholder="Email Address" />
                                        </div>
                                        <button type="submit" className="btn">Subscribe</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>


                    <Footer></Footer>
                </div>
            }
        </>
    )
}


export default Home