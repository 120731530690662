import axios from "axios";

const updateCompanyNameOnLocalStorage = (companyName) => {
  localStorage.setItem("companyName", companyName);
}

const updateProfileInfoOnLocalStorage = (fullName, avatar) => {
  localStorage.setItem("user", fullName);
  localStorage.setItem("avatar", avatar);
}

const updateUserProfileTypeOnLocalStorage = (response) => {
  localStorage.setItem("companyId", response.companyId);
  localStorage.setItem("userProfileType", "business");
  localStorage.setItem("accessToken", response.accessToken);
  localStorage.setItem("companyName", response.companyName);
}

const signup = (response) => {
  if (response.data.payload.userFullName) {
    writeLocalStorage(response.data.payload);
  }
};


const login = (email, password) => {
  return axios
    .post(process.env.REACT_APP_BASE_URL + "UserProfile/Authenticate", null, {
      params:
        { Email: email, password: password }
    })
    .then((response) => {
      if (response.data.message === 'login success') {
        writeLocalStorage(response.data.payload);
      }

      return response.data.message;
    });
};

const writeLocalStorage = (response) => {
  localStorage.setItem("user", response.userFullName);
  localStorage.setItem("accessToken", response.accessToken);
  localStorage.setItem("userProfileId", response.userProfileId);
  localStorage.setItem("avatar", response.avatar);

  localStorage.setItem("userProfileType", response.userProfileType);

  localStorage.setItem("package", response.package);
  localStorage.setItem("plan", response.plan);

  if (response.companyId > 0) {
    localStorage.setItem("companyId", response.companyId);
    localStorage.setItem("companyName", response.companyName);
  }
}




const logout = () => {
  localStorage.removeItem("user");

  localStorage.removeItem("accessToken");
  localStorage.removeItem("userProfileId");
  localStorage.removeItem("avatar");
  localStorage.removeItem("companyName");
  localStorage.removeItem("userProfileType");
  localStorage.removeItem("companyId");

  localStorage.removeItem("package");
  localStorage.removeItem("plan");

  localStorage.removeItem("CartItems");
};

const getCurrentUser = () => {
  return localStorage.getItem("user") === null ? null : localStorage.getItem("user");
};

const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

const getUserProfileId = () => {
  //return localStorage.getItem("userProfileId");
  return (localStorage.getItem("userProfileId") === null) ? '0' : localStorage.getItem("userProfileId");
};

const getUserProfileType = () => {
  return localStorage.getItem("userProfileType");
};

const getAvatar = () => {
  return localStorage.getItem("avatar");
};

const getCompanyName = () => {
  return localStorage.getItem("companyName");
};

const getCompanyId = () => {
  return localStorage.getItem("companyId");
};

const getCatererId = () => {
  return localStorage.getItem("companyId");
};

const getProductPlanId = () => {
  return localStorage.getItem("user") === null ? null : localStorage.getItem("package");
};

const updateLocalStorageOnCheckout = (response) => {
  if (response.package !== undefined) {
    if (localStorage.getItem("package") !== null) {
      localStorage.removeItem("package");
      localStorage.removeItem("plan");
    }
    
    localStorage.setItem("package", response.package);
    localStorage.setItem("plan", response.plan);
  }

  localStorage.removeItem("CartItems");
}


const getLocation = (prop) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(prop,
      (error) => {
        console.error("Error getting location: ", error);
      })
  }
  else {
    console.error("Geolocation is not supported by this browser.");
  }
}


const auth = {
  login, logout, signup, getCurrentUser, getAccessToken, getUserProfileId, getUserProfileType, getCompanyId, getCatererId,getAvatar,
  getProductPlanId, updateProfileInfoOnLocalStorage, updateUserProfileTypeOnLocalStorage, updateLocalStorageOnCheckout,
  getCompanyName, updateCompanyNameOnLocalStorage, getLocation
};



export default auth;