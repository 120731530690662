import React from 'react'


const Footer = () => {

  return (



    <footer className="customer-website-footer">
      <div className="footer-overlay"></div>
        <div className="container mb-4 footer-top">
          <div className="row px-xxl-5 mx-xxl-5">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 mb-md-4 text-md-start text-center">
              <div className="footer-logo mb-3">
                  <img src="/images/customer-website/catering-logo-white.png" alt="" />
              </div>
              <div className="footer-txt">
                <p>Catering is a platform that inspires people planning all types of events and connects them with the leading event professionals, vendors, and venues across the country and the world.</p>
              </div>
              <div>
                <div className="mt-4">
                  <a href="https://facebook.com" target="_blank" className="btn btn-social-icon btn-rounded mx-2"><i className="fa-brands fa-facebook"></i></a>
                  <a href="https://youtube.com" target="_blank" className="btn btn-social-icon btn-rounded mx-2"><i className="fa-brands fa-youtube"></i></a>
                  <a href="https://twitter.com" target="_blank" className="btn btn-social-icon btn-rounded mx-2"><i className="fa-brands fa-twitter"></i></a>
                  <a href="https://instagram.com" target="_blank" className="btn btn-social-icon btn-rounded mx-2"><i className="fa-brands fa-instagram"></i></a>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-12  col-xs-12 text-md-start text-center">
              <h6>Navigation</h6>
              <ul className="list-unstyled nav-items">
                <li><a className="footer-botton-link" href="/home-index">HOME</a></li>
                <li><a className="footer-botton-link" href="/about-catering">ABOUT US</a></li>
                <li><a className="footer-botton-link" href="/live-menu">LIVE MENU</a></li>
                <li><a className="footer-botton-link" href="/tray-menu">TRAY MENU</a></li>
                <li><a className="footer-botton-link" href="/location">LOCATION</a></li>
              </ul>
            </div>
            
            <div className="col-lg-2 col-md-4 col-sm-12  col-xs-12 text-md-start text-center">
              <h6>Quick LInks</h6>
              <ul className="nav-items list-unstyled">
                <li><a className="footer-botton-link" href="#">Advertise with us</a></li>
                <li><a className="footer-botton-link" href="#">FAQ</a></li>
                <li><a className="footer-botton-link" href="#">About Us</a></li>
                <li><a className="footer-botton-link" href="#">Contact Us</a></li>
                <li><a className="footer-botton-link" href="#">Blog</a></li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-5 col-sm-12  col-xs-12 text-md-start text-center">
              <h6>Contact information</h6>
              <ul className="contact list-unstyled">
                <li><a className="footer-botton-link" href="tel:5431216524"><i className="fa-solid fa-phone"></i> 543-121-6524</a></li>
                <li><a className="footer-botton-link" href="mailto:support@halalcaters.com"><i className="fa-solid fa-envelope"></i> support@halalcaters.com</a></li>
              </ul>
            </div>
          </div>
        </div>

      <div className="w-100 footer-bottom">
        <div className="row margin0 px-xxl-5 mx-xxl-5">
          <div className="col-12  text-center copyright-txt">
            © Catering {new Date().getFullYear()}. All Rights Reserved
          </div>


        </div>
      </div>

    </footer>


  )
}


export default Footer