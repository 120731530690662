import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'


const AdvertiseWithUs = () => {



  return (

    <div>
      <Navbar />


      <main role="main">
        <div className="innerpage-banner ">
          <img src="images/static-banners/advertise.jpg" className="innerpage-banner-img" alt="advertise with us" />
        </div>
        <section className="innerpage-container">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-md-8 col-lg-12">
                <p>Want to attract a Muslim audience that is active and has genuine spending power? Advertise on Halal Caters, one of the most popular websites for Muslims, which receives about 75 million visits and 15 million unique visitors annually (August 2013). Reach out to everyone or only those in your area!</p>
                <p>Visitors to Halal Caters are the cream of this crop: IT professionals, business travellers, and young urban Muslims with plenty of discretionary means. Muslims are among the communities with the greatest per capita income in North America. For instance, a sizable portion of our users access Halal Caters via their mobile devices. You may get in touch with them here because they care deeply about their neighbourhood, technology use, and the calibre of the goods they buy.</p>
                <h4>Ready to advertise ads with Halal Caters?</h4>
                <p>Contact us at <strong>info@halalcaters.com</strong>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

     <Footer></Footer>
    </div>
  )
}

export default AdvertiseWithUs