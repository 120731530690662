import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
// import { useGetExculsiveCuisinesQuery, useGetTop11CitiesQuery } from './../hooks/apiSlice';
import { useGetActiveBlogQuery } from '../hooks/apiSlice';
import moment from 'moment'
import parse from 'html-react-parser';

const Blogs = () => {
    // const { data: dataCuisine, isLoading: isLoadingCuisine } = useGetExculsiveCuisinesQuery()
    // const { data: dataTopCities, isLoading: isLoadingTopCities } = useGetTop11CitiesQuery()
    const { data: dataActiveBlog, isLoading: isActiveBlog } = useGetActiveBlogQuery()


    return (
        <div>
            <Navbar />
            <section id="blogs" className="section1">
                <div className="container-fluid new banner-home">
                    <div className="row margin0 justify-content-sm-center">
                        <div className="col-xxl-5 col-xl-7 col-lg-8 col-md-9 col-sm-10 col-xs-12">
                            <div className="row">
                                <div className="col-lg-12 text-banner">
                                    <h2 className="homepage-hero_heavy">
                                        {/* Blogs&nbsp; */}
                                        <div className="homepage-hero_textCarousel">
                                            <ul className="homepage-hero_scroll">
                                                <li> <span>Blogs</span></li>
                                                <li> <span>Blogs</span></li>
                                                <li> <span>Blogs</span></li>
                                            </ul>
                                        </div>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="blogS5" className="section5">
                <div className="container">
                    <div className="row top">
                        <div id="testimonial" data-bs-interval="false" className="carousel slide carousel-dark text-center" data-mdb-ride="carousel">
                            <div className="d-flex justify-content-end">
                                <button className="carousel-control-prev" type="button" data-bs-target="#testimonial" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#testimonial" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                            <div className="row col-md-12 main">
                                <div className="col-md-7 left">
                                    {isActiveBlog ? "" : <div className="carousel-inner py-4">
                                        {dataActiveBlog.map((b, index) =>
                                            <div key={index} className={"carousel-item " + (index === 0 && "active")}>
                                                {b.blogList.map((bd, index) =>
                                                    bd.isPublished &&
                                                    <div key={index} className="col-12">
                                                        <div className="blog-list">
                                                            <div className="left-sec">
                                                                <img className="carousel-img" src={process.env.REACT_APP_BLOG_MEDIA_URL + bd.image} alt="avatar" />
                                                            </div>
                                                            <div className="right-sec">
                                                                <h5>{bd.title}</h5>
                                                                <date>{moment(bd.date).format("MM-DD-YYYY")}</date>
                                                                <p>{bd.description !== null && bd.description.replace(/(<([^>]+)>)/gi, '').substring(0, 200)}</p>
                                                                <a href={"/blog-detail/" + bd.blogId + "/" + bd.slug}>Read More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>}
                                </div>

                                <div className="col-md-3 toprights">
                                    <div className="right fourth categ">
                                        <div className="top-heads">
                                            <h5>Categories</h5>
                                        </div>
                                        <hr></hr>
                                        <div className="col-12">
                                            <div className="blog-list" >
                                                <div className="carousel-indicators">
                                                    {isActiveBlog ? "" :
                                                        <>
                                                            {dataActiveBlog.map((b, index) =>
                                                                <>
                                                                    <div key={index} className={"right-categ " + (index === 0 ? "active" : "")} data-bs-target="#testimonial" data-bs-slide-to={index} aria-current={(index === 0 ? "true" : "false")} aria-label="Slide 1" value="1">
                                                                        <a href="#">
                                                                            <h5>{b.blogCategoryName} <span></span></h5>
                                                                        </a>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
    )
}

export default Blogs