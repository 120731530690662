import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetAddonServicesQuery } from '../../hooks/apiSlice';
import { default as ReactSelect } from "react-select";
import { toURLDecode } from '../../hooks/util';


const AddonServiceSelection = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data: dataAddonServices, isLoading: isLoadingAddonServices } = useGetAddonServicesQuery()
    const [addonServiceDefaultSelection, setAddonServiceDefaultSelection] = useState([]);

    const params = useParams();
    const [addonServiceLoaded, setAddonServiceLoaded] = useState(false);

    useEffect(() => {
        var updatedList;
        if (isLoadingAddonServices === false) {

            if (params.qAtt === 'services')
                updatedList = [{ value: dataAddonServices.payload.find((obj) => { return (obj.addonServiceIdName === toURLDecode(params.qPara)) }).addonServiceId.toString(), label: toURLDecode(params.qPara) }];

            if (searchParams.get("AddonService")) {
                updatedList = searchParams.get("AddonService").split(",").map((n) => {
                    return {
                        value: n,
                        label: dataAddonServices.payload.find((obj) => { return (obj.addonServiceId === parseInt(n)) }).addonServiceName
                    }
                })
            }

            if (updatedList !== undefined) {
                setAddonServiceDefaultSelection(updatedList)
                props.AddonServiceSelectionHandler(updatedList);
            }

            setAddonServiceLoaded(true);
        }
    }, [isLoadingAddonServices]);



    const handleAddonServiceSelection = (selected) => {
        props.AddonServiceSelectionHandler(selected);
    };

    return (
        addonServiceLoaded &&
        <div>
        <ReactSelect
          options={dataAddonServices.payload.map((addonService) => {
            return { value: addonService.addonServiceId.toString(), label: addonService.addonServiceName }
          })}
          isMulti
          value={props.parentInput}
          closeMenuOnSelect={false}
          //hideSelectedOptions={false}
          placeholder="Services"
          onChange={handleAddonServiceSelection}
          //filterOption={createFilter({ matchFrom: 'start' })}
          //allowSelectAll={true}
          defaultValue={addonServiceDefaultSelection}
        />
      </div>
    );
};



export default AddonServiceSelection;