import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'


const AirlineMeals = () => {



  return (

    <div>
      <Navbar />


      <main role="main">


        <div className="innerpage-banner ">
          <img src="images/static-banners/airlines-halal.jpg" className="innerpage-banner-img" alt="airlines halal" />
        </div>




        <section className="innerpage-container">

          <div className="container">
            <div className="row">


              <div className="col-12 col-md-12 col-md-12 col-lg-12">


                <h2>Halal Airline Meals</h2>
                <p>Several airlines offer Halal meals upon request to their passengers. Reviews of these meals are featured here.</p>

                <div className="row mt-4 align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>PAKISTANI AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <h5> 4 PAKISTANI AIRLINES</h5>
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Pakistan International Airlines</p>
                            <p className="airline-country mb-0">Pakistan</p>
                            <div className="airline-food">
                              <b>Pakistani</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Aero Asia</p>
                            <p className="airline-country mb-0">Pakistan</p>
                            <div className="airline-food">
                              <b>Pakistani</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Air Blue</p>
                            <p className="airline-country mb-0">Pakistan</p>
                            <div className="airline-food">
                              <b>Pakistani</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Shaheen Air</p>
                            <p className="airline-country mb-0">Pakistan</p>
                            <div className="airline-food">
                              <b>Pakistani</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>INDIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">JetLite</p>
                            <p className="airline-country mb-0">India</p>
                            <div className="airline-food">
                              <b>INDIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Jet Airways</p>
                            <p className="airline-country mb-0">India</p>
                            <div className="airline-food">
                              <b>INDIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Indian Airlines</p>
                            <p className="airline-country mb-0">India</p>
                            <div className="airline-food">
                              <b>INDIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Air India</p>
                            <p className="airline-country mb-0">India</p>
                            <div className="airline-food">
                              <b>INDIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>SINGAPOREAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Singapore Airlines</p>
                            <p className="airline-country mb-0">Singapore</p>
                            <div className="airline-food">
                              <b>SINGAPOREAN</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Silkair</p>
                            <p className="airline-country mb-0">Singapore</p>
                            <div className="airline-food">
                              <b>SINGAPOREAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Dragon Air</p>
                            <p className="airline-country mb-0">Singapore</p>
                            <div className="airline-food">
                              <b>SINGAPOREAN</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Tiger Airways</p>
                            <p className="airline-country mb-0">Singapore</p>
                            <div className="airline-food">
                              <b>SINGAPOREAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>MALAYSIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Malaysia Airlines</p>
                            <p className="airline-country mb-0">Malaysia</p>
                            <div className="airline-food">
                              <b>MALAYSIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Air Asia</p>
                            <p className="airline-country mb-0">Malaysia</p>
                            <div className="airline-food">
                              <b>MALAYSIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Berjaya-Air</p>
                            <p className="airline-country mb-0">Malaysia</p>
                            <div className="airline-food">
                              <b>MALAYSIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>AMERICAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">United Airlines</p>
                            <p className="airline-country mb-0">United States</p>
                            <div className="airline-food">
                              <b>AMERICAN</b>
                              <b>PAKISTANI</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">American Airlines</p>
                            <p className="airline-country mb-0">United States</p>
                            <div className="airline-food">
                              <b>AMERICAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Delta</p>
                            <p className="airline-country mb-0">United States</p>
                            <div className="airline-food">
                              <b>AMERICAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>INDONESIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Garuda Indonesia</p>
                            <p className="airline-country mb-0">Indonesia</p>
                            <div className="airline-food">
                              <b>INDONESIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Lion Air</p>
                            <p className="airline-country mb-0">Indonesia</p>
                            <div className="airline-food">
                              <b>INDONESIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>EMIRATI AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Emirates</p>
                            <p className="airline-country mb-0">United Arab Emirates</p>
                            <div className="airline-food">
                              <b>MIDDLE EASTERN</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Etihad Airways</p>
                            <p className="airline-country mb-0">United Arab Emirates</p>
                            <div className="airline-food">
                              <b>MIDDLE EASTERN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>BANGLADESHI AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Biman Bangladesh</p>
                            <p className="airline-country mb-0">Bangladesh</p>
                            <div className="airline-food">
                              <b>PAKISTANI</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">GMG Airlines</p>
                            <p className="airline-country mb-0">Bangladesh</p>
                            <div className="airline-food">
                              <b>PAKISTANI</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>IRANIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Iran Air</p>
                            <p className="airline-country mb-0">Iran</p>
                            <div className="airline-food">
                              <b>PERSIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Iran Air Tours</p>
                            <p className="airline-country mb-0">Iran</p>
                            <div className="airline-food">
                              <b>PERSIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5> BRITISH AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">British Airways</p>
                            <p className="airline-country mb-0">United Kingdom</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Virgin Atlantic</p>
                            <p className="airline-country mb-0">United Kingdom</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>AUSSIE AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Qantas</p>
                            <p className="airline-country mb-0">Australia</p>
                            <div className="airline-food">
                              <b>MIDDLE EASTERN</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Skywest</p>
                            <p className="airline-country mb-0">Australia</p>
                            <div className="airline-food">
                              <b>AMERICAN</b>
                              <b>AUSTRALIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>THAI AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Thai Airways</p>
                            <p className="airline-country mb-0">Thailand</p>
                            <div className="airline-food">
                              <b>THAI</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Bangkok Airways</p>
                            <p className="airline-country mb-0">Thailand</p>
                            <div className="airline-food">
                              <b>THAI</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5> SYRIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Syrian Air</p>
                            <p className="airline-country mb-0">Syria</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>ISRAELI AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">El Al</p>
                            <p className="airline-country mb-0">Israel</p>
                            <div className="airline-food">
                              <b>KOSHER</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>KIWI AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Air New Zealand</p>
                            <p className="airline-country mb-0">New Zealand</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>YEMENI AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Yemenia</p>
                            <p className="airline-country mb-0">Yemen</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>ITALIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Alitalia</p>
                            <p className="airline-country mb-0">Italy</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>PORTUGUESE AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">TAP Portugal</p>
                            <p className="airline-country mb-0">Portugal</p>
                            <div className="airline-food">
                              <b>VARIOUS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>OMANI AIRLINE</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Oman Air</p>
                            <p className="airline-country mb-0">Oman</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>CYPRIOT AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Cyprus Turkish Airlines</p>
                            <p className="airline-country mb-0">Cyprus</p>
                            <div className="airline-food">
                              <b>TURKISH</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>CANADIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Air Canada</p>
                            <p className="airline-country mb-0">Canada</p>
                            <div className="airline-food">
                              <b>NORTH AMERICAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>SWEDISH AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Scandanavian Airlines</p>
                            <p className="airline-country mb-0">Sweden</p>
                            <div className="airline-food">
                              <b>CONTINENTAL</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>GREEK AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Olympic Airlines</p>
                            <p className="airline-country mb-0">Greece</p>
                            <div className="airline-food">
                              <b>GREEK</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>MAURITIUS AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Air Mauritius</p>
                            <p className="airline-country mb-0">Mauritius</p>
                            <div className="airline-food">
                              <b>Pakistani</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>JORDANIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Royal Jordanian</p>
                            <p className="airline-country mb-0">Jordan</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>HONGKONGESE AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Cathay Pacific</p>
                            <p className="airline-country mb-0">Hong Kong</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>FIJIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Air Pacific</p>
                            <p className="airline-country mb-0">Fiji</p>
                            <div className="airline-food">
                              <b>CONTINENTAL</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>SURINAME AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Surinam Airways</p>
                            <p className="airline-country mb-0">Suriname</p>
                            <div className="airline-food">
                              <b>CONTINENTAL</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>FRENCH AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Air France</p>
                            <p className="airline-country mb-0">France</p>
                            <div className="airline-food">
                              <b>NORTH AFRICAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>EGYPTIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Egypt Air</p>
                            <p className="airline-country mb-0">Egypt</p>
                            <div className="airline-food">
                              <b>VARIOUS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>SRI LANKAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">SriLankan Airlines</p>
                            <p className="airline-country mb-0">Sri Lanka</p>
                            <div className="airline-food">
                              <b>Pakistani</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>SPANISH AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Iberia</p>
                            <p className="airline-country mb-0">Spain</p>
                            <div className="airline-food">
                              <b>MEDITERRANEAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>KUWAITI AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Kuwait Airlines</p>
                            <p className="airline-country mb-0">Kuwait</p>
                            <div className="airline-food">
                              <b>MIDDLE EASTERN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>AZERBAIJANI AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Azerbaijan Airlines</p>
                            <p className="airline-country mb-0">Azerbaijan</p>
                            <div className="airline-food">
                              <b>AZERBAIJANI</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>VIETNAMESE AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Vietnam Airlines</p>
                            <p className="airline-country mb-0">Vietnam</p>
                            <div className="airline-food">
                              <b>VIETNAMESE</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>BAHRAINI AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Gulf Air/Gulf Traveller</p>
                            <p className="airline-country mb-0">Bahrain</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>UZBEK AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Uzbekistan Airways</p>
                            <p className="airline-country mb-0">Uzbekistan</p>
                            <div className="airline-food">
                              <b>UZBEK</b>
                              <b>CONTINENTAL</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>GERMAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Lufthansa</p>
                            <p className="airline-country mb-0">Germany</p>
                            <div className="airline-food">
                              <b>MIDDLE EASTERN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>CHINESE AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Air China</p>
                            <p className="airline-country mb-0">China</p>
                            <div className="airline-food">
                              <b>CONTINENTAL</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>SAUDI AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Saudi Arabian Airlines</p>
                            <p className="airline-country mb-0">Saudi Arabia</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>KAZAKH AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Air Astana</p>
                            <p className="airline-country mb-0">Kazakhstan</p>
                            <div className="airline-food">
                              <b>KAZAKH</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>SOUTH AFRICAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">South African Airways</p>
                            <p className="airline-country mb-0">South Africa</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>TUNISIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Tunisair</p>
                            <p className="airline-country mb-0">Tunisia</p>
                            <div className="airline-food">
                              <b>NORTH AFRICAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>DUTCH AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">KLM</p>
                            <p className="airline-country mb-0">Netherlands</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>KYRGYZ AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Kyrgyzstan Airlines</p>
                            <p className="airline-country mb-0">Kyrgyzstan</p>
                            <div className="airline-food">
                              <b>CENTRAL ASIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>KENYAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Kenya Airways</p>
                            <p className="airline-country mb-0">Kenya</p>
                            <div className="airline-food">
                              <b>WEST AFRICAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>ALGERIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Air Algerie</p>
                            <p className="airline-country mb-0">Algeria</p>
                            <div className="airline-food">
                              <b>ALGERIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>AFGHAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Ariana Afghan</p>
                            <p className="airline-country mb-0">Afghanistan</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>BRUNEIAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Royal Brunei Airlines</p>
                            <p className="airline-country mb-0">Brunei Darussalam</p>
                            <div className="airline-food">
                              <b>MALAYSIAN</b>
                              <b>CONTINENTAL</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>QATARI AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Qatar Airways</p>
                            <p className="airline-country mb-0">Qatar</p>
                            <div className="airline-food">
                              <b>VARIOUS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>SENEGALESE AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Air Senegal</p>
                            <p className="airline-country mb-0">Senegal</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>TURKISH AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Turkish Airlines</p>
                            <p className="airline-country mb-0">Turkey</p>
                            <div className="airline-food">
                              <b>TURKISH</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>KOREAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Korean Airlines</p>
                            <p className="airline-country mb-0">Korea</p>
                            <div className="airline-food">
                              <b>VEGETARIAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>POLISH AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Lot Polish Airlines</p>
                            <p className="airline-country mb-0">Poland</p>
                            <div className="airline-food">
                              <b>POLISH</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>MOROCCAN AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Royal Air Maroc</p>
                            <p className="airline-country mb-0">Morocco</p>
                            <div className="airline-food">
                              <b>MOROCCAN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>LEBANESE AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">MEA</p>
                            <p className="airline-country mb-0">Lebanon</p>
                            <div className="airline-food">
                              <b>MIDDLE EASTERN</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
                <div className="row border-top align-items-center">


                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 p-4 ">
                    <h5>SWISS AIRLINES</h5>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-4">

                        <div className="airline-box align-item-center">
                          <div className="airline-badge">
                            <i className="fa-sharp fa-solid fa-plane-up"></i>
                          </div>
                          <div className="airline-details">
                            <p className="airline-name mb-0">Swiss Airlines</p>
                            <p className="airline-country mb-0">Switzerland</p>
                            <div className="airline-food">
                              <b>CONTINENTAL</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>




              </div>


            </div>



          </div>





        </section>









      </main>


      <Footer></Footer>
    </div>
  )
}

export default AirlineMeals