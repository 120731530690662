import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import { useParams } from "react-router-dom";
import { useGetBlogDetailQuery, useGetActiveBlogQuery } from '../hooks/apiSlice';
import moment from 'moment'
import parse from 'html-react-parser';

const BlogDetails = () => {

    const params = useParams();
    const { data: dataBlogDetail, isLoading: isBlogDetail } = useGetBlogDetailQuery(params.blogId)
    const { data: dataActiveBlog, isLoading: isActiveBlog } = useGetActiveBlogQuery()


    const getPopularBlogs = (blogCount) => {
        let rowCounter = 1
        return isActiveBlog ? "Loading..." : dataActiveBlog.map((b, index) =>

            b.blogList.map((bd, i) => (bd.blogId !== params.blogId && bd.isPublished && bd.title.includes("f") && rowCounter <= blogCount) && <div key={rowCounter++} className="blog-list">
                <div className="left-sec">
                    <a href={"/blog-detail/" + bd.blogId + "/" + bd.slug}>
                        <img className="carousel-img" style={{ 'maxWidth': '100px' }} src={process.env.REACT_APP_BLOG_MEDIA_URL + bd.image} alt="avatar" />
                    </a>
                </div>
                <div className="right-sec">
                    <h5>{bd.title}</h5>
                    <date>{moment(bd.date).format("MMMM DD, YYYY")}</date>
                </div>
            </div>
            )
        )
    }

    return (
        <div>
            {!isBlogDetail &&
                <>
                    <Navbar />
                    <section id="blogs" className="section1 detail">
                        <div className="container-fluid new banner-home">
                            <div className="row margin0 justify-content-sm-center">
                                {/* <div className="col-xxl-5 col-xl-7 col-lg-8 col-md-9 col-sm-10 col-xs-12"> */}
                                {/* <div className="row"> */}
                                <div className="row col-lg-12 text-banner">
                                    <div className="col-lg-7 fst">
                                        <h2 className="homepage-hero_heavy">
                                            {/* Blogs&nbsp; */}
                                            <div className="homepage-hero_textCarousel">
                                                <ul className="homepage-hero_scroll">
                                                    <li> <span>{dataBlogDetail.title}</span></li>
                                                    <li> <span>{dataBlogDetail.title}</span></li>
                                                    <li> <span>{dataBlogDetail.title}</span></li>
                                                </ul>
                                            </div>
                                        </h2>
                                        <date>{moment(dataBlogDetail.date).format("MMMM DD, YYYY")}.</date>
                                        {/* <div className="iconics">
                                    <span>
                                        <img src="/images/blogs-details/msg.png" />
                                        0
                                    </span>
                                    <span>
                                        <img src="/images/blogs-details/eye.png" />
                                        625
                                    </span>
                                    <span>
                                        <img src="/images/blogs-details/heart.png" />
                                        7
                                    </span>
                                </div> */}
                                        {/* <div class="template-demo mt-3"><a href="https://facebook.com" target="_blank" class="btn btn-social-icon btn-rounded mx-2"><i class="fa-brands fa-facebook"></i></a><a href="https://youtube.com" target="_blank" class="btn btn-social-icon btn-rounded mx-2"><i class="fa-brands fa-youtube"></i></a><a href="https://twitter.com" target="_blank" class="btn btn-social-icon btn-rounded mx-2"><i class="fa-brands fa-twitter"></i></a><a href="https://instagram.com" target="_blank" class="btn btn-social-icon btn-rounded mx-2"><i class="fa-brands fa-instagram"></i></a></div> */}
                                    </div>
                                    <div className="col-lg-5 scnd">
                                        <img src={process.env.REACT_APP_BLOG_MEDIA_URL + dataBlogDetail.image} />
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            {/* </div> */}
                        </div>
                    </section>

                    <section id="blogS5" className="section5 detail">
                        <div className="container">
                            <div className="row top">
                                <div className="row col-md-12 main">
                                    {/* col 7 start  */}
                                    <div className="col-md-7 left">
                                        <div className="carousel-inner py-4">
                                            <div className="carousel-item active">
                                                <div className="col-12">
                                                    <div className="blog-list">
                                                        <div className="right-sec">
                                                            {parse(dataBlogDetail.description)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <section id="blogS2" className="section detail more-inner">
                                    <div className="container">
                                        <div className="row px-xxl-5 mx-xxl-5">

                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                                                <img src="/images/blogs-details/inr-img1.png" alt="" />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                                                <img src="/images/blogs-details/inr-img2.png" alt="" />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                                                <img src="/images/blogs-details/inr-img3.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </section> */}

                                        {/* <section id="blogS3" className="section3 detail more-inner">
                                    <div className="container s3">
                                        <div className="row px-xxl-5 mx-xxl-5">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
                                                <div className="content">
                                                    <h3>Subscribe to my Newsletter</h3>
                                                    <h4>We'll keep you posted with news and updates!</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
                                                <div className="subscriber">
                                                    <form>
                                                        <input type="email" placeholder="Please enter your e-mail" />
                                                        <button>Subscribe</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section> */}

                                        {/* <section id="blogSComment" className="section detail more-inner">
                                    <div className="container">
                                        <div className="row px-xxl-5 mx-xxl-5">
                                            <div className="col-lg-12">
                                                <h4>Leave a Reply</h4>
                                                <p>Your email address will not be published. Required fields are marked *</p>
                                                <form>
                                                    <textarea name="" id=""></textarea>
                                                    <div className="labeled">
                                                        <label>Name*</label>
                                                        <input type="text" name="" required />
                                                    </div>

                                                    <div className="labeled">
                                                        <label>Email*</label>
                                                        <input type="email" name="" required />
                                                    </div>


                                                    <div className="labeled">
                                                        <label>Website</label>
                                                        <input type="text" name="" />
                                                    </div>

                                                    <div className="labeled">
                                                        <input type="checkbox" id="opt" name="opt" />
                                                        <label for="opt">Save my name, email, and website in this browser for the next time I comment.</label>
                                                    </div>
                                                    <button> Post Comment </button>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </section> */}

                                    </div>
                                    {/* col 7 end  */}

                                    <div className="col-md-3 toprights">
                                        <div className="right fst">
                                            <div className="top-heads">
                                                <h5>Popular Posts</h5>
                                            </div>
                                            <hr></hr>
                                            <div className="col-12">
                                                {/* <div className="blog-list">
                                            <div className="left-sec">
                                                <img className="carousel-img" src="/images/blogs/pop-post-image1.png" alt="avatar" />
                                            </div>
                                            <div className="right-sec">
                                                <h5>Homemade Oatmeal Chocolate Chip Cookie Crisp Cereal</h5>
                                                <date>June 8, 2024</date>
                                            </div>
                                        </div> */}
                                                    {getPopularBlogs(3)}
                                            </div>
                                        </div>

                                        {/* <div className="right secnd">
                                    <div className="top-heads">
                                        <h5>FOLLOW US</h5>
                                    </div>
                                    <hr></hr>
                                    <div className="col-12">
                                        <div className="blog-list">
                                            <div className="right-social">
                                                <a href="#">
                                                    <h5>Facebook <span>14K likes</span></h5>
                                                    <i class="fa-brands fa-facebook"></i>
                                                </a>
                                            </div>

                                            <div className="right-social">
                                                <a href="#">
                                                    <h5>Pinterest <span>3K Subscribers</span></h5>
                                                    <i class="fa-brands fa-pinterest"></i>
                                                </a>
                                            </div>

                                            <div className="right-social">
                                                <a href="#">
                                                    <h5>Instagram <span>20k Followers</span></h5>
                                                    <i class="fa-brands fa-instagram"></i>
                                                </a>
                                            </div>

                                            <div className="right-social">
                                                <a href="#">
                                                    <h5>YouTube <span>30k Subscribers</span></h5>
                                                    <i class="fa-brands fa-youtube"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="right third subs">
                                    <div className="top-heads">
                                        <h5>Newsletter</h5>
                                        <p>Enter your email address below to
                                            subscribe to my newsletter</p>
                                    </div>
                                    <div className="col-12">
                                        <div className="blog-list">
                                            <form>
                                                <input type="email" name="" placeholder="Please enter your e-mail" />
                                                <button>Subscribe</button>
                                            </form>
                                        </div>
                                    </div>
                                </div> */}

                                        {/* <div className="right fourth categ">
                                    <div className="top-heads">
                                        <h5>Categories</h5>
                                    </div>
                                    <hr></hr>
                                    <div className="col-12">
                                        <div className="blog-list">
                                            {isActiveBlog ? "" :
                                                <div>
                                                    {dataActiveBlog.map((b, index) =>
                                                        <>
                                                            <div key={index} className={"right-categ " + (index === 0 ? "active" : "")} data-bs-target="#testimonial" data-bs-slide-to={index} aria-current="true" aria-label="Slide 1" value="1">
                                                                <a href="#">
                                                                    <h5>{b.blogCategoryName} <span></span></h5>
                                                                </a>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </>
            }
        </div>
    )
}
export default BlogDetails

