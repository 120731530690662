import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { toPascal } from '../../../hooks/util';
import {
    useGetAddonServicesSelectionsByCompanyIdQuery, useGetPendingApprovalAddonServicesQuery,
    useAddAddonServiceMutation, useDeleteAddonServiceMutation, useSelectAddonServiceMutation, useUnselectAddonServiceMutation
} from '../../../hooks/apiSlice';


const AddonServiceSelect = () => {

    const [newAddonServiceName, setNewAddonServiceName] = useState('')
    const [newAddonServiceLoading, setNewAddonServiceLoading] = useState(false)
    const [addonServiceFilter, setAddonServiceFilter] = useState('')
    const [addonServiceCache, setAddonServiceCache] = useState([])
    const [selectionCount, setSelectionCount] = useState(0)

    const { data: dataAddonServices, isLoading: isLoadingAddonServices } = useGetAddonServicesSelectionsByCompanyIdQuery()
    const { data: dataPendingAddonServices, isLoading: isLoadingPendingAddonServices } = useGetPendingApprovalAddonServicesQuery()

    const [addAddonService] = useAddAddonServiceMutation()
    const [deleteAddonService] = useDeleteAddonServiceMutation()
    const [unselectAddonService] = useUnselectAddonServiceMutation()
    const [selectAddonService] = useSelectAddonServiceMutation()

    const AddNewAddonServiceName = async (e) => {
        e.preventDefault();
        if (newAddonServiceName !== "") {
            if (dataAddonServices.payload.some(item => newAddonServiceName.toUpperCase() === item.addonServiceName.toUpperCase())) {
                toast.error(newAddonServiceName + ` already exists, Please select it from the list`, { position: toast.POSITION.TOP_CENTER });
            }
            else if (dataPendingAddonServices.payload.some(item => newAddonServiceName.toUpperCase() === item.addonServiceName.toUpperCase())) {
                toast.error(newAddonServiceName + ` already added`, { position: toast.POSITION.TOP_CENTER });
            }
            else {
                setNewAddonServiceLoading(true);
                const returned = await addAddonService({ addonServiceName: toPascal(newAddonServiceName) }).unwrap();
                setNewAddonServiceLoading(false);
                setNewAddonServiceName('');
            }
        }
    }

    useEffect(() => {
        if (!isLoadingAddonServices) {
            setAddonServiceCache(dataAddonServices.payload);
        }
      }, [isLoadingAddonServices]);

    const toggleAddonService = (e, id) => {
        if (e.target.checked)
            selectAddonService({ addonServiceId: parseInt(id) });
        else
            unselectAddonService({ addonServiceId: parseInt(id) })

            const currentIndex = addonServiceCache.findIndex((todo) => todo.addonServiceId === id);
            const updatedTodo = {...addonServiceCache[currentIndex], isSelected: e.target.checked};
           const newTodos = [
             ...addonServiceCache.slice(0, currentIndex),
             updatedTodo,
             ...addonServiceCache.slice(currentIndex + 1)
           ];
           setAddonServiceCache(newTodos);

        setSelectionCount(e.target.checked ? selectionCount + 1 : selectionCount - 1)
    }

    const applyFilter = (e) => {
        setAddonServiceFilter(e.target.value.toLowerCase());
    }

    useEffect(() => {
        if (isLoadingAddonServices === false) {
            setSelectionCount(dataAddonServices.payload.filter(item => item.isSelected === true).length)
        }
    }, [isLoadingAddonServices])

    return (

        <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-4 border-top">
            <h4>Choose Services({selectionCount})</h4>
            <div className="mt-3 row">
                <div className="col-12 col-md-6">
                    <div className="input-group what-we-offer-search mb-3">
                        <div className="form-outline">
                            <input type="search" id="search" placeholder="Type event" className="form-control" onChange={(e) => applyFilter(e)} />
                        </div>
                        <button type="button" className="btn">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div className="horizontal-scroll-bar">
                        {isLoadingAddonServices ? <h6>Loading...</h6> :
                            addonServiceCache.filter((m) => m.addonServiceName.toLowerCase().includes(addonServiceFilter) || addonServiceFilter === '').map((spc, index) =>
                                <div key={index} >
                                    <label htmlFor={'addonService' + index} className="form-check-label">
                                        <input className="form-check-input ps-2" type="checkbox" checked={spc.isSelected} onChange={(e)=>toggleAddonService(e, spc.addonServiceId)} id={'addonService' + index}  />&nbsp;&nbsp;{spc.addonServiceName}
                                    </label>
                                </div>)
                        }
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div>
                        <div className="my-2">
                            {isLoadingPendingAddonServices ? "" :
                                <>
                                    {dataPendingAddonServices.payload.length > 0 && <h6>Pending Requests:</h6>}
                                    {dataPendingAddonServices.payload.map((spec, index) => <div key={index} className="tag-item" >
                                            <span className="text">{spec.addonServiceName}</span>&nbsp;
                                            <span className="itemClose" onClick={() => { deleteAddonService({ addonServiceId: spec.addonServiceId }) }}><i className="fa-solid fa-circle-xmark"></i></span>
                                        </div>)}
                                </>
                                }
                        </div>
                        <div className="alert alert-light d-flex p-2" role="alert">
                            <i className="fa-sharp fa-solid fa-circle-info ms-1 me-2"></i>
                            <div className="text-dark tiny-text">If you do not find specific event type in your search scroll please add it below, it will start appear in your public profile after administrator approval.</div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col">
                                    <input type="text" value={newAddonServiceName} onChange={(e) => setNewAddonServiceName(e.target.value)} className="form-control" placeholder="Service Name" maxLength="50"></input>
                                </div>
                                <div className="col-auto align-self-center">
                                    <button className="btn btn-danger mt-2" onClick={AddNewAddonServiceName} type="submit">
                                        {newAddonServiceLoading ? <span className="spinner-grow spinner-grow-sm"></span> :
                                            <i className="fa-sharp fa-solid fa-plus"></i>}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddonServiceSelect

