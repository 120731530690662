import { toast } from 'react-toastify';
import { useGetStatesQuery, useGetBusinessProfileQuery, useUpdateCompanyImageMutation, useGetAddressQuery, useGetPaymentOptionsQuery, useUpdateBusinessProfileMutation } from './../../hooks/apiSlice';
import { useRef, useState, useEffect } from "react";
import { toPascal, valideUrl } from './../../hooks/util';
import AuthService from "./../../hooks/Auth";
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import validator from 'validator'
import { ChildStateModificationFunc } from './../../components/Navbar'


const BusinessProfile = ({ menuCompletionPercentageHandler }) => {

    const { data: dataBusinessProfile, isLoading: isLoadingBusinessProfile } = useGetBusinessProfileQuery()
    const { data: dataAddress, isLoading: isLoadingAddress } = useGetAddressQuery(0)
    const { data: dataPaymentOptions, isLoading: isLoadingPaymentOptions } = useGetPaymentOptionsQuery()
    const { data: dataStates, isLoading: isLoadingStates } = useGetStatesQuery()

    const [updateCompanyImage] = useUpdateCompanyImageMutation()
    const [updateBusinessProfileMutation] = useUpdateBusinessProfileMutation()



    useEffect(() => {
        if (isLoadingBusinessProfile === false) {
            setBusinessName(dataBusinessProfile.payload.companyName);
            setPhoneNumber(dataBusinessProfile.payload.contactNumber);
            setWebsite(dataBusinessProfile.payload.website);
            setFacebookURL(dataBusinessProfile.payload.facebookURL);
            setInstagramURL(dataBusinessProfile.payload.instagramURL);
            setTwitterURL(dataBusinessProfile.payload.twitterURL);
            setPinterestURL(dataBusinessProfile.payload.pinterestURL);

            setMinimumOrderAmount(dataBusinessProfile.payload.minOrderAmount);
            setOrderPageURL(dataBusinessProfile.payload.orderPageURL);
            setPrayerAllowed(dataBusinessProfile.payload.prayerAllowed);
            setWifiAvailable(dataBusinessProfile.payload.wifiAvailable);
            setParkingAvailable(dataBusinessProfile.payload.parkingAvailable);
            setPaymentOptions(Array.from(dataBusinessProfile.payload.paymentOptions.map((x) => x.paymentOptionId.toString())));
            setDescription(dataBusinessProfile.payload.description === null ? "" : dataBusinessProfile.payload.description);
            setNicheId(Array.from(dataBusinessProfile.payload.niches.map((x) => x.nicheId.toString())));
            menuCompletionPercentageHandler(getCompletionPercentage(dataBusinessProfile.payload.contactNumber, dataBusinessProfile.payload.website, dataBusinessProfile.payload.facebookURL, dataBusinessProfile.payload.instagramURL, dataBusinessProfile.payload.twitterURL, dataBusinessProfile.payload.pinterestURL));
        }


        if (isLoadingAddress === false) {
            if (dataAddress.payload) {
                addressIdRef.current = dataAddress.payload.addressId;
                setAddress(dataAddress.payload.streetAddress === null ? "" : dataAddress.payload.streetAddress);
                setCity(dataAddress.payload.city === null ? "" : dataAddress.payload.city);
                setZip(dataAddress.payload.zipCode === null ? "" : dataAddress.payload.zipCode);
                setStateId(dataAddress.payload.state.stateId === null ? "" : dataAddress.payload.state.stateId);
                setLocation(dataAddress.payload.mapLocation === null ? "" : dataAddress.payload.mapLocation);
            }
        }

    }, [isLoadingBusinessProfile, isLoadingAddress])



    const [businessName, setBusinessName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [stateId, setStateId] = useState('0');
    const [phoneNumber, setPhoneNumber] = useState('');
    const phoneMaskGenerator = createDefaultMaskGenerator('(999) 999-9999');
    const [description, setDescription] = useState('');
    const [website, setWebsite] = useState('');
    const [location, setLocation] = useState('');
    const [facebookURL, setFacebookURL] = useState('');
    const [instagramURL, setInstagramURL] = useState('');
    const [twitterURL, setTwitterURL] = useState('');
    const [pinterestURL, setPinterestURL] = useState('');
    const [nicheId, setNicheId] = useState('');

    const [minimumOrderAmount, setMinimumOrderAmount] = useState(0);
    const [orderPageURL, setOrderPageURL] = useState('');
    const [prayerAllowed, setPrayerAllowed] = useState('');
    const [wifiAvailable, setWifiAvailable] = useState('');
    const [parkingAvailable, setParkingAvailable] = useState('');

    const [paymentOptions, setPaymentOptions] = useState([]);

    const [previewImage, setPreviewImage] = useState(undefined);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await updateBusinessProfileMutation({ companyId: AuthService.getCompanyId(), addressId: addressIdRef.current, streetAddress: address, city: city, stateId: stateId, zipCode: zip, companyName: businessName, contactNumber: phoneNumber === null ? '' : phoneNumber, website: website, mapLocation:location, description: description, FacebookURL: facebookURL, InstagramURL: instagramURL, TwitterURL: twitterURL, PinterestURL: pinterestURL, MinOrderAmount: minimumOrderAmount, ParkingAvailable: parkingAvailable, WifiAvailable: wifiAvailable, OrderPageURL: orderPageURL, PrayerAllowed: prayerAllowed, PaymentOptions: paymentOptions.join(","), Niches: nicheId === '0' ? '1,2' : nicheId })
            .then((response) => {
                if (response.data.success) {
                    addressIdRef.current = response.data;
                    toast.success(response.data.message, { position: toast.POSITION.TOP_CENTER });
                    AuthService.updateCompanyNameOnLocalStorage(businessName);
                    ChildStateModificationFunc();
                    menuCompletionPercentageHandler(getCompletionPercentage(phoneNumber, website, facebookURL, instagramURL, twitterURL, pinterestURL));
                }
                else
                    toast.error(response.data.message, { position: toast.POSITION.TOP_CENTER });
            });
    }


    function getCompletionPercentage(phoneNumber, website, facebookURL, instagramURL, twitterURL, pinterestURL) {
        let bPPer = 0;
        if (phoneNumber !== '')
            bPPer += 10;
        if (website !== '')
            bPPer += 10;
        if (facebookURL !== '')
            bPPer += 10;
        if (instagramURL !== '')
            bPPer += 10;
        if (twitterURL !== '')
            bPPer += 5;
        if (pinterestURL !== '')
            bPPer += 5;

        return 50 + bPPer;
    }



    const togglePaymentOptions = (e) => {
        var updatedList = [...paymentOptions];
        if (e.target.checked)
            updatedList = [...paymentOptions, e.target.value];
        else
            updatedList.splice(paymentOptions.indexOf(e.target.value), 1);
        setPaymentOptions(updatedList);
    }



    const addressIdRef = useRef(0)


    const selectFile = (event) => {
        if (event.target.files[0].size > 1048576) //not more than 1MB
            alert('Image file should not exceeds 1MB in size')
        else {
            setPreviewImage(URL.createObjectURL(event.target.files[0]));
            updateCompanyImage({ file: event.target.files[0] });
        }
    };



    return (
        <div>
            <div className="row">
                <div className="signin-up-wrapper d-none d-lg-block">
                    <h3>
                        <strong>Business Profile</strong>
                    </h3>
                </div>
                <div className="business-profile-head">
                    <div className=" profile-container">
                        <div className="pic-container pic-medium">
                            <label htmlFor="companyImg">
                                <input type="file" name="companyImg" id="companyImg" className="form-control" style={{ 'display': 'none' }} onChange={selectFile} accept="image/*" />
                                {previewImage !== undefined ? <img alt="" src={previewImage} style={{ 'maxHeight': '150px', 'maxWidth': '150px', 'cursor': 'pointer' }} title="click to change your image" /> :
                                    isLoadingBusinessProfile ? "" :
                                        <img alt="" src={dataBusinessProfile.payload.logoFileName !== null ? process.env.REACT_APP_BASE_URL + `MediaStorage/Logo/${dataBusinessProfile.payload.logoFileName}` : '/images/DefaultCaterer.png'} style={{ 'maxHeight': '200px', 'maxWidth': '200px', 'cursor': 'pointer' }} title="click to change your image" />
                                }
                                <div className="upload-profilepic text-center card-img-overlay d-none flex-column justify-content-center">
                                    <i className="fa-solid fa-camera"></i>
                                    <div className="overlay-txt">Upload<br />200x165 recommended</div>
                                </div>
                            </label>
                        </div>
                        <h2>{businessName}</h2>
                    </div>
                </div>


                <form onSubmit={handleSubmit}>
                    <div className="row pt-2 margin0 form-log">
                        <div className="col-lg-6 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
                            <input type="text" placeholder="Business Name" aria-label="Business Name"
                                onChange={(e) => setBusinessName(toPascal(e.target.value))}
                                required
                                value={businessName}
                                className={businessName === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                                maxLength="60" />
                        </div>


                        <div className="col-lg-6 col-md-12 col-12 pe-lg-0 p-lg-2 px-md-0">
                            <input type="text" placeholder="Street Address" aria-label="Street Address"
                                onChange={(e) => setAddress(e.target.value)}
                                required
                                className={address === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                                value={address}
                                maxLength="100" />
                        </div>

                        <div className="col-lg-6 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
                            <input type="text" placeholder="City" aria-label="City"
                                onChange={(e) => setCity(e.target.value)}
                                required
                                className={city === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                                value={city}
                                maxLength="60" />
                        </div>

                        <div className="col-lg-6 col-md-12 col-12 pe-lg-0 p-lg-2 px-md-0">
                            {isLoadingStates ? <h1>loading...</h1> :
                                <select value={stateId}
                                    className={stateId === "0" ? "combobox dropdown border border-danger" : "combobox dropdown"}
                                    onChange={(e) => setStateId(e.target.value)}>
                                    <option value="0">State</option>
                                    {
                                        dataStates.payload.map((st, index) =>
                                            <option key={index} value={st.stateId} >{st.stateName}</option>
                                        )
                                    }
                                </select>}
                        </div>

                        <div className="col-lg-6 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
                            <input className="form-control form-control-lg" type="text" placeholder="ZIP / Postal Code" aria-label="ZIP / Postal Code"
                                onChange={(e) => setZip((v) => (e.target.validity.valid ? e.target.value : v))}
                                pattern="[0-9]*"
                                value={zip}
                                maxLength="10" />
                        </div>

                        <div className="col-lg-6 col-md-12 col-12 pe-lg-0 p-lg-2 px-md-0">
                            <MaskedInput type="tel" keepMask="true" maskGenerator={phoneMaskGenerator} value={phoneNumber} onChange={setPhoneNumber} placeholder="Contact Number" aria-label="Business Phone Number" className="form-control form-control-lg" />
                        </div>

                        <div className="col-lg-11 col-md-11 col-11 px-lg-0 p-lg-2 px-md-0">

                            <input type="text" aria-label="Location"
                                onChange={(e) => setLocation(e.target.value)}
                                className={location !== "" && !validator.isURL(location) ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                                value={location}
                                placeholder="Map Location"
                                maxLength="600" />

                        </div>


                        <div className="col-lg-1 col-md-1 col-1 px-lg-0 p-lg-2 px-md-0">
                            <img className="mx-3" src="Images/49864460.jpg" height="45" width="45" alt="" title="how to get map location"></img>
                        </div>

                        <div className="col-lg-12 col-md-12 col-12 px-lg-0 p-lg-2 px-md-0">
                            <textarea className="form-control form-control-lg" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value.slice(0, 1000))} row="6" />
                        </div>

                        <div className="col-lg-12 col-md-12 col-12 px-lg-0 p-lg-2 px-md-0">
                            <input type="text" aria-label="Website"
                                onChange={(e) => setWebsite(e.target.value)}
                                className={website !== "" && !validator.isURL(website) ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                                value={website}
                                placeholder="https://example.com"
                                maxLength="100" />
                        </div>


                        <div className="col-lg-6 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
                            <input className={facebookURL !== "" && !validator.isURL(facebookURL) ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} type="text" placeholder="Facebook" aria-label="Facebook"
                                onChange={(e) => setFacebookURL(e.target.value)}
                                value={facebookURL}
                                maxLength="100" />
                        </div>

                        <div className="col-lg-6 col-md-12 col-12 pe-lg-0 p-lg-2 px-md-0">
                            <input className={instagramURL !== "" && !validator.isURL(instagramURL) ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} type="text" placeholder="Instagram" aria-label="Instagram"
                                onChange={(e) => setInstagramURL(e.target.value)}
                                value={instagramURL}
                                maxLength="100" />
                        </div>


                        <div className="col-lg-6 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
                            <input className={twitterURL !== "" && !validator.isURL(twitterURL) ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} type="text" placeholder="Twitter" aria-label="Twitter"
                                onChange={(e) => setTwitterURL(e.target.value)}
                                value={twitterURL}
                                maxLength="100" />
                        </div>

                        <div className="col-lg-6 col-md-12 col-12 pe-lg-0 p-lg-2 px-md-0">
                            <input className={pinterestURL !== "" && !validator.isURL(pinterestURL) ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} type="text" placeholder="Pinterest" aria-label="Pinterest"
                                onChange={(e) => setPinterestURL(e.target.value)}
                                value={pinterestURL || ''}
                                maxLength="100" />
                        </div>

                        <div className="col-lg-12 col-md-12 col-12 pe-lg-0 p-lg-2 px-md-0 my-3">
                            <div className="row align-items-center">
                                <div className="col-3 col-md-2"><h5 className="margin0">Are you?</h5></div>
                                <div className={nicheId.length < 1 ? "col-9 col-md-10 border border-danger" : "col-9 col-md-10"}>
                                    <div className="row">
                                        <div className="col-auto">
                                            <input className="form-check-input" checked={nicheId.toString() === '1,2'} type="radio" name="niche" id="restaurant-company-both" value="0" onChange={(e) => setNicheId('1,2')} />
                                            <label htmlFor="restaurant-company-both" className=" form-check-label ps-1" >
                                                Restaurant Caterer Both
                                            </label>
                                        </div>
                                        <div className="col-auto">
                                            <input className="form-check-input" checked={nicheId.toString() === '1'} type="radio" name="niche" id="restaurant" value="1" onChange={(e) => setNicheId(e.target.value)} />
                                            <label htmlFor="restaurant" className=" form-check-label ps-1">
                                                Restaurant Only
                                            </label>
                                        </div>
                                        <div className="col-auto">
                                            <input className="form-check-input" checked={nicheId.toString() === '2'} type="radio" name="niche" id="company" value="2" onChange={(e) => setNicheId(e.target.value)} />
                                            <label htmlFor="company" className=" form-check-label ps-1">
                                                Caterer Only
                                            </label>
                                        </div>

                                        <div className="col-auto">
                                            <input className="form-check-input" checked={nicheId.toString() === '3'} type="radio" name="niche" id="bakers" value="3" onChange={(e) => setNicheId(e.target.value)} />
                                            <label htmlFor="bakers" className="form-check-label ps-1">
                                                Bakers Only
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-12 ps-lg-0 p-lg-2 px-md-0">
                            {nicheId !== '1' && <input className="form-control form-control-lg" placeholder="Per person order amount for catering" aria-label="Per person order amount for catering" type="text"
                                onChange={(e) => {
                                    const re = /^\d*\.?\d*$/;
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                        setMinimumOrderAmount(e.target.value)
                                    }
                                }}
                                value={minimumOrderAmount || ''} maxLength="6" />
                            }
                        </div>

                        <div className="col-lg-6 col-md-12 col-12 pe-lg-0 p-lg-2 px-md-0">
                            <input className={orderPageURL !== "" && !validator.isURL(orderPageURL) ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                                type="text" placeholder="Your website order page URL" aria-label="Pinterest"
                                onChange={(e) => setOrderPageURL(e.target.value)}
                                value={orderPageURL}
                                maxLength="300" />
                        </div>



                        <div className="col-lg-12 col-md-12 col-12 pe-lg-0 p-lg-2 px-md-0 my-3">
                            <div className="row feature-check-icons">
                                <div className="col">
                                    <input className="btn-check* form-check-input" type="checkbox" id="prayerAllowed" value="1" onChange={(e) => setPrayerAllowed(e.target.checked)} checked={prayerAllowed} />
                                    <label htmlFor="prayerAllowed" className="btn* btn-primary* form-check-label ps-1">
                                        <img src="/images/PrayerAllowedIcon.png" alt="Prayer Allowed" />&nbsp;Prayer Allowed
                                    </label>
                                </div>
                                <div className="col">
                                    <input className="btn-check* form-check-input" type="checkbox" id="parkingAvailable" value="1" onChange={(e) => setParkingAvailable(e.target.checked)} checked={parkingAvailable} />
                                    <label htmlFor="parkingAvailable" className="btn* btn-primary* form-check-label ps-1">
                                        <img src="/images/ParkingAllowedIcon.png" alt="Parking Available" />&nbsp;Parking Available
                                    </label>
                                </div>
                                <div className="col">
                                    <input className="btn-check* form-check-input" type="checkbox" id="wifiAvailable" value="1" onChange={(e) => setWifiAvailable(e.target.checked)} checked={wifiAvailable} />
                                    <label htmlFor="wifiAvailable" className="btn* btn-primary* form-check-label ps-1">
                                        <img src="/images/WifiAvailableIcon.png" alt="Wifi Available" />&nbsp;Wifi Available
                                    </label>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="col-lg-12 col-md-12 col-12 pe-lg-0 p-lg-2 px-md-0">

                            <div className="row">
                                <h5 className="pb-3 payment-option-heading-bp">Which payment options do you accept? (Optional)</h5>

                                {
                                    isLoadingPaymentOptions ? <h6>Loading...</h6> :
                                        !isLoadingBusinessProfile &&
                                        dataPaymentOptions.payload.map((po, index) =>
                                            <div className="col-auto" key={index} >
                                                <input className="btn-check* form-check-input" type="checkbox" id={'paymentOption' + index} defaultChecked={dataBusinessProfile.payload.paymentOptions.some(item => po.paymentOptionId === item.paymentOptionId)} value={po.paymentOptionId} onChange={togglePaymentOptions} />
                                                <label htmlFor={'paymentOption' + index} className="btn* btn-primary* form-check-label ps-1">
                                                    <img src={'/images/' + po.paymentOptionImage} alt={po.paymentOptionName} style={{ 'maxHeight': '20px' }} />&nbsp;{po.paymentOptionName}
                                                </label>
                                            </div>)
                                }
                            </div>
                        </div>

                        <div className="d-flex align-items-center col-lg-6 col-md-6 col-sm-6 mt-3 ps-lg-0 p-lg-2 px-md-0 ">
                            <button className="btn btn-danger btn-lg " disabled={businessName === "" || address === "" || city === "" || stateId === "0" ? true : false || (website !== "" && !validator.isURL(website)) || (location !== "" && !validator.isURL(location)) || (facebookURL !== "" && !validator.isURL(facebookURL)) || (instagramURL !== "" && !validator.isURL(instagramURL)) || (twitterURL !== "" && !validator.isURL(twitterURL)) || (pinterestURL !== "" && !validator.isURL(pinterestURL)) || (orderPageURL !== "" && !validator.isURL(orderPageURL)) || nicheId.length < 1}>Save</button>
                        </div>


                    </div>
                </form>
            </div>



        </div>
    )
}

export default BusinessProfile

