import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { toPascal } from '../../../hooks/util';
import {
    useGetEventTypesSelectionsByCompanyIdQuery, useGetPendingApprovalEventTypesQuery,
    useAddEventTypeMutation, useDeleteEventTypeMutation, useSelectEventTypeMutation, useUnselectEventTypeMutation
} from '../../../hooks/apiSlice';


const EventTypeSelect = () => {

    const [newEventTypeName, setNewEventTypeName] = useState('')
    const [newEventTypeLoading, setNewEventTypeLoading] = useState(false)
    const [eventTypeFilter, setEventTypeFilter] = useState('')
    const [eventTypeCache, setEventTypeCache] = useState([])
    const [selectionCount, setSelectionCount] = useState(0)

    const { data: dataEventTypes, isLoading: isLoadingEventTypes } = useGetEventTypesSelectionsByCompanyIdQuery()
    const { data: dataPendingEventTypes, isLoading: isLoadingPendingEventTypes } = useGetPendingApprovalEventTypesQuery()

    const [addEventType] = useAddEventTypeMutation()
    const [deleteEventType] = useDeleteEventTypeMutation()
    const [unselectEventType] = useUnselectEventTypeMutation()
    const [selectEventType] = useSelectEventTypeMutation()

    const AddNewEventTypeName = async (e) => {
        e.preventDefault();
        if (newEventTypeName !== "") {
            if (dataEventTypes.payload.some(item => newEventTypeName.toUpperCase() === item.eventTypeName.toUpperCase())) {
                toast.error(newEventTypeName + ` already exists, Please select it from the list`, { position: toast.POSITION.TOP_CENTER });
            }
            else if (dataPendingEventTypes.payload.some(item => newEventTypeName.toUpperCase() === item.eventTypeName.toUpperCase())) {
                toast.error(newEventTypeName + ` already added`, { position: toast.POSITION.TOP_CENTER });
            }
            else {
                setNewEventTypeLoading(true);
                const returned = await addEventType({ eventTypeName: toPascal(newEventTypeName) }).unwrap();
                setNewEventTypeLoading(false);
                setNewEventTypeName('');
            }
        }
    }

    useEffect(() => {
        if (!isLoadingEventTypes) {
            setEventTypeCache(dataEventTypes.payload);
        }
    }, [isLoadingEventTypes]);


    const toggleEventType = (e, id) => {
        if (e.target.checked)
            selectEventType({ eventTypeId: parseInt(id) });
        else
            unselectEventType({ eventTypeId: parseInt(id) })

        const currentIndex = eventTypeCache.findIndex((todo) => todo.eventTypeId === id);
        const updatedTodo = { ...eventTypeCache[currentIndex], isSelected: e.target.checked };
        const newTodos = [
            ...eventTypeCache.slice(0, currentIndex),
            updatedTodo,
            ...eventTypeCache.slice(currentIndex + 1)
        ];
        setEventTypeCache(newTodos);

        setSelectionCount(e.target.checked ? selectionCount + 1 : selectionCount - 1)
    }

    const applyFilter = (e) => {
        setEventTypeFilter(e.target.value.toLowerCase());
    }

    useEffect(() => {
        if (isLoadingEventTypes === false) {
            setSelectionCount(dataEventTypes.payload.filter(item => item.isSelected === true).length)
        }
    }, [isLoadingEventTypes])

    return (

        <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-4 border-top">
            <h4>Choose Event Type({selectionCount})</h4>
            <div className="mt-3 row">
                <div className="col-12 col-md-6">
                    <div className="input-group what-we-offer-search mb-3">
                        <div className="form-outline">
                            <input type="search" id="search" placeholder="Type event" className="form-control" onChange={(e) => applyFilter(e)} />
                        </div>
                        <button type="button" className="btn">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div className="horizontal-scroll-bar">
                        {isLoadingEventTypes ? <h6>Loading...</h6> :
                            eventTypeCache.filter((m) => m.eventTypeName.toLowerCase().includes(eventTypeFilter) || eventTypeFilter === '').map((spc, index) =>
                                <div key={index} >
                                    <label htmlFor={'eventType' + index} className="form-check-label">
                                        <input className="form-check-input ps-2" type="checkbox" checked={spc.isSelected} onChange={(e)=>toggleEventType(e, spc.eventTypeId)} id={'eventType' + index}  />&nbsp;&nbsp;{spc.eventTypeName}                                        
                                    </label>
                                </div>)
                        }
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div>
                        <div className="my-2">
                            {isLoadingPendingEventTypes ? "" :
                                <>
                                    {dataPendingEventTypes.payload.length > 0 && <h6>Pending Requests:</h6>}
                                    {dataPendingEventTypes.payload.map((spec, index) => <div key={index} className="tag-item" >
                                        <span className="text">{spec.eventTypeName}</span>&nbsp;
                                        <span className="itemClose" onClick={() => { deleteEventType({ eventTypeId: spec.eventTypeId }) }}><i className="fa-solid fa-circle-xmark"></i></span>
                                    </div>)}
                                </>
                            }
                        </div>
                        <div className="alert alert-light d-flex p-2" role="alert">
                            <i className="fa-sharp fa-solid fa-circle-info ms-1 me-2"></i>
                            <div className="text-dark tiny-text">If you do not find specific event type in your search scroll please add it below, it will start appear in your public profile after administrator approval.</div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col">
                                    <input type="text" value={newEventTypeName} onChange={(e) => setNewEventTypeName(e.target.value)} className="form-control" placeholder="Event Name" maxLength="50"></input>
                                </div>
                                <div className="col-auto align-self-center">
                                    <button className="btn btn-danger mt-2" onClick={AddNewEventTypeName} type="submit">
                                        {newEventTypeLoading ? <span className="spinner-grow spinner-grow-sm"></span> :
                                            <i className="fa-sharp fa-solid fa-plus"></i>}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EventTypeSelect

