import React from 'react'
import Header from './Header';
import Footer from './Footer';



const TrayMenu = () => {


    return (

        <div>
            <Header />
            <section className="customer-banner menu">
                <div className="container-fluid banner-home d-flex justify-content-center align-items-center">
                    <div className="row margin0 justify-content-center">
                        <div className="col-auto">
                            <h1 className="text-uppercase banner-title"><strong>Tray Menu</strong></h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="">
                <div className="container">
                    <div className="row align-items-center justify-content-center px-xxl-5 mx-xxl-5 py-xxl-5 my-5">
                        <div className="col-12">
                            <img className="w-100" src="/images/customer-website/traymenu.jpg" alt="" />
                        </div>

                    </div>
                </div>
            </section>
            <Footer></Footer>






        </div>
    )
}


export default TrayMenu