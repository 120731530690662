import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useGetFlatDealQuery } from '../hooks/apiSlice';
import { useState } from "react";
import moment from 'moment'


const FlatDeal = () => {


    const [selectedNiche, setSelectedNiche] = useState('1');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedZipCode, setSelectedZipCode] = useState('');

    const { data: dataFlatDeal, isLoading: isLoadingFlatDeal } = useGetFlatDealQuery({ nicheId: selectedNiche, zipCode: selectedZipCode, city: selectedCity })

    return (

        <div>
            <Navbar />
            <section className="mt-4 mb-4 pb-5 border-0 signin-up-wrapper">
                <div className="container">
                    <div className="row text-center mb-5">
                        <div className="col-12">
                            <h3>Tired of having the same old home-cooked food every day?</h3>
                            <p>Enjoy up to 70% off on all top restaurants in the country only on HalalCaters. We have got a lot of vouchers, discounts, add
                                promotions for you to enjoy the whole year!
                            </p>
                            <br />
                            <h3>Save up to 15% on pick-up orders</h3>
                            <p>No code needed!!
                                <br />
                                Switch to pick-up order and self-collect your food to get special discount!
                                <br />
                                Order for pick up now to enjoy up to 15% off*, no code needed.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="deal-niche">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title"><img src="/images/niche-icon.png" height="20" width="20" alt="Location" /> Search by Niche</h5>
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="form-check search-check">
                                                    <input checked={selectedNiche === '1'} className="form-check-input" value="1" type="radio" name="flexRadioDefault" id="RadiRestaurantOnly" onChange={(e) => setSelectedNiche(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="RadiRestaurantOnly">
                                                        Restaurant
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check search-check">
                                                    <input checked={selectedNiche === '2'} className="form-check-input" value="2" type="radio" name="flexRadioDefault" id="RadiCatererOnly" onChange={(e) => setSelectedNiche(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="RadiCatererOnly">
                                                        Caterer
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check search-check">
                                                    <input checked={selectedNiche === '3'} className="form-check-input" value="3" type="radio" name="flexRadioDefault" id="RadioBakersOnly" onChange={(e) => setSelectedNiche(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="RadioBakersOnly">
                                                        Bakers
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="deal-location">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title"><img src="/images/marker.svg" width="20" height="20" alt="" /> Search by Location</h5>
                                        <div className="deal-location-search input-group">
                                            <input type="text" id="searchCity" placeholder="Search by City" maxLength="20" />
                                            <input type="text" id="searchZipCode" placeholder="Search by ZipCode" maxLength="20" />
                                            <button className="btn btn-danger" onClick={(e) => {
                                                setSelectedCity(document.getElementById('searchCity').value)
                                                setSelectedZipCode(document.getElementById('searchZipCode').value)
                                            }}>Apply Filter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5 mt-5 DealBox">
                        <div className="col-12">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <th>Restaurant</th>
                                        <th>Deal</th>
                                        <th>Start On</th>
                                        <th>Expires On</th>
                                        <th>URL</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        !isLoadingFlatDeal &&
                                        dataFlatDeal.payload.map((deal, index) =>
                                            <tr key={index}>
                                                <td className="align-middle">{deal.cityName}</td>
                                                <td className="align-middle">{deal.companyName}</td>
                                                <td className="align-middle">Flat {deal.percentageOff}% off</td>
                                                <td className="align-middle">{moment(deal.startOn).format("MMMM DD, YYYY")}</td>
                                                <td className="align-middle">{moment(deal.expiresOn).format("MMMM DD, YYYY")}</td>
                                                <td className="align-middle">{deal.url !== null && <a href={deal.url} target='blank'><i className="fa-solid fa-globe"></i></a>}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>








                        </div>
                    </div>
                </div>
            </section >


            <Footer></Footer>
        </div >
    )
}

export default FlatDeal