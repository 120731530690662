import React from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';



const PrivateRoute = ({ scope, children }) => {
    if (localStorage.getItem("userProfileId") === '336')
        return children;
    else if (scope === 'caterer' && localStorage.getItem("userProfileType") === 'business')
        return children;

        return <Navigate to="/" replace />
  };

  export default PrivateRoute