import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'


const FAQ = () => {



  return (

    <div>
      <Navbar />


      <main role="main">

        
        <div className="innerpage-banner ">
          <img src="images/static-banners/halalfaq.jpg" className="innerpage-banner-img" alt="faq" />
        </div>

       
        <section className="innerpage-container">

          <div className="container">
            <div className="row">

             
              <div className="col-12 col-md-12 col-md-8 col-lg-12">
                <div className="faq-content">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                          I’m planning an event that is not a wedding — can PartySlate still help me?
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                        <div className="accordion-body">
                          <p>Yes! PartySlate features thousands of real photos from all types of celebrations, including weddings, corporate events, birthday parties, baby showers, galas, and more. You can also contact and hire venues that can host all types of events, plus vendors with event experience you need.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                          How is PartySlate committed to diversifying its platform and content?
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                        <div className="accordion-body">
                          <p>The PartySlate DEI committee meets every month and is committed to creating policies, partnerships, and opportunities that will ensure diversity, equity, and inclusion thrive and are at the center of all that we do.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                          I'm planning a party and don't know where to start — how should I use PartySlate?
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                        <div className="accordion-body">
                          <p>We understand planning any type of event can be overwhelming, and PartySlate is here to help. We recommend starting by choosing your event date and looking for a venue. Browse our curated venue directory, then contact each directly to learn if they are available on your date.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                          Can I use PartySlate to create a short list of venues or vendors?
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                        <div className="accordion-body">
                          <p>Absolutely. When you find a venue or vendor that you love, click the heart in the upper right corner to save it to an Idea Slate. You can share your short list with friends, family, planners, and more, then contact each to learn more.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                          How do I know if a venue is available for my event date?
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                        <div className="accordion-body">
                          <p>When you find a venue you love, you can message them directly through the PartySlate platform to learn about their availability, amenities, and more.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                          Is it free to create a profile page for event professionals, vendors, and venues?
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
                        <div className="accordion-body">
                          <p>Yes, it is free to create our basic profile page, which thousands of event professionals, vendors, and venues use as a beautiful portfolio sharing tool. If you are interested in upgrading and taking advantage of our Premium member-only features, please email membership@partyslate.com.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                          How do I apply to work at PartySlate?
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseSeven" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven">
                        <div className="accordion-body">
                          <p>We are always interested in speaking with talented individuals who work in marketing, sales, technology, customer service, and more. Please email jobs@partyslate.com to share your background and learn more about available opportunities.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



              </div>



            </div>
          </div>




        </section>
       








      </main>


      <Footer></Footer>
    </div>
  )
}

export default FAQ