import React, { useState } from "react";
import moment from 'moment'
import { useGetAllTagQuery, useEditTagMutation, useDeleteTagMutation, useAddTagMutation } from '../hooks/apiSlice';

const TagManagement = () => {

    const [selectedTagId, setSelectedTagId] = useState(0);

    const [tagName, setTagName] = useState('');
    const [addTagName, setAddTagName] = useState('');

    const { data: dataTag, isLoading: isLoadingTag } = useGetAllTagQuery()
    const [addTagMutation] = useAddTagMutation()
    const [editTagMutation] = useEditTagMutation()
    const [deleteTagMutation] = useDeleteTagMutation()

    return (
        <>
            {isLoadingTag ? "" :
                <div>
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-auto">
                                Add Tag
                            </div>
                            <div className="col-auto">
                                <input type="text" id="dealName" name="dealName" value={addTagName} onChange={(e) => setAddTagName(e.target.value)} />
                            </div>
                            <div className="col-2">
                                <button className="btn-fill pull-right btn btn-dark btn-small" onClick={(e) => {
                                    if (tagName !== '') {
                                        addTagMutation({ tagName: addTagName });
                                        setAddTagName('');
                                    }
                                }}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="strpied-tabled-with-hover card align-items-center">
                                    <div className="table-full-width table-responsive px-0 card-body">
                                        <table className="table-hover table-striped table" style={{ 'width': '300px' }}><thead>
                                        </thead>
                                            <tbody>
                                                {dataTag.payload.length === 0 ? <tr><td colSpan="6" className="text-center p-4">No record found</td></tr> :
                                                    dataTag.payload.map((cont, index) =>
                                                        <tr key={index}>
                                                            <td>{cont.tagId}</td>
                                                            {
                                                                (cont.tagId === selectedTagId)
                                                                    ?
                                                                    <td><input type="text" id="dealName" name="dealName" value={tagName} onBlur={(e) => {
                                                                        editTagMutation({ tagId: cont.tagId, tagName: tagName });
                                                                        setSelectedTagId(0);
                                                                    }} onChange={(e) => setTagName(e.target.value)} className={tagName === "" ? "form-control border-danger" : "form-control"} /></td>
                                                                    :
                                                                    <td style={{ cursor: 'pointer' }} onClick={(e) => {
                                                                        setTagName(cont.tagName)
                                                                        setSelectedTagId(cont.tagId)
                                                                    }}>{cont.tagName}</td>
                                                            }
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default TagManagement