import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'


const MeatPoultry = () => {



  return (

    <div>
      <Navbar />

      <main role="main">

        <div className="innerpage-banner ">
          <img src="images/static-banners/poultry1.jpg" className="innerpage-banner-img" alt="meat and poultry" />
        </div>


        <section className="innerpage-container">

          <div className="container">
            <div className="row">

              <div className="col-12 col-md-12 col-md-12 col-lg-12">


                <div className="row align-items-center">

                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">



                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Al Safa Popcorn Chicken</p>
                      <p className="subtitle mb-0">Al-Safa Halal</p>

                    </div>




                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Al Safa Breaded Chicken Strips</p>
                      <p className="subtitle mb-0">Al-Safa Halal</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Chicken Tenders</p>
                      <p className="subtitle mb-0">Saffron Road</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Tandoori Seasoned Chicken Nuggets</p>
                      <p className="subtitle mb-0">Saffron Road</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Chicken Nuggets</p>
                      <p className="subtitle mb-0">Saffron Road</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Al Safa Breaded Chicken Wings</p>
                      <p className="subtitle mb-0">Al-Safa Halal</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Crescent Chicken Original Patties</p>
                      <p className="subtitle mb-0">Crescent Foods</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Crescent Chicken Cajun Patties</p>
                      <p className="subtitle mb-0">Crescent Foods</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Crescent Chicken BBQ Patties</p>
                      <p className="subtitle mb-0">Crescent Foods</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Tahira Fricandelles</p>
                      <p className="subtitle mb-0">Tahira Foods Ltd.</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Crescent Chicken Gyro Patties</p>
                      <p className="subtitle mb-0">Crescent Foods</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Crescent Chicken Chili Patties</p>
                      <p className="subtitle mb-0">Crescent Foods</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Crave Chicken Patties</p>
                      <p className="subtitle mb-0">Crave Foods</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Crave Burger</p>
                      <p className="subtitle mb-0">Crave Foods</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Tahira Chicken Samosas</p>
                      <p className="subtitle mb-0">Tahira Foods Ltd.</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Tahira Chicken Nuggets</p>
                      <p className="subtitle mb-0">Tahira Foods Ltd.</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Tahira Chicken Frankfurters</p>
                      <p className="subtitle mb-0">Tahira Foods Ltd.</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Tahira Chicken Croquette</p>
                      <p className="subtitle mb-0">Tahira Foods Ltd.</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Tahira Chicken Burger</p>
                      <p className="subtitle mb-0">Tahira Foods Ltd.</p>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-4 border">
                    <div className="meat-poultry-box align-item-center">

                      <p className="name mb-0">Al Safa Chicken Patties</p>
                      <p className="subtitle mb-0">Al-Safa Halal</p>

                    </div>
                  </div>





                </div>




              </div>


            </div>



          </div>





        </section>









      </main>


      <Footer></Footer>
    </div>
  )
}

export default MeatPoultry