import React, { useState } from "react";
import { useAddBusinessClaimMutation } from '../hooks/apiSlice';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';


const ClaimForm = (props) => {

    const [addClaim] = useAddBusinessClaimMutation()
    const params = useParams();
    const [formValues, setFormValues] = useState({ vname: "", phone: "", email: "", comments: "" });
    const [formErrors, setFormErrors] = useState({ vname: "", phone: "", email: "", comments: "" });
    const [isSubmit, setIsSubmit] = useState(false);


    const handleClaimAdd = () => {
        setFormErrors(validate(formValues));
        if (Object.keys(validate(formValues)).length === 0) {
            addClaim({ name: formValues.vname, phone: formValues.phone, email: formValues.email, comments: formValues.comments, 'company.companyid': params.companyId });
            toast.success(`Your Claim has been submitted successfully`, { position: toast.POSITION.TOP_CENTER });
            handleClose();
            props.setSubmitted(true);
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })
    }

    const validate = (values) => {
        const errors = {};
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (!values.vname)
            errors.vname = "Enter your full name";
        if (!values.phone)
            errors.phone = "Enter your phone number";
        if (!values.email)
            errors.email = "Enter your email address";
        else if (!regex.test(values.email))
            errors.email = "Your email does not seems like a valid email address";

        return errors
    }


    const handleClose = () => props.setShow(false);

    return (
        <>
            <Modal show={props.show} onHide={handleClose} >
                <Modal.Header className="bg-danger text-light" closeButton>
                    <Modal.Title><h4 className="modal-title" id="claim-profile-modalLabel"><strong><i className="fa-solid fa-anchor-circle-exclamation"></i>&nbsp;Claim your Business Profile</strong></h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                    <form>
                        <div className="row margin0 form-log">
                            <div className="col-lg-12 col-md-12 col-12 px-lg-0 p-lg-2 px-md-0">
                                <input type="text" placeholder="Full Name*" aria-label="Name" required className="form-control form-control-lg" name="vname" onChange={handleChange} />
                                <div className="text-danger">{formErrors.vname}</div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 px-lg-0 p-lg-2 px-md-0">
                                <input type="email" autoComplete="false" placeholder="Email*" aria-label="Email" required className="form-control form-control-lg" name="email" onChange={handleChange} />
                                <div className="text-danger">{formErrors.email}</div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 px-lg-0 p-lg-2 px-md-0">
                                <input type="tel" autoComplete="false" placeholder="Phone*" aria-label="Phone" required className="form-control form-control-lg" name="phone" onChange={handleChange} />
                                <div className="text-danger">{formErrors.phone}</div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 px-lg-0 p-lg-2 px-md-0">
                                <textarea className="form-control form-control-lg" placeholder="Comments" row="8" name="comments" onChange={handleChange} />
                                <div className="text-danger">{formErrors.comments}</div>
                            </div>
                        </div>
                    </form>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-danger" onClick={handleClose}>Cancel</button>
                    <button type="button" className="btn btn-danger" onClick={(e) => handleClaimAdd(e)}>Submit your Claim</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ClaimForm