
import { Routes, Route, Link } from "react-router-dom";
import Customers from "./Customers";
import CompanyManagement from "./CompanyManagement";
import BadgeManagement from "./BadgeManagement";
import Contact from "./Contact";
import ReviewManagement from "./ReviewManagement";
import CatererOfferings from "./CatererOfferings";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AdminReports from "./AdminReports";
import { useNavigate } from "react-router-dom";
import AuthService from "../hooks/Auth";
import Claims from "./Claims";
import TagManagement from "./TagManagement";
import Boost from "./Boost";
import BlogManagement from "./BlogManagement";
import BannerAd from "./BannerAd";
import PaidListing from "./PaidListing";
import PaidListingPrice from "./PaidListingPrice";
import BannerAdPrice from "./BannerAdPrice";
import BoostPrice from "./BoostPrice";
import Package from "./Package";

function Admin() {

    const navigate = useNavigate();
    const logOut = () => {
      AuthService.logout();
      navigate("/");
    };


    return (
        <>
            <ToastContainer />
            <div className="row dashboard-wrapper grow w-100*">

                <div className="sidebar col-2* bg-dark* py-3 collapse" id="sidebar">
                    <div className="logo d-flex justify-content-center">
                        <a href="#">
                            <img src="/images/logo.png" alt="..." />
                        </a>
                    </div>

                    <div className="dashboard-nav mt-4 d-flex flex-column flex-wrap align-content-center">
                        <ul className="padding0 list-unstyled">
                            <li className="d-flex align-items-center">
                                <Link to="./" className="link-light item"><i className="fa-solid fa-chalkboard-user"></i> Caterer Offerings</Link>
                            </li>
                            <li className="d-flex align-items-center">
                                <Link to="customers" className="link-light item"><i className="fa-solid fa-users"></i> Customers</Link>
                            </li>
                            <li className="d-flex align-items-center">
                                <Link to="company-management" className="link-light item"><i className="fa-solid fa-bell-concierge"></i> Company Management</Link>
                            </li>
                            {/* <li className="d-flex align-items-center">
                                <Link to="badge-management" className="link-light item"><i className="fa-solid fa-certificate"></i> Badge Management</Link>
                            </li> */}
                            <li className="d-flex align-items-center">
                                <Link to="review-management" className="link-light item"><i className="fa-regular fa-comment"></i> Reviews</Link>
                            </li>
                            <li className="d-flex align-items-center">
                                <Link to="visitor-contacts" className="link-light item"><i className="fa-solid fa-arrow-right-from-bracket"></i> Contact Form Messages </Link>
                            </li>
                            <li className="d-flex align-items-center">
                                <Link to="claims" className="link-light item"><i className="fa-solid fa-plug-circle-exclamation"></i> Claims </Link>
                            </li>

                            <li className="d-flex align-items-center">
                                <Link to="paid-listing-price" className="link-light item"><i className="fa-solid fa-dollar"></i> Paid Listing Price</Link>
                            </li>

                            <li className="d-flex align-items-center">
                                <Link to="banner-ad-price" className="link-light item"><i className="fa-solid fa-dollar"></i> Banner Ad Price</Link>
                            </li>

                            <li className="d-flex align-items-center">
                                <Link to="boost-price" className="link-light item"><i className="fa-solid fa-dollar"></i> Boost Price</Link>
                            </li>

                            <li className="d-flex align-items-center">
                                <Link to="boost" className="link-light item"><i className="fa-solid fa-rocket"></i> Boost </Link>
                            </li>

                            <li className="d-flex align-items-center">
                                <Link to="banner-ad" className="link-light item"><i className="fa-solid fa-bullhorn"></i> Banner Ad </Link>
                            </li>

                            <li className="d-flex align-items-center">
                                <Link to="paid-listing" className="link-light item"><i className="fa-solid fa-crown"></i> Paid Listing </Link>
                            </li>

                            <li className="d-flex align-items-center">
                                <Link to="package" className="link-light item"><i className="fa-solid fa-cube"></i> Package Subscription </Link>
                            </li>

                            <li className="d-flex align-items-center">
                                <Link to="tags" className="link-light item"><i className="fa-solid fa-tags"></i> Tag Management </Link>
                            </li>

                            <li className="d-flex align-items-center">
                                <Link to="blogs" className="link-light item"><i className="fa-solid fa-tags"></i> Blog Management </Link>
                            </li>

                            <li className="d-flex align-items-center">
                                <Link to="admin-reports" className="link-light item"><i className="fa-solid fa-file"></i> Admin Reports </Link>
                            </li>
                        </ul>

                    </div>



                </div>
                <div className="main-panel col-10* h-100* padding0">
                    <div className="topbar">

                        <nav className="navbar navbar-expand-lg navbar-light mb-3 shadow">
                            <div className="container-fluid">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebar">
                                    <span className="my-1 mx-2 close nav-close">X</span>
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
                                    <a href="#home" className="mr-2 navbar-brand"><i className="fa-solid fa-house"></i> Admin Panel</a>
                                </div>

                                <ul className="navbar-nav ml-auto">

                                    <li className="nav-item dropdown no-arrow mx-1">
                                        <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-bell fa-fw"></i>
                                            <span className="badge badge-danger badge-counter">3+</span>
                                        </a>
                                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                            aria-labelledby="alertsDropdown">
                                            <h6 className="dropdown-header">
                                                Alerts Center
                                            </h6>
                                            <a className="dropdown-item d-flex align-items-center" href="#">
                                                <div className="mr-3">
                                                    <div className="icon-circle bg-primary">
                                                        <i className="fas fa-file-alt text-white"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="small text-gray-500">December 12, 2019</div>
                                                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                                </div>
                                            </a>
                                            <a className="dropdown-item d-flex align-items-center" href="#">
                                                <div className="mr-3">
                                                    <div className="icon-circle bg-success">
                                                        <i className="fas fa-donate text-white"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="small text-gray-500">December 7, 2019</div>
                                                    $290.29 has been deposited into your account!
                                                </div>
                                            </a>
                                            <a className="dropdown-item d-flex align-items-center" href="#">
                                                <div className="mr-3">
                                                    <div className="icon-circle bg-warning">
                                                        <i className="fas fa-exclamation-triangle text-white"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="small text-gray-500">December 2, 2019</div>
                                                    Spending Alert: We've noticed unusually high spending for your account.
                                                </div>
                                            </a>
                                            <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                                        </div>
                                    </li>

                                    <li className="nav-item dropdown no-arrow mx-1">
                                        <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-envelope fa-fw"></i>
                                            <span className="badge badge-danger badge-counter">7</span>
                                        </a>
                                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                            aria-labelledby="messagesDropdown">
                                            <h6 className="dropdown-header">
                                                Message Center
                                            </h6>
                                            <a className="dropdown-item d-flex align-items-center" href="#">
                                                <div className="dropdown-list-image mr-3">
                                                    <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                                        alt="..." />
                                                    <div className="status-indicator bg-success"></div>
                                                </div>
                                                <div className="font-weight-bold">
                                                    <div className="text-truncate">I am having trouble updating my business profile.</div>
                                                    <div className="small text-gray-500">Emily Fowler · 58m</div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item d-flex align-items-center" href="#">
                                                <div className="dropdown-list-image mr-3">
                                                    <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                                        alt="..." />
                                                    <div className="status-indicator"></div>
                                                </div>
                                                <div>
                                                    <div className="text-truncate">I have the photos of my lat event but the system does not accept it because of the size</div>
                                                    <div className="small text-gray-500">Jae Chun · 1d</div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                                        </div>
                                    </li>

                                    <div className="topbar-divider d-none d-sm-block"></div>

                                    <li className="nav-item dropdown no-arrow">
                                        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="mr-3 d-none d-lg-inline text-gray-600 small">Halal Caters - Admin</span>
                                            <img className="img-profile rounded-circle"
                                                src="/images/profile.svg" />
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                            aria-labelledby="userDropdown">
                                            <a className="dropdown-item" href="#">
                                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                                Profile
                                            </a>
                                            <a className="dropdown-item" href="#">
                                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                                Settings
                                            </a>
                                            <a className="dropdown-item" href="#">
                                                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                                Activity Log
                                            </a>
                                            <div className="dropdown-divider"></div>
                                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" onClick={logOut}>
                                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                                Logout
                                            </a>
                                        </div>
                                    </li>

                                </ul>

                            </div>
                        </nav>
                    </div>
                    <div className="content">

                        <Routes>

                            <Route path="login" element={<div>dashboard with switch</div>} />

                            <Route path="/" element={<CatererOfferings />} />
                            <Route path="customers" element={<Customers />} />
                            <Route path="company-management" element={<CompanyManagement />} />
                           {/*  <Route path="badge-management" element={<BadgeManagement />} /> */}
                            <Route path="visitor-contacts" element={<Contact />} />
                            <Route path="claims" element={<Claims />} />
                            <Route path="tags" element={<TagManagement />} />
                            <Route path="blogs" element={<BlogManagement />} />
                            <Route path="review-management" element={<ReviewManagement />} />
                            <Route path="paid-listing-price" element={<PaidListingPrice />} />
                            <Route path="banner-ad-price" element={<BannerAdPrice />} />
                            <Route path="boost-price" element={<BoostPrice />} />
                            <Route path="boost" element={<Boost />} />
                            <Route path="banner-ad" element={<BannerAd />} />
                            <Route path="paid-listing" element={<PaidListing />} />
                            <Route path="package" element={<Package />} />
                            <Route path="admin-reports" element={<AdminReports />} />
                            
                        </Routes>
                    </div>
                    <div className="footer">
                        <div className="row w-100">
                            <div className="col-12">
                                <nav>

                                    <p className="copyright text-center margin0">
                                        © {new Date().getFullYear()}{" "}
                                        <a href="https://halalcaters.com">Halal Caters</a>
                                    </p>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





        </>
    );
}

export default Admin;
