// export function toPascal(string) {
//   return `${string}`
//     .toLowerCase()
//     //.replace(new RegExp(/[-]+/, 'g'), ' ')
//     //.replace(new RegExp(/[^\w\s']/, 'g'), '')
//     //.replace(new RegExp(/\s+(.)(\w*)/, 'g'), ($1, $2, $3) => `${$2.toUpperCase() + $3}`)
//     .replace(new RegExp(/\w/), s => s.toUpperCase());
// }

export const toPascal = str => (str.match(/[a-zA-Z0-9\s']+/g) || []).map(w => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join('');

export const toURLEncode = str => (str.toLowerCase().replace(" ", "-"));

export const toURLDecode = (str) => {
  return str !== undefined ? (str.match(/[a-zA-Z0-9\s']+/g) || []).map(w => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(' ') : false;
}

export function valideUrl(url) {
  const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  return url === "" ? true : regex.test(url);
};

export function shortify(str, maxLength) {
  if (str !== '' && str !== null) {
    if (str.length > maxLength)
      return str.substring(0, maxLength) + ' ...';
    else
      return str;
  }
  else
    return str;
}

export function isNumberKey(evt) {
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
    evt.preventDefault();
}


export function getCurrentPathWithoutQueryParameters() {
  const url = window.location.href;
  const urlObj = new URL(url);
  urlObj.search = '';
  return urlObj.toString();
}


export function HCSpinner() {
  return (
    <div className="mt-5 spinner-border text-danger" role="status"><span className="visually-hidden">Loading...</span></div>)
}





export const structureCart = addTargetAud => {
  var aryCart = []
  var targetAud = []
  var master = [];
  var aryOffer = {};
  var lastOffer = '';

  aryCart = addTargetAud.sort((a, b) => {
    if (a.offer.toLowerCase() < b.offer.toLowerCase()) {
      return -1;
    }
    if (a.offer.toLowerCase() > b.offer.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  aryCart.map((cItem, index) => {
    if ((lastOffer === cItem.offer || index === 0)) {
      targetAud.push({ targetAudienceId: cItem.targetAudienceId, offerName: cItem.offerName, stateName: cItem.stateName, price: cItem.price, amount: cItem.price, subscriptionPlanName: cItem.subscriptionPlanName, productPlanId: cItem.productPlanId })
    }
    else {
      aryOffer.offer = lastOffer;
      aryOffer.targetAudiences = targetAud;
      //if (index + 1 !== addTargetAud.length){
      master.push(aryOffer);
      aryOffer = {};
      targetAud = [];
      //}
      targetAud.push({ targetAudienceId: cItem.targetAudienceId, offerName: cItem.offerName, stateName: cItem.stateName, price: cItem.price, amount: cItem.price, subscriptionPlanName: cItem.subscriptionPlanName, productPlanId: cItem.productPlanId })
    }

    if (index + 1 === addTargetAud.length) {
      aryOffer.offer = cItem.offer;
      aryOffer.targetAudiences = targetAud;
      master.push(aryOffer);
    }
    lastOffer = cItem.offer;
  })
  return master;
}



export const readUploadedFileDataURL = inputFile => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
          temporaryFileReader.abort();
          reject("Problem parsing input file.");
      };

      temporaryFileReader.onload = () => {
          resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
  });
};