import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { toPascal } from '../../../hooks/util';
import {
    useGetCuisinesSelectionsByCompanyIdQuery, useGetPendingApprovalCuisinesQuery,
    useAddCuisineMutation, useDeleteCuisineMutation, useSelectCuisineMutation, useUnselectCuisineMutation
} from '../../../hooks/apiSlice';


const CuisineSelect = () => {

    const [newCuisineName, setNewCuisineName] = useState('')
    const [newCuisineLoading, setNewCuisineLoading] = useState(false)
    const [cuisineFilter, setCuisineFilter] = useState('')
    const [cuisineCache, setCuisineCache] = useState([])
    const [selectionCount, setSelectionCount] = useState(0)

    const { data: dataCuisines, isLoading: isLoadingCuisines } = useGetCuisinesSelectionsByCompanyIdQuery()
    const { data: dataPendingCuisines, isLoading: isLoadingPendingCuisines } = useGetPendingApprovalCuisinesQuery()

    const [addCuisine] = useAddCuisineMutation()
    const [deleteCuisine] = useDeleteCuisineMutation()
    const [unselectCuisine] = useUnselectCuisineMutation()
    const [selectCuisine] = useSelectCuisineMutation()

    const AddNewCuisineName = async (e) => {
        e.preventDefault();
        if (newCuisineName !== "") {
            if (dataCuisines.payload.some(item => newCuisineName.toUpperCase() === item.cuisineName.toUpperCase())) {
                toast.error(newCuisineName + ` already exists, Please select it from the list`, { position: toast.POSITION.TOP_CENTER });
            }
            else if (dataPendingCuisines.payload.some(item => newCuisineName.toUpperCase() === item.cuisineName.toUpperCase())) {
                toast.error(newCuisineName + ` already added`, { position: toast.POSITION.TOP_CENTER });
            }
            else {
                setNewCuisineLoading(true);
                const returned = await addCuisine({ cuisineName: toPascal(newCuisineName) }).unwrap();
                setNewCuisineLoading(false);
                setNewCuisineName('');
            }
        }
    }

    useEffect(() => {
        if (!isLoadingCuisines) {
            setCuisineCache(dataCuisines.payload);
        }
    }, [isLoadingCuisines]);


    const toggleCuisine = (e, id) => {
        if (e.target.checked)
            selectCuisine({ cuisineId: parseInt(id) });
        else
            unselectCuisine({ cuisineId: parseInt(id) })

        const currentIndex = cuisineCache.findIndex((todo) => todo.cuisineId === id);
        const updatedTodo = { ...cuisineCache[currentIndex], isSelected: e.target.checked };
        const newTodos = [
            ...cuisineCache.slice(0, currentIndex),
            updatedTodo,
            ...cuisineCache.slice(currentIndex + 1)
        ];
        setCuisineCache(newTodos);

        setSelectionCount(e.target.checked ? selectionCount + 1 : selectionCount - 1)
    }

    const applyFilter = (e) => {
        setCuisineFilter(e.target.value.toLowerCase());
    }


    useEffect(() => {
        if (isLoadingCuisines === false) {
            setSelectionCount(dataCuisines.payload.filter(item => item.isSelected === true).length)
        }
    }, [isLoadingCuisines])

    return (

        <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-4 border-top">
            <h4>Choose Cuisines({selectionCount})</h4>
            <div className="mt-3 row">
                <div className="col-12 col-md-12">
                    <div className="input-group what-we-offer-search mb-3">
                        <div className="form-outline">
                            <input type="search" id="search" placeholder="Type event" className="form-control" onChange={(e) => applyFilter(e)} />
                        </div>
                        <button type="button" className="btn">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div className="horizontal-scroll-bar">
                        {isLoadingCuisines ? <h6>Loading...</h6> :
                            cuisineCache.filter((m) => m.cuisineName.toLowerCase().includes(cuisineFilter) || cuisineFilter === '').map((spc, index) =>
                                <div key={index} >
                                    <label htmlFor={'cuisine' + index} className="form-check-label">
                                        <input className="form-check-input ps-2" type="checkbox" checked={spc.isSelected} onChange={(e)=>toggleCuisine(e, spc.cuisineId)} id={'cuisine' + index}  />&nbsp;&nbsp;{spc.cuisineName}                                        
                                    </label>
                                </div>)
                        }
                    </div>
                </div>


            </div>
        </div>
    )
}

export default CuisineSelect

