import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { toPascal } from '../../../hooks/util';
import {
    useGetDietariesSelectionsByCompanyIdQuery, useGetPendingApprovalDietariesQuery,
    useAddDietaryMutation, useDeleteDietaryMutation, useSelectDietaryMutation, useUnselectDietaryMutation
} from '../../../hooks/apiSlice';


const DietarySelect = () => {

    const [newDietaryName, setNewDietaryName] = useState('')
    const [newDietaryLoading, setNewDietaryLoading] = useState(false)
    const [dietaryFilter, setDietaryFilter] = useState('')
    const [dietaryCache, setDietaryCache] = useState([])
    const [selectionCount, setSelectionCount] = useState(0)

    const { data: dataDietaries, isLoading: isLoadingDietaries } = useGetDietariesSelectionsByCompanyIdQuery()
    const { data: dataPendingDietaries, isLoading: isLoadingPendingDietaries } = useGetPendingApprovalDietariesQuery()

    const [addDietary] = useAddDietaryMutation()
    const [deleteDietary] = useDeleteDietaryMutation()
    const [unselectDietary] = useUnselectDietaryMutation()
    const [selectDietary] = useSelectDietaryMutation()

    const AddNewDietaryName = async (e) => {
        e.preventDefault();
        if (newDietaryName !== "") {
            if (dataDietaries.payload.some(item => newDietaryName.toUpperCase() === item.dietaryName.toUpperCase())) {
                toast.error(newDietaryName + ` already exists, Please select it from the list`, { position: toast.POSITION.TOP_CENTER });
            }
            else if (dataPendingDietaries.payload.some(item => newDietaryName.toUpperCase() === item.dietaryName.toUpperCase())) {
                toast.error(newDietaryName + ` already added`, { position: toast.POSITION.TOP_CENTER });
            }
            else {
                setNewDietaryLoading(true);
                const returned = await addDietary({ dietaryName: toPascal(newDietaryName) }).unwrap();
                setNewDietaryLoading(false);
                setNewDietaryName('');
            }
        }
    }

    useEffect(() => {
        if (!isLoadingDietaries) {
            setDietaryCache(dataDietaries.payload);
        }
    }, [isLoadingDietaries]);

    const toggleDietary = (e, id) => {
        if (e.target.checked)
            selectDietary({ dietaryId: parseInt(id) });
        else
            unselectDietary({ dietaryId: parseInt(id) })

        const currentIndex = dietaryCache.findIndex((todo) => todo.dietaryId === id);
        const updatedTodo = { ...dietaryCache[currentIndex], isSelected: e.target.checked };
        const newTodos = [
            ...dietaryCache.slice(0, currentIndex),
            updatedTodo,
            ...dietaryCache.slice(currentIndex + 1)
        ];
        setDietaryCache(newTodos);

        setSelectionCount(e.target.checked ? selectionCount + 1 : selectionCount - 1)
    }

    const applyFilter = (e) => {
        setDietaryFilter(e.target.value.toLowerCase());
    }


    useEffect(() => {
        if (isLoadingDietaries === false) {
            setSelectionCount(dataDietaries.payload.filter(item => item.isSelected === true).length)
        }
    }, [isLoadingDietaries])

    return (

        <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-4 border-top">
            <h4>Choose Dietary({selectionCount})</h4>
            <div className="mt-3 row">
                <div className="col-12 col-md-12">
                    <div className="input-group what-we-offer-search mb-3">
                        <div className="form-outline">
                            <input type="search" id="search" placeholder="Type event" className="form-control" onChange={(e) => applyFilter(e)} />
                        </div>
                        <button type="button" className="btn">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div className="horizontal-scroll-bar">
                        {isLoadingDietaries ? <h6>Loading...</h6> :
                            dietaryCache.filter((m) => m.dietaryName.toLowerCase().includes(dietaryFilter) || dietaryFilter === '').map((spc, index) =>
                                <div key={index} >
                                    <label htmlFor={'dietary' + index} className="form-check-label">
                                        <input className="form-check-input ps-2" type="checkbox" checked={spc.isSelected} onChange={(e)=>toggleDietary(e, spc.dietaryId)} id={'dietary' + index}  />&nbsp;&nbsp;{spc.dietaryName}                                        
                                    </label>
                                </div>)
                        }
                    </div>
                </div>

                {/* <div className="col-12 col-md-6">
                    <div>
                        <div className="my-2">
                            <h6>Pending Requests:</h6>
                            {isLoadingPendingDietaries ? "Loading..." :
                                dataPendingDietaries.payload.map((spec, index) => <div key={index} className="tag-item" >
                                    <span className="text">{spec.dietaryName}</span>&nbsp;
                                    <span className="itemClose" onClick={() => { deleteDietary({ dietaryId: spec.dietaryId }) }}><i className="fa-solid fa-circle-xmark"></i></span>
                                </div>)}

                        </div>
                        <div className="alert alert-light d-flex p-2" role="alert">
                            <i className="fa-sharp fa-solid fa-circle-info ms-1 me-2"></i>
                            <div className="text-dark tiny-text">If you do not find specific event type in your search scroll please add it below, it will start appear in your public profile after administrator approval.</div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col">
                                    <input type="text" value={newDietaryName} onChange={(e) => setNewDietaryName(e.target.value)} className="form-control" placeholder="Dietary" maxLength="50"></input>
                                </div>
                                <div className="col-auto align-self-center">
                                    <button className="btn btn-danger mt-2" onClick={AddNewDietaryName} type="submit">
                                        {newDietaryLoading ? <span className="spinner-grow spinner-grow-sm"></span> :
                                            <i className="fa-sharp fa-solid fa-plus"></i>}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div> */}
            </div>
        </div>
    )
}

export default DietarySelect

