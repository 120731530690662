import Navbar from "../Navbar";
import Footer from '../Footer';
import { ToastContainer } from 'react-toastify';
import { useReducer, useEffect } from "react";
import CartReducer from "../CompanyPanel/CartContext";
import { useNavigate } from "react-router-dom";


const ShoppingCart = () => {

    const [cartState, dispatch] = useReducer(CartReducer, []);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch({ type: "GET-CART" });
    }, [])



    return (
        <div>
            <div>
                <ToastContainer />
            </div>
            <Navbar />

            <main role="main">
                <section className="pt-4 pb-4 mb-5">
                    <div className="container">
                        <div className="row mb-0">
                            <div className="col-12">
                                <h2 className="standardHeading m-0 p-0">Shopping Cart</h2>
                                {(cartState === null || cartState.length === 0) ? <div className="row">Your cart is empty</div>
                                    :

                                    <div>
                                        {cartState.map((crtItem, index) => <div className="m-4 row border" key={index}>
                                            <div className="col">
                                                {crtItem.subscriptionTypeId}-{crtItem.productName}
                                                {crtItem.productDetail.map((productDet, index) => <div key={index} className="row border">
                                                    <div className="col-4">
                                                        {productDet.title}-{productDet.uniqueId}

                                                        {productDet.imageData && <img src={productDet.imageData} alt="Banner Ad image"></img>}



                                                    </div>
                                                    <div className="my-1 col-4">

                                                        {crtItem.subscriptionTypeId === 4 ? <>{productDet.offers[0].offer} ${productDet.offers[0].targetAudiences[0].price} / {productDet.offers[0].targetAudiences[0].subscriptionPlanName}</> :
                                                            <>
                                                                {productDet.offers.map((offer, index) => <div key={index} className="row border">
                                                                    <div className="col">
                                                                        <strong>{offer.offer}</strong>
                                                                        <div className="m-2"> {offer.targetAudiences.map((ta, index) => <div key={index} className="row border">{ta.targetAudienceId + ' | ' + ta.offerName + ' | ' + ta.stateName + ' | $' + ta.price + ' | ' + ta.amount + ' | ' + ta.subscriptionPlanName + ' | ' + ta.productPlanId}</div>)}</div>
                                                                    </div>
                                                                </div>)}
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="col-4"><i onClick={() => { dispatch({ type: "DEL-FROM-CART", payload: productDet.uniqueId }); dispatch({ type: "GET-CART" }); }} className="fa-sharp fa-solid fa-trash" ></i></div>
                                                </div>)}
                                            </div></div>)}
                                        <div className="row mb-0">
                                            <button className="btn btn-danger mt-2" onClick={(e) => { navigate(`/checkout`) }}>
                                                Complete Purchase {cartState.length > 0 && ' | $' + cartState.map(crt => crt.price).reduce((a, b) => a + b, 0).toString() + '/-'}
                                            </button>
                                        </div>

                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </section>
                <Footer></Footer>
            </main>
        </div>
    )
}

export default ShoppingCart