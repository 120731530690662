import { toast } from 'react-toastify';
import CartReducer from "../CompanyPanel/CartContext";
import React, { useState, useReducer, useEffect } from "react";
import {
    useGetEventTypesQuery, useGetCuisinesQuery, useGetStatesQuery, useGetSubscriptionQuery,
    useGetProductPricesQuery, useGetSpecialitiesQuery, useGetPaidListingSubscriptionDetailQuery, useGetSubscriptionByCompanyQuery
} from './../../hooks/apiSlice';
import { structureCart } from './../../hooks/util';
import moment from 'moment'
import Modal from 'react-bootstrap/Modal';
import AuthService from "./../../hooks/Auth";

const PackageSelection = ({ onCartItemChange2 }) => {

    const { data: dataPackagePrices, isLoading: isLoadingPackagePrices } = useGetProductPricesQuery({ offer: 'DELUX', productId: 4 })

    const [selectedPlan, setSelectedPlan] = useState('');
    const [cartState, dispatch] = useReducer(CartReducer, []);

    useEffect(() => {
        setSelectedPlan(AuthService.getProductPlanId());
    }, [])

    const addToCart = async (e) => {
        var selectedItem = dataPackagePrices.filter((item) => item.productPlanId === parseInt(selectedPlan))[0];

        let cart = {
            subscriptionTypeId: 4, productName: 'Package', productDetail: [{
                uniqueId: Date.now().toString(36), title: '', imageData: '', startDate: '', endDate: '', offers:
                    structureCart([{ id: Date.now().toString(36), offer: selectedItem.productPlanName, offerId: 0, offerName: selectedItem.productPlanName, stateId: 0, stateName: 'none', productPlanId: selectedItem.productPlanId, subscriptionPlanName: selectedItem.subscriptionPlanName, productPriceId: parseInt(selectedItem.productPriceId), price: selectedItem.price, targetAudienceId: 0 }])
            }]
        }

        dispatch({ type: "Add-TO-CART", payload: cart });
        setTimeout(() => {
            onCartItemChange2();
        }, 500)
    }


    return (
        <>
            <div className="row">
                <div className="col-6">
                    &nbsp;
                </div>
                <div className="col-2">
                    FREE
                </div>
                <div className="col-2">
                    {isLoadingPackagePrices ? "loading..." :
                        <div className="row">
                            <b>{dataPackagePrices[0].productPlanName}</b>
                            <div className="form-check">
                                <input type="radio" className="form-check-input rdo" id="DELM" name="PackagePlanSelectg" checked={selectedPlan === '16'} value="16" onChange={(e) => setSelectedPlan(e.target.value)} />
                                <label className="form-check-label" htmlFor="DELM" >{dataPackagePrices[0].subscriptionPlanName}:${dataPackagePrices[0].price}</label>
                            </div>
                            <div className="form-check">
                                <input type="radio" className="form-check-input rdo" id="DELY" name="PackagePlanSelectg" checked={selectedPlan === '17'} value="17" onChange={(e) => setSelectedPlan(e.target.value)} />
                                <label className="form-check-label" htmlFor="DELY" >{dataPackagePrices[1].subscriptionPlanName}:${dataPackagePrices[1].price}</label>
                            </div>
                        </div>
                    }
                </div>
                <div className="col-2">
                    {isLoadingPackagePrices ? "loading..." :
                        <div className="row">
                            <b>{dataPackagePrices[2].productPlanName}</b>
                            <div className="form-check">
                                {dataPackagePrices[2].productPlanId.toString()}
                                <input type="radio" className="form-check-input rdo" id="STDM" name="PackagePlanSelectg" checked={selectedPlan === '18'} value="18" onChange={(e) => setSelectedPlan(e.target.value)} />
                                <label className="form-check-label" htmlFor="STDM" >{dataPackagePrices[2].subscriptionPlanName}:${dataPackagePrices[2].price}</label>
                            </div>
                            <div className="form-check">
                                <input type="radio" className="form-check-input rdo" id="STDY" name="PackagePlanSelectg" checked={selectedPlan === '19'} value="19" onChange={(e) => setSelectedPlan(e.target.value)} />
                                <label className="form-check-label" htmlFor="STDY" >{dataPackagePrices[3].subscriptionPlanName}:${dataPackagePrices[3].price}</label>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    Advanced Performance Tracking
                </div>
                <div className="col-2">
                    [Yes/No]
                </div>
                <div className="col-2">
                    [Yes/No]
                </div>
                <div className="col-2">
                    [Yes/No]
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-6">
                </div>
                <div className="col-2">
                </div>
                <div className="col-2">
                    <button className="btn btn-danger btn-sm py-0 mx-1" disabled={!(selectedPlan === '16' || selectedPlan === '17')} onClick={() => {
                        addToCart();
                    }}>Upgrade</button>
                </div>
                <div className="col-2">
                    <button className="btn btn-danger btn-sm py-0 mx-1" disabled={!(selectedPlan === '18' || selectedPlan === '19')} onClick={() => {
                        addToCart();
                    }}>Upgrade</button>
                </div>
            </div>
        </>
    )
}

const PaidListingList = () => {

    const [includeActive, setIncludeActive] = useState(true);
    const [includeExpired, setIncludeExpired] = useState(false);

    const { data: dataSubscriptionDetail, isLoading: isLoadingSubscriptionDetail } = useGetPaidListingSubscriptionDetailQuery({ includeActive: includeActive, includeExpired: includeExpired })

    return (
        <div>
            <div className="row">
                <div className="row align-items-center">
                    <div className="col-6">
                        <input type="checkbox" checked={includeActive} id="showExpiredPaidListing" onChange={(e) => setIncludeActive(e.target.checked)}></input>
                        <label className="form-check-label ps-1" htmlFor="showExpiredPaidListing">Active</label>

                        <input type="checkbox" checked={includeExpired} id="showExpired2PaidListing" onChange={(e) => setIncludeExpired(e.target.checked)}></input>
                        <label className="form-check-label ps-1" htmlFor="showExpired2PaidListing">Expired</label>
                    </div>
                </div>
                <br />                <br />
                <div className="row m-0">
                    {isLoadingSubscriptionDetail ? "Loading" :
                        dataSubscriptionDetail.payload.length === 0 ? <div> You do not have any Paid Listing Subscription </div> :
                            <div className="row m-0">
                                <div className="col">
                                    {dataSubscriptionDetail.payload.length}
                                </div>
                                <div className="col-12">
                                    <div>
                                        <div className="row overflow-auto" style={{ 'height': '200px' }}>
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Description</th>
                                                        <th>Plan</th>
                                                        <th>Expiry</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        dataSubscriptionDetail.payload.map((sub, index) =>
                                                            <tr key={index}>
                                                                <td className="align-middle">{sub.targetAudience.offer} | {sub.targetAudience.offerName} | {sub.targetAudience.state.stateName}</td>
                                                                <td className="align-middle">{sub.productPlan.productPlanName} - {sub.productPlan.duration}</td>
                                                                <td className="align-middle">{moment(sub.expiry).format("MMM DD YYYY")}</td>
                                                                <td className="align-middle" width={90}>
                                                                    <button className="btn btn-danger btn-sm py-0 mx-1" style={{ 'fontSize': '.7rem' }} onClick={() => {
                                                                        alert('business rule in-progress')
                                                                    }}>Renew</button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}


const CreatePaidListing = ({ onCartItemChange2 }) => {

    const [eventTypeOfferId, setEventTypeOfferId] = useState('0');
    const [eventTypeStateId, setEventTypeStateId] = useState('0');

    const [cuisineOfferId, setCuisineOfferId] = useState('0');
    const [cuisineStateId, setCuisineStateId] = useState('0');

    const [specialtyOfferId, setSpecialtyOfferId] = useState('0');
    const [specialtyStateId, setSpecialtyStateId] = useState('0');

    const { data: dataEventTypes, isLoading: isLoadingEventTypes } = useGetEventTypesQuery()
    const { data: dataStates, isLoading: isLoadingStates } = useGetStatesQuery()
    const { data: dataCuisines, isLoading: isLoadingCuisines } = useGetCuisinesQuery()
    const { data: dataSpecialty, isLoading: isLoadingSpecialty } = useGetSpecialitiesQuery()

    const { data: dataEventTypePrices, isLoading: isLoadingEventTypePrices } = useGetProductPricesQuery({ offer: 'EventType', offerId: eventTypeOfferId, stateId: eventTypeStateId, productId: 3 })
    const { data: dataCuisinePrices, isLoading: isLoadingCuisinePrices } = useGetProductPricesQuery({ offer: 'Cuisine', offerId: cuisineOfferId, stateId: cuisineStateId, productId: 3 })
    const { data: dataSpecialtyPrices, isLoading: isLoadingSpecialtyPrices } = useGetProductPricesQuery({ offer: 'Speciality', offerId: specialtyOfferId, stateId: specialtyStateId, productId: 3 })

    const [addTargetAud, setAddTargetAud] = useState([]);
    const [cartState, dispatch] = useReducer(CartReducer, []);

    const handleAddTargetAud = (event, offerName) => {
        event.preventDefault();

        if (addTargetAud.filter((item) => item.offer === offerName && item.offerId === event.target[0].value && item.stateId === event.target[1].value).length > 0)
            toast.error("You already added that item", { position: toast.POSITION.TOP_CENTER });
        else {
            var selectedItem;
            if (offerName === "EventType") {
                selectedItem = dataEventTypePrices.filter((item) => item.productPriceId === parseInt(event.target['ProductPriceId'].value))[0];
                setEventTypeOfferId('0');
                setEventTypeStateId('0');
            }
            else if (offerName === "Cuisine") {
                selectedItem = dataCuisinePrices.filter((item) => item.productPriceId === parseInt(event.target['ProductPriceId'].value))[0];
                setCuisineOfferId('0');
                setCuisineStateId('0');
            }
            else if (offerName === "Specialty") {
                selectedItem = dataSpecialtyPrices.filter((item) => item.productPriceId === parseInt(event.target['ProductPriceId'].value))[0];
                setSpecialtyOfferId('0');
                setSpecialtyStateId('0');
            }

            setAddTargetAud((crt) => [{ id: Date.now().toString(36), offer: offerName, offerId: event.target[0].value, offerName: event.target[0].options[event.target[0].selectedIndex].text, stateId: event.target[1].value, stateName: event.target[1].options[event.target[1].selectedIndex].text, productPlanId: selectedItem.productPlanId, subscriptionPlanName: selectedItem.subscriptionPlanName, productPriceId: parseInt(event.target['ProductPriceId'].value), price: selectedItem.price, targetAudienceId: selectedItem.targetAudienceId }, ...crt]);
            event.target.querySelectorAll('button')[0].disabled = true;
        }
    }

    const handleDeleteTargetAud = (id) => {
        const newArray = addTargetAud.filter((item) => item.id !== id);
        setAddTargetAud(newArray);
    };

    const Validate = (e) => {
        if (e.target.form.querySelectorAll('select')[0].value === "0")
            e.target.form.querySelectorAll('select')[0].classList.add("border-danger");
        else
            e.target.form.querySelectorAll('select')[0].classList.remove("border-danger");

        if (e.target.form.querySelectorAll('select')[1].value === "0")
            e.target.form.querySelectorAll('select')[1].classList.add("border-danger");
        else
            e.target.form.querySelectorAll('select')[1].classList.remove("border-danger");

        if (e.target.form.querySelector('input[name="ProductPriceId"]:checked') === null)
            e.target.form.querySelectorAll('.col')[1].classList.add("border-danger");
        else
            e.target.form.querySelectorAll('.col')[1].classList.remove("border-danger");


        if ((e.target.form.querySelectorAll('select')[0].value !== "0") &&
            (e.target.form.querySelectorAll('select')[1].value !== "0") &&
            (e.target.form.querySelector('input[name="ProductPriceId"]:checked') !== null)) {
            e.target.form.querySelectorAll('button')[0].disabled = false;
        }
        else
            e.target.form.querySelectorAll('button')[0].disabled = true;
    };

    const addToCart = (e) => {
        let cart = {
            subscriptionTypeId: 3, productName: 'ListingAd', productDetail: [{
                uniqueId: Date.now().toString(36), title: '', imageFileName: '', offers:
                    structureCart(addTargetAud)
            }]
        }

        dispatch({ type: "Add-TO-CART", payload: cart });
        setTimeout(() => {
            onCartItemChange2();
        }, 500)
    }

    const totals = (offerName) => {
        var totals = (addTargetAud.filter((item) => item.offer === offerName).length > 0) && addTargetAud.filter((item) => item.offer === offerName).length.toString() + ' / $' + addTargetAud.filter((item) => item.offer === offerName).map(movie => movie.price).reduce((a, b) => a + b, 0).toString();
        return (
            <>
                {totals}
            </>
        )
    }


    return (
        <>
            <div>
                <div className="my-5">
                    <h4>Event Type {totals('EventType')}</h4>
                    <form onSubmit={(e) => handleAddTargetAud(e, "EventType")}>
                        <div className="row">
                            <div className="col">
                                {isLoadingEventTypes ? <h1>loading...</h1> :
                                    <select className="form-control" name="event" value={eventTypeOfferId} onChange={(e) => { setEventTypeOfferId(e.target.value); Validate(e); }}>
                                        <option value="0" >Select Event Type</option>
                                        {
                                            dataEventTypes.payload.map((et, index) =>
                                                <option key={index} value={et.eventTypeId}>{et.eventTypeName}</option>
                                            )
                                        }
                                    </select>
                                }

                                {isLoadingStates ? <h1>loading...</h1> :
                                    <select className="form-control" name="eventState" value={eventTypeStateId} onChange={(e) => {
                                        setEventTypeStateId(e.target.value); Validate(e);
                                    }}>
                                        <option value="0">Select State</option>
                                        {
                                            dataStates.payload.map((st, index) =>
                                                <option key={index} value={st.stateId} >{st.stateName}</option>
                                            )
                                        }
                                    </select>}
                            </div>
                            <div className="col" style={{ border: '1px solid #FFFFFF' }}>
                                {isLoadingEventTypePrices ? <h1>loading...</h1> :
                                    <div className="row">
                                        <div className="col">
                                            <b>{dataEventTypePrices[0].productPlanName}</b>
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="EBM" name="ProductPriceId" data-key={dataEventTypePrices[0].price} value={dataEventTypePrices[0].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="EBM" >{dataEventTypePrices[0].subscriptionPlanName}:${dataEventTypePrices[0].price}</label>
                                            </div>

                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="EBY" name="ProductPriceId" data-key={dataEventTypePrices[1].price} value={dataEventTypePrices[1].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="EBY" >{dataEventTypePrices[1].subscriptionPlanName}:${dataEventTypePrices[1].price}</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <b>{dataEventTypePrices[2].productPlanName}</b>
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="ESM" name="ProductPriceId" data-key={dataEventTypePrices[2].price} value={dataEventTypePrices[2].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="ESM" >{dataEventTypePrices[2].subscriptionPlanName}:${dataEventTypePrices[2].price}</label>
                                            </div>

                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="ESY" name="ProductPriceId" data-key={dataEventTypePrices[3].price} value={dataEventTypePrices[3].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="ESY" >{dataEventTypePrices[3].subscriptionPlanName}:${dataEventTypePrices[3].price}</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <b>{dataEventTypePrices[4].productPlanName}</b>
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="EPM" name="ProductPriceId" data-key={dataEventTypePrices[4].price} value={dataEventTypePrices[4].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="EPM" >{dataEventTypePrices[4].subscriptionPlanName}:${dataEventTypePrices[4].price}</label>
                                            </div>

                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="EPY" name="ProductPriceId" data-key={dataEventTypePrices[5].price} value={dataEventTypePrices[5].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="EPY" >{dataEventTypePrices[5].subscriptionPlanName}:${dataEventTypePrices[5].price}</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-danger mt-2" type="submit" disabled>
                                    <i className="fa-sharp fa-solid fa-plus"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        {addTargetAud.filter((item, index) => item.offer === "EventType").map((cu, index) => <span key={index}>{cu.id}  |  {cu.offerId}-{cu.offerName}-{cu.offer}  |  {cu.stateId}-{cu.stateName}  |  {cu.productPlanId}-{cu.subscriptionPlanName}  | {cu.price}  <i className="fa-sharp fa-solid fa-trash" onClick={() => { handleDeleteTargetAud(cu.id) }}></i></span>)}
                    </div>
                </div>

                <div className="my-5">
                    <h4>Cuisine {totals('Cuisine')}</h4>
                    <form onSubmit={(e) => handleAddTargetAud(e, "Cuisine")}>
                        <div className="row">
                            <div className="col">
                                {isLoadingCuisines ? <h1>loading...</h1> :
                                    <select className="form-control" name="cuisine" value={cuisineOfferId} onChange={(e) => { setCuisineOfferId(e.target.value); Validate(e); }}>
                                        <option value="0" >Select Cuisine</option>
                                        {
                                            dataCuisines.payload.map((cu, index) =>
                                                <option key={index} text={cu.cuisineName} value={cu.cuisineId}>{cu.cuisineName}</option>
                                            )
                                        }
                                    </select>
                                }

                                {isLoadingStates ? <h1>loading...</h1> :
                                    <select className="form-control" name="cuisineState" value={cuisineStateId} onChange={(e) => {
                                        setCuisineStateId(e.target.value); Validate(e);
                                    }}>
                                        <option value="0">Select State</option>
                                        {
                                            dataStates.payload.map((st, index) =>
                                                <option key={index} value={st.stateId} >{st.stateName}</option>
                                            )
                                        }
                                    </select>}
                            </div>
                            <div className="col" style={{ border: '1px solid #FFFFFF' }}>
                                {isLoadingCuisinePrices ? <h1>loading...</h1> :
                                    <div className="row">
                                        <div className="col">
                                            <b>{dataCuisinePrices[0].productPlanName}</b>
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="CBM" name="ProductPriceId" data-key={dataCuisinePrices[0].price} value={dataCuisinePrices[0].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="CBM" >{dataCuisinePrices[0].subscriptionPlanName}:${dataCuisinePrices[0].price}</label>
                                            </div>

                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="CBY" name="ProductPriceId" data-key={dataCuisinePrices[1].price} value={dataCuisinePrices[1].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="CBY" >{dataCuisinePrices[1].subscriptionPlanName}:${dataCuisinePrices[1].price}</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <b>{dataCuisinePrices[2].productPlanName}</b>
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="CSM" name="ProductPriceId" data-key={dataCuisinePrices[2].price} value={dataCuisinePrices[2].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="CSM" >{dataCuisinePrices[2].subscriptionPlanName}:${dataCuisinePrices[2].price}</label>
                                            </div>

                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="CSY" name="ProductPriceId" data-key={dataCuisinePrices[3].price} value={dataCuisinePrices[3].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="CSY" >{dataCuisinePrices[3].subscriptionPlanName}:${dataCuisinePrices[3].price}</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <b>{dataCuisinePrices[4].productPlanName}</b>
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="CPM" name="ProductPriceId" data-key={dataCuisinePrices[4].price} value={dataCuisinePrices[4].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="CPM" >{dataCuisinePrices[4].subscriptionPlanName}:${dataCuisinePrices[4].price}</label>
                                            </div>

                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="CPY" name="ProductPriceId" data-key={dataCuisinePrices[5].price} value={dataCuisinePrices[5].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="CPY" >{dataCuisinePrices[5].subscriptionPlanName}:${dataCuisinePrices[5].price}</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-danger mt-2" type="submit" disabled>
                                    <i className="fa-sharp fa-solid fa-plus"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        {addTargetAud.filter((item) => item.offer === "Cuisine").map((cu, index) => <span key={index}>{cu.id}  |  {cu.offerId}-{cu.offerName}-{cu.offer}  |  {cu.stateId}-{cu.stateName}  |  {cu.productPlanId}-{cu.subscriptionPlanName}  | {cu.price}  <i className="fa-sharp fa-solid fa-trash" onClick={() => { handleDeleteTargetAud(cu.id) }}></i></span>)}
                    </div>
                </div>


                <div className="my-5">
                    <h4>Specialty {totals('Specialty')}</h4>
                    <form onSubmit={(e) => handleAddTargetAud(e, "Specialty")}>
                        <div className="row">
                            <div className="col">
                                {isLoadingSpecialty ? <h1>loading...</h1> :
                                    <select className="form-control" name="specialty" value={specialtyOfferId} onChange={(e) => { setSpecialtyOfferId(e.target.value); Validate(e); }}>
                                        <option value="0" >Select Specialty</option>
                                        {
                                            dataSpecialty.payload.map((cu, index) =>
                                                <option key={index} text={cu.specialityName} value={cu.specialityId}>{cu.specialityName}</option>
                                            )
                                        }
                                    </select>
                                }

                                {isLoadingSpecialty ? <h1>loading...</h1> :
                                    <select className="form-control" name="specialtyState" value={specialtyStateId} onChange={(e) => {
                                        setSpecialtyStateId(e.target.value); Validate(e);
                                    }}>
                                        <option value="0">Select State</option>
                                        {
                                            dataStates.payload.map((st, index) =>
                                                <option key={index} value={st.stateId} >{st.stateName}</option>
                                            )
                                        }
                                    </select>}
                            </div>
                            <div className="col" style={{ border: '1px solid #FFFFFF' }}>
                                {isLoadingSpecialtyPrices ? <h1>loading...</h1> :
                                    <div className="row">
                                        <div className="col">
                                            <b>{dataSpecialtyPrices[0].productPlanName}</b>
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="SBM" name="ProductPriceId" data-key={dataSpecialtyPrices[0].price} value={dataSpecialtyPrices[0].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="SBM" >{dataSpecialtyPrices[0].subscriptionPlanName}:${dataSpecialtyPrices[0].price}</label>
                                            </div>

                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="SBY" name="ProductPriceId" data-key={dataSpecialtyPrices[1].price} value={dataSpecialtyPrices[1].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="SBY" >{dataSpecialtyPrices[1].subscriptionPlanName}:${dataSpecialtyPrices[1].price}</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <b>{dataSpecialtyPrices[2].productPlanName}</b>
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="SSM" name="ProductPriceId" data-key={dataSpecialtyPrices[2].price} value={dataSpecialtyPrices[2].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="SSM" >{dataSpecialtyPrices[2].subscriptionPlanName}:${dataSpecialtyPrices[2].price}</label>
                                            </div>

                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="SSY" name="ProductPriceId" data-key={dataSpecialtyPrices[3].price} value={dataSpecialtyPrices[3].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="SSY" >{dataSpecialtyPrices[3].subscriptionPlanName}:${dataSpecialtyPrices[3].price}</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <b>{dataSpecialtyPrices[4].productPlanName}</b>
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="SPM" name="ProductPriceId" data-key={dataSpecialtyPrices[4].price} value={dataSpecialtyPrices[4].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="SPM" >{dataSpecialtyPrices[4].subscriptionPlanName}:${dataSpecialtyPrices[4].price}</label>
                                            </div>

                                            <div className="form-check">
                                                <input type="radio" className="form-check-input rdo" id="SPY" name="ProductPriceId" data-key={dataSpecialtyPrices[5].price} value={dataSpecialtyPrices[5].productPriceId} onChange={(e) => { Validate(e); }} />
                                                <label className="form-check-label" htmlFor="SPY" >{dataSpecialtyPrices[5].subscriptionPlanName}:${dataSpecialtyPrices[5].price}</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-danger mt-2" type="submit" disabled>
                                    <i className="fa-sharp fa-solid fa-plus"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        {addTargetAud.filter((item) => item.offer === "Specialty").map((cu, index) => <span key={index}>{cu.id}  |  {cu.offerId}-{cu.offerName}-{cu.offer}  |  {cu.stateId}-{cu.stateName}  |  {cu.productPlanId}-{cu.subscriptionPlanName}  | {cu.price}  <i className="fa-sharp fa-solid fa-trash" onClick={() => { handleDeleteTargetAud(cu.id) }}></i></span>)}
                    </div>
                </div>


                <div className="m-2">
                    <button className="btn btn-danger mt-2" onClick={(e) => { addToCart(e) }}>
                        Add to Cart {addTargetAud.length > 0 && ' | $' + addTargetAud.map(crt => crt.price).reduce((a, b) => a + b, 0).toString() + '/-'}
                    </button>
                </div>
            </div>
        </>
    )

}


const Invoices = () => {

    const { data: dataPayment, isLoading: isLoadingPayment } = useGetSubscriptionByCompanyQuery();

    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [skipToken, setSkipToken] = useState(true)

    const ModalPopup = () => {

        const { data: dataSubscription, isLoading: isLoadingSubscription } = useGetSubscriptionQuery(selectedSubscriptionId, { skip: skipToken });

        return (
            <>
                <Modal size="lg" show={showModal} >
                    <Modal.Header>
                        <Modal.Title>

                        </Modal.Title>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                            setShowModal(false)
                        }}></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-2*">
                                    <>
                                        {!isLoadingSubscription && selectedSubscriptionId !== 0 && <>
                                            <div className="row">
                                                <div className="col-6">
                                                    <img src="/images/logo.png" alt="Halal Caters" />
                                                </div>
                                                <div className="col-6">
                                                    <h1 className="bg-dark text-light">INVOICE</h1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 mt-2"><b>
                                                    Invoice Number: {("00000" + dataSubscription.payload.subscriptionId).substr(("00000" + dataSubscription.payload.subscriptionId).length - 5)}
                                                    <br />
                                                    Date: {moment(dataSubscription.payload.subscriptionDate).format("MMMM DD YYYY")}
                                                    <br />
                                                    Product: Paid Listing
                                                </b>
                                                </div>
                                                <div>

                                                </div>
                                            </div>

                                            <hr />

                                            <div className="row">
                                                <div className="col-6">
                                                </div>
                                                <div className="col-6">
                                                    Bill to: <br />
                                                    {dataSubscription.payload.company.companyName}
                                                    {dataSubscription.payload.company.addresses[0].streetAddress}, {dataSubscription.payload.company.addresses[0].city}, {dataSubscription.payload.company.addresses[0].state.stateName}, {dataSubscription.payload.company.addresses[0].zipCode}<br />
                                                </div>
                                            </div>

                                            <hr />

                                            <div className="row">
                                                {dataSubscription.payload.subscriptionDetails.map((sd, index) =>
                                                    <div key={index} className="row">
                                                        <div className="col">
                                                            {sd.targetAudience.offer} - {sd.targetAudience.offerName} - {sd.targetAudience.state.stateName} / {sd.productPlan.duration}
                                                        </div>
                                                        <div className="col-auto">
                                                            ${sd.price}
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="row mt-2">
                                                    <div className="col">
                                                        <b>Total</b>
                                                    </div>
                                                    <div className="col-auto">
                                                        <b>${dataSubscription.payload.amount}</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        }
                                    </>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-danger" onClick={() => {
                            setShowModal(false)
                        }}>Close</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }




    return (
        <>
            <ModalPopup />
            {!isLoadingPayment &&
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Mode of Payment</th>
                            <th>Amount</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataPayment.payload.map((sub, index) =>
                            <tr key={index}>
                                <td>{moment(sub.payment.paymentDate).format("MMM DD YYYY")}</td>
                                <td>{sub.payment.paymentOption.paymentOptionName}</td>
                                <td>${sub.payment.amount}</td>
                                <td>
                                    <button className="btn btn-danger btn-sm py-0 mx-1" style={{ 'fontSize': '.7rem' }} onClick={() => {
                                        setSkipToken(false);
                                        setSelectedSubscriptionId(sub.subscriptionId);
                                        setShowModal(true);
                                    }}>View Invoice</button></td>
                            </tr>)}
                    </tbody>
                </table>
            }
        </>
    )
}


const PaidListing = ({ onCartItemChange }) => {




    return (
        <div>
            <div className="signin-up-wrapper">
                <h3>
                    <strong>&nbsp;Paid Listing</strong>
                </h3>
            </div>
            <div className="row">
                <PackageSelection onCartItemChange2={onCartItemChange} />
            </div>
            <div className="row">
                <PaidListingList />
            </div>
            <div className="row">
                <CreatePaidListing onCartItemChange2={onCartItemChange} />
            </div>
            <div className="row">
                <Invoices />
            </div>
        </div>
    )
}

export default PaidListing



