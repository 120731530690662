import { toast } from 'react-toastify';
import CartReducer from "../CompanyPanel/CartContext";
import React, { useState, useReducer } from "react";
import {
    useGetEventTypesQuery, useGetCuisinesQuery, useGetStatesQuery,
    useGetProductPricesQuery, useGetSpecialitiesQuery, useGetSubscriptionsQuery,
    useGetSubscriptionDetailQuery, useUpdateSubscriptionMutation
} from './../../hooks/apiSlice';
import { readUploadedFileDataURL, valideUrl, structureCart } from './../../hooks/util';
import moment from 'moment'
import Modal from 'react-bootstrap/Modal';
import BoostInvoice  from './../../shared/BoostInvoice';


const ModifyDetail = (props) => {

    const [title, setTitle] = useState(props.sub.title);
    const [currentFile, setCurrentFile] = useState(props.sub.imageFileName);
    const [previewImage, setPreviewImage] = useState(undefined);

    const [campState, setCampState] = useState(props.sub.subscriptionStatusId);

    const [updateSubscription] = useUpdateSubscriptionMutation()

    const selectFile = (event) => {
        if (event.target.files[0].size > 1048576)
            toast.error(`Image file should not exceeds 1MB in size`, { position: toast.POSITION.TOP_CENTER });
        else {
            setCurrentFile(event.target.files[0]);
            setPreviewImage(URL.createObjectURL(event.target.files[0]));
        }
    };



    return (
        <div className="row ">
            <div className="col-12 mt-3">
                <label htmlFor="txtTitle">Title</label>
                <input type="text" id="txtTitle" name="txtTitle" maxLength={60} value={title} onChange={(e) => setTitle(e.target.value)} className={title === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} />
            </div>
            <div className="col-12 mt-3 d-flex align-items-center gap-2">
                <div className={previewImage === undefined ? "border border-danger" : ""}>
                    <div className="px-4">
                        <label>Upload Image (Max 20MB | Dimension 180 x 240)</label>
                        <div className="row p-1">
                            <div><input type="file" name="txtTitle" className="py-1" onChange={(e) => selectFile(e)} accept="image/x-png,image/gif,image/jpeg" /></div>
                            {previewImage !== undefined ?
                                <div className="d-flex align-items-center justify-content-center my-2" style={{ 'border': 'dotted 4px', 'borderRadius': '12px', 'padding': '4px' }} >
                                    <img src={previewImage} alt="" style={{ maxHeight: '120px' }} />
                                </div>
                                :
                                currentFile !== '' &&
                                <div className="d-flex align-items-center justify-content-center my-2" style={{ 'border': 'dotted 4px', 'borderRadius': '12px', 'padding': '4px' }} >
                                    <img src={process.env.REACT_APP_BASE_URL + `MediaStorage/BannerAd/` + currentFile} alt="" style={{ maxHeight: '120px' }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-3">
                <label htmlFor="txtTitle">State</label>
                <select value={campState}
                    className="combobox dropdown"
                    onChange={(e) => setCampState(e.target.value)}>
                    <option value="1">Pending</option>
                    <option value="2">Live</option>
                    <option value="3">Onhold</option>
                </select>
            </div>
            <div className="col-12 mt-3">
                <button type="submit" className="btn btn-danger" onClick={() => {
                    let formData = new FormData();
                    formData.append("file", currentFile);
                    updateSubscription({ file: formData, subscriptionId: props.sub.subscriptionId, title: title, subscriptionStatusId: campState, subscriptionTypeId: 2 })
                    props.onStateChange();
                }}>Save</button>
            </div>
        </div>
    )
}


const TargetAudience = (props) => {

    const [subscriptionId, setSubscriptionId] = useState(props.sub.subscriptionId);
    const { data: dataSubscriptionDetail, isLoading: isLoadingSubscriptionDetail } = useGetSubscriptionDetailQuery(subscriptionId);

    return (
        <>
            {!isLoadingSubscriptionDetail &&
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Offer Type</th>
                            <th>Offer Name | State</th>
                            <th>Period / Price</th>
                            <th>Expiry</th>
                            <th>Impressions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataSubscriptionDetail.payload.map((sub, index) =>
                            <tr key={index}>
                                <td>{sub.targetAudience.offer}</td>
                                <td>{sub.targetAudience.offerName} | {sub.targetAudience.state.stateName}</td>
                                <td>{sub.productPlan.duration} / ${sub.price}</td>
                                <td>{sub.productPlan.subscriptionPlan === 1 ? new Date(new Date(props.sub.startDate).setMonth(new Date(props.sub.startDate).getMonth() + 1) - 1).toLocaleDateString() : new Date(new Date(props.sub.startDate).setFullYear(new Date(props.sub.startDate).getFullYear() + 1) - 1).toLocaleDateString()}</td>
                                <td>{parseInt(Math.random() * (100 - 10) + 10)}</td>
                            </tr>)}
                    </tbody>
                </table>
            }
        </>
    )
}



const BoostListing = () => {

    const [includeActive, setIncludeActive] = useState(true);
    const [includeExpired, setIncludeExpired] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [selectedSubscription, setSelectedSubscription] = useState();

    const { data: dataSubscription, isLoading: isLoadingSubscription } = useGetSubscriptionsQuery({ subscriptionTypeId: 1, includeActive: includeActive, includeExpired: includeExpired });


    const ModalPopup = () => {

        const handleStateChange = (value) => {
            setShowModal(value);
        };

        return (
            <>
                <Modal size="lg" show={showModal} >
                    <Modal.Header>
                        <Modal.Title>

                        </Modal.Title>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                            setShowModal(false)
                        }}></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-2*">
                                    {modalContent === 'Modify Detail' ? <ModifyDetail sub={selectedSubscription} onStateChange={handleStateChange} /> : modalContent === 'Invoice' ? <BoostInvoice sub={selectedSubscription.subscriptionId} /> : modalContent === 'Target Audience' ? <TargetAudience sub={selectedSubscription} /> : <Boost />}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-danger" onClick={() => {
                            setShowModal(false)
                        }}>Close</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <div>
            <ModalPopup />
            <div className="row">
                <div className="row">
                    <h3>Boost Listing</h3>
                </div>
                <div className="row align-items-center">
                    <div className="col-6">
                        <input type="checkbox" checked={includeActive} id="showExpiredBoost" onChange={(e) => setIncludeActive(e.target.checked)}></input>
                        <label className="form-check-label ps-1" htmlFor="showExpiredBoost">Active</label>

                        <input type="checkbox" checked={includeExpired} id="showExpired2Boost" onChange={(e) => setIncludeExpired(e.target.checked)}></input>
                        <label className="form-check-label ps-1" htmlFor="showExpired2Boost">Expired</label>
                    </div>
                </div>
                <div className="row m-0">
                    {isLoadingSubscription ? "Loading" :
                        dataSubscription.payload.length === 0 ? <div> You do not have any Banner Ad </div> :
                            <div className="row m-0">
                                <div className="col">
                                    {dataSubscription.payload.length}
                                </div>
                                <div className="col-12">
                                    <div>
                                        <div className="row overflow-auto" style={{ 'height': '200px' }}>
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>State/Date</th>
                                                        <th>Image</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Target Audience</th>
                                                        <th>Status</th>
                                                        <th>Period</th>
                                                        <th>Payment</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        dataSubscription.payload.map((sub, index) =>
                                                            <tr key={index}>
                                                                <td className="align-middle"><center><b>{new Date(sub.endDate) >= new Date() ? sub.subscriptionStatus : "-"}</b><br />{moment(sub.subscriptionDate).format("MMMM DD YYYY")}</center></td>
                                                                <td className="align-middle"><img className="item-img border-0" src={process.env.REACT_APP_BASE_URL + "MediaStorage/BannerAd/" + (sub.imageFileName)} alt={sub.imageFileName} width={80} /></td>
                                                                <td className="align-middle">{sub.title}</td>
                                                                <td className="align-middle">{sub.description}</td>
                                                                <td className="align-middle">{sub.subscriptionDetails.map((t, index) => <div key={index}><b>{t.targetAudience.offer}</b> | {t.targetAudience.offerName} | {t.targetAudience.state.stateName}</div>)}</td>
                                                                <td className="align-middle">{new Date(sub.endDate) >= new Date() ? "Active" : "Expired"}</td>
                                                                <td className="align-middle">{moment(sub.startDate).format("MMM DD YYYY")} - {moment(sub.endDate).format("MMM DD YYYY")}</td>
                                                                <td className="align-middle">{sub.payment.paymentOption.paymentOptionName}</td>
                                                                <td className="align-middle" width={90}>
                                                                    <button className="btn btn-danger btn-sm py-0 mx-1" style={{ 'fontSize': '.7rem' }} onClick={() => {
                                                                        setShowModal(true);
                                                                        setModalContent('Invoice');
                                                                        setSelectedSubscription(sub);
                                                                    }}>Invoice</button><br />
                                                                    <button className="btn btn-danger btn-sm py-0 mx-1" style={{ 'fontSize': '.7rem' }} onClick={(e) => {
                                                                        setShowModal(true);
                                                                        setModalContent('Target Audience');
                                                                        setSelectedSubscription(sub);
                                                                    }}>Detail</button><br />

                                                                    {new Date(sub.endDate) >= new Date() &&
                                                                        <>
                                                                            <button className="btn btn-danger btn-sm py-0 mx-1" style={{ 'fontSize': '.7rem' }} onClick={() => {
                                                                                setShowModal(true);
                                                                                setModalContent('Modify Detail');
                                                                                setSelectedSubscription(sub);
                                                                            }}>Edit</button>
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}


const CreateBoost = ({ onCartItemChange2 }) => {


    const [eventTypeOfferId, setEventTypeOfferId] = useState('0');
    const [eventTypeStateId, setEventTypeStateId] = useState('0');

    const [cuisineOfferId, setCuisineOfferId] = useState('0');
    const [cuisineStateId, setCuisineStateId] = useState('0');

    const [specialtyOfferId, setSpecialtyOfferId] = useState('0');
    const [specialtyStateId, setSpecialtyStateId] = useState('0');

    const [title, setTitle] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [url, setUrl] = useState('');

    const [currentFile, setCurrentFile] = useState(undefined);
    const [previewImage, setPreviewImage] = useState(undefined);

    const { data: dataEventTypes, isLoading: isLoadingEventTypes } = useGetEventTypesQuery()
    const { data: dataStates, isLoading: isLoadingStates } = useGetStatesQuery()
    const { data: dataCuisines, isLoading: isLoadingCuisines } = useGetCuisinesQuery()
    const { data: dataSpecialty, isLoading: isLoadingSpecialty } = useGetSpecialitiesQuery()

    const { data: dataEventTypePrices, isLoading: isLoadingEventTypePrices } = useGetProductPricesQuery({ offer: 'EventType', offerId: eventTypeOfferId, stateId: eventTypeStateId, productId: 1 })
    const { data: dataCuisinePrices, isLoading: isLoadingCuisinePrices } = useGetProductPricesQuery({ offer: 'Cuisine', offerId: cuisineOfferId, stateId: cuisineStateId, productId: 1 })
    const { data: dataSpecialtyPrices, isLoading: isLoadingSpecialtyPrices } = useGetProductPricesQuery({ offer: 'Speciality', offerId: specialtyOfferId, stateId: specialtyStateId, productId: 1 })



    const [addTargetAud, setAddTargetAud] = useState([]);

    const [cartState, dispatch] = useReducer(CartReducer, []);

    const selectFile = (event) => {
        if (event.target.files[0].size > 1048576)
            toast.error(`Image file should not exceeds 1MB in size`, { position: toast.POSITION.TOP_CENTER });
        else {
            setCurrentFile(event.target.files[0]);
            setPreviewImage(URL.createObjectURL(event.target.files[0]));
        }
    };





    const handleAddTargetAud = (event, offerName) => {
        event.preventDefault();

        if (addTargetAud.filter((item) => item.offer === offerName && item.offerId === event.target[0].value && item.stateId === event.target[1].value).length > 0)
            toast.error("You already added that item", { position: toast.POSITION.TOP_CENTER });
        else {
            var selectedItem;
            if (offerName === "EventType") {
                selectedItem = dataEventTypePrices[0];
            }
            else if (offerName === "Cuisine") {
                selectedItem = dataCuisinePrices[0];
            }
            else if (offerName === "Specialty") {
                selectedItem = dataSpecialtyPrices[0];
            }
            setAddTargetAud((prevTodos) => [{ id: Date.now().toString(36), offer: offerName, offerId: event.target[0].value, offerName: event.target[0].options[event.target[0].selectedIndex].text, stateId: event.target[1].value, stateName: event.target[1].options[event.target[1].selectedIndex].text, productPlanId: selectedItem.productPlanId, subscriptionPlanName: selectedItem.subscriptionPlanName, productPriceId: selectedItem.ProductPriceId, price: selectedItem.price, targetAudienceId: selectedItem.targetAudienceId }, ...prevTodos]);
        }
    }

    const handleDeleteTargetAud = (id) => {
        const newArray = addTargetAud.filter((item) => item.id !== id);
        setAddTargetAud(newArray);
    };

    const Validate = (e) => {
        if (e.target.form.querySelectorAll('select')[0].value === "0")
            e.target.form.querySelectorAll('select')[0].classList.add("border-danger");
        else
            e.target.form.querySelectorAll('select')[0].classList.remove("border-danger");

        if (e.target.form.querySelectorAll('select')[1].value === "0")
            e.target.form.querySelectorAll('select')[1].classList.add("border-danger");
        else
            e.target.form.querySelectorAll('select')[1].classList.remove("border-danger");

        if ((e.target.form.querySelectorAll('select')[0].value !== "0") &&
            (e.target.form.querySelectorAll('select')[1].value !== "0")) {
            e.target.form.querySelectorAll('button')[0].disabled = false;
        }
        else
            e.target.form.querySelectorAll('button')[0].disabled = true;
    };

    const addToCart = async (e) => {
        await readUploadedFileDataURL(currentFile).then(
            function (val) {
                let cart = {
                    subscriptionTypeId: 1, productName: 'Boost', productDetail: [{
                        uniqueId: Date.now().toString(36), title: title, imageData: val, startDate: startDate, endDate: endDate, offers:
                            structureCart(addTargetAud)
                    }]
                }

                dispatch({ type: "Add-TO-CART", payload: cart });
                setTimeout(() => {
                    onCartItemChange2();
                }, 500)
            },
            function (error) {
                toast.error(error, { position: toast.POSITION.TOP_CENTER });
            }
        );
    }

    const totals = (offerName) => {
        var totals = (addTargetAud.filter((item) => item.offer === offerName).length > 0) && addTargetAud.filter((item) => item.offer === offerName).length.toString() + ' / $' + addTargetAud.filter((item) => item.offer === offerName).map(movie => movie.price).reduce((a, b) => a + b, 0).toString();
        return (
            <>
                {totals}
            </>
        )
    }


    return (
        <div>
            <div className="row">
                <h3>Create Boost</h3>
            </div>
            <div className="row">
                <div className="row">

                    <div className="col-6">
                        <div className="row ">
                            <div className="col-12 mt-3">
                                <label htmlFor="txtTitle">Title</label>
                                <input type="text" id="txtTitle" name="txtTitle" maxLength={60} value={title} onChange={(e) => setTitle(e.target.value)} className={title === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} />
                            </div>
                            <div className="col-12 mt-3 d-flex align-items-center gap-2">
                                <div className={previewImage === undefined ? "border border-danger" : ""}>
                                    <div className="px-4">
                                        <label>Upload Image (Max 20MB | Dimension 180 x 240)</label>
                                        <div className="row p-1">
                                            <div><input type="file" name="txtTitle" className="py-1" onChange={(e) => selectFile(e)} accept="image/x-png,image/gif,image/jpeg" /></div>
                                            {previewImage !== undefined ?
                                                <div className="d-flex align-items-center justify-content-center my-2" style={{ 'border': 'dotted 4px', 'borderRadius': '12px', 'padding': '4px' }} >
                                                    <img src={previewImage} alt="" style={{ maxHeight: '120px' }} />
                                                </div>
                                                :
                                                currentFile !== '' &&
                                                <div className="d-flex align-items-center justify-content-center my-2" style={{ 'border': 'dotted 4px', 'borderRadius': '12px', 'padding': '4px' }} >
                                                    <img src={process.env.REACT_APP_BASE_URL + `MediaStorage/DealImages/` + currentFile} alt="" style={{ maxHeight: '120px' }} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <label htmlFor="startDate">Start Date</label>
                                <input type="date"
                                    onChange={(e) => setStartDate(e.target.value)}
                                    required
                                    value={startDate}
                                    className={startDate === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} />
                            </div>
                            <div className="col-6 mt-3">
                                <label htmlFor="endDate">End Date</label>
                                <input type="date"
                                    onChange={(e) => setEndDate(e.target.value)}
                                    required
                                    value={endDate}
                                    className={endDate === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} />
                            </div>



                            <div className="col-12 mt-3">
                                <label htmlFor="url">URL</label>
                                <input type="text" className='form-control'
                                    onChange={(e) => setUrl(e.target.value)}
                                    required
                                    value={url}
                                    maxLength="120" />
                            </div>



                        </div>
                    </div>


                    <div className="col-6">

                        <div className="my-5">
                            <h4>Event Type {totals('EventType')}</h4>
                            <form onSubmit={(e) => handleAddTargetAud(e, "EventType")}>
                                <div className="row">
                                    <div className="col">
                                        {isLoadingEventTypes ? <h1>loading...</h1> :
                                            <select className="form-control" name="event" value={eventTypeOfferId} onChange={(e) => { setEventTypeOfferId(e.target.value); Validate(e); }}>
                                                <option value="0" >Select Event Type</option>
                                                {
                                                    dataEventTypes.payload.map((et, index) =>
                                                        <option key={index} value={et.eventTypeId}>{et.eventTypeName}</option>
                                                    )
                                                }
                                            </select>
                                        }

                                        {isLoadingStates ? <h1>loading...</h1> :
                                            <select className="form-control" name="eventState" value={eventTypeStateId} onChange={(e) => {
                                                setEventTypeStateId(e.target.value); Validate(e);
                                            }}>
                                                <option value="0">Select State</option>
                                                {
                                                    dataStates.payload.map((st, index) =>
                                                        <option key={index} value={st.stateId} >{st.stateName}</option>
                                                    )
                                                }
                                            </select>}
                                    </div>
                                    <div className="col-auto">
                                        {isLoadingEventTypePrices ? <h1>...</h1> : '$' + dataEventTypePrices[0].price}
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-danger mt-2" type="submit" disabled>
                                            <i className="fa-sharp fa-solid fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className="row">
                                {addTargetAud.filter((item, index) => item.offer === "EventType").map((cu, index) => <span key={index}>{cu.id}  |  {cu.offerId}-{cu.offerName}-{cu.offer}  |  {cu.stateId}-{cu.stateName}  |  {cu.productPlanId}-{cu.subscriptionPlanName}  | {cu.price}  <i className="fa-sharp fa-solid fa-trash" onClick={() => { handleDeleteTargetAud(cu.id) }}></i></span>)}
                            </div>
                        </div>

                        <div className="my-5">
                            <h4>Cuisine {totals('Cuisine')}</h4>
                            <form onSubmit={(e) => handleAddTargetAud(e, "Cuisine")}>
                                <div className="row">
                                    <div className="col">
                                        {isLoadingCuisines ? <h1>loading...</h1> :
                                            <select className="form-control" name="cuisine" value={cuisineOfferId} onChange={(e) => { setCuisineOfferId(e.target.value); Validate(e); }}>
                                                <option value="0" >Select Cuisine</option>
                                                {
                                                    dataCuisines.payload.map((cu, index) =>
                                                        <option key={index} text={cu.cuisineName} value={cu.cuisineId}>{cu.cuisineName}</option>
                                                    )
                                                }
                                            </select>
                                        }

                                        {isLoadingStates ? <h1>loading...</h1> :
                                            <select className="form-control" name="cuisineState" value={cuisineStateId} onChange={(e) => {
                                                setCuisineStateId(e.target.value); Validate(e);
                                            }}>
                                                <option value="0">Select State</option>
                                                {
                                                    dataStates.payload.map((st, index) =>
                                                        <option key={index} value={st.stateId} >{st.stateName}</option>
                                                    )
                                                }
                                            </select>}
                                    </div>
                                    <div className="col-auto">
                                        {isLoadingCuisinePrices ? <h1>...</h1> : '$' + dataCuisinePrices[0].price}
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-danger mt-2" type="submit" disabled>
                                            <i className="fa-sharp fa-solid fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className="row">
                                {addTargetAud.filter((item) => item.offer === "Cuisine").map((cu, index) => <span key={index}>{cu.id}  |  {cu.offerId}-{cu.offerName}-{cu.offer}  |  {cu.stateId}-{cu.stateName}  |  {cu.productPlanId}-{cu.subscriptionPlanName}  | {cu.price}  <i className="fa-sharp fa-solid fa-trash" onClick={() => { handleDeleteTargetAud(cu.id) }}></i></span>)}
                            </div>
                        </div>


                        <div className="my-5">
                            <h4>Specialty {totals('Specialty')}</h4>
                            <form onSubmit={(e) => handleAddTargetAud(e, "Specialty")}>
                                <div className="row">
                                    <div className="col">
                                        {isLoadingSpecialty ? <h1>loading...</h1> :
                                            <select className="form-control" name="specialty" value={specialtyOfferId} onChange={(e) => { setSpecialtyOfferId(e.target.value); Validate(e); }}>
                                                <option value="0" >Select Specialty</option>
                                                {
                                                    dataSpecialty.payload.map((cu, index) =>
                                                        <option key={index} text={cu.specialityName} value={cu.specialityId}>{cu.specialityName}</option>
                                                    )
                                                }
                                            </select>
                                        }

                                        {isLoadingSpecialty ? <h1>loading...</h1> :
                                            <select className="form-control" name="specialtyState" value={specialtyStateId} onChange={(e) => {
                                                setSpecialtyStateId(e.target.value); Validate(e);
                                            }}>
                                                <option value="0">Select State</option>
                                                {
                                                    dataStates.payload.map((st, index) =>
                                                        <option key={index} value={st.stateId} >{st.stateName}</option>
                                                    )
                                                }
                                            </select>}
                                    </div>
                                    <div className="col-auto">
                                        {isLoadingSpecialtyPrices ? <h1>...</h1> : '$' + dataSpecialtyPrices[0].price}
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-danger mt-2" type="submit" disabled>
                                            <i className="fa-sharp fa-solid fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className="row">
                                {addTargetAud.filter((item) => item.offer === "Specialty").map((cu, index) => <span key={index}>{cu.id}  |  {cu.offerId}-{cu.offerName}-{cu.offer}  |  {cu.stateId}-{cu.stateName}  |  {cu.productPlanId}-{cu.subscriptionPlanName}  | {cu.price}  <i className="fa-sharp fa-solid fa-trash" onClick={() => { handleDeleteTargetAud(cu.id) }}></i></span>)}
                            </div>
                        </div>
                        <div className="m-2">
                            <button className="btn btn-danger mt-2"
                                disabled={title === '' || startDate === "" || endDate === "" || previewImage === undefined || !valideUrl(url) || addTargetAud.length === 0}
                                onClick={(e) => { addToCart(e) }}>
                                Add to Cart {addTargetAud.length > 0 && ' | $' + addTargetAud.map(crt => crt.price).reduce((a, b) => a + b, 0).toString() + '/-'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const Boost = ({ onCartItemChange }) => {


    return (
        <div>
            <div className="row">
                <div className="signin-up-wrapper">
                    <h3>
                        <strong>&nbsp;Boost</strong>
                    </h3>
                </div>
                <div className="row my-4">
                    <BoostListing />
                </div>
                <div className="row my-4">
                    <CreateBoost onCartItemChange2={onCartItemChange} />
                </div>
            </div>
        </div>
    )
}






export default Boost