import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'


const HalalAuthorities = () => {



  return (

    <div>
      <Navbar />


      <main role="main">

        <div className="innerpage-banner ">
          <img src="images/static-banners/HalalAuthorities.jpg" className="innerpage-banner-img" alt="Halal Authorities" />
        </div>




        <section className="innerpage-container">

          <div className="container">
            <div className="row">

              <div className="col-12 col-md-12 col-md-12 col-lg-12">


                <h2>Halal certifying authorities</h2>
                <p>Restaurants and products are often halal certified by reputable halal certification organisations. Here, you can browse agencies from around the world and see how they drive the industry through the implementation of their standards. Many of these agencies have different approaches to certification.</p>

                <div className="row mt-4">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>AMERICAN HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">

                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">American Zabiha Authority</p>
                            <p className="halal-auth-country mb-0">Washington, DC</p>
                            <div className="halal-auth-food">
                              <b>209 RESTAURANTS</b>
                              <b>1 PRODUCT</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Transactions of Omaha</p>
                            <p className="halal-auth-country mb-0">Omaha, NE</p>
                            <div className="halal-auth-food">
                              <b>186 RESTAURANTS</b>
                              <b>1 PRODUCT</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Authentic Halal Services</p>
                            <p className="halal-auth-country mb-0">Houston, TX</p>
                            <div className="halal-auth-food">
                              <b>159 RESTAURANTS</b>
                              <b>1 PRODUCT</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Food Council USA</p>
                            <p className="halal-auth-country mb-0">Salisbury, MD</p>
                            <div className="halal-auth-food">
                              <b>131 RESTAURANTS</b>
                              <b>7 PRODUCTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Food Standards Alliance of America</p>
                            <p className="halal-auth-country mb-0">Mill Valley, CA</p>
                            <div className="halal-auth-food">
                              <b>113 RESTAURANTS</b>
                              <b>1 PRODUCT</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Society of the Washington Area</p>
                            <p className="halal-auth-country mb-0">Washington, DC</p>
                            <div className="halal-auth-food">
                              <b>177 RESTAURANTS</b>
                              <b>14 PRODUCTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">American Halal Foundation</p>
                            <p className="halal-auth-country mb-0">Bolingbrook, IL</p>
                            <div className="halal-auth-food">
                              <b>65 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Monitoring Services</p>
                            <p className="halal-auth-country mb-0">Chicago, IL</p>
                            <div className="halal-auth-food">
                              <b>57 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="row airline-row" id="americanHalalMore">
                      <span id="dots">...</span>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Zabiha Halal Slaughter Assoc for the State of LA</p>
                            <p className="halal-auth-country mb-0">Sterlington, LA</p>
                            <div className="halal-auth-food">
                              <b>51 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">ISWA Halal Certification</p>
                            <p className="halal-auth-country mb-0">Washington, DC</p>
                            <div className="halal-auth-food">
                              <b>44 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Green Valley Halal</p>
                            <p className="halal-auth-country mb-0">Houston, TX</p>
                            <div className="halal-auth-food">
                              <b>28 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Services of America</p>
                            <p className="halal-auth-country mb-0">Cedar Rapids, IA</p>
                            <div className="halal-auth-food">
                              <b>26 RESTAURANTS</b>
                              <b>2 PRODUCTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Muslim American Food Council</p>
                            <p className="halal-auth-country mb-0">Claremont, CA</p>
                            <div className="halal-auth-food">
                              <b>18 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal N Pure Certification Agency</p>
                            <p className="halal-auth-country mb-0">Dallas, TX</p>
                            <div className="halal-auth-food">
                              <b>16 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">American Halal Institute</p>
                            <p className="halal-auth-country mb-0">Oak Brook, IL</p>
                            <div className="halal-auth-food">
                              <b>9 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Board of San Diego</p>
                            <p className="halal-auth-country mb-0">San Diego, CA</p>
                            <div className="halal-auth-food">
                              <b>6 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">HalalCo</p>
                            <p className="halal-auth-country mb-0">Atlanta, GA</p>
                            <div className="halal-auth-food">
                              <b>6 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Food & Nutrition Council of America</p>
                            <p className="halal-auth-country mb-0">Park Ridge, IL</p>
                            <div className="halal-auth-food">
                              <b>4 RESTAURANTS</b>
                              <b>57 PRODUCTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Rahmat-e-Alam Foundation</p>
                            <p className="halal-auth-country mb-0">Chicago, IL</p>
                            <div className="halal-auth-food">
                              <b>4 RESTAURANTS</b>
                              <b>13 PRODUCTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">IDCP Halal US</p>
                            <p className="halal-auth-country mb-0">McKinney, TX</p>
                            <div className="halal-auth-food">
                              <b>1 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Watch World</p>
                            <p className="halal-auth-country mb-0">Glenmont, NY</p>
                            <div className="halal-auth-food">
                              <b>1 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Food Trust of San Joaquin Valley</p>
                            <p className="halal-auth-country mb-0">Tracy, CA</p>
                            <div className="halal-auth-food">
                              <b>NO AUTHORIZATIONS</b>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>AUSSIE HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Australian Federation of Islamic Councils</p>
                            <p className="halal-auth-country mb-0">Zetland, New South Wales</p>
                            <div className="halal-auth-food">
                              <b>205 RESTAURANTS</b>
                              <b>13 PRODUCTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Australian Halal Food Services</p>
                            <p className="halal-auth-country mb-0">Springwood, Queensland</p>
                            <div className="halal-auth-food">
                              <b>39 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Western Australian Halal Authority</p>
                            <p className="halal-auth-country mb-0">Maddington, Western Australia</p>
                            <div className="halal-auth-food">
                              <b>30 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Al Iman Islamic Society</p>
                            <p className="halal-auth-country mb-0">Collingwood, Victoria</p>
                            <div className="halal-auth-food">
                              <b>17 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Certification Authority Australia</p>
                            <p className="halal-auth-country mb-0">Sydney, New South Wales</p>
                            <div className="halal-auth-food">
                              <b>11 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Australia</p>
                            <p className="halal-auth-country mb-0">Auburn, New South Wales</p>
                            <div className="halal-auth-food">
                              <b>9 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Coordinating Council of Victoria</p>
                            <p className="halal-auth-country mb-0">East Brunswick, Victoria</p>
                            <div className="halal-auth-food">
                              <b>8 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Council of Western Australia</p>
                            <p className="halal-auth-country mb-0">Rivervale, Western Australia</p>
                            <div className="halal-auth-food">
                              <b>7 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Council of Queensland</p>
                            <p className="halal-auth-country mb-0">Karawatha, Queensland</p>
                            <div className="halal-auth-food">
                              <b>5 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Gold Coast Halal Certification Services</p>
                            <p className="halal-auth-country mb-0">Arundel, Queensland</p>
                            <div className="halal-auth-food">
                              <b>3 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>CANADIAN HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Monitoring Authority</p>
                            <p className="halal-auth-country mb-0">Toronto, ON</p>
                            <div className="halal-auth-food">
                              <b>103 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Canada Halal Examination & Certification</p>
                            <p className="halal-auth-country mb-0">North York, ON</p>
                            <div className="halal-auth-food">
                              <b>69 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Advisory Group</p>
                            <p className="halal-auth-country mb-0">Toronto, ON</p>
                            <div className="halal-auth-food">
                              <b>62 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">BC Muslim Association</p>
                            <p className="halal-auth-country mb-0">Richmond, BC</p>
                            <div className="halal-auth-food">
                              <b>30 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Alberta Halal Monitoring Certification Council</p>
                            <p className="halal-auth-country mb-0">Calgary, AB</p>
                            <div className="halal-auth-food">
                              <b>15 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Inspection & Certification Canada</p>
                            <p className="halal-auth-country mb-0">Richmond, BC</p>
                            <div className="halal-auth-food">
                              <b>12 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">ISNA Halal Certification Agency</p>
                            <p className="halal-auth-country mb-0">Mississauga, ON</p>
                            <div className="halal-auth-food">
                              <b>11 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Montreal Certification Authority</p>
                            <p className="halal-auth-country mb-0">Longueuil, QC</p>
                            <div className="halal-auth-food">
                              <b>11 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>BRITISH HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Monitoring Committee</p>
                            <p className="halal-auth-country mb-0">Leicester, England</p>
                            <div className="halal-auth-food">
                              <b>338 RESTAURANTS</b>
                              <b>7 PRODUCTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Food Authority</p>
                            <p className="halal-auth-country mb-0">London, England</p>
                            <div className="halal-auth-food">
                              <b>161 RESTAURANTS</b>
                              <b>1 PRODUCTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Institute of Islamic Jurisprudence</p>
                            <p className="halal-auth-country mb-0">Batley, West Yorkshire</p>
                            <div className="halal-auth-food">
                              <b>54 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">UK Halal Corporation</p>
                            <p className="halal-auth-country mb-0">Birmingham, West Midlands</p>
                            <div className="halal-auth-food">
                              <b>17 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Muslim Food Board</p>
                            <p className="halal-auth-country mb-0">Leicester, Leicestershire</p>
                            <div className="halal-auth-food">
                              <b>9 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Council for Mosques, Bradford</p>
                            <p className="halal-auth-country mb-0">Bradford, West Yorkshire</p>
                            <div className="halal-auth-food">
                              <b>4 RESTAURANTS</b>
                              <b>1 PRODUCTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>FRENCH HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">AVS</p>
                            <p className="halal-auth-country mb-0">Saint Denis, France</p>
                            <div className="halal-auth-food">
                              <b>30 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Comm de Surveillance du Hâlal de la Réunion</p>
                            <p className="halal-auth-country mb-0">St. Pierre, Reunion</p>
                            <div className="halal-auth-food">
                              <b>13 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Societe Francaise de Controle des Viandes Halal</p>
                            <p className="halal-auth-country mb-0">Aigues, France</p>
                            <div className="halal-auth-food">
                              <b>2 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">L'Assoc de Defense du Consommateur Musulman</p>
                            <p className="halal-auth-country mb-0">Orly, France</p>
                            <div className="halal-auth-food">
                              <b>1 RESTAURANT</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">HIFMI Institut</p>
                            <p className="halal-auth-country mb-0">Drancy, Ile-de-France</p>
                            <div className="halal-auth-food">
                              <b>NO AUTHORIZATIONS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>GERMAN HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Quality Control GmbH</p>
                            <p className="halal-auth-country mb-0">Osnabruck, Germany</p>
                            <div className="halal-auth-food">
                              <b>40 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Control e.K.</p>
                            <p className="halal-auth-country mb-0">Russelsheim, Germany</p>
                            <div className="halal-auth-food">
                              <b>10 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">European Institute of Halal Certification</p>
                            <p className="halal-auth-country mb-0">Hamburg, Germany</p>
                            <div className="halal-auth-food">
                              <b>10 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Centre Hamburg</p>
                            <p className="halal-auth-country mb-0">Hamburg, Germany</p>
                            <div className="halal-auth-food">
                              <b>4 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>SOUTH AFRICAN HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">South African National Halaal Authority</p>
                            <p className="halal-auth-country mb-0">Fordsburg, South Africa</p>
                            <div className="halal-auth-food">
                              <b>212 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Council of South Africa</p>
                            <p className="halal-auth-country mb-0">Athlone, South Africa</p>
                            <div className="halal-auth-food">
                              <b>49 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">National Independent Halaal Trust</p>
                            <p className="halal-auth-country mb-0">Lenasia, South Africa</p>
                            <div className="halal-auth-food">
                              <b>38 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Muslim Judicial Council Halaal Trust</p>
                            <p className="halal-auth-country mb-0">Cape Town, Western Cape</p>
                            <div className="halal-auth-food">
                              <b>7 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>DUTCH HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Feed and Food Inspection Authority</p>
                            <p className="halal-auth-country mb-0">The Hague, The Netherlands</p>
                            <div className="halal-auth-food">
                              <b>6 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Correct</p>
                            <p className="halal-auth-country mb-0">Leiden, The Netherlands</p>
                            <div className="halal-auth-food">
                              <b>4 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">European Certification Centre for Halal</p>
                            <p className="halal-auth-country mb-0">Rotterdam, Zuid-Holland</p>
                            <div className="halal-auth-food">
                              <b>NO AUTHORIZATIONS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>APANESE HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Malaysia Halal Corporation</p>
                            <p className="halal-auth-country mb-0">Minato-ku, Tokyo</p>
                            <div className="halal-auth-food">
                              <b>8 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Japan Islamic Trust</p>
                            <p className="halal-auth-country mb-0">Toshima, Tokyo</p>
                            <div className="halal-auth-food">
                              <b>7 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Nippon Asia Halal Association</p>
                            <p className="halal-auth-country mb-0">Hanamigawa-ku, Chiba-shi</p>
                            <div className="halal-auth-food">
                              <b>6 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>INDIAN HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Jamiat Ulama-I-Hind Halal Trust</p>
                            <p className="halal-auth-country mb-0">New Delhi, India</p>
                            <div className="halal-auth-food">
                              <b>11 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal India</p>
                            <p className="halal-auth-country mb-0">Triplicane, Chennai</p>
                            <div className="halal-auth-food">
                              <b>6 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>CHILEAN HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Centro Islamico de Chile</p>
                            <p className="halal-auth-country mb-0">Santiago, Chile</p>
                            <div className="halal-auth-food">
                              <b>1 RESTAURANT</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Certification Center of Chile</p>
                            <p className="halal-auth-country mb-0">Providencia, Region Metropolitana</p>
                            <div className="halal-auth-food">
                              <b>NO AUTHORIZATIONS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>FILIPINO HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Muslim Mindanao Halal Certification Board</p>
                            <p className="halal-auth-country mb-0">Davao, The Philippines</p>
                            <div className="halal-auth-food">
                              <b>9 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Da'wah Council of the Philippines</p>
                            <p className="halal-auth-country mb-0">Manila, Philippines</p>
                            <div className="halal-auth-food">
                              <b>3 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>VIETNAMESE HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Center of Halal Certification</p>
                            <p className="halal-auth-country mb-0">Hanoi, Vietnam</p>
                            <div className="halal-auth-food">
                              <b>7 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Community of Ho Chi Minh City</p>
                            <p className="halal-auth-country mb-0">Phu Nhuan District, Ho Chi Minh City</p>
                            <div className="halal-auth-food">
                              <b>2 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>BRAZILIAN HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">CIBAL Halal</p>
                            <p className="halal-auth-country mb-0">Sao Paulo, Brazil</p>
                            <div className="halal-auth-food">
                              <b>9 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Centro de Divulgao do Islam para America Latina</p>
                            <p className="halal-auth-country mb-0">Sao Paulo, Brazil</p>
                            <div className="halal-auth-food">
                              <b>4 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>TURKISH HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Kas International Certification</p>
                            <p className="halal-auth-country mb-0">Izmir, Turkey</p>
                            <div className="halal-auth-food">
                              <b>90 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">GİMDES</p>
                            <p className="halal-auth-country mb-0">Esenler, Istanbul Province</p>
                            <div className="halal-auth-food">
                              <b>8 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>IRISH HALAL AUTHORITIES</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Foundation of Ireland</p>
                            <p className="halal-auth-country mb-0">Dublin, Ireland</p>
                            <div className="halal-auth-food">
                              <b>121 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Department of Halal Certification</p>
                            <p className="halal-auth-country mb-0">Blanchardstown, Dublin</p>
                            <div className="halal-auth-food">
                              <b>5 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>NAMIBIA HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Namibia Islamic Halaal Association</p>
                            <p className="halal-auth-country mb-0">Windhoek, Namibia</p>
                            <div className="halal-auth-food">
                              <b>NO AUTHORIZATIONS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6> MEXICAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">El Centro Cultural Islámico de México</p>
                            <p className="halal-auth-country mb-0">Mexico City, Mexico</p>
                            <div className="halal-auth-food">
                              <b>3 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>RUSSIAN HALAL AUTHORITY </h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Centre for Halal Standardisation & Certification</p>
                            <p className="halal-auth-country mb-0">Moscow, Russia</p>
                            <div className="halal-auth-food">
                              <b>14 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>COLOMBIA HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Colombia Halal Institute</p>
                            <p className="halal-auth-country mb-0">BogotÃ¡ D.C., Colombia</p>
                            <div className="halal-auth-food">
                              <b>NO AUTHORIZATIONS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>SWISS HALAL AUTHORITY  </h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Certification Services Switzerland</p>
                            <p className="halal-auth-country mb-0">Rheinfelden, Switzerland</p>
                            <div className="halal-auth-food">
                              <b>6 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6> MALAYSIAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">JAKIM</p>
                            <p className="halal-auth-country mb-0">Putrajaya, Malaysia</p>
                            <div className="halal-auth-food">
                              <b>40 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>KENYAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Kenya Bureau of Halal Certification</p>
                            <p className="halal-auth-country mb-0">Nairobi, Kenya</p>
                            <div className="halal-auth-food">
                              <b>7 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>TRINIDADIAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Dar ul Uloom Halal Committee</p>
                            <p className="halal-auth-country mb-0">Cunupia, St. George</p>
                            <div className="halal-auth-food">
                              <b>42 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>SINGAPOREAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Majlis Ugama Islam Singapura</p>
                            <p className="halal-auth-country mb-0">Singapore, Singapore</p>
                            <div className="halal-auth-food">
                              <b>215 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>SPANISH HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Instituto Halal</p>
                            <p className="halal-auth-country mb-0">Cordoba, Spain</p>
                            <div className="halal-auth-food">
                              <b>17 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6> HONGKONGESE HALAL AUTHORITY

                    </h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Islamic Community Fund of Hong Kong</p>
                            <p className="halal-auth-country mb-0">Wan Chai, Hong Kong</p>
                            <div className="halal-auth-food">
                              <b>21 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>EMIRATI HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Muslim Advisory Board</p>
                            <p className="halal-auth-country mb-0">Sharjah, UAE</p>
                            <div className="halal-auth-food">
                              <b>9 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>ARGENTINE HALAL AUTHORITY </h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">The Halal Catering Argentina</p>
                            <p className="halal-auth-country mb-0">Buenos Aires, Argentina</p>
                            <div className="halal-auth-food">
                              <b>NO AUTHORIZATIONS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>LITHUANIAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Zam Zam Lithuania</p>
                            <p className="halal-auth-country mb-0">NemėÂis, Vilniaus apskritis</p>
                            <div className="halal-auth-food">
                              <b>2 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>THAI HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Standard Institute of Thailand</p>
                            <p className="halal-auth-country mb-0">Bangkok, Thailand</p>
                            <div className="halal-auth-food">
                              <b>6 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>KOREAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Korea Muslim Federation Halal Committee</p>
                            <p className="halal-auth-country mb-0">Mapo-gu, Seoul</p>
                            <div className="halal-auth-food">
                              <b>6 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>KIWI HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Federation of Islamic Associations of New Zealand</p>
                            <p className="halal-auth-country mb-0">Wellington, New Zealand</p>
                            <div className="halal-auth-food">
                              <b>27 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>BANGLADESHI HALAL AUTHORITY  </h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Certification Bangladesh</p>
                            <p className="halal-auth-country mb-0">Agrabad, Chittagong</p>
                            <div className="halal-auth-food">
                              <b>2 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6> BRUNEIAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Brunei Halal</p>
                            <p className="halal-auth-country mb-0">Brunei Darussalam, Brunei</p>
                            <div className="halal-auth-food">
                              <b>5 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6> PORTUGUESE HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Comité Halal de Portugal</p>
                            <p className="halal-auth-country mb-0">Lisboa, Portugal</p>
                            <div className="halal-auth-food">
                              <b>8 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>POLISH HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Polska</p>
                            <p className="halal-auth-country mb-0">Poznan, Poland</p>
                            <div className="halal-auth-food">
                              <b>7 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>ZIMBABWEAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">National Halaal Authority of Zimbabwe</p>
                            <p className="halal-auth-country mb-0">Harare, Zimbabwe</p>
                            <div className="halal-auth-food">
                              <b>2 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>URUGUAYAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Uruguay Islamic Center</p>
                            <p className="halal-auth-country mb-0">Montevideo, Uruguay</p>
                            <div className="halal-auth-food">
                              <b>4 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>INDONESIAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">LPPOM</p>
                            <p className="halal-auth-country mb-0">Jakarta, Indonesia</p>
                            <div className="halal-auth-food">
                              <b>227 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>SRI LANKAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">All Ceylon Jamiyyathul Ulama</p>
                            <p className="halal-auth-country mb-0">Colombo, Sri Lanka</p>
                            <div className="halal-auth-food">
                              <b>9 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>ITALIAN HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Halal Italy</p>
                            <p className="halal-auth-country mb-0">Bari, Italy</p>
                            <div className="halal-auth-food">
                              <b>13 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className="row border-top">


                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 p-4 ">
                    <h6>BAHRAINI HALAL AUTHORITY</h6>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-8 col-12 p-4 border-start">
                    <div className="row airline-row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-4">

                        <div className="halal-auth-box align-item-center">
                          <div className="halal-auth-badge">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="halal-auth-details">
                            <p className="halal-auth-name mb-0">Agency for Halal Quality Certification</p>
                            <p className="halal-auth-country mb-0">Tuzla, Bosnia and Herzegovina</p>
                            <div className="halal-auth-food">
                              <b>75 RESTAURANTS</b>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>


            </div>



          </div>
        </section>

      </main>


      <Footer></Footer>
    </div>
  )
}

export default HalalAuthorities