import { toast } from 'react-toastify';
import { useGetVideoByCompanyIdQuery, useAddVideoMutation, useDeleteVideoMutation } from './../../hooks/apiSlice';
import AuthService from "./../../hooks/Auth";
import React, { useState, useEffect, useReducer, useRef } from "react";
import Confirmation from '../../hooks/Confirmation';


const URL_REGEX = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

const Video = ({ menuCompletionPercentageHandler }) => {

    const { data: dataVideoByCaterer, isLoading: isLoadingVideoByCaterer } = useGetVideoByCompanyIdQuery(AuthService.getCatererId())

    const [addVideo] = useAddVideoMutation()
    const [deleteVideo] = useDeleteVideoMutation()

    // const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const [videoId, setVideoId] = useState();
    const [currentFile, setCurrentFile] = useState(undefined);
    const [butLoading, setButLoading] = useState(false)
    const [progress, setProgress] = useState(false);

    const videoRef = useRef();

    const [youtubeURL, setYoutubeURL] = useState("");
    const [validYoutubeURL, setValidYoutubeURL] = useState(false);

    useEffect(() => {
        if (!isLoadingVideoByCaterer) {
            if (dataVideoByCaterer.payload != null) {
                setVideoId(dataVideoByCaterer.payload.videoId);
                setYoutubeURL(dataVideoByCaterer.payload.youtubeURL);
                setValidYoutubeURL(URL_REGEX.test(dataVideoByCaterer.payload.youtubeURL));
                menuCompletionPercentageHandler(100);
                videoRef.current?.load();
            }
            else
                menuCompletionPercentageHandler(0);
        }
    }, [dataVideoByCaterer])

    const saveVideo = async () => {
        setButLoading(true);
        setProgress(true);
        await addVideo({ videoId: videoId, youtubeURL: youtubeURL, 'caterer.catererId': AuthService.getCatererId(), 'userProfile.userProfileId': AuthService.getUserProfileId(), file: currentFile })
            .then((response) => {
                //setProgress(true);
                setButLoading(false);
                setCurrentFile(null);
                toast.success(`Your changes has been saved successfully`, { position: toast.POSITION.TOP_CENTER });
                //forceUpdate();
                setProgress(false);
            })
            .catch((err) => {
                setProgress(false);
                toast.ERROR(`Unable to save Video, detail: ` + err.response.data.message, { position: toast.POSITION.TOP_CENTER })
                setCurrentFile(undefined);
                //setProgress(false);
            });
    };


    const selectFile = (event) => {
        if (event.target.files[0].size > 20971520) {
            alert("Video size does not exceeds from 20MB")
        }
        else {
            setCurrentFile(event.target.files[0]);
            setProgress(false);
        }
    };


    const YoutubeURL_onChange = (e) => {
        setValidYoutubeURL(URL_REGEX.test(e));
        setYoutubeURL(e);
    }


    const [deleteId, setDeleteId] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const submitDelete = async (id) => {
        await deleteVideo({ videoId: id });
        setDisplayConfirmationModal(false);
        toast.success(`Your Video has been deleted successfully`, { position: toast.POSITION.TOP_CENTER });
    };




    return (
        <div>
            <div className="row">
                <div className="signin-up-wrapper d-none d-lg-block">
                    <h3>
                        <strong>Video</strong>
                    </h3>
                </div>

                <div>
                    <div className="row mt-4">

                        {isLoadingVideoByCaterer ? "" :

                            !dataVideoByCaterer.payload &&
                            <div className="row">
                                <h5>Upload your video here</h5>
                            </div>
                        }
                        <div className="row* video">



                            <div className="awardCard shadow-sm" style={{ 'minHeight': 'unset', 'margin': '10px 0px' }}>

                                {progress && <div className="video-loader"><div className="cp-eclipse"></div></div>}


                                {isLoadingVideoByCaterer ? "" :
                                    (dataVideoByCaterer.payload != null && dataVideoByCaterer.payload.videoFileName != null) &&

                                    <div className="text-center position-relative">

                                        <video controls width="100%" ref={videoRef}>
                                            <source src={process.env.REACT_APP_BASE_URL + "MediaStorage/vid/" + dataVideoByCaterer.payload.videoFileName} type="video/mp4" autoPlay={true} />
                                            Your browser does not support the video tag.
                                        </video>

                                        <div>
                                            <button className="lowHightButton" onClick={() => {
                                                setDeleteId(dataVideoByCaterer.payload.videoId)
                                                setDisplayConfirmationModal(true)
                                            }
                                            } href="#" title="click here if you forgot your password">
                                                <i className="fas fa-trash"></i>  remove
                                            </button>
                                            <Confirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={() => { setDisplayConfirmationModal(false) }} id={deleteId} message={"Are you sure to delete this Video ?"} />
                                        </div>
                                    </div>
                                }

                                <div className="input-group mt-2">
                                    <input type="file" className="form-control" id="inputGroupFile02" onChange={selectFile} accept="video/mp4,video/x-m4v,video/*" />
                                    <button className="input-group-text" onClick={(e) => { saveVideo() }} disabled={!currentFile}>Upload</button>
                                </div>


                                <div className="mx-3 alert alert-light d-flex p-2 mt-3" role="alert">
                                    <i className="fa-sharp fa-solid fa-circle-info ms-1 me-2"></i>
                                    <div className="text-dark tiny-text">Video should be under 10 minutes and file size should not exceed than 20MB.</div>
                                </div>
                            </div>


                            <div className="mt-2 text-center"><h3>OR</h3></div>

                            <div className="mt-2">
                                <label className="form-label"><h5>Paste Youtube video URL here</h5></label>
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">Youtube URL</span>
                                    <input type="text" value={youtubeURL} placeholder="example: https://www.youtube.com/" onChange={(e) => YoutubeURL_onChange(e.target.value)} required className={!validYoutubeURL ? "form-control form-control-lg border" : "form-control form-control-lg"} />
                                    <button className="input-group-text" onClick={(e) => { saveVideo() }} disabled={!youtubeURL || !validYoutubeURL}>Save</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Video

