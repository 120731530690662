import Navbar from "../components/Navbar";
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import { useState } from "react";


const SignupHome = () => {

  const [profile, setProfile] = useState('');

  return (

    <div>
      <Navbar />



      <section className="pb-5 mb-4 bg-pink*">
        <div className="container">
          <div className="row align-items-center">


            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="standardHeading">
                <h2><strong>Create account,</strong></h2>
                <h3>sign-up to get started!</h3>
              </div>
              <div className="row margin0">
                {/* <div className="col-lg-12 d-flex justify-content-center border rounded p-3 mt-4 loginwith">
                  <LoginSocialFacebook appId="2228773394176812"

                    onResolve={(response) => {
                      console.log(response)
                      setProfile(response.data)
                    }}

                    onReject={(error) => { console.log(error) }
                    }
                  >

                    <FacebookLoginButton />
                  </LoginSocialFacebook>

                  {profile.data ? <div><h1>{profile.name}</h1> <img src={profile.picture.data.url} alt="" /></div> : ""}
                </div> */}
                {/* <div className="col-lg-12 d-flex justify-content-center border rounded p-3 mt-2 loginwith">
        <a href="#">
          <img src="images/google-icon.png" alt=""/> Continue with Google
        </a>
      </div> */}
                <div className="col-lg-12 d-flex justify-content-center border rounded p-3 mt-2 loginwith">

                  <Link to="/signup" state={{ from: "customer" }} >
                    <img src="images/envelope.png" alt="" /> Sign-up as customer
                  </Link>
                </div>

                <div className="col-lg-12 d-flex justify-content-center p-3 mt-2 or-div">
                  <span>or</span>
                </div>

              </div>


              <div className="row margin0 form-log">


                <Link to="/signup" state={{ from: "business" }} className="btn btn-danger btn-lg">Add My Business Listing</Link>
                <p className="text-left newlink mt-2">By clicking Sign-up, you agree to Halal Caters Term of Services and Privacy Policy.</p>


                <p className="text-left newlink mt-3">Already a member?  <Link to="/signin" style={{ color: "black" }}>Sign-in</Link> </p>
              </div>



            </div>



            <div className="offset-md-1 col-lg-7 col-md-12 col-sm-12 col-12">
              <img src="images/siginin.jpg" className="img-fluid" alt="" />
            </div>



          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  )
}


export default SignupHome