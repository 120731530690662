import { toast } from 'react-toastify';
import {
    useGetMenuDocumentQuery, useGetCuisinesQuery, useGetDietariesQuery, useGetMenuItemByCompanyQuery, useGetMenuItemCategoryQuery,
    useAddMenuItemMutation, useAddMenuDocMutation, useDeleteMenuItemMutation, useDeleteMenuDocumentMutation, useSaveExternalMenuURLMutation, useGetPackageFeatureQuery
} from './../../hooks/apiSlice';
import AuthService from "./../../hooks/Auth";
import React, { useState, useEffect } from "react";
import MaskedInput from 'react-text-mask'
import DeleteConfirmation from '../../hooks/Confirmation';






const MenuItem = () => {

    const { data: dataMenuItemByCompany, isLoading: isLoadingMenuItemByCompany } = useGetMenuItemByCompanyQuery()
    const { data: dataMenuItemCategory, isLoading: isLoadingMenuItemCategory } = useGetMenuItemCategoryQuery()
    const { data: dataCuisine, isLoading: isLoadingCuisine } = useGetCuisinesQuery()
    const { data: dataDietaries, isLoading: isLoadingDietaries } = useGetDietariesQuery()
    const { data: dataMenuDocument, isLoading: isLoadingMenuDocument } = useGetMenuDocumentQuery()
    const { data: dataPackageFeature, isLoading: isLoadingPackageFeature } = useGetPackageFeatureQuery(12)

    const [addMenuItemMutation] = useAddMenuItemMutation()
    const [deleteMenuItemMutation] = useDeleteMenuItemMutation()
    const [addMenuDocMutation] = useAddMenuDocMutation()
    const [deleteMenuDocument] = useDeleteMenuDocumentMutation()
    const [saveExternalMenuURL] = useSaveExternalMenuURLMutation()



    const [menuItemName, setMenuItemName] = useState('');
    const [isSpecialty, setIsSpecialty] = useState(false);
    const [cuisineId, setCuisineId] = useState('0');
    const [menuItemCategoryId, setMenuItemCategoryId] = useState('0');
    const [price, setPrice] = useState('');
    const [noOfServing, setNoOfServing] = useState(1);
    const [description, setDescription] = useState('');
    const [checked, setChecked] = useState([]);
    const [progress, setProgress] = useState(0);
    const [pdfUploaded, setPdfUploaded] = useState(false);
    const [menuCategoryId, setMenuCategoryId] = useState('0');
    const [externalMenuURL, setExternalMenuURL] = useState('');

    useEffect(() => {
        if (isLoadingMenuDocument === false) {
            if (dataMenuDocument.payload)
                if (dataMenuDocument.payload.externalMenuURL !== null)
                    setExternalMenuURL(dataMenuDocument.payload.externalMenuURL)
                else
                    setExternalMenuURL('')
        }
    }, [isLoadingMenuDocument])

    const [currentFile, setCurrentFile] = useState(undefined);
    const [previewImage, setPreviewImage] = useState(undefined);
    const [message, setMessage] = useState("");


    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

    const addMenuItem = () => {

        addMenuItemMutation({ 'company.companyId': AuthService.getCompanyId(), 'cuisine.cuisineId': cuisineId, menuItemName: menuItemName, specialtyItem: isSpecialty, price: price, noOfServing: noOfServing, description: description, 'menuItemCategory.menuItemCategoryId': menuItemCategoryId, DietaryIds: checked, file: currentFile });
        toast.success(`Menu Item has been added successfully`, { position: toast.POSITION.TOP_CENTER });

        setMenuItemName('');
        setIsSpecialty(false);
        setCuisineId('0');
        setPrice('');
        setNoOfServing(1);
        setChecked([])
        setDescription('')
        setMenuItemCategoryId(0)
        setPreviewImage('')

        var items = document.getElementsByName('cbDietary');
        for (var i = 0; i < items.length; i++) {
            if (items[i].type === 'checkbox')
                items[i].checked = false;
        }
    }



    const MenuList = (props) => {
        const groupByCategory = !isLoadingMenuItemByCompany && dataMenuItemByCompany.payload.filter((m) => m.menuItemCategory.menuItemCategoryId === parseInt(menuCategoryId) || menuCategoryId === "0").reduce((sum, li, index) => {
            sum[li.menuItemCategory.menuItemCategoryName] = sum[li.menuItemCategory.menuItemCategoryName] ?? [];
            sum[li.menuItemCategory.menuItemCategoryName].push(li);
            return sum;
        }, {});

        return (<>
            {Object.keys(groupByCategory).map((m, index) =>
                <div className="row mb-4 mb-lg-0" key={index}>
                    {groupByCategory[m].map((menuItem, index) =>
                        <div className="col-lg-6" key={index}>
                            <div className="row mx-1 mt-4 align-items-center* dish-box">
                                <div className="col-sm-4 col-4 text-center">
                                    {
                                        (menuItem.pictureFileName) ?
                                            <img className="item-img border-0" src={process.env.REACT_APP_BASE_URL + "MediaStorage/MenuItemImages/" + (menuItem.pictureFileName)} alt={menuItem.pictureFileName} />
                                            :
                                            <div className="menu-placeholder">
                                                <img className="placeholder-img" src="https://i.imgur.com/PeXwOWe.png" alt="placeholder" />
                                            </div>
                                    }
                                </div>
                                <div className="col-sm-8 col-8">
                                    <div className="dish-info">
                                        <span className="simpleIconButton close-btn"><i className="fas fa-circle-xmark" title="delte this menu item" onClick={() => { showDeleteModal(menuItem.menuItemId) }}></i></span>
                                        <div className="cuisine">
                                            {menuItem.cuisine.cuisineName}
                                        </div>

                                        <div className="d-flex flex-column align-items-start title">
                                            <h5>{menuItem.menuItemName}</h5>
                                            <div className="d-flex gap-1 align-items-center">
                                                <p>Speciality {menuItem.specialtyItem ? <i className="text-success fa-solid fa-circle-check" title="special menu item"></i> : <i className="text-danger fa-solid fa-circle-xmark" title="regular menu item"></i>}</p>
                                                <p className="category">{menuItem.menuItemCategory.menuItemCategoryName}</p>
                                            </div>
                                        </div>

                                        <div className="dietry d-flex flex-wrap gap-1">
                                            {
                                                menuItem.dietaries.map
                                                    (
                                                        (d, index) =>
                                                            <div className="dietry-list" key={index} >
                                                                <img src={"/images/dietaryIcon_" + d.dietaryName.replace(" ", "") + ".png"} height="24" width="24" alt={d.dietaryName} title={d.dietaryName} />
                                                                <p>{d.dietaryName}</p>
                                                            </div>
                                                    )
                                            }
                                        </div>
                                        {
                                            <p className="desc">{menuItem.description}</p>
                                        }
                                        <p className="serving"><i className="fas fa-user"></i>&nbsp;{menuItem.noOfServing} Serve</p>
                                        <p className="price">${menuItem.price}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
        )
    }







    const [deleteId, setDeleteId] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const showDeleteModal = (deleteId) => {
        setDeleteId(deleteId);
        setDisplayConfirmationModal(true);
    };

    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };


    const submitDelete = async (id) => {
        await deleteMenuItemMutation(id);
        setDisplayConfirmationModal(false);
    };






    const selectFile = async (event) => {
        setProgress(true)
        event.preventDefault();
        if (event.target.files[0].size > 4194304) {
            alert("Your pdf size does not exceeds from 4MB")
        }
        else {
            await addMenuDocMutation({ 'company.companyId': AuthService.getCompanyId(), file: event.target.files[0] })
        }
        setProgress(false)
        setPdfUploaded(true);
        toast.success(`Your menu document has been added and available to view on your public profile`, { position: toast.POSITION.TOP_CENTER });
    };

    const selectFile2 = (event) => {
        if (event.target.files[0].size > 1048576) {
            alert("Menu Item image size does not exceeds from 1MB")
        }
        else {
            setCurrentFile(event.target.files[0]);
            setPreviewImage(URL.createObjectURL(event.target.files[0]));
            setMessage("");
        }
    };


    const documentThmbnail = (doc) => {
        const ext = doc.substr(dataMenuDocument.payload.documentFileName.lastIndexOf('.') + 1).toLowerCase();

        return (
            <>
                {(ext === "png" || ext === "gif" || ext === "jpeg" || ext === "jpg") ?
                    <>
                        <a target="_blank" href={process.env.REACT_APP_BASE_URL + "MediaStorage/MenuItemDocuments/" + doc}><br /><img src={process.env.REACT_APP_BASE_URL + "MediaStorage/MenuItemDocuments/" + doc} alt="Menu" className="viewImg" /></a>
                        <span className="simpleIconButton close-btn" data-key={dataMenuDocument.payload.menuDocumentId} onClick={(e) => { deleteMenuDocument(e.currentTarget.getAttribute('data-key')) }}>
                            <i className="fas fa-circle-xmark" title="Delete document"></i>
                        </span>
                    </>
                    :
                    <><a target="_blank" href={process.env.REACT_APP_BASE_URL + "MediaStorage/MenuItemDocuments/" + doc}><i className="p-2 fa-solid fa-file-alt fa-2x"></i><br />click here to view menu document</a><span className="simpleIconButton close-btn" data-key={dataMenuDocument.payload.menuDocumentId} onClick={(e) => { deleteMenuDocument(e.currentTarget.getAttribute('data-key')) }}><i className="fas fa-circle-xmark" title="Delete document"></i></span></>}
            </>
        )

    }


    return (
        <div>
            <div className="row menuitem">
                <div className="signin-up-wrapper align-text-bottom d-none d-lg-block">
                    <h3>
                        <strong>Menu Details</strong>
                    </h3>
                </div>

                <div>



                    <div className="row py-3">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" >
                            <h3 className="mt-2">Please enter your website menu url</h3>
                            <input className="form-control form-control-lg" type="url" placeholder="Website Menu URL" aria-label="Website Menu URL" value={externalMenuURL} onChange={(e) => setExternalMenuURL(e.target.value)} />
                            <button className="btn btn-danger btn-lg mt-2" onClick={(e) =>
                                saveExternalMenuURL({ externalMenuURL: externalMenuURL }).then((response) => {
                                    toast.success(response.data.message, { position: toast.POSITION.TOP_CENTER })
                                })
                            }>Save</button>

                            <h3 className="mt-5 pb-2 border-bottom">Upload menu pdf/docx/jpg</h3>

                            <div className="awardCard caterer-upload-menu" style={{ 'minHeight': 'unset' }}>
                                <div className="row">
                                    <div className="col-auto" >
                                        {progress ? <div style={{ 'height': '100px', 'width': '130px', 'backgroundColor': '#e6e6e6' }} className="rounded border-0 align-self-center text-center"><span className="mt-4 spinner-border spinner-border-sm"></span><br />Uploading...</div> :
                                            <div>
                                                <input type="file" accept="application/pdf, image/png, image/gif, image/jpeg, application/msword" id="btn-upload" hidden onChange={selectFile} />
                                                <label htmlFor="btn-upload" style={{ 'height': '100px', 'width': '130px', 'cursor': 'pointer', 'backgroundColor': '#e6e6e6' }} title="click here to upload menu in PDF format" className="rounded border-0 align-self-center text-center"><i className="p-2 fa-solid fa-upload fa-2x"></i>upload menu</label>
                                            </div>
                                        }
                                    </div>

                                    <div className="col-auto position-relative" >
                                        {
                                            isLoadingMenuDocument ? "" :
                                                dataMenuDocument.payload &&
                                                <div style={{ 'height': '100px', 'width': '160px', 'backgroundColor': '#e6e6e6', 'overflow': 'hidden' }} className="view rounded border-0 align-self-center text-center">
                                                    {documentThmbnail(dataMenuDocument.payload.documentFileName)}
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 border-bottom justify-content-sm-between pb-2 ">
                        {(isLoadingPackageFeature === false && isLoadingMenuItemByCompany === false) &&
                        
                            (dataMenuItemByCompany.payload.length >= dataPackageFeature.payload.packageFeatureValue) ?
                            <div>Your current subscription does not allow you to add more Menu Items <a href='../company-panel'>Click here to upgrade your subscription</a></div>
                            :
                            <div className="col-sm-auto d-flex align-items-center gap-3">
                                <h3 className="mb-0">Create digital menu</h3>
                                <i className="fa fa-plus-circle fa-xl text-success" style={{ 'cursor': 'pointer' }} title='add new menu item' data-bs-toggle="modal" data-bs-target="#addMenuModal" aria-hidden="true" ></i>
                            </div>
                        }
                        <div className="col-sm-auto my-2 my-sm-0">
                            {isLoadingMenuItemCategory ? <h1>loading...</h1> :
                                <select style={{ 'fontSize': '14px' }} className="form-select" aria-label="Default select example" onChange={(e) => setMenuCategoryId(e.target.value)}>
                                    <option value="0">Select Menu Category</option>
                                    {
                                        dataMenuItemCategory.payload.map((st, index) =>
                                            <option key={index} value={st.menuItemCategoryId} >{st.menuItemCategoryName}</option>
                                        )
                                    }
                                </select>
                            }
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-12">
                            <div className="container">
                                <MenuList />
                                <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} id={deleteId} message={"Are you sure to delete this Menu Item ?"} />
                            </div>

                            <div className="event-collection-modal modal fade" id="addMenuModal" tabIndex="-1" role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-xl" role="document">
                                    <div className="modal-content p-4 p-md-5">
                                        <div className="modal-header  pb-0">
                                            <div className="modal-title col-lg-7 col-md-7 col-10 ps-lg-0 p-lg-2 px-md-2">
                                                <h6>New Menu Item</h6>
                                            </div>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body p-4">
                                            <div className="row">
                                                <div className="col-sm-4 text-sm-end">
                                                    <label className="col-form-label">Cuisine</label>
                                                </div>
                                                <div className="col-sm-8">
                                                    {isLoadingCuisine ? "" :
                                                        <select value={cuisineId}
                                                            className={cuisineId === "0" ? "combobox-little dropdown border " : "combobox-little dropdown"}
                                                            onChange={(e) => setCuisineId(e.target.value)}>
                                                            <option value="0">Select Cuisine</option>
                                                            {
                                                                dataCuisine.payload.map((cui, index) =>
                                                                    <option key={index} value={cui.cuisineId} >{cui.cuisineName}</option>
                                                                )
                                                            }
                                                        </select>
                                                    }
                                                </div>
                                            </div>


                                            <div className="row align-items-center">
                                                <div className="col-sm-4 text-sm-end">
                                                    <label className="col-form-label">Category</label>
                                                </div>
                                                <div className="col-sm-8">
                                                    {isLoadingMenuItemCategory ? "" :
                                                        <select style={{ 'fontSize': '14px' }} className="form-select" aria-label="Default select example"
                                                            onChange={(e) => setMenuItemCategoryId(e.target.value)}>
                                                            <option value="0">Select Menu Item Category</option>
                                                            {
                                                                dataMenuItemCategory.payload.map((mic, index) =>
                                                                    <option key={index} value={mic.menuItemCategoryId} >{mic.menuItemCategoryName}</option>
                                                                )
                                                            }
                                                        </select>
                                                    }
                                                </div>
                                            </div>




                                            <div className="row align-items-center">
                                                <div className="col-sm-4 text-sm-end">
                                                    <label className="col-form-label">Menu Item Name</label>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input type="text" placeholder="Menu Item Name" aria-label="Menu Item Name"
                                                        onChange={(e) => setMenuItemName(e.target.value)}
                                                        required
                                                        value={menuItemName}
                                                        className={menuItemName === "" ? "form-control-little border " : "form-control-little"}
                                                        maxLength="100" />
                                                </div>
                                            </div>




                                            <div className="row align-items-center">
                                                <div className="col-auto col-sm-4 text-sm-end">
                                                    <label className="col-form-label">Is this your speciality</label>
                                                </div>
                                                <div className="col-auto col-sm-8">
                                                    <input className="my-3" style={{ 'width': '18px', 'height': '18px', 'float': 'none', 'accentColor': 'red' }} type="checkbox" onChange={(e) => setIsSpecialty(e.target.checked)} checked={isSpecialty} />
                                                </div>
                                            </div>


                                            <div className="row align-items-center">
                                                <div className="col-auto col-sm-4 text-sm-end">
                                                    <label className="col-form-label">Price</label>
                                                </div>
                                                <div className="col-auto col-sm-8">
                                                    <MaskedInput required onChange={(e) => setPrice(e.target.value.replace('$', '').replace(/_/g, ''))} mask={['$', /[1-9]/, /[1-9]?/, /[1-9]?/, /[0-9]?/, /./, /[0-9]/, /[0-9]/]} className={price === "" ? "form-control-little border " : "form-control-little"} value={price} style={{ 'width': '80px' }} />
                                                </div>
                                            </div>



                                            <div className="row align-items-center">
                                                <div className="col-auto col-sm-4 text-sm-end">
                                                    <label className="col-form-label">No. of servings</label>
                                                </div>
                                                <div className="col-auto col-sm-8">
                                                    <select value={noOfServing} style={{ 'width': '60px' }}
                                                        className={noOfServing === "0" ? "combobox-little dropdown border border-danger" : "combobox-little dropdown"}
                                                        onChange={(e) => setNoOfServing(e.target.value)}>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="18">18</option>
                                                        <option value="19">19</option>
                                                        <option value="20">20</option>
                                                    </select>
                                                </div>
                                            </div>



                                            <div className="row align-items-center pb-1">
                                                <div className="col-sm-4 text-sm-end">
                                                    <label className="col-form-label">Dietary</label>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="row pe-lg-5">
                                                        {isLoadingDietaries ? <h6>Loading...</h6> :
                                                            dataDietaries.payload.map((spc, index) =>
                                                                <div className="col-lg-4 col-md-6 col-sm-6" key={index} >
                                                                    <label htmlFor={'die' + index} className="form-check-label">
                                                                        <input name="cbDietary" value={spc.dietaryId} className="form-check-input ps-2" onChange={handleCheck} type="checkbox" id={'die' + index} />&nbsp;{spc.dietaryName}
                                                                    </label>
                                                                </div>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row align-items-center pb-3">
                                                <div className="col-sm-4 text-sm-end">
                                                    <label className="col-form-label">Description</label>
                                                </div>
                                                <div className="col-sm-8">
                                                    <textarea className="form-control-little" value={description} onChange={(e) => setDescription(e.target.value)} maxLength="250"></textarea>
                                                </div>
                                            </div>

                                            <div className="row border-top pt-3">
                                                <div className="col-sm-4 text-sm-end">
                                                    <label className="col-form-label">Menu Image (optional)</label>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="row rounded p-2" style={{ 'border': '1px dashed #bbbbbb' }}>
                                                        <div className="col-12 padding0 col-sm-auto">
                                                            <input type="file" accept="image/*" onChange={selectFile2} />
                                                        </div>

                                                        <div className="col-12 text-center mt-2 mt-lg-0 col-sm-auto">
                                                            {previewImage && (
                                                                <div style={{ 'background': '#f5f5f5' }} >
                                                                    <img className="preview" src={previewImage} style={{ 'height': '100px', 'width': '100px', 'objectFit': 'contain' }} alt="" />
                                                                </div>
                                                            )}

                                                            {message && (
                                                                <div className="alert alert-secondary mt-3" role="alert">
                                                                    {message}
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <div className="row">
                                                <div className="col text-sm-end">
                                                    <button className="btn btn-danger" disabled={menuItemName === "" || cuisineId === "0" || menuItemCategoryId === "0" || price === "" || checked.length === 0 ? true : false} onClick={(e) => addMenuItem()}><i className="fas fa-plus"></i>&nbsp;Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuItem
