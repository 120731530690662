import React from 'react'
import Header from './Header';
import Footer from './Footer';

const AboutUs = () => {






    return (


        <div>
            <Header />
            <section className="customer-banner about">
                <div className="container-fluid banner-home d-flex justify-content-center align-items-center">
                    <div className="row margin0 justify-content-center">
                        <div className="col-auto">
                            <h1 className="text-uppercase banner-title"><strong>ABOUT US</strong></h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="customer-about-catering">
                <div className="container">
                    <div className="row align-items-center justify-content-center px-xxl-5 mx-xxl-5 py-xxl-5 my-5">
                        <div className="col-12">
                            <h2 className="section-heading mb-4">About Catering</h2>
                            <img className="w-100 my-4" src="/images/customer-website/about-catering.jpg" alt="" />
                            <p><strong>Catering</strong> is a platform that inspires people planning all types of events and connects them with the leading event professionals, vendors, and venues across the country and the world.</p>
                            <p>People planning all types of events — from weddings and holiday parties to birthday parties and galas — come to <strong>Catering</strong> to find new and unique party ideas, discover the best local vendors and venues, and build their event teams.</p>
                            <p>Event professionals, venues, and vendors use <strong>Catering</strong> to grow their businesses, connect with industry peers, and reach more clients. Companies can share photos and videos from all types of events, get recognized for their work with our unique crediting system, and use their content-rich profiles as portfolio-sharing tools.</p>
                            <div className="d-flex justify-content-center mt-5 gap-4">
                                <a className="customer btn-primary" href="/live-menu">Live Menu</a>
                                <a className="customer btn-secondary" href="/tray-menu">Tray Menu</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer></Footer>






        </div>




    )
}

export default AboutUs