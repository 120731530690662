import React from "react";
import { useGetOfferSearchCountReportQuery } from './../hooks/apiSlice';
import Accordion from 'react-bootstrap/Accordion';
import DailyKeywordInsightsAndTrends from "./insides/DailyKeywordInsightsAndTrends";
import ClickThroughReport from "./insides/ClickThroughReport";







const AdminReports = () => {

    //const { data: dataDuplicateCaterer, isLoading: isLoadingDuplicateCaterer } = useGetDuplicateCatererQuery()
    const { data: dataOfferSearchCount, isLoading: isLoadingOfferSearchCount } = useGetOfferSearchCountReportQuery()

    return (
        <>
            <div className="row m-3">
                <div className="col-1 p-0">
                    <ul className="nav nav-tabs" id="myDealTab" role="tablist">
                        <li className="w-100" role="presentation">
                            <span className="nav-link2 w-100 active p-3" id="flat-deal-tab" data-bs-toggle="tab" data-bs-target="#flat-deal" type="button" role="tab" aria-controls="flat-deal" aria-selected="true" >Search Keyword Counts</span>
                        </li>
                        <li className="w-100" role="presentation">
                            <span className="nav-link2 w-100 p-3" id="custom-deal-tab" data-bs-toggle="tab" data-bs-target="#custom-deal" type="button" role="tab" aria-controls="custom-deal" aria-selected="false" >Daily Keyword Insights and Trends</span>
                        </li>
                        <li className="w-100" role="presentation">
                            <span className="nav-link2 w-100 p-3" id="click-through-tab" data-bs-toggle="tab" data-bs-target="#click-through" type="button" role="tab" aria-controls="click-through" aria-selected="false" >Click Through Report</span>
                        </li>
                    </ul>
                </div>
                <div className="col-11 p-0">
                    <div className="tab-content p-4" style={{ 'backgroundColor': '#e1e3ee' }} id="myTabContent">
                        <div className="tab-pane fade show active" id="flat-deal" role="tabpanel" aria-labelledby="flat-deal">
                            <div className="row">
                                <div className="col">
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Event Type</Accordion.Header>
                                            <Accordion.Body>
                                                {isLoadingOfferSearchCount ? "Loading" :
                                                    <table className="table-hover table-striped table" ><thead>
                                                        <tr>
                                                            <th>Offer Name</th>
                                                            <th>Search Count</th>
                                                        </tr>
                                                    </thead>
                                                        <tbody>
                                                            {dataOfferSearchCount.filter((item) => item.offer === 'EventTypes').map((rpt, index) =>
                                                                <tr key={index}>
                                                                    <td>{rpt.offerName}</td>
                                                                    <td>{rpt.searchCount}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Cuisines</Accordion.Header>
                                            <Accordion.Body>
                                                {isLoadingOfferSearchCount ? "Loading" :
                                                    <table className="table-hover table-striped table" ><thead>
                                                        <tr>
                                                            <th>Offer Name</th>
                                                            <th>Search Count</th>
                                                        </tr>
                                                    </thead>
                                                        <tbody>
                                                            {dataOfferSearchCount.filter((item) => item.offer === 'Cuisines').map((rpt, index) =>
                                                                <tr key={index}>
                                                                    <td>{rpt.offerName}</td>
                                                                    <td>{rpt.searchCount}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Specialities</Accordion.Header>
                                            <Accordion.Body>
                                                {isLoadingOfferSearchCount ? "Loading" :
                                                    <table className="table-hover table-striped table" ><thead>
                                                        <tr>
                                                            <th>Offer Name</th>
                                                            <th>Search Count</th>
                                                        </tr>
                                                    </thead>
                                                        <tbody>
                                                            {dataOfferSearchCount.filter((item) => item.offer === 'Specialities').map((rpt, index) =>
                                                                <tr key={index}>
                                                                    <td>{rpt.offerName}</td>
                                                                    <td>{rpt.searchCount}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>City/Zip</Accordion.Header>
                                            <Accordion.Body>
                                                {isLoadingOfferSearchCount ? "Loading" :
                                                    <table className="table-hover table-striped table" ><thead>
                                                        <tr>
                                                            <th>Offer Name</th>
                                                            <th>Search Count</th>
                                                        </tr>
                                                    </thead>
                                                        <tbody>
                                                            {dataOfferSearchCount.filter((item) => item.offer === 'Location').map((rpt, index) =>
                                                                <tr key={index}>
                                                                    <td>{rpt.offerName}</td>
                                                                    <td>{rpt.searchCount}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="custom-deal" role="tabpanel" aria-labelledby="custom-deal-tab">
                            <DailyKeywordInsightsAndTrends />
                        </div>
                        <div className="tab-pane fade" id="click-through" role="tabpanel" aria-labelledby="click-through-tab">
                            <ClickThroughReport />
                        </div>
                    </div>
                </div>
            </div>





            {/* {isLoadingDuplicateCaterer ? "Loading" :
                <table className="table-hover table-striped table" ><thead>
                    <tr>
                        <th>Caterer Name</th>
                        <th>Caterer Contact</th>
                        <th>Street Address</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zip</th>
                        <th>Occurrence</th>
                    </tr>
                </thead>
                    <tbody>
                        {dataDuplicateCaterer.length === 0 ? <tr><td colSpan="5" className="text-center p-4">No duplicate caterer(s) found</td></tr> :
                            dataDuplicateCaterer.map((cat, index) =>
                                <tr key={index}>
                                    <td><img src={cat.imageFile ? `data:image/png;base64,${cat.imageFile}` : '/images/DefaultCaterer.png'} alt="" style={{ 'height': '30px', 'width': '30px' }} className="bg-info rounded-circle" />&nbsp;{cat.catererName}</td>
                                    <td>&nbsp;&nbsp;{cat.contactNumber}</td>
                                    <td>&nbsp;&nbsp;{cat.streetAddress}</td>
                                    <td>&nbsp;&nbsp;{cat.city}</td>
                                    <td>&nbsp;&nbsp;{cat.stateName}</td>
                                    <td>&nbsp;&nbsp;{cat.zipcode}</td>
                                    <td>&nbsp;&nbsp;<b>{cat.occurrence}</b></td>
                                </tr>
                            )}
                    </tbody>
                </table>
            } */}
        </>
    )
}
export default AdminReports