import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import Review from "../components/Review";
import EventGallery from "../components/EventGallery";
import Menu from "../components/Menu";
import ClaimForm from "../components/ClaimForm";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useGetCompanyDetailQuery, useGetEventTypesQuery, useSaveMessageMutation } from '../hooks/apiSlice';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import RatingStar from "./../components/RatingStar"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AuthService from "./../hooks/Auth";
import { Helmet } from 'react-helmet-async';
import { getCurrentPathWithoutQueryParameters } from '../hooks/util';
import { HCSpinner } from '../hooks/util';


const CompanyDetail = () => {
  const params = useParams();
  const [showEventGallery, setShowEventGallery] = useState(false);
  const [defualtVal, setDefualtVal] = useState(window.location.search.indexOf('claim-model=yes') > 0);

  const { data: dataCompanyDetail, isLoading: isLoadingCompanyDetail } = useGetCompanyDetailQuery(params.companyId)
  const { data: dataEventTypes, isLoading: isLoadingEventTypes } = useGetEventTypesQuery()
  const [saveMessageMutation] = useSaveMessageMutation()


  const getAvgStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (rating - 1 < i) {
        stars.push(<i key={i} className="fa-regular fa-star"></i>);
      } else {
        stars.push(<i key={i} className="fa-solid fa-star checked"></i>);
      }
    }
    return stars;
  }

  useEffect(() => {

    // if (window.location.search.indexOf('claim-model=yes'))
    //   setDefualtVal(true);

      if ((!isLoadingCompanyDetail) && (!dataCompanyDetail.payload.companyTitle !== '')) {
        //make sure the URL been redirected to correct canonical
        if (window.location.pathname !== "/company-detail/" + dataCompanyDetail.payload.companyId + "/" + dataCompanyDetail.payload.locationSlug + "/" + dataCompanyDetail.payload.companySlug)
          window.location.href = "/company-detail/" + dataCompanyDetail.payload.companyId + "/" + dataCompanyDetail.payload.locationSlug + "/" + dataCompanyDetail.payload.companySlug

        document.title = dataCompanyDetail.payload.companyTitle + " | Halal Caters";
      }
  }, [isLoadingCompanyDetail, params.companyId]);




  const OffersCarousel = ({ aryItems, entityName }) => {
    const resultsRender = [];
    for (var i = 0; i < aryItems.length; i += 3) {
      resultsRender.push(
        <div key={i} className={i === 0 ? "carousel-item active" : "carousel-item"}><div className="row px-4">
          {
            aryItems.slice(i, i + 3)
              .map((spc, index) => (
                <div key={index} className="col-4">
                  <img className="rounded-circle shadow-1-strong"
                    src={process.env.REACT_APP_BASE_URL + "MediaStorage/Offers/" + spc.imageFileName} alt="avatar" style={{ width: "62px" }} title={entityName === "Certification" ? "please confirm current status as certifications get evaluated on annual basis" : ""} />
                  <p className="margin0 pt-2">{Object.values(spc)[1]}</p>
                </div>
              ))
          }
        </div></div>
      );
    }
    return (

      <div id={entityName} className="carouselSpeciality carousel slide carousel-dark text-center mt-3" data-bs-ride="false" data-bs-interval="500" data-bs-wrap="false">
        {aryItems.length >= 4 &&
          <>
            <button className="carousel-control-prev" type="button" data-bs-target={'#' + entityName} data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target={'#' + entityName} data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </>
        }
        <div className="carousel-inner">
          {resultsRender}
        </div>
      </div>
    );
  };


  const [showMessageModel, setShowMessageModel] = useState(false);
  
  function SendMessage() {
    const [message, setMessage] = useState('');

    const sendMessageHandler = (e) => {
      saveMessageMutation({ 'company.companyId': params.companyId, messageBody: message })
      toast.success(`Your Message has been sent successfully`, { position: toast.POSITION.TOP_CENTER })
    }





    return (
      <Modal show={showMessageModel} >
        <Modal.Header>
          <Modal.Title><strong>Tell us briefly what you’re looking for.</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-log">
              <textarea className="form-control form-control-lg" placeholder="Add your message here" row="8" onChange={(e) => { setMessage(e.target.value) }} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-danger" onClick={() => {
            setShowMessageModel(false);
          }}>Close</button>
          <button type="button" className="btn btn-danger" onClick={() => {
            sendMessageHandler();
            setShowMessageModel(false);
          }}>Send Message</button>
        </Modal.Footer>
      </Modal>
    )
  }





  const FindYourOrderValue = () => {
    const [personCount, setPersonCount] = useState('')
    const [eventTypeSelect, setEventTypeSelect] = useState('')
    const [startDate, setStartDate] = useState(new Date());

    return (
      <div className="calculate-form mb-4">
        <div className="col-12">

          <div className="card">
            <div className="card-body">



              <h5>Find your order value</h5>
              <div className="input-group mb-3">
                <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa-solid fa-user-group"></i></span>
                <input type="text" className="form-control guest-input" placeholder="No of Guests" maxLength="4" value={personCount} onChange={(e) => { setPersonCount(e.target.value) }} />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" id="inputGroup-sizing-default">
                  <i className="fa-solid fa-calendar"></i>
                </span>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control guest-input" placeholder="Schedule" />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa-solid fa-calendar"></i></span>
                {isLoadingEventTypes ? <HCSpinner /> :
                  <select className="form-select event-type" id="select-eventType" onChange={(e) => { setEventTypeSelect(e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text) }} name="event">
                    <option value="">Select your event</option>
                    {
                      dataEventTypes.payload.map((et, index) =>
                        <option key={index} text={et.eventTypeName} value={et.eventTypeId}>{et.eventTypeName}</option>
                      )
                    }
                  </select>
                }


              </div>
              <div className="row mx-1">
                <button className="btn p-2 btn-danger" onClick={() => {
                  saveMessageMutation({ 'company.companyId': params.companyId, 'userProfile.userProfileId': AuthService.getUserProfileId(), contactNumber: '9999999999', messageBody: "Request a Quote Received, Number of Persons: " + personCount + ", Date: " + startDate.toDateString() + ", Event:" + eventTypeSelect })
                  toast.success(`Your Quote request has been sent successfully`, { position: toast.POSITION.TOP_CENTER })
                }}>Request Quote</button>
                <p className="disc mt-3"><i className="fa-solid fa-comment"></i> Responed within <strong>a day</strong></p>
              </div>

            </div>
          </div>


        </div>

      </div>
    );
  };

  function ClaimFormHandler(props) {
    const [show, setShow] = useState(props.defualtVal);
    const [submitted, setSubmitted] = useState(false);

    const handleShow = () => setShow(true);
    return (
      !submitted &&
      <>
        <button type="button" className="claim-profile" onClick={handleShow}>
          Claim Profile
        </button>
        <ClaimForm show={show} setSubmitted={setSubmitted} setShow={setShow} />
      </>
    )
  }






  return (

    <div>



      <ToastContainer />
      <Navbar />


      <main role="main">

        <section className="vendor-container">

          <div className="container">
            <div className="row pb-3">
              <div className="col-12">
                <nav className="navbar navbar-expand-lg" id="navbar_top">
                  <div className="container px-4">

                    <ul className="navbar-nav">
                      <li className="nav-item"><a className="nav-link" href="#overview">About</a></li>
                      <li className="nav-item"><a className="nav-link" href="#reviews">Reviews</a></li>
                      {showEventGallery && <li className="nav-item"><a className="nav-link" href="#event">Event Gallery</a></li>}
                    </ul>

                  </div>
                </nav>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-12 col-md-12 col-md-8 col-lg-8">

                <section id="overview">
                  <div className="card">
                    <div className="card-body">
                      {isLoadingCompanyDetail ? "Loading" :
                        <div className="row border-top intro">
                          <div className="col-lg-3 col-md-3 col-sm-4 col-6 text-md-center">
                            <Helmet prioritizeSeoTags>
                              <link rel="canonical" href={getCurrentPathWithoutQueryParameters()} />
                            </Helmet>
                            <div className="profile-wrapper">
                              {
                                dataCompanyDetail.payload.logoFileName ?
                                  <img className="image--cover" src={process.env.REACT_APP_BASE_URL + "MediaStorage/logo/" + dataCompanyDetail.payload.logoFileName} alt={dataCompanyDetail.payload.companyName} style={{ 'maxHeight': '200px', 'maxWidth': '200px' }} />
                                  :
                                  <div className="picture-placeholder">
                                    <div>{dataCompanyDetail.payload.companyName.substring(0, 1)}</div>
                                  </div>
                              }
                            </div>
                            <div className="d-sm-none d-flex flex-column align-items-center mt-2">
                              <ul className="social-icons d-flex align-items-start">
                                {dataCompanyDetail.payload.facebookURL != "" && <li><a href={dataCompanyDetail.payload.facebookURL} target="_blank"><i className="fa-brands fa-facebook-f"></i></a></li>}
                                {dataCompanyDetail.payload.twitterURL != "" && <li><a href={dataCompanyDetail.payload.twitterURL} target="_blank"><i className="fa-brands fa-twitter"></i></a></li>}
                                {dataCompanyDetail.payload.instagramURL != "" && <li><a href={dataCompanyDetail.payload.instagramURL} target="_blank"><i className="fa-brands fa-instagram"></i></a></li>}
                                {dataCompanyDetail.payload.pinterestURL != "" && <li><a href={dataCompanyDetail.payload.pinterestURL} target="_blank"><i className="fa-brands fa-pinterest"></i></a></li>}
                                <li><a href="#" data-bs-toggle="modal" data-bs-target="#videoModal"><i className="fa-solid fa-circle-play"></i></a></li>
                              </ul>
                              
                              <ClaimFormHandler />

                            </div>
                          </div>
                          <div className="col-lg-5 col-md-5 col-sm-5 col-6   align-self-md-center padding0 profile-info">
                            <div className="row">
                              <div className="col">
                                <h2> {dataCompanyDetail.payload.companyName}
                                </h2>
                              </div>
                            </div>
                            <div className="row">
                              {(dataCompanyDetail.payload.addresses.length > 0)
                                &&
                                <div className="col-12"><h6><img style={{ 'marginRight': '3px' }} src="/images/marker1.svg" />&nbsp;{dataCompanyDetail.payload.addresses[0].streetAddress}, {dataCompanyDetail.payload.addresses[0].city}{dataCompanyDetail.payload.addresses[0].zipCode !== null && ', ' + dataCompanyDetail.payload.addresses[0].zipCode}, {dataCompanyDetail.payload.addresses[0].state.stateName}</h6></div>
                              }
                            </div>
                            <div className="row">
                              {(dataCompanyDetail.payload.contactNumber !== '' && dataCompanyDetail.payload.contactNumber !== null)
                                &&
                                <div className="col-12"><h6><img style={{ 'marginRight': '3px' }} src="/images/phone.svg" title={dataCompanyDetail.payload.contactNumber} />&nbsp;{dataCompanyDetail.payload.contactNumber}</h6></div>
                              }
                            </div>
                            <div className="row mb-sm-2">
                              {(dataCompanyDetail.payload.website !== '')
                                &&
                                <div className="col-12"><h6 className="web"><a style={{ 'textDecoration': 'none', 'color': '#000' }} href={dataCompanyDetail.payload.website} target="_blank"><img style={{ 'marginTop': '-1px', 'marginRight': '3px' }} src="/images/web-link.svg" title={dataCompanyDetail.payload.website} />
                                  &nbsp;Website</a></h6></div>
                              }
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <ul className="social-icons earned-badge-mob d-flex gap-2 d-sm-none">
                                  {dataCompanyDetail.payload.isVetted &&
                                    <li>
                                      <img src="/images/vetted-inf.png" height="14" alt="" title="It is zabiha verified and confirmed by our team" />
                                      <h6>&nbsp;Vetted</h6>
                                    </li>}

                                  {dataCompanyDetail.payload.isVerified &&
                                    <li>
                                      <img src="/images/vetted-icon.svg" height="10" alt="" title="Profile contact information is verified by our team" />
                                      <h6>&nbsp;Verified</h6>
                                    </li>}
                                </ul>
                                <div className="badges d-inline-flex flex-wrap gap-1 mb-2 d-sm-none">
                                  {
                                    isLoadingCompanyDetail ? <h6>Loading...</h6> :
                                      dataCompanyDetail.payload.dietaries.map((dry, index) =>
                                        <div key={index} className="items">
                                          <img className="mx-1" src={"/images/dietaryIcon_" + dry.dietaryName.replace(" ", "") + ".png"} alt="Halal" width="32px" height="32px" title={dry.dietaryName} />
                                          <p className="margin0">{dry.dietaryName}</p>
                                        </div>
                                      )
                                  }
                                </div>
                                <ul className="social-icons d-none align-items-start d-sm-flex">
                                  {dataCompanyDetail.payload.facebookURL != "" && <li><a href={dataCompanyDetail.payload.facebookURL} target="_blank"><i className="fa-brands fa-facebook-f"></i></a></li>}
                                  {dataCompanyDetail.payload.twitterURL != "" && <li><a href={dataCompanyDetail.payload.twitterURL} target="_blank"><i className="fa-brands fa-twitter"></i></a></li>}
                                  {dataCompanyDetail.payload.instagramURL != "" && <li><a href={dataCompanyDetail.payload.instagramURL} target="_blank"><i className="fa-brands fa-instagram"></i></a></li>}
                                  {dataCompanyDetail.payload.pinterestURL != "" && <li><a href={dataCompanyDetail.payload.pinterestURL} target="_blank"><i className="fa-brands fa-pinterest"></i></a></li>}
                                </ul>
                                <div className="modal fade" id="videoModal" tabIndex="-1" role="dialog" aria-hidden="true">
                                  <div className="modal-dialog" role="document">
                                    <div className="modal-content p-4">
                                      <div className="modal-header border-0 p-0 pb-4">
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                      </div>
                                      <div className="modal-body">
                                        Video
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-3 col-2 ps-sm-0 align-items-center align-self-center text-sm-end d-none d-sm-block">
                            <div className="mb-2 mb-md-3">
                              {(!(dataCompanyDetail.payload.isVerified || dataCompanyDetail.payload.isVetted)) &&
                                  <ClaimFormHandler defualtVal={defualtVal} />
                              }
                              <ul className="social-icons earned-badge d-flex flex-wrap gap-2 align-items-stretch justify-content-sm-end">

                                {dataCompanyDetail.payload.isVetted &&
                                  <li>
                                    <img className="info" src="/images/info-icon.png" height="12" alt="" title="It is zabiha verified and confirmed by our team" />
                                    <img className="badge-icon1 mx-1" src="/images/vetted-inf.png" height="20" alt="" />
                                    Vetted
                                  </li>}

                                {dataCompanyDetail.payload.isVerified &&
                                  <li>
                                    <img className="info" src="/images/info-icon.png" height="12" alt="" title="Profile contact information is verified by our team" />
                                    <img className="badge-icon2 mx-1" src="/images/vetted-icon.svg" height="16" alt="" />
                                    Verified
                                  </li>}

                              </ul>
                            </div>
                            <div className="badges d-inline-flex flex-wrap justify-content-end gap-1">
                              {dataCompanyDetail.payload.dietaries.map((dry, index) =>
                                <div key={index} className="items">
                                  <img className="mx-1" src={"/images/dietaryIcon_" + dry.dietaryName.replace(" ", "") + ".png"} alt="Halal" width="32px" height="32px" title={dry.dietaryName} />
                                  <p className="margin0">{dry.dietaryName}</p>
                                </div>
                              )}
                            </div>
                          </div>

                        </div>
                      }

                      {isLoadingCompanyDetail ? "Loading" : dataCompanyDetail.payload.niches[0].nicheId !== 1 &&

                        <div className="row calculate-form border-top d-lg-none">
                          <div className="col-12">

                            <div className="card">
                              <div className="card-body">
                                <h5>Find your order value</h5>
                                <div className="input-group mb-3">
                                  <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa-solid fa-user-group"></i></span>
                                  <input type="text" className="form-control guest-input" placeholder="No of Guests" />
                                </div>
                                <div className="input-group mb-3">
                                  <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa-solid fa-calendar"></i></span>
                                  <input type="text" className="form-control guest-input" placeholder="Schedule" />
                                </div>
                                <div className="input-group mb-3">
                                  <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa-solid fa-calendar"></i></span>
                                  <select className="form-select event-type">
                                    <option value="" disabled>Event Type</option>
                                    <option value="Valima">Valima</option>
                                    <option value="Baraat">Baraat</option>
                                    <option value="Birthday">Birthday</option>
                                  </select>
                                </div>
                                <div className="row mx-1">
                                  <button className="btn p-2 btn-danger">Request Quote</button>
                                  <p className="disc mt-3"><i className="fa-solid fa-comment"></i> Responed within <strong>a day</strong></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }


                      {
                        isLoadingCompanyDetail ? "Loading" :
                          <div className="row border-top border-bottom about">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-4">
                              {dataCompanyDetail.payload.description &&
                                <>
                                  <h4>Introduction</h4>
                                  <p className="margin0">{dataCompanyDetail.payload.description}</p> </>
                              }
                              <div className="d-flex flex-wrap justify-content-between mt-4">

                                <div className="first-col">
                                  <h6>Overview</h6>
                                  <div className="items">

                                    {dataCompanyDetail.payload.niches[0].nicheId !== 1 &&
                                      <div className="d-flex align-items-center mb-1">
                                        <div className="d-flex align-items-center mr-2">
                                          <svg height="18" width="18" fill="currentColor" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="M15.5 6.75a2.248 2.248 0 01-1.59 2.14c.053-.29.09-.585.09-.89V3.004h1.25a.25.25 0 01.25.25V6.75zM12 8c0 1.654-1.346 3-3 3S6 9.654 6 8V3h6v5zM2.5 6.75V3.246a.25.25 0 01.25-.25H4V8c0 .305.037.6.09.89A2.248 2.248 0 012.5 6.75zM15.25 1H2.75C1.785 1 1 1.785 1 2.75v4a3.75 3.75 0 003.692 3.744c.706 1.214 1.89 2.115 3.308 2.403V15H6a1 1 0 100 2h6a1 1 0 100-2h-2v-2.103c1.418-.288 2.603-1.189 3.308-2.403A3.75 3.75 0 0017 6.75v-4C17 1.785 16.215 1 15.25 1z"></path></svg>
                                        </div>
                                        <p className="margin0">Hired {dataCompanyDetail.payload.hiredCount} times</p>
                                      </div>}

                                    {(dataCompanyDetail.payload.addresses.length > 0)
                                      &&
                                      <div className="d-flex align-items-center mb-1">
                                        <div className="d-flex align-items-center mr-2">
                                          <svg height="18" width="18" fill="currentColor" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="M3.002 7.25c0 3.248 4.342 7.756 5.23 8.825l.769.925.769-.926c.888-1.068 5.234-5.553 5.234-8.824C15.004 4.145 13 1 9.001 1c-3.999 0-6 3.145-6 6.25zm1.994 0C4.995 5.135 6.175 3 9 3s4.002 2.135 4.002 4.25c0 1.777-2.177 4.248-4.002 6.59C7.1 11.4 4.996 9.021 4.996 7.25zM8.909 5.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z"></path></svg>                                      </div>
                                        <p className="margin0">{dataCompanyDetail.payload.addresses[0].city}, {dataCompanyDetail.payload.addresses[0].state.stateName}</p>
                                      </div>
                                    }


                                    {dataCompanyDetail.payload.isVerified &&
                                      <div className="d-flex align-items-center mb-1">
                                        <div className="d-flex align-items-center mr-2">
                                          <svg height="18" width="18" fill="currentColor" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="M10 10a3 3 0 013 3v3a1 1 0 11-2 0v-3a1 1 0 00-1-1H4a1 1 0 00-.993.883L3 13v3a1 1 0 11-2 0v-3l.005-.176A3 3 0 014 10h6zm6.78-3.78a.75.75 0 010 1.06l-3 3a.75.75 0 01-1.06 0l-1.5-1.5a.75.75 0 011.06-1.06l.97.97 2.47-2.47a.75.75 0 011.06 0zM7 1a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4z"></path></svg>                                      </div>
                                        <p className="margin0">Background checked</p>
                                      </div>
                                    }


                                    {(dataCompanyDetail.payload.niches[0].nicheId !== 1 && dataCompanyDetail.payload.minOrderAmount) &&
                                      <div className="d-flex align-items-center mb-1">
                                        <div className="d-flex align-items-center mr-2">
                                          <svg className="undefined " height="18" width="18" fill="currentColor" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="M9 15c-3.309 0-6-2.691-6-6s2.691-6 6-6c3.31 0 6 2.691 6 6s-2.69 6-6 6zM9 1C4.589 1 1 4.589 1 9s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm.75 7.25h-1.5a.752.752 0 01-.75-.75c0-.413.337-.75.75-.75h2.25a.75.75 0 000-1.5h-.75V5a.75.75 0 00-1.5 0v.25C7.01 5.25 6 6.26 6 7.5s1.01 2.25 2.25 2.25h1.5c.413 0 .75.337.75.75s-.337.75-.75.75H7.5a.75.75 0 000 1.5h.764c-.002.022-.014.044-.014.068V13a.75.75 0 001.5 0v-.182c0-.024-.012-.046-.014-.068h.014c1.24 0 2.25-1.01 2.25-2.25s-1.01-2.25-2.25-2.25z"></path></svg>                                      </div>
                                        <p className="margin0">${dataCompanyDetail.payload.minOrderAmount}/person starting price</p>
                                      </div>
                                    }



                                    <div className="d-flex align-items-center mb-1">
                                      <div className="d-flex align-items-center mr-2">
                                        <svg fill="#000000" width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M16.84,11.63A3,3,0,0,0,19,10.75l2.83-2.83a1,1,0,0,0,0-1.41,1,1,0,0,0-1.42,0L17.55,9.33a1,1,0,0,1-1.42,0h0L19.67,5.8a1,1,0,1,0-1.42-1.42L14.72,7.92a1,1,0,0,1,0-1.41l2.83-2.83a1,1,0,1,0-1.42-1.42L13.3,5.09a3,3,0,0,0,0,4.24h0L12,10.62,3.73,2.32l-.1-.06a.71.71,0,0,0-.17-.11l-.18-.07L3.16,2H3.09l-.2,0a.57.57,0,0,0-.18,0,.7.7,0,0,0-.17.09l-.16.1-.07,0-.06.1a1,1,0,0,0-.11.17,1.07,1.07,0,0,0-.07.19s0,.07,0,.11a11,11,0,0,0,3.11,9.34l2.64,2.63-5.41,5.4a1,1,0,0,0,0,1.42,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29L9.9,15.57h0l2.83-2.83h0l2-2A3,3,0,0,0,16.84,11.63ZM9.19,13.45,6.56,10.81A9.06,9.06,0,0,1,4,5.4L10.61,12Zm6.24.57A1,1,0,0,0,14,15.44l6.3,6.3A1,1,0,0,0,21,22a1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42Z"></path></g></svg>
                                      </div>
                                      <p className="margin0">
                                        {dataCompanyDetail.payload.niches.length > 1 ? "Restaurant And Caterer Both" : dataCompanyDetail.payload.niches[0].nicheName + " Only"}
                                      </p>
                                    </div>
                                    {isLoadingCompanyDetail ? "" :
                                      (dataCompanyDetail.payload.prayerAllowed || dataCompanyDetail.payload.wifiAvailable || dataCompanyDetail.payload.parkingAvailable) &&
                                      <div className="inline-features* d-flex flex-column">
                                        {dataCompanyDetail.payload.prayerAllowed &&
                                          <div className="d-flex align-items-center mb-1">
                                            <div className="d-flex align-items-center mr-2">
                                              <svg fill="#000000" width="18px" height="18px" viewBox="0 -64 640 640" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M0 480c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V160H0v320zm579.16-192c17.86-17.39 28.84-37.34 28.84-58.91 0-52.86-41.79-93.79-87.92-122.9-41.94-26.47-80.63-57.77-111.96-96.22L400 0l-8.12 9.97c-31.33 38.45-70.01 69.76-111.96 96.22C233.79 135.3 192 176.23 192 229.09c0 21.57 10.98 41.52 28.84 58.91h358.32zM608 320H192c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h32v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h64v-72c0-48 48-72 48-72s48 24 48 72v72h64v-64c0-17.67 14.33-32 32-32s32 14.33 32 32v64h32c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM64 0S0 32 0 96v32h128V96c0-64-64-96-64-96z"></path></g></svg>
                                            </div>
                                            <p className="margin0">
                                              Prayer Allowed upon request
                                            </p>
                                          </div>

                                        }
                                        {dataCompanyDetail.payload.wifiAvailable &&
                                          <div className="d-flex align-items-center mb-1">
                                            <div className="d-flex align-items-center mr-2">
                                              <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="System / Wifi_High"> <path id="Vector" d="M8.34277 14.5899C8.80861 14.0903 9.37187 13.6915 9.9978 13.418C10.6237 13.1446 11.2995 13.0025 11.9826 13.0001C12.6656 12.9977 13.3419 13.1353 13.9697 13.4044C14.5975 13.6735 15.1637 14.0683 15.633 14.5646M6.14941 11.5439C6.89476 10.7446 7.79597 10.1066 8.79745 9.66902C9.79893 9.23148 10.8793 9.00389 11.9721 9.00007C13.065 8.99626 14.1466 9.21651 15.1511 9.64704C16.1556 10.0776 17.0617 10.7094 17.8127 11.5035M3.22363 8.81635C4.34165 7.61742 5.69347 6.66028 7.19569 6.00398C8.69791 5.34768 10.3179 5.0058 11.9572 5.00007C13.5966 4.99435 15.2208 5.32472 16.7276 5.97052C18.2344 6.61632 19.5931 7.56458 20.7195 8.75568M12 19.0001C11.4477 19.0001 11 18.5524 11 18.0001C11 17.4478 11.4477 17.0001 12 17.0001C12.5523 17.0001 13 17.4478 13 18.0001C13 18.5524 12.5523 19.0001 12 19.0001Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g> </g></svg>
                                            </div>
                                            <p className="margin0">
                                              Free Wifi
                                            </p>
                                          </div>
                                        }
                                        {dataCompanyDetail.payload.parkingAvailable &&
                                          <div className="d-flex align-items-center mb-1">
                                            <div className="d-flex align-items-center mr-2">
                                              <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path fillRule="evenodd" clipRule="evenodd" d="M2 14.803v6.447c0 .414.336.75.75.75h1.614a.75.75 0 0 0 .74-.627L5.5 19h13l.395 2.373a.75.75 0 0 0 .74.627h1.615a.75.75 0 0 0 .75-.75v-6.447a5.954 5.954 0 0 0-1-3.303l-.78-1.17a1.994 1.994 0 0 1-.178-.33h.994a.75.75 0 0 0 .671-.415l.25-.5A.75.75 0 0 0 21.287 8H19.6l-.31-1.546a2.5 2.5 0 0 0-1.885-1.944C15.943 4.17 14.141 4 12 4c-2.142 0-3.943.17-5.405.51a2.5 2.5 0 0 0-1.886 1.944L4.399 8H2.714a.75.75 0 0 0-.67 1.085l.25.5a.75.75 0 0 0 .67.415h.995a1.999 1.999 0 0 1-.178.33L3 11.5c-.652.978-1 2.127-1 3.303zm15.961-4.799a4 4 0 0 0 .34.997H5.699c.157-.315.271-.65.34-.997l.632-3.157a.5.5 0 0 1 .377-.39C8.346 6.157 10 6 12 6c2 0 3.654.156 4.952.458a.5.5 0 0 1 .378.389l.631 3.157zM5.5 16a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM20 14.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" fill="#000000"></path></g></svg>
                                            </div>
                                            <p className="margin0">
                                              Parking Area Available
                                            </p>
                                          </div>
                                        }
                                      </div>
                                    }

                                  </div>
                                </div>
                                <div className="second-col">

                                  <div className="menu mt-sm-0 mt-3">
                                    <h6 className="">Menu</h6>
                                    <p className="d-flex flex-column justify-content-center">

                                      {dataCompanyDetail.payload.externalMenuURL &&
                                        <a href={dataCompanyDetail.payload.externalMenuURL} className="instance" target="_blank">
                                          <svg style={{ 'marginRight': '5px' }} width="18" height="16" className="icon_svg"><path d="M14.5 1h-3.974a.5.5 0 00-.353.854l1.47 1.469L8.254 6.71a.75.75 0 101.06 1.06l3.388-3.386 1.444 1.443A.5.5 0 0015 5.474V1.5a.5.5 0 00-.5-.5z"></path><path d="M14 14.75H2a.75.75 0 01-.75-.75V2A.75.75 0 012 1.25h3.824a.75.75 0 010 1.5H2.75v10.5h10.5v-3.133a.75.75 0 111.5 0V14a.75.75 0 01-.75.75z"></path></svg>
                                          Website Menu
                                        </a>}

                                      {dataCompanyDetail.payload.pdfMenu &&
                                        <a href={process.env.REACT_APP_BASE_URL + "MediaStorage/MenuItemDocuments/" + dataCompanyDetail.payload.pdfMenu} className="instance" target="_blank">
                                          <svg style={{ 'marginRight': '5px' }} fill="#000000" width="18" height="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <g id="Complete"> <g id="download"> <g> <path d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path> <g> <polyline data-name="Right" fill="none" id="Right-2" points="7.9 12.3 12 16.3 16.1 12.3" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></polyline> <line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="12" x2="12" y1="2.7" y2="14.2"></line> </g> </g> </g> </g> </g></svg>
                                          Download Menu</a>}

                                      {dataCompanyDetail.payload.onlineMenuExists === true &&
                                        <a href="#" className="instance" data-bs-toggle="modal" data-bs-target="#menuModal">
                                          <svg style={{ 'marginRight': '5px' }} fill="#000000" width="18" height="18" viewBox="0 0 24.00 24.00" xmlns="http://www.w3.org/2000/svg" stroke="#000000" strokeWidth="0.00024000000000000003"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.096"></g><g id="SVGRepo_iconCarrier"><path d="M21,6a1.009,1.009,0,0,0-.294-.708l-4-4a1,1,0,0,0-.323-.216A.988.988,0,0,0,16,1H4A1,1,0,0,0,3,2V22a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1ZM19,21H5V3H15V6a1,1,0,0,0,1,1h3ZM16,9a1,1,0,0,1,0,2H8A1,1,0,0,1,8,9ZM7,6A1,1,0,0,1,8,5h4a1,1,0,0,1,0,2H8A1,1,0,0,1,7,6Zm10,8a1,1,0,0,1-1,1H8a1,1,0,0,1,0-2h8A1,1,0,0,1,17,14Zm0,4a1,1,0,0,1-1,1H8a1,1,0,0,1,0-2h8A1,1,0,0,1,17,18Z"></path></g></svg>
                                          Online Menu</a>}

                                      {dataCompanyDetail.payload.orderPageURL &&
                                        <a href={dataCompanyDetail.payload.orderPageURL} className="instance" target="_blank" alt={dataCompanyDetail.payload.orderPageURL}>
                                          {/* <svg style={{ 'marginRight': '5px' }} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" height="18" width="18" fill="none"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="#000000"> <path d="M9 .75A.75.75 0 019.75 0h4.5c.206 0 .393.083.529.218l.001.002.002.001A.748.748 0 0115 .75v4.5a.75.75 0 01-1.5 0V2.56L7.28 8.78a.75.75 0 01-1.06-1.06l6.22-6.22H9.75A.75.75 0 019 .75z"></path> <path d="M3.25 3.5a.75.75 0 00-.75.75v7.5c0 .414.336.75.75.75h7.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0110.75 14h-7.5A2.25 2.25 0 011 11.75v-7.5A2.25 2.25 0 013.25 2h4a.75.75 0 010 1.5h-4z"></path> </g> </g></svg> */}
                                          <svg style={{ 'marginRight': '5px' }} width="18" height="16" className="icon_svg"><path d="M14.5 1h-3.974a.5.5 0 00-.353.854l1.47 1.469L8.254 6.71a.75.75 0 101.06 1.06l3.388-3.386 1.444 1.443A.5.5 0 0015 5.474V1.5a.5.5 0 00-.5-.5z"></path><path d="M14 14.75H2a.75.75 0 01-.75-.75V2A.75.75 0 012 1.25h3.824a.75.75 0 010 1.5H2.75v10.5h10.5v-3.133a.75.75 0 111.5 0V14a.75.75 0 01-.75.75z"></path></svg>
                                          Order Online
                                        </a>}


                                    </p>
                                  </div>
                                  {isLoadingCompanyDetail ? "" :
                                    dataCompanyDetail.payload.paymentOptions.length > 0 &&
                                    <div className="payment-info">
                                      {/* <h6>Payment methods</h6> */}
                                      <div className="payment-icons d-inline-flex flex-wrap align-items-center">
                                        {
                                          dataCompanyDetail.payload.paymentOptions.map((po, index) =>
                                            <img key={index} className="ms-0 mb-1 mx-1" src={'/images/' + po.paymentOptionImage} alt={po.paymentOptionName} title={po.paymentOptionName} />
                                          )
                                        }
                                      </div>
                                    </div>
                                  }
                                </div>

                              </div>




                              <div className="btn-group contact-btns d-flex* d-none gap-4 mt-4" role="group">

                                <button href="#" onClick={() => { setShowMessageModel(true) }} className="btn border p-3 w-100" ><i className="fa-solid fa-comments mr-2"></i> Message</button>

                                {(dataCompanyDetail.payload.contactNumber === "") ?
                                  <a href="#" className="btn border p-3 w-100"><i className="fa-solid fa-phone mr-2"></i> Contact not available</a>
                                  :
                                  <a href={"tel:" + dataCompanyDetail.payload.contactNumber} className="btn border p-3 w-100"><i className="fa-solid fa-phone mr-2"></i> Request a call</a>
                                }
                              </div>


                            </div>
                          </div>
                      }



                      {isLoadingCompanyDetail ? <h6>Loading...</h6> :

                        <div className="row *mt-4 border-top* speciality-row">

                          {dataCompanyDetail.payload.cuisines.length > 0 &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 padding0 border-top* border-end*  speciality-col">
                              <div className="bg p-3">
                                <h5>Cuisine <span>({dataCompanyDetail.payload.cuisines.length})</span></h5>
                                <OffersCarousel aryItems={dataCompanyDetail.payload.cuisines} entityName="cuisine" />
                              </div>
                            </div>
                          }

                          {dataCompanyDetail.payload.specialities.length > 0 &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 padding0 border-top* border-end* speciality-col">
                              <div className="bg p-3">
                                <h5>Speciality <span>({dataCompanyDetail.payload.specialities.length})</span></h5>
                                <OffersCarousel aryItems={dataCompanyDetail.payload.specialities} entityName="specialities" />
                              </div>
                            </div>
                          }

                          {dataCompanyDetail.payload.eventTypes.length > 0 &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 padding0 border-end* border-top* speciality-col ">
                              <div className="bg p-3">
                                <h5>Event Type <span>({dataCompanyDetail.payload.eventTypes.length})</span></h5>
                                <OffersCarousel aryItems={dataCompanyDetail.payload.eventTypes} entityName="eventType" />
                              </div>
                            </div>
                          }
                          {dataCompanyDetail.payload.servingOptions.length > 0 &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 padding0 border-end*   border-top* speciality-col">
                              <div className="bg p-3">
                                <h5>Serving Option <span>({dataCompanyDetail.payload.servingOptions.length})</span></h5>
                                <OffersCarousel aryItems={dataCompanyDetail.payload.servingOptions} entityName="servingOptions" />
                              </div>
                            </div>
                          }
                          {dataCompanyDetail.payload.addonServices.length > 0 &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 padding0 border-end*  border-top* speciality-col">
                              <div className="bg p-3">
                                <h5>Addon Services <span>({dataCompanyDetail.payload.addonServices.length})</span></h5>
                                <OffersCarousel aryItems={dataCompanyDetail.payload.addonServices} entityName="addonServices" />
                              </div>
                            </div>
                          }


                          {dataCompanyDetail.payload.certifications.length > 0 &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 padding0 border-top* border-end* speciality-col">
                              <div className="bg p-3">
                                <h5>Certification <span>({dataCompanyDetail.payload.certifications.length})</span></h5>
                                <OffersCarousel aryItems={dataCompanyDetail.payload.certifications} entityName="Certification" />
                              </div>
                            </div>
                          }
                        </div>
                      }


                      <EventGallery showGalleryLink={setShowEventGallery} />

                      <div className="digital-menu-modal modal fade" id="menuModal" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-xl" role="document">
                          <div className="modal-content p-4">
                            <div className="modal-header p-0 pb-2">
                              <div className="modal-title col-lg-7 col-md-7 col-10 ps-lg-0 p-lg-2 px-md-2">
                                <h5>Menu</h5>
                              </div>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body padding0">
                              <Menu />
                            </div>

                            <div className="modal-footer border-0">
                              <br></br>
                            </div>
                          </div>
                        </div>
                      </div>

                      {isLoadingCompanyDetail ? <h6>Loading...</h6> :

                        <Review reviewData={dataCompanyDetail} />
                      }


                    </div>
                  </div>
                </section>
              </div>


              <div className="col-12 col-md-12 col-md-4 col-lg-4  d-none d-lg-block">
                <div className="sticky-md-top sticky-col-overflow">
                  {isLoadingCompanyDetail ? "" :
                    dataCompanyDetail.payload.ratingAverage !== 0 &&
                    <div className="review-wrapper mb-4">
                      <div className="row justify-content-between">
                        <div className="col">
                          <h3>Reviews</h3>
                        </div>
                      </div>

                      {
                        <div className="row rating d-flex justify-content-around align-items-center">
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            <h4> {dataCompanyDetail.payload.ratingAverage + (dataCompanyDetail.payload.ratingAverage.toString().length === 1 && ".0")}</h4>
                          </div>
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 stars">
                            {getAvgStars(dataCompanyDetail.payload.ratingAverage)}
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            <h5 className="margin0">out of 5</h5>
                          </div>
                        </div>
                      }


                      <div className="my-2">
                        <div className="row hori-rating align-items-center">
                          <div className="col-7 col-sm-7 col-md-7 col-lg-6">
                            <p>Taste</p>
                          </div>
                          <div className="col-5 col-sm-5 col-md-5 col-lg-6 vendor-detail-stars">
                            <span style={{ 'textDecoration': 'none', 'marginRight': '10px' }}>{dataCompanyDetail.payload.ratingTaste}{dataCompanyDetail.payload.ratingTaste.toString().length === 1 && ".0"}</span>
                            <RatingStar starCount={dataCompanyDetail.payload.ratingTaste} />
                          </div>
                        </div>
                        <div className="row hori-rating align-items-center">
                          <div className="col-7 col-sm-7 col-md-7 col-lg-6">
                            <p>On Time Delivery</p>
                          </div>
                          <div className="col-5 col-sm-5 col-md-5 col-lg-6 vendor-detail-stars">
                            <span style={{ 'textDecoration': 'none', 'marginRight': '10px' }}>{dataCompanyDetail.payload.ratingOnTimeDelivery}{dataCompanyDetail.payload.ratingOnTimeDelivery.toString().length === 1 && ".0"}</span>
                            <RatingStar starCount={dataCompanyDetail.payload.ratingOnTimeDelivery} />
                          </div>
                        </div>



                        <div className="row hori-rating align-items-center">

                          <div className="col-7 col-sm-7 col-md-7 col-lg-6">
                            <p>Communication</p>

                          </div>

                          <div className="col-5 col-sm-5 col-md-5 col-lg-6 vendor-detail-stars">
                            <span style={{ 'textDecoration': 'none', 'marginRight': '10px' }}>{dataCompanyDetail.payload.ratingCommunication}{dataCompanyDetail.payload.ratingCommunication.toString().length === 1 && ".0"}</span>
                            <RatingStar starCount={dataCompanyDetail.payload.ratingCommunication} />
                          </div>


                        </div>
                      </div>

                      <div className="row align-items-center justify-content-center rating-end">
                        <div className="col-7 col-sm-7 col-md-7 col-lg-6 text-md-end* text-sm-center*"><p>({isLoadingCompanyDetail ? "" : dataCompanyDetail.payload.activeReviewCount}) reviews received</p></div>
                        <div className="col-5 col-sm-5 col-md-5 col-lg-6">
                          <a className="btn btn-primary* btn-md" href="#reviews">Review Now</a>
                        </div>
                      </div>



                    </div>
                  }



                  {isLoadingCompanyDetail ? "Loading" : dataCompanyDetail.payload.niches[0].nicheId !== 1 &&
                    <FindYourOrderValue />
                  }
                  <div className="catererdetail-video">
                    {isLoadingCompanyDetail ? "" :
                      (dataCompanyDetail.payload !== undefined && dataCompanyDetail.payload !== null) &&
                      (
                        dataCompanyDetail.payload.videoFile == null ?
                          (dataCompanyDetail.payload.youtubeURL === '' || dataCompanyDetail.payload.youtubeURL === null) ? '' :
                            <object data={dataCompanyDetail.payload.youtubeURL.replace('watch?v=', 'embed/') + '/?autoplay=1'} alt="" width="100%" height="300" ></object>
                          :
                          // <video autoPlay muted style={{ 'maxWidth': '420px' }} controls src={'data:video/mp4;base64,' + dataCompanyDetail.payload.videoFile} />
                          <video controls width="100%" height="300">
                            <source autoPlay muted src={process.env.REACT_APP_BASE_URL + "MediaStorage/vid/" + dataCompanyDetail.payload.videoFile} type="video/mp4" autoPlay={true} />
                            Your browser does not support the video tag.
                          </video>

                      )
                    }
                  </div>


                </div>
              </div>

            </div>
          </div>
        </section>

        <SendMessage />
        <Footer></Footer>
      </main>
    </div>
  )
}


export default CompanyDetail