import React from 'react'
import Header from './Header';
import Footer from './Footer';



const Location = () => {
 

  return (

    <div>
      <Header />
      <section className="customer-banner location">
        <div className="container-fluid banner-home d-flex justify-content-center align-items-center">
          <div className="row margin0 justify-content-center">
            <div className="col-auto">
              <h1 className="text-uppercase banner-title"><strong>Location</strong></h1>
            </div>
          </div>
        </div>
      </section>

      <section className="customer-locations location">
        <div className="container">
          <div className="row px-xxl-5 mx-xxl-5">
            <div className="col-sm-6 my-3 customer-location">
              <div className="location-img">
                <img className="w-100" src="/images/customer-website/location-11.jpg" alt="" />
              </div>
              <div className="location-content">
                <h6>Fullerton, California</h6>
                <a className="customer btn-primary" href="#">DIRECTIONS</a>
              </div>
            </div>
            <div className="col-sm-6 my-3 customer-location">
              <div className="location-img">
                <img className="w-100" src="/images/customer-website/location-11.jpg" alt="" />
              </div>
              <div className="location-content">
                <h6>Fullerton, California</h6>
                <a className="customer btn-primary" href="#">DIRECTIONS</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>






    </div>
  )
}


export default Location