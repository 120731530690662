import { useGetDailyKeywordInsightsAndTrendsQuery } from './../../hooks/apiSlice';
import moment from 'moment'

const DailyKeywordInsightsAndTrends = () => {
    const { data: dataDailyKeywordInsightsAndTrends, isLoading: isLoadingDailyKeywordInsightsAndTrends } = useGetDailyKeywordInsightsAndTrendsQuery()


    return (
        <>
            {isLoadingDailyKeywordInsightsAndTrends ? "Loading" :
                <table className="table-hover table-striped table" ><thead>
                    <tr>
                        <th>Day - Date</th>
                        <th>Event Type (Count)</th>
                        <th>Cuisine (Count)</th>
                        <th>Specialty (Count)</th>
                    </tr>
                </thead>
                    <tbody>
                        {dataDailyKeywordInsightsAndTrends.map((rpt, index) =>
                            <tr key={index}>
                                <td>{moment(rpt.date).format("dddd - MMMM DD, YYYY")}</td>
                                <td>{rpt.eventType}</td>
                                <td>{rpt.cuisine}</td>
                                <td>{rpt.specialty}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
        </>
    )


}

export default DailyKeywordInsightsAndTrends