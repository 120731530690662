import Navbar, { ChildStateModificationFunc } from '../components/Navbar'
import Footer from '../components/Footer'
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toPascal } from '../hooks/util';
import AuthService from "../hooks/Auth";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useGetUserProfileByUserProfileIdQuery, useUpdateUserProfileMutation } from '../hooks/apiSlice';


//new branch added

const USER_REGEX = /^.{3,}$/;
//const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const MyProfile = () => {

    const { data: dataUserProfileByUserProfileId, isLoading: isLoadingUserProfileByUserProfileId } = useGetUserProfileByUserProfileIdQuery(AuthService.getUserProfileId())
    const [updateUserProfile] = useUpdateUserProfileMutation()

    const location = useLocation()
    const navigate = useNavigate();

    const userRef = useRef();

    const [userFullName, setUserFullName] = useState('');
    const [validFullName, setValidFullName] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [validCurrentPassword, setValidCurrentPassword] = useState(false);

    //const [matchPwd, setMatchPwd] = useState('');
    //const [validMatch, setValidMatch] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);

    const [passwordType, setPasswordType] = useState("password");
    const [message, setMessage] = useState("");
    const [progress, setProgress] = useState("");



    const handlePassword = (passwordValue) => {
        const strengthChecks = {
            length: 0,
            hasUpperCase: false,
            hasLowerCase: false,
            hasDigit: false,
            hasSpecialChar: false,
        };

        strengthChecks.length = passwordValue.length >= 8 ? true : false;
        strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
        strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
        strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
        strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

        let verifiedList = Object.values(strengthChecks).filter((value) => value);

        let strength =
            verifiedList.length == 5
                ? "Strong"
                : verifiedList.length >= 2
                    ? "Medium"
                    : "Weak";

        setPwd(passwordValue);
        setProgress(`${(verifiedList.length / 5) * 100}%`);
        setMessage(strength);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }


    const getActiveColor = (type) => {
        if (type === "Strong") return "#8BC926";
        if (type === "Medium") return "#FEBD01";
        return "#FF0054";
    };



    useEffect(() => {
        (!isLoadingUserProfileByUserProfileId) && setUserFullName(dataUserProfileByUserProfileId.payload.firstName);
    }, [isLoadingUserProfileByUserProfileId])

    useEffect(() => {
        setValidFullName(USER_REGEX.test(userFullName));

        if (currentPassword !== "")
            setValidPwd((pwd.length >= 5 && pwd !== ""));
        else
            setValidPwd(true);

        //setValidMatch(pwd === matchPwd);
    }, [userFullName, pwd, currentPassword])


    const handleSubmit = async (e) => {
        e.preventDefault();
        await updateUserProfile({ userProfileId: AuthService.getUserProfileId(), firstName: toPascal(userFullName), password: currentPassword, newPassword: pwd, file:currentFile })
            .then((response) => {
                if (!response.data.success)
                    toast.error(response.data.message, { position: toast.POSITION.TOP_CENTER });
                else {
                    toast.success(`Profile information has been updated`, { position: toast.POSITION.TOP_CENTER });
                    AuthService.updateProfileInfoOnLocalStorage(userFullName, response.data.payload.avatar);
                    ChildStateModificationFunc();
                    setCurrentPassword("");
                    setPwd("");
                    setProgress("");
                    setMessage("");
                }
            });
    }


    const [currentFile, setCurrentFile] = useState(undefined);
    const [previewImage, setPreviewImage] = useState(undefined);

    const selectFile = (event) => {
        if (event.target.files[0].size > 1048576) //not more than 1MB
            alert('Image file should not exceeds 1MB in size')
        else {
            setCurrentFile(event.target.files[0]);
            setPreviewImage(URL.createObjectURL(event.target.files[0]));
        }
    };



    return (

        <div>
            <div>
                <ToastContainer />
            </div>
            <Navbar />


            <section className="my-4 bg-pink"><form onSubmit={handleSubmit}>
                <div className="container">

                    <div className="row">

                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="standardHeading">
                                {/* <h2><strong>Profile</strong></h2> */}
                                <h3>change your profile information</h3>
                            </div>
                            <div className="row pt-4 margin0 form-log">


                                <div className="pic-container pic-medium">
                                    <label htmlFor="companyImg">
                                        <input type="file" name="companyImg" id="companyImg" className="form-control" style={{ 'display': 'none' }} onChange={selectFile} accept="image/*" />
                                        {previewImage !== undefined ? <img alt="" src={previewImage} style={{ 'maxHeight': '140px', 'maxWidth': '140px', 'cursor': 'pointer' }} title="click to change your image" /> :
                                            isLoadingUserProfileByUserProfileId ? "" :
                                                <img alt="" src={dataUserProfileByUserProfileId.payload.avatar !== null ? process.env.REACT_APP_BASE_URL + `MediaStorage/Avatar/${dataUserProfileByUserProfileId.payload.avatar}` : '/images/AvatarDefault.png'} style={{ 'maxHeight': '140px', 'maxWidth': '140px', 'cursor': 'pointer' }} title="click to change your image" />
                                        }
                                        <div className="upload-profilepic text-center card-img-overlay d-none flex-column justify-content-center">
                                            <i className="fa-solid fa-camera"></i>
                                            <div className="overlay-txt">Upload<br />200x165 recommended</div>
                                        </div>
                                    </label>
                                </div>



                                <input
                                    type="text"
                                    id="username"
                                    ref={userRef}
                                    autoComplete="off"
                                    onChange={(e) => setUserFullName(toPascal(e.target.value))}
                                    value={userFullName}
                                    maxLength="60"
                                    className={!validFullName ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                                    placeholder="Full Name"
                                    required
                                    aria-invalid={validFullName ? "false" : "true"}
                                    aria-describedby="uidnote"
                                />

                                <input type="password" id="currentpassword"
                                    placeholder="Current password" onChange={(e) => setCurrentPassword(e.target.value)}
                                    className={currentPassword.length > 0 ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                                    maxLength="24" value={currentPassword} aria-describedby="pwdnote" />

                                {/* <input type="password" id="password"
                                    className={!validPwd ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                                    placeholder="New password" onChange={(e) => setPwd(e.target.value)}
                                    maxLength="24" value={pwd} aria-invalid={validPwd ? "false" : "true"} aria-describedby="pwdnote" />

                                <input type="password" id="confirm_pwd"
                                    className={!validMatch ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                                    placeholder="Confirm new password" onChange={(e) => setMatchPwd(e.target.value)}
                                    value={matchPwd} aria-invalid={validMatch ? "false" : "true"} aria-describedby="confirmnote" maxLength="24" />

                                <div className="pt-3 alert-warning text-dark">
                                    <ul>
                                        <li>Must enter the password in both input fields.</li>
                                        <li>8 to 24 characters.</li>
                                        <li>Must include uppercase and lowercase letters, a number and a special character.</li>
                                        <li>Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span></li>
                                    </ul>
                                </div> */}


                                <div style={{ 'padding': '0px' }}>
                                    <div className="password-field">
                                        <input
                                            value={pwd}
                                            onChange={({ target }) => {
                                                handlePassword(target.value);
                                            }}
                                            type={passwordType}
                                            className={!validPwd ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"}
                                            placeholder="Enter Password"
                                        />
                                        <i className={passwordType === "password" ? "far fa-eye" : "far fa-eye-slash"} onClick={togglePassword}></i>
                                    </div>


                                    <div className="progress-bg2">
                                        <div
                                            className="progress2"
                                            style={{
                                                width: progress,
                                                backgroundColor: getActiveColor(message),
                                            }}
                                        ></div>
                                    </div>


                                    {pwd.length !== 0 ? (
                                        <p className="message2" style={{ color: getActiveColor(message) }}>
                                            Your password is {message}
                                        </p>
                                    ) : null}
                                </div>

                                <button className="btn btn-danger btn-sm" disabled={!validFullName || (currentPassword.length > 0 && validPwd===false)}>Save</button>

                            </div>
                        </div>
                        <div className="offset-md-1 col-lg-7 col-md-12 col-sm-12 col-12">
                            <img src="/images/signup-vendor.png" className="img-fluid" alt="" />
                        </div>



                    </div>

                </div>
            </form>
            </section>


            <Footer></Footer>
        </div>
    )
}

export default MyProfile