import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetEventTypesQuery } from '../../hooks/apiSlice';
import { default as ReactSelect } from "react-select";
import { toURLDecode } from '../../hooks/util';


const EventTypeSelection = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: dataEventTypes, isLoading: isLoadingEventTypes } = useGetEventTypesQuery()
  const [eventTypeDefaultSelection, setEventTypeDefaultSelection] = useState([]);

  const params = useParams();
  const [eventTypeLoaded, setEventTypeLoaded] = useState(false);

  useEffect(() => {
    var updatedList;
    if (isLoadingEventTypes === false) {
      if (params.qAtt === 'events')
        updatedList = [{ value: dataEventTypes.payload.find((obj) => { return (obj.eventTypeName === toURLDecode(params.qPara)) }).eventTypeId.toString(), label: toURLDecode(params.qPara) }];

      if (searchParams.get("EventType")) {
        updatedList = searchParams.get("EventType").split(",").map((n) => {
          return {
            value: n,
            label: dataEventTypes.payload.find((obj) => { return (obj.eventTypeId === parseInt(n)) }).eventTypeName
          }
        })
      }

      if (updatedList !== undefined) {
        setEventTypeDefaultSelection(updatedList)
        props.EventTypeSelectionHandler(updatedList)
      }
      setEventTypeLoaded(true);

    }
  }, [isLoadingEventTypes]);



  const handleEventTypeSelection = (selected) => {
    props.EventTypeSelectionHandler(selected);
  };

  return (
    eventTypeLoaded &&
    <div>
      <ReactSelect
        options={dataEventTypes.payload.map((eventType) => {
          return { value: eventType.eventTypeId.toString(), label: eventType.eventTypeName }
        })}
        isMulti
        value={props.parentInput}
        closeMenuOnSelect={false}
        //hideSelectedOptions={false}
        placeholder="Event Type"
        onChange={handleEventTypeSelection}
        //filterOption={createFilter({ matchFrom: 'start' })}
        //allowSelectAll={true}
        defaultValue={eventTypeDefaultSelection}
      />
    </div>
  );
};



export default EventTypeSelection;


