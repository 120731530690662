import React from "react";
import { useState, useRef } from "react";
import { default as ReactSelect } from "react-select";
import { useGetCompanyByKeywordQuery } from './../../hooks/apiSlice';
import { useNavigate } from "react-router-dom";

var ChildStateModificationFunc;

const CompanySelect = ({ handleSelection }) => {

    const [keyword, setKeyword] = useState('');
    const [skip, setSkip] = React.useState(true)
    const companyReactSel = useRef(null);

    const navigate = useNavigate();

    const { data: dataCompanyByKeyword, isLoading: isLoadingCompanyByKeyword } = useGetCompanyByKeywordQuery(keyword === '' ? '0' : keyword, {
        skip,
    })

    const handle = (opt) => {
        setKeyword(opt);
        setSkip(false);
    }

    ChildStateModificationFunc = (Val) => {
        companyReactSel.current.clearValue();
    }


    return (
        <>
            <div className="App" id="companySearchComp">
                <ReactSelect ref={companyReactSel}
                    isLoading={isLoadingCompanyByKeyword}
                    isClearable={true}
                    name="company"
                    options={(isLoadingCompanyByKeyword || dataCompanyByKeyword === undefined) ? [] : dataCompanyByKeyword.payload}
                    getOptionLabel={(x) => x.companyName}
                    onChange={opt => {
                        if (opt !== null)
                            navigate("/company-detail/" + opt.companyId);
                    }}
                    onInputChange={opt => { handle(opt); }}
                    placeholder="Type company name ..."
                />
            </div>
        </>
    );
}

export default CompanySelect;
export { ChildStateModificationFunc }