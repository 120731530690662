import { useState, useEffect } from "react";
import { useGetCertificationsQuery } from './../../hooks/apiSlice';
import { useSearchParams } from "react-router-dom";

const CertificationSelection = ({ CertificationSelectionHandler }) => {

    const [checked, setChecked] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const { data: dataCert, isLoading: isLoadingCert } = useGetCertificationsQuery()


    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, parseInt(event.target.value)];
        } else {
            updatedList.splice(checked.indexOf(parseInt(event.target.value)), 1);
        }
        setChecked(updatedList);
        CertificationSelectionHandler(updatedList);

        if (updatedList.length === 0)
            searchParams.delete("Certification")
        else
            searchParams.set("Certification", updatedList);

        setSearchParams(searchParams);
    };

    useEffect(() => {
        if (searchParams.get("Certification")) {
            setChecked(searchParams.get("Certification").split(",").map((n) => parseInt(n)));
            CertificationSelectionHandler(searchParams.get("Certification").split(",").map((n) => parseInt(n)));
        }
    }, []);

    return (
        <div className="horizontal-scroll-bar">
            {
                isLoadingCert ? <h6>loading...</h6> :
                    dataCert.payload.map((item, index) => (
                        <div key={index} >
                            <label className="form-check-label">
                                <input value={item.certificationId} checked={checked.some(element => { return element === item.certificationId })} className="form-check-input ps-2" type="checkbox" onChange={handleCheck} />&nbsp;&nbsp;{item.certificationName}
                            </label>
                        </div>
                    ))
            }
        </div>
    );
};

export default CertificationSelection;