import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'


const AboutUs = () => {



  return (

    <div>
      <Navbar />


      <main role="main">


        <div className="innerpage-banner ">
          <img src="images/static-banners/about.jpg" className="innerpage-banner-img" alt="About" />
        </div>




        <section className="innerpage-container">

          <div className="container">
            <div className="row">


              <div className="col-12 col-md-12 col-md-8 col-lg-12">
                <p>Halal Caters is a platform that inspires people planning all types of events and connects them with the leading event professionals, vendors, and venues across the country and the world.</p>

                <p>People planning all types of events — from weddings and holiday parties to birthday parties and galas — come to Halal Caters to find new and unique party ideas, discover the best local vendors and venues, and build their event teams.</p>

                <p>Event professionals, venues, and vendors use Halal Caters to grow their businesses, connect with industry peers, and reach more clients. Companies can share photos and videos from all types of events, get recognized for their work with our unique crediting system, and use their content-rich profiles as portfolio-sharing tools.</p>

                <p>Since its founding in 2015, Halal Caters has acquired over 25,000 of the world’s top event professionals, vendors, and venues who have uploaded more than 1,000,000 photos of real celebrations to the platform.</p>



              </div>



            </div>
          </div>




        </section>









      </main>


      <Footer></Footer>
    </div>
  )
}

export default AboutUs