import React, { useState } from "react";
import {
    useGetPaidListingPriceQuery, useGetProductPricesByProductQuery, useGetEventTypesQuery, useGetCuisinesQuery, useGetStatesQuery,
    useGetSpecialitiesQuery, useSaveProductPriceMutation
} from '../hooks/apiSlice';
import Modal from 'react-bootstrap/Modal';
import { isNumberKey } from '../hooks/util';


const BannerAdPrice = () => {

    const [page, setPage] = useState(1)

    const [offerSelected, setOfferSelected] = useState('EventType');
    const [offer, setOffer] = useState('EventType');
    const [offerId, setOfferId] = useState(null);
    const [stateId, setStateId] = useState(null);
    const [includeDefault, setIncludeDefault] = useState(true);
    const [includeException, setIncludeException] = useState(true);

    const [sortField, setSortField] = useState("Offer");
    const [order, setOrder] = useState("desc");

    const [showSetDefPriceModel, setShowSetDefPriceModel] = useState(false);
    const [showSetExPriceModel, setShowSetExPriceModel] = useState(false);

    const { data: dataSubscription, isLoading: isLoadingSubscription, isFetching } = useGetPaidListingPriceQuery({ productId: 2, offer: offer, offerId: offerId, stateId: stateId, includeDefault: includeDefault, includeExceptional: includeException, sortColumn: sortField, sortDirection: order, pageNum: page })
    const { data: dataDefaultProductPrice, isLoading: isLoadingDefaultProductPrice } = useGetProductPricesByProductQuery(2)


    const { data: dataEventTypes, isLoading: isLoadingEventTypes } = useGetEventTypesQuery()
    const { data: dataCuisines, isLoading: isLoadingCuisines } = useGetCuisinesQuery()
    const { data: dataSpecialties, isLoading: isLoadingSpecialties } = useGetSpecialitiesQuery()
    const { data: dataStates, isLoading: isLoadingStates } = useGetStatesQuery()


    const handleSearch = () => {
        setIncludeDefault(document.getElementById('showAciveBoost').checked);
        setIncludeException(document.getElementById('showExpiredBoost').checked);
        setOffer(document.getElementById('select-offer').value);

        if (document.getElementById('select-offer').value === 'EventType')
            setOfferId(document.getElementById('select-eventType').value);
        else if (document.getElementById('select-offer').value === 'Cuisine')
            setOfferId(document.getElementById('select-cuisines').value);
        else
            setOfferId(document.getElementById('select-specialty').value);

        setStateId(document.getElementById('select-state').value);

        setPage(1);
    }

    const handleSortingChange = (accessor) => {
        setSortField(accessor);
        setOrder(order === "asc" ? "desc" : "asc");
        handleSearch();
    };


    function getColumnHeader(columnName, sortString) {
        const arrowDirection = sortField === sortString && order === "asc" ? <i className="fas fa-arrow-up"></i> : sortField === sortString && order === "desc" ? <i className="fas fa-arrow-down"></i> : ""

        return (
            <span onClick={() => handleSortingChange(sortString)}> {isFetching ? <span className="ms-2 spinner-border spinner-border-sm"></span> : columnName} {arrowDirection}</span>
        )

    }

    const [saveProductPrice] = useSaveProductPriceMutation()

    function SetDefaultPricing() {
        const [inputs, setInputs] = useState([]);

        const handleChange = (event) => {
            const tempBlogs = [...inputs];
            const index = tempBlogs.findIndex((temp) => temp.productPriceId === event.target.name);
            if (index >= 0) {
                tempBlogs[index]['price'] = event.target.value;
                setInputs(tempBlogs);
            }
            else {
                setInputs(oldArray => [...oldArray, { 'productPriceId': event.target.name, 'price': event.target.value }]);
            }
        }


        const handleSubmit = () => {
            inputs.map((u) => {
                if (u.price !== '')
                    saveProductPrice({ 'productPriceId': u.productPriceId, 'price': u.price });
            })
            setShowSetDefPriceModel(false);
        }

        return (
            <Modal show={showSetDefPriceModel} >
                <Modal.Header>
                    <Modal.Title><i className="fas fa-cog"></i>&nbsp;SET DEFAULT PRICING{console.log(inputs)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {isLoadingDefaultProductPrice ? "Loading..." :

                        <div className="row">

                            <div className="col-6">
                                <div className="row pb-3 text-center">
                                    <b>MONTHLY</b>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        Event Type
                                    </div>
                                    {dataDefaultProductPrice.payload.filter((item) => item.productPlan.duration === 'Monthly' && item.offer === 'EventType').map((p, index) =>
                                        <div className="col" key={index}>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">${p.productPriceId}</span>
                                                <input type="text" name={p.productPriceId} className="form-control" defaultValue={p.price} onKeyDown={(e) => isNumberKey(e)}
                                                    onBlur={handleChange} maxLength="3" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col">
                                        Cuisine
                                    </div>
                                    {dataDefaultProductPrice.payload.filter((item) => item.productPlan.duration === 'Monthly' && item.offer === 'Cuisine').map((p, index) =>
                                        <div className="col" key={index}>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">${p.productPriceId}</span>
                                                <input type="text" name={p.productPriceId} className="form-control" defaultValue={p.price} onKeyDown={(e) => isNumberKey(e)}
                                                    onBlur={handleChange} maxLength="3" />
                                            </div>
                                        </div>)}
                                </div>
                                <div className="row">
                                    <div className="col">
                                        Specialty
                                    </div>
                                    {dataDefaultProductPrice.payload.filter((item) => item.productPlan.duration === 'Monthly' && item.offer === 'Speciality').map((p, index) =>
                                        <div className="col" key={index}>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">${p.productPriceId}</span>
                                                <input type="text" name={p.productPriceId} className="form-control" defaultValue={p.price} onKeyDown={(e) => isNumberKey(e)}
                                                    onBlur={handleChange} maxLength="3" />
                                            </div>
                                        </div>)}
                                </div>




                            </div>
                            <div className="col-6">
                                <div className="row pb-3 text-center">
                                    <b>YEARLY</b>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        Event Type
                                    </div>
                                    {dataDefaultProductPrice.payload.filter((item) => item.productPlan.duration === 'Yearly' && item.offer === 'EventType').map((p, index) =>
                                        <div className="col" key={index}>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">${p.productPriceId}</span>
                                                <input type="text" name={p.productPriceId} className="form-control" defaultValue={p.price} onKeyDown={(e) => isNumberKey(e)}
                                                    onBlur={handleChange} maxLength="3" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col">
                                        Cuisine
                                    </div>
                                    {dataDefaultProductPrice.payload.filter((item) => item.productPlan.duration === 'Yearly' && item.offer === 'Cuisine').map((p, index) =>
                                        <div className="col" key={index}>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">${p.productPriceId}</span>
                                                <input type="text" name={p.productPriceId} className="form-control" defaultValue={p.price} onKeyDown={(e) => isNumberKey(e)}
                                                    onBlur={handleChange} maxLength="3" />
                                            </div>
                                        </div>)}
                                </div>
                                <div className="row">
                                    <div className="col">
                                        Specialty
                                    </div>
                                    {dataDefaultProductPrice.payload.filter((item) => item.productPlan.duration === 'Yearly' && item.offer === 'Speciality').map((p, index) =>
                                        <div className="col" key={index}>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">${p.productPriceId}</span>
                                                <input type="text" name={p.productPriceId} className="form-control" defaultValue={p.price} onKeyDown={(e) => isNumberKey(e)}
                                                    onBlur={handleChange} maxLength="3" />
                                            </div>
                                        </div>)}
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="adminButton" onClick={() => { handleSubmit() }}>
                        Save
                    </button>
                    <button className="adminButton" onClick={() => { setShowSetDefPriceModel(false) }}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const [productPrice, setProductPrice] = useState({ targetAudienceId: 0, productPriceId: 0, offer: '', offerName: '', stateName: '' });
    const [modalMode, setModalMode] = useState('');

    function SetExceptionPricing() {

        const handleSubmit = () => {
            saveProductPrice({ 'productPriceId': productPrice.productPriceId, 'productPlan.productPlanId': 12, 'targetAudience.targetAudienceId': productPrice.targetAudienceId, 'price': document.getElementById('monthly').value });
            saveProductPrice({ 'productPriceId': productPrice.productPriceId, 'productPlan.productPlanId': 14, 'targetAudience.targetAudienceId': productPrice.targetAudienceId, 'price': document.getElementById('yearly').value });

            setShowSetExPriceModel(false);
        }

        const removeExceptionalPrice = (targetAudienceId) => {
            saveProductPrice({ 'productPriceId': 0, 'productPlan.productPlanId': 0, 'targetAudience.targetAudienceId': targetAudienceId, 'price': 0 });
            setShowSetExPriceModel(false);
        }



        return (
            <Modal show={showSetExPriceModel} >
                <Modal.Header>
                    <Modal.Title>{modalMode === 'modify' ? 'MODIFY EXCEPTIONAL PRICING' : 'SET EXCEPTIONAL PRICING'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-4">
                        <div className="col-6">
                            <b><i className="fas fa-map-marker-alt"></i>&nbsp;{productPrice.stateName}</b>
                        </div>
                        <div className="col-6 text-end">
                            <b>{productPrice.offer}: {productPrice.offerName}</b>
                        </div>
                    </div>


                    <div className="row mb-2">
                        <div className="col-6 text-center">
                            <b>MONTHLY</b>
                        </div>
                        <div className="col-6 text-center">
                            <b>YEARLY</b>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input type="text" id="monthly" defaultValue={productPrice.monthly} onKeyDown={(e) => isNumberKey(e)} className="form-control" maxLength="3" />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input type="text" id="yearly" defaultValue={productPrice.yearly} onKeyDown={(e) => isNumberKey(e)} className="form-control" maxLength="3" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {modalMode === 'modify' && <button className="adminDeleteButton" onClick={() => { removeExceptionalPrice(productPrice.targetAudienceId) }}>Remove Exception Price</button>}

                    <button className="adminButton" onClick={() => { handleSubmit() }}>
                        Save
                    </button>
                    <button className="adminButton" onClick={() => { setShowSetExPriceModel(false) }}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }




    return (
        <>
            {isLoadingSubscription ? "" :
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-10">
                                <h4><b><i className="fas fa-dollar"></i>&nbsp;Banner Ad Pricing</b></h4>
                            </div>
                            <div className="col-md-2 text-end">
                                <button style={{ 'fontSize': '0.7rem', 'fontWeight': '600' }} className="pull-right adminButton" onClick={() => {
                                    setShowSetDefPriceModel(true);
                                }}><i className="fas fa-cog"></i>&nbsp;set default prices</button>
                            </div>
                            <SetDefaultPricing />
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="strpied-tabled-with-hover card">
                                    <div className="card-header"><div className="row">
                                        <div className="col-md-12">
                                            <div className="row justify-content-between">
                                                <div className="col-auto">
                                                    <div className="row py-1 rounded" style={{ 'backgroundColor': '#b9bfc5' }}>
                                                        <div className="col-auto">
                                                            <select name="offer" id="select-offer" onChange={(e) =>
                                                                setOfferSelected(e.target.value)
                                                            } >
                                                                <option value="EventType">Event Type</option>
                                                                <option value="Cuisine">Cuisine</option>
                                                                <option value="Speciality">Speciality</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-auto justify-content-start">
                                                            <i className="fas fa-arrow-right"></i>
                                                        </div>
                                                        <div className="col-auto justify-content-start">
                                                            {
                                                                offerSelected === 'EventType' ?
                                                                    isLoadingEventTypes ? <h1>loading...</h1> :
                                                                        <select id={'select-eventType'} name="event">
                                                                            <option value="">Select Event Type</option>
                                                                            {
                                                                                dataEventTypes.payload.map((et, index) =>
                                                                                    <option key={index} text={et.eventTypeName} value={et.eventTypeId}>{et.eventTypeName}</option>
                                                                                )
                                                                            }
                                                                        </select>
                                                                    :
                                                                    offerSelected === 'Cuisine' ?
                                                                        isLoadingCuisines ? <h1>loading...</h1> :
                                                                            <select name="cuisines" id={'select-cuisines'} >
                                                                                <option value="">Select your cuisines</option>
                                                                                {
                                                                                    dataCuisines.payload.map((cu, index) =>
                                                                                        <option key={index} text={cu.cuisineName} value={cu.cuisineId}>{cu.cuisineName}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                        :
                                                                        isLoadingSpecialties ? <h1>loading...</h1> :
                                                                            <select id={'select-specialty'} name="specialty">
                                                                                <option value="">Select Speciality</option>
                                                                                {
                                                                                    dataSpecialties.payload.map((et, index) =>
                                                                                        <option key={index} text={et.specialityName} value={et.specialityId}>{et.specialityName}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-2">
                                                    {isLoadingStates ? <h1>loading...</h1> :
                                                        <select id={'select-state'} name="state">
                                                            <option value="">Select State</option>
                                                            {
                                                                dataStates.payload.map((et, index) =>
                                                                    <option key={index} text={et.stateName} value={et.stateId}>{et.stateName}</option>
                                                                )
                                                            }
                                                        </select>
                                                    }</div>






                                                <div className="col-1">
                                                    &nbsp;
                                                </div>
                                                <div className="col-4">
                                                    <div className="d-flex justify-content-end">
                                                        <input type="checkbox" className="form-check-input" style={{ width: '1.2em', height: '1.2em' }} defaultChecked="true" id="showAciveBoost"></input>
                                                        <label className="form-check-label ps-1" htmlFor="showAciveBoost">Include Default</label>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <input type="checkbox" className="form-check-input" style={{ width: '1.2em', height: '1.2em' }} id="showExpiredBoost"></input>
                                                        <label className="form-check-label ps-1" htmlFor="showExpiredBoost">Include Exceptional Pricing</label>
                                                        <button type="submit" className="pull-right adminButton ms-3" onClick={handleSearch}>
                                                            <i className="fa-sharp fa-solid fa-magnifying-glass"></i>&nbsp;SEARCH
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="clearfix"></div>

                                        </div>
                                    </div>
                                    </div>
                                    <div className="table-full-width table-responsive px-0 card-body">

                                        <table className="table-hover table-striped table">
                                            <thead>
                                                <tr>
                                                    <th>{getColumnHeader('Offer', 'Offer')}</th>
                                                    <th>{getColumnHeader('Exception/Default', 'IsDefault')}</th>
                                                    <th>{getColumnHeader('Offer Name', 'OfferName')}</th>
                                                    <th>{getColumnHeader('State', 'StateName')}</th>
                                                    <th>{getColumnHeader('Monthly', 'Monthly')}</th>
                                                    <th>{getColumnHeader('Yearly', 'Yearly')}</th>
                                                    <th>{getColumnHeader('Spot', 'Spot')}</th>
                                                    <th>{getColumnHeader('Active Subscribers', 'Yearly')}</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataSubscription.payload.map((bst, index) =>
                                                    <tr key={index}>
                                                        <td>{bst.offer}</td>
                                                        <td className="text-center">{bst.isDefault ? <span className="badge rounded-pill bg-success">default</span> : <span className="badge rounded-pill text-dark bg-warning">exception</span>}</td>
                                                        <td>{bst.offerName}</td>
                                                        <td>{bst.stateName}</td>
                                                        <td>BASIC: ${bst.monthly}</td>
                                                        <td>BASIC: ${bst.yearly}</td>
                                                        <td>{bst.spot}</td>
                                                        <td>44</td>
                                                        <td className="text-center">
                                                            {bst.isDefault ? <button className="pull-right adminButton" onClick={() => {
                                                                setModalMode('add')
                                                                setProductPrice({ targetAudienceId: bst.targetAudienceId, productPriceId: 0, offer: bst.offer, offerName: bst.offerName, stateName: bst.stateName, monthly: bst.monthly, yearly: bst.yearly });
                                                                setShowSetExPriceModel(true)
                                                            }} >set exception price</button> : <button className="pull-right adminButton" onClick={() => {
                                                                setModalMode('modify')
                                                                setProductPrice({ targetAudienceId: bst.targetAudienceId, productPriceId: -1, offer: bst.offer, offerName: bst.offerName, stateName: bst.stateName, monthly: bst.monthly, yearly: bst.yearly });
                                                                setShowSetExPriceModel(true)
                                                            }}>modify</button>}
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                        <SetExceptionPricing />
                                    </div>


                                    <div className="pagination pb-3">
                                        <button className="prev-btn"
                                            onClick={() => setPage((prev) => prev - 1)}
                                            disabled={page === 1 || isFetching}
                                        >
                                            {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-left"></i>}
                                        </button>
                                        <button className="next-btn"
                                            onClick={() => setPage((prev) => prev + 1)}
                                            disabled={page >= dataSubscription.totalPages || isFetching}
                                        >
                                            {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-right"></i>}
                                        </button>
                                        <div className="page">
                                            {`${page} / ${dataSubscription.totalPages}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }



        </>
    )
}





export default BannerAdPrice