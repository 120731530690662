import React, { useState } from "react";
import { useGetPackageQuery, useGetSubscriptionDetailQuery, useSaveProductPriceMutation, useGetProductPricesByProductQuery } from '../hooks/apiSlice';
import moment from 'moment'
import { DateRangeFilter } from '../shared/DateRangeFilter';
import BoostInvoice from './../shared/BoostInvoice';
import Modal from 'react-bootstrap/Modal';
import { isNumberKey } from '../hooks/util';

const Package = () => {

  const [page, setPage] = useState(1)
  const [dateRange, setDateRange] = useState('2021-01-01 - 2025-09-04');
  const [includeActive, setIncludeActive] = useState(true);
  const [includeExpired, setIncludeExpired] = useState(false);

  const [sortField, setSortField] = useState("SubscriptionId");
  const [order, setOrder] = useState("desc");

  const { data: dataSubscription, isLoading: isLoadingSubscription, isFetching } = useGetPackageQuery({ dateRange: dateRange, includeInactive: includeActive, includeExpired: includeExpired, sortColumn: sortField, sortDirection: order, pageNum: page })
  const { data: dataDefaultProductPrice, isLoading: isLoadingDefaultProductPrice } = useGetProductPricesByProductQuery(4)

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [selectedSubscription, setSelectedSubscription] = useState();

  const handleSearch = () => {
    setIncludeActive(document.getElementById('showAciveBoost').checked);
    setIncludeExpired(document.getElementById('showExpiredBoost').checked);
    setDateRange(document.getElementById('txtDateRange').value);
    setPage(1);
  }

  const handleSortingChange = (accessor) => {
    setSortField(accessor);
    setOrder(order === "asc" ? "desc" : "asc");
    handleSearch();
  };


  function getColumnHeader(columnName, sortString) {
    const arrowDirection = sortField === sortString && order === "asc" ? <i className="fas fa-arrow-up"></i> : sortField === sortString && order === "desc" ? <i className="fas fa-arrow-down"></i> : ""

    return (
      <span onClick={() => handleSortingChange(sortString)}> {isFetching ? <span className="ms-2 spinner-border spinner-border-sm"></span> : columnName} {arrowDirection}</span>
    )

  }

  const [open, setOpen] = useState(false);

  const onChange = (ranges) => {
    if (moment(ranges.startDate).format("MM-DD-YYYY") !== moment(ranges.endDate).format("MM-DD-YYYY")) {
      setOpen(false);
    } else if (ranges.startDate === "" && ranges.endDate === "") {
      setOpen(false);
    }
  };

  const [showSetDefPriceModel, setShowSetDefPriceModel] = useState(false);
  const [saveProductPrice] = useSaveProductPriceMutation()

  function SetDefaultPricing() {
    const [inputs, setInputs] = useState([]);

    const handleChange = (event) => {
      const tempBlogs = [...inputs];
      const index = tempBlogs.findIndex((temp) => temp.productPriceId === event.target.name);
      if (index >= 0) {
        tempBlogs[index]['price'] = event.target.value;
        setInputs(tempBlogs);
      }
      else {
        setInputs(oldArray => [...oldArray, { 'productPriceId': event.target.name, 'price': event.target.value }]);
      }
    }


    const handleSubmit = () => {
      inputs.map((u) => {
        if (u.price !== '')
          saveProductPrice({ 'productPriceId': u.productPriceId, 'price': u.price });
      })
      setShowSetDefPriceModel(false);
    }

    return (
      <Modal show={showSetDefPriceModel} >
        <Modal.Header>
          <Modal.Title><i className="fas fa-cog"></i>&nbsp;PRICING</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoadingDefaultProductPrice ? "Loading..." :
            <div className="row">
              <div className="col-6">
                <div className="row pb-3 text-center">
                  <b>MONTHLY</b>
                </div>

                <div className="row">
                  <div className="col">
                    DELUX
                  </div>
                  {dataDefaultProductPrice.payload.filter((item) => item.productPlan.duration === 'Monthly' && item.productPlan.productPlanName === 'DELUX').map((p, index) =>
                    <div className="col" key={index}>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input type="text" name={p.productPriceId} className="form-control" defaultValue={p.price} onKeyDown={(e) => isNumberKey(e)}
                          onBlur={handleChange} maxLength="3" />
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col">
                  STANDARD
                  </div>
                  {dataDefaultProductPrice.payload.filter((item) => item.productPlan.duration === 'Monthly' && item.productPlan.productPlanName === 'STANDARD').map((p, index) =>
                    <div className="col" key={index}>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input type="text" name={p.productPriceId} className="form-control" defaultValue={p.price} onKeyDown={(e) => isNumberKey(e)}
                          onBlur={handleChange} maxLength="3" />
                      </div>
                    </div>)}
                </div>
                <div className="row">
                  <div className="col">
                    BASIC
                  </div>

                  <div className="col">
                    <div className="input-group mb-3">
                      FREE
                    </div>
                  </div>
                </div>




              </div>
              <div className="col-6 ps-3">
                <div className="row pb-3 text-center">
                  <b>YEARLY</b>
                </div>

                <div className="row">
                  <div className="col">
                  DELUX
                  </div>
                  {dataDefaultProductPrice.payload.filter((item) => item.productPlan.duration === 'Yearly' && item.productPlan.productPlanName === 'DELUX').map((p, index) =>
                    <div className="col" key={index}>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input type="text" name={p.productPriceId} className="form-control" defaultValue={p.price} onKeyDown={(e) => isNumberKey(e)}
                          onBlur={handleChange} maxLength="3" />
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col">
                  STANDARD
                  </div>
                  {dataDefaultProductPrice.payload.filter((item) => item.productPlan.duration === 'Yearly' && item.productPlan.productPlanName === 'STANDARD').map((p, index) =>
                    <div className="col" key={index}>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input type="text" name={p.productPriceId} className="form-control" defaultValue={p.price} onKeyDown={(e) => isNumberKey(e)}
                          onBlur={handleChange} maxLength="3" />
                      </div>
                    </div>)}
                </div>
                <div className="row">
                  <div className="col">
                    BASIC
                  </div>
                  <div className="col">
                    <div className="input-group mb-3">
                      FREE
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button className="adminButton" onClick={() => { handleSubmit() }}>
            Save
          </button>
          <button className="adminButton" onClick={() => { setShowSetDefPriceModel(false) }}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <>
      {isLoadingSubscription ? "" :
        <div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10">
                <h4><b><i className="fas fa-cube"></i>&nbsp;Package Subscription</b></h4>
              </div>
              <div className="col-md-2 text-end">
                <button style={{ 'fontSize': '0.7rem', 'fontWeight': '600' }} className="pull-right adminButton" onClick={() => {
                  setShowSetDefPriceModel(true);
                }}><i className="fas fa-cog"></i>&nbsp;pricing</button>
              </div>
              <SetDefaultPricing />
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="strpied-tabled-with-hover card">
                  <div className="card-header"><div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-2"><div>
                          <DateRangeFilter onChange={onChange} open={open} setOpen={setOpen} />
                        </div>
                        </div>
                        <div className="col-md-3">
                          &nbsp;
                        </div>
                        <div className="col"><div className="d-flex justify-content-end">
                          <input type="checkbox" className="form-check-input" style={{ width: '1.2em', height: '1.2em' }} defaultChecked="true" id="showAciveBoost"></input>
                          <label className="form-check-label ps-1" htmlFor="showAciveBoost">Active</label>
                          &nbsp;&nbsp;&nbsp;
                          <input type="checkbox" className="form-check-input" style={{ width: '1.2em', height: '1.2em' }} id="showExpiredBoost"></input>
                          <label className="form-check-label ps-1" htmlFor="showExpiredBoost">Expired</label>
                        </div>
                        </div>
                        <div className="col-2 d-flex justify-content-end">
                          <button type="submit" className="pull-right adminButton" onClick={handleSearch}>
                            <i className="fa-sharp fa-solid fa-magnifying-glass"></i>&nbsp;SEARCH
                          </button>
                        </div>
                      </div>
                      <div className="clearfix"></div>

                    </div>
                  </div>
                  </div>
                  <div className="table-full-width table-responsive px-0 card-body">

                    <table className="table-hover table-striped table">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>{getColumnHeader('Company/Address', 'CompanyName')}</th>
                          <th>{getColumnHeader('Package', 'ProductPlanName')}</th>
                          <th>{getColumnHeader('Payment Detail', 'paymentOptionName')}</th>
                          <th>{getColumnHeader('Purchase Date', 'PurchaseDate')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataSubscription.data.map((bst, index) =>
                          <tr key={index}>
                            <td className="text-center subscription-ico"><i className="fas fa-cube fs-3" title={bst.subscriptionId}></i></td>
                            <td><div className="row"><div className="col-auto"><img src={bst.logoFileName ? process.env.REACT_APP_BASE_URL + "MediaStorage/logo/" + bst.logoFileName : '/images/DefaultCaterer.png'} alt="" style={{ 'height': '30px', 'width': '30px' }} className="bg-info rounded-circle" /></div><div className="col p-0"><a href={'../company-detail/' + bst.companyId + '/' + (bst.locationSlug === '' ? 'none' : bst.locationSlug) + '/' + bst.companySlug} target="_blank" style={{ 'textDecoration': 'none' }}>{bst.companyName}</a><br /><span style={{ 'fontSize': '11px' }}>{bst.companyAddress}</span></div></div></td>
                            <td>{bst.productPlanName}&nbsp;{bst.duration}</td>
                            <td className="text-center"><span className="admin-paymentOption-badge"><img src={'/images/' + bst.paymentOptionImage} style={{ 'maxHeight': '20px' }} alt={bst.paymentOptionName} ></img>&nbsp;${bst.amount}</span></td>
                            <td><i className="far fa-calendar-alt"></i>&nbsp;{moment(bst.purchaseDate).format("MMMM DD, YYYY")}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                  </div>


                  <div className="pagination pb-3">
                    <button className="prev-btn"
                      onClick={() => setPage((prev) => prev - 1)}
                      disabled={page === 1 || isFetching}
                    >
                      {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-left"></i>}
                    </button>
                    <button className="next-btn"
                      onClick={() => setPage((prev) => prev + 1)}
                      disabled={page >= dataSubscription.totalPages || isFetching}
                    >
                      {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-right"></i>}
                    </button>
                    <div className="page">
                      {`${page} / ${dataSubscription.totalPages}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }


      <Modal size="lg" show={showModal} >
        <Modal.Header>
          <Modal.Title>
          </Modal.Title>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
            setShowModal(false)
          }}></button>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-body">
              <div className="mb-2*">
                {modalContent === 'Modify Detail' ? '' : modalContent === 'Invoice' ? <BoostInvoice sub={selectedSubscription} /> : modalContent === 'Target Audience' ? <TargetAudience sub={selectedSubscription} /> : '<Boost />'}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-dark" onClick={() => {
            setShowModal(false)
          }}>Close</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}


const TargetAudience = (props) => {

  const [subscriptionId, setSubscriptionId] = useState(props.sub);
  const { data: dataSubscriptionDetail, isLoading: isLoadingSubscriptionDetail } = useGetSubscriptionDetailQuery(subscriptionId);

  return (
    <>
      {!isLoadingSubscriptionDetail &&
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Offer Type</th>
              <th>Offer Name | State</th>
              <th>Period / Price</th>
              <th>Expiry</th>
              <th>Impressions</th>
            </tr>
          </thead>
          <tbody>
            {dataSubscriptionDetail.payload.map((sub, index) =>
              <tr key={index}>
                <td>{sub.targetAudience.offer}</td>
                <td>{sub.targetAudience.offerName} | {sub.targetAudience.state.stateName}</td>
                <td>{sub.productPlan.duration} / ${sub.price}</td>
                <td>{sub.productPlan.subscriptionPlan === 1 ? new Date(new Date(props.sub.startDate).setMonth(new Date(props.sub.startDate).getMonth() + 1) - 1).toLocaleDateString() : new Date(new Date(props.sub.startDate).setFullYear(new Date(props.sub.startDate).getFullYear() + 1) - 1).toLocaleDateString()}</td>
                <td>{parseInt(Math.random() * (100 - 10) + 10)}</td>
              </tr>)}
          </tbody>
        </table>
      }
    </>
  )
}


export default Package