

// function ProductExists(ary, uniqueId){
//   var result;
//   ary.forEach(entry => {
//     result = entry.productDetail.filter(x => x.uniqueId === uniqueId).length > 0
//   })
//   return result;
// }

const ProductExists = (ary, uniqueId) => {
  var result = false;
  ary.forEach((entry, index, arr) => {
    if (entry.productDetail.filter(x => x.uniqueId === uniqueId).length > 0) {
      entry.length = index + 1;
      result = true;
    }
  })
  return result;
}

const ProductExists2 = (ary, uniqueId) => {
  var result;

  result = ary.filter(x => x.uniqueId === uniqueId).length > 0

  return result;
}


let v = (ary, uniqueId) =>
  ary.forEach(entry => {
    return entry.productDetail.filter(x => x.uniqueId === uniqueId).length > 0
  })





const CartReducer = (state, action) => {
  if (action.type === "Add-TO-CART") {
    let localCartData = JSON.parse(localStorage.getItem("CartItems"));
    if (localCartData === null) {
      localStorage.setItem("CartItems", JSON.stringify([action.payload]));
    }
    else {
      //   if (function () {
      //     localCartData.forEach(entry => {
      //       return entry.productDetail.filter(x => x.uniqueId === action.payload.productDetail[0].uniqueId).length > 0
      //     })
      // }) {
      if (ProductExists(localCartData, action.payload.productDetail[0].uniqueId) === false) {
        //if (ProductExists2(localCartData.filter((item) => item.productId === action.payload.productId)[0].productDetail, action.payload.productDetail[0].uniqueId) === false) {
        //if (localCartData.productDetail.filter((item) => item.uniqueId === action.payload.uniqueId).length === 0) {

        if (localCartData.filter((item) => item.subscriptionTypeId === action.payload.subscriptionTypeId).length === 0) {
          localCartData.push(action.payload)
        }
        else {
          localCartData.filter((item) => item.subscriptionTypeId === action.payload.subscriptionTypeId)[0].productDetail.push(action.payload.productDetail[0])
        }
        localStorage.setItem("CartItems", JSON.stringify(localCartData));
        //localCartData.push(action.payload)
        //localStorage.setItem("CartItems", JSON.stringify(localCartData));
      }
    }
    state = action.payload;
    return state;
  }
  else if (action.type === "GET-CART") {
    let localCartData = localStorage.getItem("CartItems");
    if (localCartData === 'undefined') {
      return [];
    } else {
      return JSON.parse(localCartData);
    }
  }
  else if (action.type === "DEL-FROM-CART") {
    let localCartData = localStorage.getItem("CartItems");
    if (localCartData !== 'undefined') {
      let cartItems = JSON.parse(localCartData);

      if (ProductExists(cartItems, action.payload)) {

        cartItems.forEach(function (o) {
          o.productDetail = o.productDetail.filter(c => c.uniqueId !== action.payload);
        })

        cartItems.forEach(function (o, index, object) {
          if (o.productDetail.length === 0)
            object.splice(index, 1);
        })

        localStorage.setItem("CartItems", JSON.stringify(cartItems));
      }
      return cartItems;
    }
  }
};




export default CartReducer;


